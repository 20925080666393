import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ApiService } from '../../services/api.service';
import { ShopDefinition } from '../../classes/shop-definition';
import { ShopService } from '../../services/shop.service';
import { AuthService } from '@work4all/api';
import { Router, ActivatedRoute } from '@angular/router';
import { PageType } from '../../enums/page-type.enum';
import { PageComponentType } from '../../enums/page-component-type.enum';
import { PageComponentContentOrientation } from '../../enums/page-component-content-orientation.enum';
import { PageComponentContentAlign } from '../../enums/page-component-content-align.enum';
import { PageComponent } from '../../classes/page-component';
import { PageDefinition } from '../../classes/page-definition';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {

    shopDefinitions: ShopDefinition[];
    loggedUser: any = null;
    ansprechpartner: any = null;
    currentShopDefinition: ShopDefinition;
    page: number = 0;
    PageType = PageType
    PageComponentType = PageComponentType
    PageComponentContentOrientation = PageComponentContentOrientation
    PageComponentContentAlign = PageComponentContentAlign
    editComponentIndex: number = null
    marginPaddingHover: boolean = false;
    editPage: boolean = false;
    backupShopDefinition: ShopDefinition

    constructor(public dialog: MatDialog, protected apiService: ApiService, protected shopService: ShopService, protected authService: AuthService, protected router: Router, protected route: ActivatedRoute) {
        if (!this.authService.isAuthenticated()) {
            localStorage.setItem('RequestedUrl', window.location.pathname);
            localStorage.setItem('RequestedUrlPath', window.location.search);
            this.router.navigate(['/login']);
        }
        else {
        }
    }

    ngOnInit() {
        console.log("init", this)
        localStorage.removeItem("w4aShopSelectedProjekt")

        if (localStorage.getItem("ansprechpartner") != undefined && localStorage.getItem("ansprechpartner") != null) {
            this.ansprechpartner = JSON.parse(localStorage.getItem("ansprechpartner"))

        } else {
            this.apiService.setLoggedUser()
            this.loggedUser = this.apiService.getLoggedUser()

        }

        this.shopService.ShopDefinitions.subscribe(data => {
            this.shopDefinitions = data
        })
        this.shopService.CurrentShopDefinition.subscribe(data => {
            this.currentShopDefinition = data;

            this.route
            if (this.currentShopDefinition != null && this.currentShopDefinition != undefined) {
                if (!this.currentShopDefinition.startseiteDefinition) {
                    this.currentShopDefinition.startseiteDefinition = new PageDefinition()
                }
                if (this.route.url["value"].length == 0) {
                    switch (this.currentShopDefinition.startseite) {
                        case PageType.ArtikelSeite: this.router.navigate(['/artikel']); break;
                        case PageType.ProjektSeite: this.router.navigate(['/veranstaltungen']); break;
                        case PageType.BestellungenSeite: this.router.navigate(['/bestellungen']); break;
                        default: break;
                    }
                }
            }
        })

        this.shopService.setShopDefinition()
    }

    addPageComponent() {
        let component = new PageComponent(PageComponentType.ImageTextBox)
        component.content.title = "Ihr Titel"
        component.content.text = "Ihr Text"
        this.currentShopDefinition.startseiteDefinition.components.push(component)
        this.editComponentIndex = this.currentShopDefinition.startseiteDefinition.components.length - 1
    }

    deletePageComponent(componentIndex) {
        this.currentShopDefinition.startseiteDefinition.components.splice(componentIndex, 1)
        this.editComponentIndex = 0
    }

    moveComponentUp(componentIndex) {
        if (componentIndex > 0) {
            this.arrayMove(componentIndex, componentIndex - 1)
            this.editComponentIndex--
        }
    }

    moveComponentDown(componentIndex) {
        if (componentIndex < this.currentShopDefinition.startseiteDefinition.components.length - 1) {
            this.arrayMove(componentIndex, componentIndex + 1)
            this.editComponentIndex++
        }
    }

    arrayMove(old_index, new_index) {
        if (new_index >= this.currentShopDefinition.startseiteDefinition.components.length) {
            var k = new_index - this.currentShopDefinition.startseiteDefinition.components.length + 1;
            while (k--) {
                this.currentShopDefinition.startseiteDefinition.components.push(undefined);
            }
        }
        this.currentShopDefinition.startseiteDefinition.components.splice(new_index, 0, this.currentShopDefinition.startseiteDefinition.components.splice(old_index, 1)[0]);

    };

    getComponentTypeString(type: PageComponentType): string {
        let result = ""
        switch (type) {
            case PageComponentType.ArtikelSlider: result = "Artikel-Slider"; break;
            case PageComponentType.ImageTextBox: result = "Bild-/Text-Box"; break;
            case PageComponentType.MeineBestellungenList: result = "Bestellungen-Widget"; break;
            case PageComponentType.VeranstaltungenList: result = "Veranstaltungen-Widget"; break;
            default: break;
        }

        return result
    }

    selectedIndex: number = 0;

    updateWidth(editComponentIndex) {

        this.editPage = false
        this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.lg = !this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.lg
        this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.md = !this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.md
        this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.sm = !this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.sm
        this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.xs = !this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.xs

        setTimeout(() => {
            this.editPage = true
            this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.lg = !this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.lg
            this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.md = !this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.md
            this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.sm = !this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.sm
            this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.xs = !this.currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.xs
            this.selectedIndex = 3
        }, 1)

    }

    backup() {
        this.backupShopDefinition = new ShopDefinition("")
        $.extend(true, this.backupShopDefinition, this.currentShopDefinition)
    }

    rollback() {
        $.extend(true, this.currentShopDefinition, this.backupShopDefinition)
        this.shopService.saveShopDefinitions()
    }

    save() {
        this.shopService.updateShopDefinition(this.currentShopDefinition)
    }

    logout() {
        localStorage.removeItem("loginLevel")
        localStorage.removeItem("apiUrl")
        localStorage.removeItem("w4aShopWarenkorb")
        localStorage.removeItem("setting_ShopDefinition")
        this.authService.logout();
        //location.reload();
    }

    getObjecTypeString(objType) {
        let result = this.apiService.getObjectTypeString(objType)
        if (result == "Bedarfsanforderung")
            result = "Bedarf"
        return result
    }

    toUpperCase(value): string {
        return value.toUpperCase()
    }



}
import { OrderDefinition } from "./order-definition";
import { FormDefinition } from "./form-definition";
import { PageType } from "../enums/page-type.enum";
import { PageDefinition } from "./page-definition";
import { LinkDefinition } from "./link-definition";

export class ShopDefinition {
    id: string = "";
    name: string = "";
    primaryColor: string = "#499ce0"
    secondaryColor: string = ""
    backgroundImagePath: string = ""
    logoImagePath1: string = ""
    logoImagePath2: string = ""
    logo1Height: number = 30;
    logo1MarginTop: number = 0;
    logo1MarginLeft: number = 0;
    logo1MarginRight: number = 0;
    logo1MarginBottom: number = 0;
    logo2Height: number = 30;
    logo2MarginTop: number = 0;
    logo2MarginLeft: number = 0;
    logo2MarginRight: number = 0;
    logo2MarginBottom: number = 0;
    orderDefinitions: OrderDefinition[] = []
    displayPreise: boolean = true
    displayPreiseInEMail: boolean
    displayBestaende: boolean = true
    displayBestellungen: boolean = true;
    bestellungenNavigationTitle: string = "Meine Bestellungen"
    bestellungenNavigationTitleSingular: string = "Bestellung"
    bestellungenInsertable: boolean = true
    bestellungenPreiseEditable: boolean = false
    bestellungenMengeEditable: boolean = true
    bestellungenTexteEditable: boolean = false
    displayProjekte: boolean = false
    projekteInsertable: boolean = false
    projekteEditable: boolean = false
    projekteDeletable: boolean = false
    projekteNavigationTitle: string = "Veranstaltungen"
    projekteNavigationTitleSingular: string = "Veranstaltung"
    projekteFormDefinition: FormDefinition = new FormDefinition()
    standardProjektGruppeCode:number = 0
    displayInfofensterCodes: number[] = []
    hide: boolean = false
    startseite: PageType = PageType.StartSeite
    startseiteNavigationTitle: string = "Startseite"
    startseiteDefinition: PageDefinition = new PageDefinition()
    artikelNavigationTitle: string = "Artikel"
    artikelNavigationTitleSingular: string = "Artikel"
    displayArtikel: boolean = true
    footerLinks: LinkDefinition[] = []
    notificationNew: boolean = false;
    notificationEdit: boolean = false;
    notificationSenderName: string = ""
    notificationSenderEmail: string = ""
    notificationRecipientEmails: string = ""
    notificationSubject: string = "Ihre Bestellung ist eingegangen"
    notificationStartText: string = `
        <p>[ansprechpartner.briefanrede]</p>
        <p>Vielen Dank Für Ihre Bestellung!<br>
        Nachfolgend finden Sie Ihre Daten in Kopie:</p>
        <p>
        Veranstaltung
        [projekt.name]
        </p>
        <p>
        Zeitraum
        [projekt.start] - [projekt.ende]
        </p>
        <p>
        Positionen
        [bestellung.positionen]
        </p>
        <p>
        Mit freundlichem Grüßen
        </p>
    `
    notificationEndText: string = "Besuchen Sie uns bald wieder!"
    notificationAddPositions: boolean = true; 

    constructor(name: string) {
        this.name = name
        this.footerLinks = []
        this.displayInfofensterCodes = []
        this.orderDefinitions = []
    }
}

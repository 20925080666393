<div class="container p-default">
  <div class="row" *ngIf="!showDropBorder" (dragover)="allowDrop($event)" (drop)="dropped($event)">

    <div class="col-md-6">
      <h3>Dateien Hochladen</h3>
      <input type="file" ng2FileSelect [uploader]="uploader" multiple (change)="dropped($event)" /><br />
    </div>

    <div class="col-md-6" style="margin-bottom: 40px">
      <h3 style="margin-bottom: 0px;">Hochgeladene Dateien: </h3>
      <table class="table">
        <thead>
          <tr>
            <th width="50%">Name</th>
            <th *ngIf="currentUpload && currentUpload['item'] "></th>
            <th *ngIf="uploader.getNotUploadedItems().length > 1"></th>
            <th><span class="float-right">Aktion</span></th>
          </tr>
        </thead>
        <tbody *ngIf="order != undefined && anhaenge.length">
          <tr *ngFor="let item of anhaenge; let i = index">
            <td style="padding: 8px;">
              <strong>{{ item.dateiname }}</strong>
            </td>

            <td *ngIf="currentUpload && currentUpload['item'] " class="list-element" style="padding: 8px;">
              {{currentUpload['item']._file.name}}
              <mat-progress-bar mode="determinate" [value]="currentUpload['progress']"></mat-progress-bar>
            </td>

            <td *ngIf="uploader.getNotUploadedItems().length > 1" style="padding: 8px;">
              <small>+ {{uploader.getNotUploadedItems().length -1}} Datei
                <span *ngIf="uploader.getNotUploadedItems().length -1 > 1">en</span> in der
                Warteschleife</small>
            </td>

            <td style="padding: 8px;">
              <button mat-button class="float-right" (click)="deleteTmpAttachment(i)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td> 

          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-md-6">
    </div>
    <div class="col-md-6">
      <table class="table" *ngIf="order != undefined && order.anhaenge != undefined">
        <tbody>
          <tr *ngFor="let item of order.anhaenge; let i = index">
            <td style="padding: 8px;">
              <strong>{{ item.originalDateiname }}</strong>
            </td>
            <td style="padding: 8px;">
                <button mat-button class="float-right" (click)="deleteAttachement(item)">
                  <i class="fas fa-trash-alt"></i>
                </button> 
                <button mat-button class="float-right" (click)="downloadAttachement(item)">
                    <i class="fa fa-download"></i>
                </button>
            </td>
          </tr>

        </tbody>

      </table>
    </div>
  </div>

  <div class="col-md-12" style="height: 200px;" *ngIf="showDropBorder" (dragover)="allowDrop($event)">
    <div ng2FileDrop [uploader]="uploader" [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
      (fileOver)="fileOverBase($event)" class="well my-drop-zone text-center max-height" (onFileDrop)="dropped($event)">
      <h3>Datei per Maus hier ablegen</h3>
      <br>
      <mat-icon style="font-size: 70px;width: 100% !important;">cloud_upload</mat-icon>
    </div>
  </div>
</div>

import { ApiService } from './../../services/api.service';
import { ShopService } from './../../services/shop.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { W4aTreeNode } from './w4a-tree-node';
import { Angebot, Auftrag, Kalkulation, Bestellung, Bedarf } from '@work4all/api';

@Component({
  selector: 'app-w4a-tree',
  templateUrl: './w4a-tree.component.html',
  styleUrls: ['./w4a-tree.component.scss']
})
export class W4aTreeComponent implements OnInit {
  [x: string]: any;
  subGruppenOpen: boolean = false;
  @Input() activeNode: W4aTreeNode;
  @Input() treeNode: W4aTreeNode;

  @Output() codeSelected: EventEmitter<W4aTreeNode> = new EventEmitter()

  requiredArtikleGroups: number[] = [];
  artikleGroupCodes: number[] = [];
  artikelCodes: any[] = [];
  hasArtikleGroup: boolean = false;
  currentBeleg: Angebot | Auftrag | Kalkulation | Bestellung | Bedarf;

  constructor(protected shopService: ShopService, protected apiService: ApiService) {
    this.artikleGroupCodes = [];

    this.shopService.CurrentPflichtartikelGruppen.subscribe(data => {
      if(data){
        this.requiredArtikleGroups = data; 
      } else {
        this.requiredArtikleGroups = []
      }
    });
    this.shopService.CurrentArtikelGruppen.subscribe(data => {
      if (data) {
        this.artikleGroupCodes = data; 
      } else {
        this.artikleGroupCodes = [];
      }
    });

  }



  ngOnInit() {
  }

  nodeClicked(node) {
    this.subGruppenOpen = true;
    this.activeNode = node;
    this.codeSelected.emit(this.activeNode);
  }

  isMandatorySubNode(treeNode) {
    if (this.requiredArtikleGroups.indexOf(treeNode.code) >= 0 && this.artikleGroupCodes.indexOf(treeNode.code) < 0) {
      return true;
    } else {
      return false;
    }
  }

  checkSubNodes(treeNode) {
    if (this.requiredArtikleGroups.indexOf(treeNode.code) >= 0 && this.artikleGroupCodes.indexOf(treeNode.code) < 0) {
      return true;
    }

    let result = false;
    treeNode.subGruppen.some(element => {
      if (this.checkSubNodes(element)) {
        result = true;
        return;
      }
    });
    return result;
  }

  hasMandatorySubNode(treeNode) {
    let result = false;
    treeNode.subGruppen.some(element => {
      if (this.checkSubNodes(element)) {
        result = true;
        return;
      }
    });
    return result;
  }

}

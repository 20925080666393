/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./veranstaltung-auswahl.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../veranstaltungen/veranstaltungen.component.ngfactory";
import * as i7 from "../veranstaltungen/veranstaltungen.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../services/api.service";
import * as i10 from "../../services/shop.service";
import * as i11 from "@work4all/api";
import * as i12 from "./veranstaltung-auswahl.component";
var styles_VeranstaltungAuswahlComponent = [i0.styles];
var RenderType_VeranstaltungAuswahlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VeranstaltungAuswahlComponent, data: {} });
export { RenderType_VeranstaltungAuswahlComponent as RenderType_VeranstaltungAuswahlComponent };
export function View_VeranstaltungAuswahlComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "float-right"], ["mat-button", ""], ["style", "    position: relative;\nz-index: 999;"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "div", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-veranstaltungen", [], null, [[null, "confirm"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("confirm" === en)) {
        var pd_0 = (_co.confirm($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_VeranstaltungenComponent_0, i6.RenderType_VeranstaltungenComponent)), i1.ɵdid(4, 114688, null, 0, i7.VeranstaltungenComponent, [i8.MatDialog, i9.ApiService, i10.ShopService, i11.Config], { showDetails: [0, "showDetails"] }, { confirm: "confirm" })], function (_ck, _v) { var currVal_2 = false; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_VeranstaltungAuswahlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-veranstaltung-auswahl", [], null, null, null, View_VeranstaltungAuswahlComponent_0, RenderType_VeranstaltungAuswahlComponent)), i1.ɵdid(1, 114688, null, 0, i12.VeranstaltungAuswahlComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VeranstaltungAuswahlComponentNgFactory = i1.ɵccf("app-veranstaltung-auswahl", i12.VeranstaltungAuswahlComponent, View_VeranstaltungAuswahlComponent_Host_0, {}, {}, []);
export { VeranstaltungAuswahlComponentNgFactory as VeranstaltungAuswahlComponentNgFactory };

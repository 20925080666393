<div class="max-height relative" style="padding-top:72px">
  <div class="absolute p-default border-bottom" style="height:72;top:0;left:0;right:0">
    <div class="float-right">

      <mat-menu #positionMenu="matMenu" [overlapTrigger]="false">
        <div class="p-x-default">
          <table class="table table-hover">

            <ng-container *ngFor="let item of artikelList; let itemIndex = index">
              <tr>
                <td>{{item.menge}}{{setEinheit(item.artikel.einheitCode)}}</td>
                <td><strong>{{item.artikel.name}}</strong></td>
                <td>
                  <i class="fas fa-trash pointer" (click)="removeArtikel(item)"></i>
                </td>
              </tr>
            </ng-container>
          </table>
        </div>

      </mat-menu>

      <button class="hidden-xs narrow-btn" mat-button *ngIf="artikelList.length > 0" [matMenuTriggerFor]="positionMenu">
        {{artikelList.length}} Artikel ausgewählt
      </button>

      <button mat-raised-button [disabled]="noRequiredGroup" color="accent" class="hidden-xs narrow-btn" *ngIf="artikelList.length > 0" (click)="confirm()">
        Übernehmen
      </button>

      <button mat-button (click)="close()" class="narrow-btn">
        <i class="fas fa-times"></i>
      </button>

    </div>
    <strong>{{currentShopDefinition.artikelNavigationTitle}}</strong><br>
    <small>Treffen Sie Ihre Auswahl</small>
  </div>

  <div class="max-height hidden-xs" style="overflow-x:hidden;">
    <app-artikel class="max-height" (artikelSelected)="addArtikel($event)" [disableChangeProjekt]="true"></app-artikel>
  </div>

  <div class="max-height visible-xs" style="padding-bottom:64px">
    <app-artikel class="max-height" (artikelSelected)="addArtikel($event)" [disableChangeProjekt]="true"></app-artikel>
    <div class="absolute max-width border-top p-default" style="height:64px;background: whitesmoke;">
      <button class="narrow-btn col-xs-6" mat-button *ngIf="artikelList.length > 0" [matMenuTriggerFor]="positionMenu">
        {{artikelList.length}} Artikel 
      </button>

      <button mat-raised-button color="accent" [disabled]="noRequiredGroup" class="narrow-btn col-xs-6" *ngIf="artikelList.length > 0" (click)="confirm()">
        Übernehmen
      </button>

    </div>

  </div>
</div>
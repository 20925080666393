import { SucheDialogObjectType } from '../enums/suche-dialog-object-type.enum';

export class SucheDialogData {
    type: SucheDialogObjectType
    title: string = null

    constructor(type:SucheDialogObjectType, title:string = null){
        this.type = type
        if(title){
            this.title = title
        }
    }
}

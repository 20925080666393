export class PageComponentVisibility {
    lg:boolean
    md: boolean
    sm: boolean
    xs: boolean

    constructor(){
        this.lg = true
        this.md = true
        this.sm = true
        this.xs = true
    }
}

<div class="p-default border-bottom background-whitesmoke">
  <strong class="">{{data.title}}</strong>
</div>
<div class="p-default">
  <div [innerHtml]="data.message"> </div>
</div>
<div class="p-default align-right border-top">
  <button class=""  mat-button *ngFor="let button of data.buttons;let i = index"
    [style.width]="(100/data.buttons.length)+'%'" (click)="close(i)"  >
    {{button.title}}
  </button>
<div class="clearfix"></div>
</div>
  
  

import { Component, OnInit } from '@angular/core';
import { ShopDefinition } from '../../classes/shop-definition';
import { ObjectType, AuthService, Position } from '../../../../node_modules/@work4all/api';
import { ApiService } from '../../services/api.service';
import { MatDialog } from '../../../../node_modules/@angular/material';
import { ShopService } from '../../services/shop.service';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { ShopDefinitionDialogComponent } from '../shop-definition-dialog/shop-definition-dialog.component';
import { PageType } from '../../enums/page-type.enum';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    loggedUser: any = null;
    loginMode: number = 1;
    loginLevel: number = 1;
    shopMaster: boolean = false;
    ansprechpartner: any = null;
    currentShopDefinition: ShopDefinition = null;
    shopDefinitions: ShopDefinition[];
    warenkorb: Position[]
    openSideNav: boolean = false;
    PageType = PageType
    _shopService = this.shopService

    constructor(protected apiService: ApiService, protected authService: AuthService, protected router: Router, activatedRoute: ActivatedRoute,
        public dialog: MatDialog, protected shopService: ShopService) {

    }

    ngOnInit() {

        if (localStorage.getItem("ansprechpartner") != undefined && localStorage.getItem("ansprechpartner") != null) {
            this.ansprechpartner = JSON.parse(localStorage.getItem("ansprechpartner"))
        }
        else {
            this.loggedUser = this.apiService.getLoggedUser()
        }


        this.shopService.CurrentShopDefinition.subscribe(shopDefinition => {
            this.currentShopDefinition = shopDefinition

            if (this.currentShopDefinition) {
                $("html > head > title").text(this.currentShopDefinition.name)
            }
        })

        this.shopService.Warenkorb.subscribe(warenkorb => {
            this.warenkorb = warenkorb
        })

        this.shopService.ShopDefinitions.subscribe(data => {
            this.shopDefinitions = data
        })

        this.apiService.ShopMaster.subscribe(data => {
            this.shopMaster = data
        })

        this.apiService.LoginMode.subscribe(data => {
            this.loginMode = data
        })

        this.apiService.LoginLevel.subscribe(data => {
            this.loginLevel = data
        })



        this.shopService.getShopDefinitions()
        this.shopService.setShopDefinition()


    }
 

    openUserManagement(){
        this.router.navigate(["/users"])
    }

    getBackgroundImagePath() {
        return this.currentShopDefinition.backgroundImagePath != null && this.currentShopDefinition.backgroundImagePath.length > 0 ? 'url(' + this.currentShopDefinition.backgroundImagePath + ')' : ""
    }

    setShopDefinition(index) {
        //this.router.navigate(['/shop']);
        this.shopService.setShopDefinition(index)
    }

    navigateToShop() {
        //this.router.navigate(["/shop"])
    }

    setLoginMode(value) {
        this.apiService.setLoginMode(value)
    }

    logout() {
        localStorage.removeItem("loginLevel")
        localStorage.removeItem("apiUrl")
        localStorage.removeItem("w4aShopWarenkorb")
        localStorage.removeItem("setting_ShopDefinition")
        localStorage.removeItem("shopRechte")
        localStorage.removeItem("loginId") 

        this.shopService.setShopDefinition(0)
        this.authService.logout();
        //location.reload();
    }

    getObjectTypeString(objectType: ObjectType, plural: boolean = false): string {
        return this.apiService.getObjectTypeString(objectType, plural);
    }

    openShopDefinitionDialog(): void {
        let dialogRef = this.dialog.open(ShopDefinitionDialogComponent, {

            autoFocus: false,
            disableClose: false
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

}

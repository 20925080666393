import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from '../../services/api.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { List } from 'linqts';

@Component({
  selector: 'mail-configuration-dialog',
  templateUrl: './mail-configuration-dialog.component.html',
  styleUrls: ['./mail-configuration-dialog.component.scss']
})
export class MailConfigurationDialogComponent implements OnInit {

  availableEmailList: string[] = []
  chosenEmails: string[] = []
  emailControl = new FormControl();
  filteredEmailList: Observable<string[]>;

  constructor(public dialogRef: MatDialogRef<MailConfigurationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, protected apiService: ApiService) {
    this.getAnsprechpartner()
  }

  async getAnsprechpartner() {
    let ansprechpartner = null
    if (localStorage.getItem("ansprechpartner")) {
      ansprechpartner = JSON.parse(localStorage.getItem("ansprechpartner"))
    }

    if (ansprechpartner) {
      let availableAnsprechpartnerList = await this.apiService.getAnsprechpartnerByKundeCode(ansprechpartner.kundenCode)
      this.availableEmailList = new List(availableAnsprechpartnerList).Select(x => x.eMail).ToArray()
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  } 

  close(): void {
    this.dialogRef.close(this.chosenEmails);
  }

  ngOnInit() {
    this.filteredEmailList = this.emailControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(name: string): string[] {
    const filterValue = name.toLowerCase();

    return this.availableEmailList.filter(option => option.toLowerCase().includes(filterValue));
  }

  
  addEmail() {
    if (this.validateEmail(this.emailControl.value)) {
      this.chosenEmails.push(this.emailControl.value)
      this.emailControl.setValue("")
    }
  }

  removeEmail(index: number) {
    this.chosenEmails.splice(index, 1)
  }

}
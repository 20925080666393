import { List } from 'linqts';
import { W4aTreeNode } from './../w4a-tree/w4a-tree-node';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AuthService, ObjectType, Position, Config, SendMailRequest, Projekt, Anhaenge, Ansprechpartner, Artikel, Angebot, Auftrag, Kalkulation, Bedarf, Bestellung } from '../../../../node_modules/@work4all/api';
import { ShopService } from '../../services/shop.service';
import { ShopDefinition } from '../../classes/shop-definition';
import { OrderDefinition } from '../../classes/order-definition';
import { MatDialog } from '@angular/material';
import { ArtikelDialogComponent } from '../artikel-dialog/artikel-dialog.component';
import { VeranstaltungAuswahlComponent } from '../veranstaltung-auswahl/veranstaltung-auswahl.component';
import { Router, ActivatedRoute } from '@angular/router';
import { EditableType } from '../../enums/editable-type.enum';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { MailConfigurationDialogComponent } from '../../dialogs/mail-configuration-dialog/mail-configuration-dialog.component';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { CurrencyFormatPipe } from '../../pipes/currency-format.pipe';

@Component({
  selector: 'app-meine-bestellungen',
  templateUrl: './meine-bestellungen.component.html',
  styleUrls: ['./meine-bestellungen.component.scss']
})
export class MeineBestellungenComponent implements OnInit, OnDestroy {

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent

  subscribers: Subscription[] = []

  loggedUser: any = null;
  currentShopDefinition: ShopDefinition;

  loading: boolean = false;
  kundenCode: number;

  orders: any[] = [];

  anhenge: any[] = [];
  tmpAnhaenge: any[] = [];

  deletedPositions: any[] = [];

  openOrderIndex: number = null;
  openOrderDefinition: OrderDefinition = null;
  editOpenOrder: boolean = false;

  neuePositionen: Position[] = [];
  ObjectType = ObjectType;
  EditableType = EditableType

  fieldsValidated: boolean = true;
  ansprechpartner: any = null;

  artikelGruppen: W4aTreeNode = {
    code: 0,
    index: 0,
    level: 0,
    name: "",
    subGruppen: [],
  };

  artikleGroups: number[] = [];
  noRequiredGroup: boolean = false;
  requiredArtikleGroups: number[] = [];

  projekteFormControl = new FormControl();

  dateVon = new FormControl(moment());
  dateBis = new FormControl(moment().add(90, 'days'));
  artikelliste: Artikel[] = []

  _apiService = this.apiService

  firstLoaded: boolean = false

  //openOrderCreatorLogin: ManagedExternalLogin = null
  searchText: string = ""

  currentBeleg: any = null

  additionalBestaende = {}

  constructor(protected currencyFormat:CurrencyFormatPipe, protected authService: AuthService, protected apiService: ApiService, protected shopService: ShopService, public dialog: MatDialog, protected config: Config, protected router: Router, protected route: ActivatedRoute) {


  }

  getFirstLine(value: string): string {
    let result = value.split('|')[0]
    return result
  }

  getAfterFirstLine(value: string): string {
    let result = value.split('|')
    result.splice(0, 1)
    return result.join('|')
  }

  getOrderDefinitionById(id: string) {
    let result: OrderDefinition = null
    this.currentShopDefinition.orderDefinitions.some((orderDefinition: OrderDefinition, orderDefinitionIndex: number) => {
      if (orderDefinition.id == id) {
        result = orderDefinition
        return true
      }
    })
    return result;
  }

  toggleGenehmigung(bzObject: any) {
    bzObject.shopGenehmigt = !bzObject.shopGenehmigt
    bzObject.shopGenehmigtDatum = new Date()
    bzObject.shopGenehmigtDurchLoginID = this.apiService.loginId
    this.saveOpenOrderChanges(bzObject.shopGenehmigt)
  }

  editAllowed(): boolean {
    let result: boolean = false;
    let beleg = this.currentBeleg;
    if (this.currentShopDefinition.orderDefinitions[0].editable == EditableType.EditableUntilProjektStart && beleg.projekt != undefined) {
      let daysBeforeEnd: number = this.currentShopDefinition.orderDefinitions[0].editableUntil
      let endDate: Date = new Date(beleg.projekt.anfangDatum)

      let today = new Date();
      today.setHours(0, 0, 0)
      var timeDiff = endDate.getTime() - today.getTime();
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (diffDays > daysBeforeEnd) {
        result = true
      }
    }

    return result;
  }

  editAllowedList(order): boolean {
    let result: boolean = false;
    let beleg = order;
    if (this.currentShopDefinition.orderDefinitions[0].editable == EditableType.EditableUntilProjektStart && beleg.projekt != undefined) {
      let daysBeforeEnd: number = this.currentShopDefinition.orderDefinitions[0].editableUntil
      let endDate: Date = new Date(beleg.projekt.anfangDatum)

      let today = new Date();
      today.setHours(0, 0, 0)
      var timeDiff = endDate.getTime() - today.getTime();
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (diffDays > daysBeforeEnd) {
        result = true
      }
    }

    return result;
  }

  anhaengeUploaded(event) {
    this.tmpAnhaenge = (event);
  }

  saveOpenOrderChanges(genehmigung: boolean = null) {

    if (this.currentBeleg.code) {
      this.currentBeleg.positionen = this.currentBeleg.positionen.concat(this.deletedPositions);
    }

    this.deletedPositions = []
    this.loading = true

    if (this.currentBeleg.anhaenge == undefined) {
      this.currentBeleg.anhaenge = [];
    }

    this.currentBeleg.webShopOrderDefinitionData = this.currentShopDefinition.id

    if (this.loggedUser) {
      this.currentBeleg.benutzerCode = this.currentBeleg.benutzerCode2 = this.loggedUser.code
    }
    if (this.ansprechpartner && (this.currentBeleg.code == undefined || !this.currentBeleg.code)) {
      this.currentBeleg.createdByLoginId = this.ansprechpartner.loginId
    }

    this.currentBeleg = this.shopService.fillBzObject(this.currentBeleg, this.openOrderDefinition.formDefinition)

    if (this.currentBeleg.projekt != undefined && this.currentBeleg.projekt && this.currentBeleg.projekt.vonDatum && this.currentBeleg.projekt.bisDatum) {
      this.currentBeleg.positionen.forEach((position: Position) => {
        position.dispositionBegin = this.currentBeleg.projekt.vonDatum
        position.dispositionEnde = this.currentBeleg.projekt.bisDatum
      })
    }

    for (let i = 0; i < this.currentBeleg.positionen.length; i++) {
      const pos = this.currentBeleg.positionen[i];
      pos.index = i
    }


    let wert: number = 0


    this.currentBeleg.positionen.forEach(position => {
      if (!position.deleteOnUpdate)
        wert += position.anzahl * position.einzelpreis
    })

    this.currentBeleg.wert = wert



    setTimeout(() => {
      if (this.currentBeleg.code && this.currentBeleg.code != 0) {
        switch (this.currentBeleg.bzObjType) {
          case ObjectType.Angebot:
            this.apiService.updateAngebot(this.currentBeleg, async () => {
              this.setAnhaenge()
              let beleg = await this.apiService.getAngebot(this.currentBeleg.code)
              this.closeOpenOrder();
              if (this.currentShopDefinition.notificationEdit)
                await this.sendConfirmationMail(beleg, genehmigung)
            }, (err) => { console.log(err) });
            break;

          case ObjectType.Auftrag:
            this.apiService.updateAuftrag(this.currentBeleg, async () => {
              this.setAnhaenge()
              let beleg = await this.apiService.getAuftrag(this.currentBeleg.code)
              this.closeOpenOrder();
              if (this.currentShopDefinition.notificationEdit)
                await this.sendConfirmationMail(beleg, genehmigung)
            }, (err) => { console.log(err) });
            break;

          case ObjectType.Bedarfsanforderung:
            this.apiService.updateBedarf(this.currentBeleg, async () => {
              this.setAnhaenge()
              let beleg = await this.apiService.getBedarf(this.currentBeleg.code)
              this.closeOpenOrder();
              if (this.currentShopDefinition.notificationEdit)
                await this.sendConfirmationMail(beleg, genehmigung)
            }, (err) => { console.log(err) });
            break;

          case ObjectType.Bestellung:
            this.apiService.updateBestellung(this.currentBeleg, async () => {
              this.setAnhaenge()
              let beleg = await this.apiService.getBestellung(this.currentBeleg.code)
              this.closeOpenOrder();
              if (this.currentShopDefinition.notificationEdit)
                await this.sendConfirmationMail(beleg, genehmigung)
            }, (err) => { console.log(err) });
            break;

          case ObjectType.Kalkulation:
            this.apiService.updateKalkulation(this.currentBeleg, async () => {
              this.setAnhaenge()
              let beleg = await this.apiService.getKalkulation(this.currentBeleg.code)
              this.closeOpenOrder();
              if (this.currentShopDefinition.notificationEdit)
                await this.sendConfirmationMail(beleg, genehmigung)
            }, (err) => { console.log(err) });
            break;

          default: this.closeOpenOrder(); break;

        }
      }
      else {
        this.currentBeleg.hauptadresseCompanyCode = this.shopService.getSdObjmemberCode()
        this.currentBeleg.hauptadresseCompanyType = this.shopService.getSdObjmemberType()

        this.currentBeleg.webShopOrderDefinitionData = this.currentShopDefinition.id
        if(this.ansprechpartner)
        this.currentBeleg.hauptadresseApCode =  this.ansprechpartner.code

        if (this.loggedUser) {
          this.currentBeleg.benutzerCode = this.currentBeleg.benutzerCode2 = this.loggedUser.code
        }
        if (this.ansprechpartner) {
          this.currentBeleg.createdByLoginId = this.ansprechpartner.loginId
        }
        switch (this.currentBeleg.bzObjType) {
          case ObjectType.Angebot:
            this.apiService.insertAngebot(this.currentBeleg, async (code) => {
              let beleg = await this.apiService.getAngebot(code)
              this.setAnhaenge()
              this.closeOpenOrder();
              if (this.currentShopDefinition.notificationNew)
                await this.sendConfirmationMail(beleg, genehmigung)
            }, (err) => { console.log(err) });
            break;

          case ObjectType.Auftrag:
            this.apiService.insertAuftrag(this.currentBeleg, async (code) => {
              let beleg = await this.apiService.getAuftrag(code)
              this.setAnhaenge()
              this.closeOpenOrder();
              if (this.currentShopDefinition.notificationNew)
                await this.sendConfirmationMail(beleg, genehmigung)
            }, (err) => { console.log(err) });
            break;

          case ObjectType.Bedarfsanforderung:
            this.apiService.insertBedarf(this.currentBeleg, async (code) => {
              let beleg = await this.apiService.getBedarf(code)
              this.setAnhaenge()
              this.closeOpenOrder();
              if (this.currentShopDefinition.notificationNew)
                await this.sendConfirmationMail(beleg, genehmigung)
            }, (err) => { console.log(err) });
            break;

          case ObjectType.Bestellung:
            this.apiService.insertBestellung(this.currentBeleg, async (code) => {
              let beleg = await this.apiService.getBestellung(code)
              this.setAnhaenge()
              this.closeOpenOrder();
              if (this.currentShopDefinition.notificationNew)
                await this.sendConfirmationMail(beleg, genehmigung)
            }, (err) => { console.log(err) });
            break;

          case ObjectType.Kalkulation:
            this.apiService.insertKalkulation(this.currentBeleg, async (code) => {
              let beleg = await this.apiService.getKalkulation(code)
              this.setAnhaenge()
              this.closeOpenOrder();
              if (this.currentShopDefinition.notificationNew)
                await this.sendConfirmationMail(beleg, genehmigung)
            }, (err) => { console.log(err) });
            break;

          default: this.closeOpenOrder(); break;

        }
      }

    }, 200);

  }

  setAnhaenge() {
    this.tmpAnhaenge.forEach((anhang: Anhaenge) => {
      anhang.bzobjMemberCode = this.currentBeleg.code
      anhang.bzObjType = this.currentBeleg.bzObjType
      this.apiService.saveAnhangPermanently(anhang, anh => {
        anh.bzObjType = this.currentBeleg.bzObjType;
        anh.bzobjMemberCode = anhang.bzobjMemberCode;
        anh.dateiname = anhang.dateiname;
        this.currentBeleg.anhaenge.push(anh);
      }, error => {
        console.log(error)
      })
    }
    );
  }

  validateForm(formDefinition) {
    if (formDefinition != null) {
      this.openOrderDefinition.formDefinition = formDefinition;
      this.fieldsValidated = true;
    }
    else {
      this.fieldsValidated = false;
    }
  }

  cancelOpenOrderChanges() {
    this.shopService.setCurrentArtikelGruppen([])
    this.requiredArtikleGroups = []
    this.shopService.setCurrentBeleg(null)
    this.closeOpenOrder()
  }

  closeOpenOrder() {
    this.shopService.setCurrentArtikelGruppen([])
    this.requiredArtikleGroups = []
    this.shopService.setCurrentBeleg(null)

    this.openOrderIndex = null;
    this.editOpenOrder = false;
    this.currentBeleg = null

    this.orders = []
    this.loading = false
    this.additionalBestaende = {}
    this.getOrders()
  }

  orderIsEditable(): boolean {
    let result: boolean = false;

    let orderDefinition: OrderDefinition = this.getOrderDefinitionById(this.openOrderDefinition.id)

    if (orderDefinition && orderDefinition.editable) {
      result = true;
    }
    if (this.apiService.loginId && this.apiService.loginId !== this.currentBeleg.createdByLoginId && !this.apiService.shopRechte.bestellungenBearbeiten) {
      result = false;
    }

    return result
  }

  ngOnInit() {

    this.initComponent()

    /*this.router.events.subscribe((val) => {
      this.unsubscribeObservables()
    });*/

    this.subscribers.push(
      this.shopService.CurrentShopDefinition.subscribe(currentShopDefinition => {
        this.currentShopDefinition = currentShopDefinition

        if (this.currentShopDefinition != undefined && !this.currentShopDefinition.displayBestellungen && this.route.url["value"][0].path == "bestellungen") {
          this.router.navigate(["/"])
        }
        else {
          if (!this.firstLoaded && this.currentShopDefinition.orderDefinitions.length > 0) {
            this.firstLoaded = true
            this.getOrders()
          }
        }
      })
    )

  }

  async initComponent() {
    //await this.shopService.getShopDefinitions()
    await this.shopService.setShopDefinition()

    if (localStorage.getItem("ansprechpartner") != undefined && localStorage.getItem("ansprechpartner") != null) {
      this.ansprechpartner = JSON.parse(localStorage.getItem("ansprechpartner"))
      this.kundenCode = this.ansprechpartner.kundenCode;
    }
    else {
      this.loggedUser = this.apiService.getLoggedUser()
      this.kundenCode = this.config.get("kundenCode");
    }

  }

  sortByIndex(a, b) {
    if (a.index > b.index) {
      return 1;
    }
    if (a.index < b.index) {
      return -1;
    }
    // a muss gleich b sein
    return 0;
  }

  getFreigabeStatus() {

    let request: any[] = []

    this.orders.forEach(bestellung => {
      let requestItem = {
        item1: bestellung.bzObjType,
        item2: bestellung.code
      }
      request.push(requestItem)
    });

    this.apiService.getFreigabeStatus(request, freigaben => {
      if ($.isArray(freigaben)) {
        freigaben.forEach((freigabeStatus, freigabeIndex) => {
          this.orders.some((bestellung, bestellungIndex) => {
            if (freigabeStatus.requestBzObjMemberCode == bestellung.code) {
              bestellung.freigabeStatus = freigabeStatus
              return true
            }
          });

        });
      }

    }, error => {

    })
  }

  getObjecTypeString(objType) {
    let result = this.apiService.getObjectTypeString(objType)
    if (result == "Bedarfsanforderung")
      result = "Bedarf"
    return result
  }

  dataSource: any = null

  getAdditionalBelegData() {
    let projektCodes: number[] = []
    this.orders.forEach(beleg => {
      if (beleg.projektCode) {
        projektCodes.push(beleg.projektCode)
      }
    })

    this.apiService.getProjekteByCodes(projektCodes, projekte => {
      if (projekte) {
        this.orders.forEach(beleg => {
          projekte.some(projekt => {
            if (beleg.projektCode == projekt.code) {
              beleg.projekt = projekt
              beleg.projektName = projekt.name
              beleg.projektAnfangDatum = projekt.anfangDatum
              beleg.projektEndeDatum = projekt.endeDatum
              beleg.editable = this.editAllowedList(beleg)
              return true
            }
          })
        })
      }

      this.orders.forEach(beleg => {
        beleg.creator = this.getManagedExternalLoginByID(beleg.createdByLoginId)
        if (beleg.creator)
          beleg.creatorAnzeigeName = beleg.creator.vorname + " " + beleg.creator.name
      })

      this.dataSource = this.orders
      this.loading = false;
    })



  }

  ngOnDestroy() {
  }

  unsubscribeObservables() {

    for (let sub of this.subscribers) {
      sub.unsubscribe()
    }
  }

  getManagedExternalLoginByID(id: string) {
    let result = this.shopService.getManagedExternalLoginByID(id)
    return result
  }



  async getOrders() {

    await this.shopService.getManagedExternalLogins()

    this.orders = []
    let benutzerCode = null;

    if (this.loggedUser != null) {
      benutzerCode = this.loggedUser.code
    }

    let orderDefinition = this.currentShopDefinition.orderDefinitions[0];
    if (orderDefinition.active) {

      this.loading = true;
      switch (orderDefinition.formDefinition.bzObjType) {
        case ObjectType.Angebot: {
          this.apiService.getAngeboteByBenutzerCode(benutzerCode, this.currentShopDefinition.id, data => {
            data.forEach(bestellung => {
              bestellung.positionen.sort(this.sortByIndex)
            });
            this.orders = data;
            this.getAdditionalBelegData()
          }); break;
        }
        case ObjectType.Auftrag: {
          this.apiService.getAuftraegeByBenutzerCode(benutzerCode, this.currentShopDefinition.id, data => {
            data.forEach(bestellung => {
              bestellung.positionen.sort(this.sortByIndex)
            });
            this.orders = data
            this.getAdditionalBelegData()
          }); break;
        }
        case ObjectType.Bedarfsanforderung: {
          this.apiService.getBedarfsanforderungenByBenutzerCode(benutzerCode, this.currentShopDefinition.id, data => {
            data.forEach(bestellung => {
              bestellung.positionen.sort(this.sortByIndex)
            });
            this.orders = data
            this.getAdditionalBelegData()
            this.getFreigabeStatus()
          }); break;
        }
        case ObjectType.Bestellung: {
          this.apiService.getBestellungenByBenutzerCode(benutzerCode, this.currentShopDefinition.id, data => {
            data.forEach(bestellung => {
              bestellung.positionen.sort(this.sortByIndex)
            });
            this.orders = data
            this.getAdditionalBelegData()
            this.getFreigabeStatus()
          }); break;
        }
        case ObjectType.Kalkulation: {
          this.apiService.getKalkulationenByBenutzerCode(benutzerCode, this.currentShopDefinition.id, data => {
            data.forEach(bestellung => {
              bestellung.positionen.sort(this.sortByIndex)
            });
            this.orders = data
            this.getAdditionalBelegData()
          }); break;
        }
        default: break;
      }
    }
  }

  async createNewBeleg() {
    switch (this.currentShopDefinition.orderDefinitions[0].formDefinition.bzObjType) {
      case ObjectType.Angebot: this.currentBeleg = await this.apiService.createAngebotAsync(this.shopService.getSdObjmemberCode(), this.shopService.getSdObjmemberType()); break;
      case ObjectType.Auftrag: this.currentBeleg = await this.apiService.createAuftragAsync(this.shopService.getSdObjmemberCode(), this.shopService.getSdObjmemberType()); break;
      case ObjectType.Kalkulation: this.currentBeleg = await this.apiService.createKalkulationAsync(this.shopService.getSdObjmemberCode(), this.shopService.getSdObjmemberType()); break;
      case ObjectType.Bestellung: this.currentBeleg = await this.apiService.createBestellungAsync(this.shopService.getSdObjmemberCode(), this.shopService.getSdObjmemberType()); break;
      case ObjectType.Bedarfsanforderung: this.currentBeleg = await this.apiService.createBedarfAsync(this.shopService.getSdObjmemberCode(), this.shopService.getSdObjmemberType()); break;
      default: break;
    }
    return
    //this.currentBeleg.individualFieldData = []
  }

  async proceedNewBestellung() {

    await this.createNewBeleg()

    this.openOrderIndex = 0;
    this.openOrderDefinition = new OrderDefinition("");
    $.extend(true, this.openOrderDefinition, this.currentShopDefinition.orderDefinitions[0])
    this.openOrderDefinition.formDefinition = this.shopService.fillFormDefinition(this.currentBeleg, this.openOrderDefinition.formDefinition)
    this.editOpenOrder = true;

    this.currentBeleg.webShopOrderDefinitionData = this.currentShopDefinition.id;

    if (this.currentBeleg.bzObjType == ObjectType.Auftrag) {
      this.currentBeleg.auftragsDatum = new Date()
    }
    else {
      this.currentBeleg.datum = new Date()
    }

    this.currentBeleg.notiz = this.currentBeleg.hauptadresseText.substr(0, this.currentBeleg.hauptadresseText.indexOf("\r\n"))

    if (this.currentShopDefinition.displayProjekte) {
      this.openVeranstaltungAuswahlDialog()
    }
    else {
      this.openArtikelDialog()
    }

    this.loading = false;
  }

  async addOrder() {
    this.shopService.loadGroupCodes();
    //this.shopService.loadCurrentPflichtartikelGruppen();
    this.requiredArtikleGroups = await this.shopService.getCurrentPflichtartikelGruppen();
    this.loading = true;
    this.proceedNewBestellung()


  }

  calculateGesamtpreis(positionen): number {
    let result: number = 0;
    positionen.forEach(position => {
      if (position != null)
        result += <number>position.einzelpreis * <number>position.anzahl
    });
    return result;
  }

  deletePosition(positionIndex) {

    let data = {
      title: "Achtung",
      message: "Möchten Sie die Position verbindlich löschen?",
      buttons: [{
        title: "Abbrechen",
        callback: () => {
        }
      },
      {
        title: "Löschen",
        callback: () => {
          this.currentBeleg.positionen[positionIndex].deleteOnUpdate = true;
          this.deletedPositions.push(JSON.parse(JSON.stringify(this.currentBeleg.positionen[positionIndex])))
          this.currentBeleg.positionen.splice(positionIndex, 1)
          this.getArtikelGruppenFromBeleg()
          this.shopService.setCurrentBeleg(this.currentBeleg);
          //this.refreshBestaende();
          this.hasRequiredArtikleGroup();
        }
      }]
    }
    this.shopService.openGenericDialog(data)


  }

  async updatePosition(positionIndex) {
    this.loading = true
    let beleg = this.currentBeleg;
    let position: Position = beleg.positionen[positionIndex]
    let artikel: Artikel = new List(this.artikelliste).FirstOrDefault(x => x.code == position.artikelCode)
    //let artikel: Artikel = await this.apiService.getArtikelByCodeAsync(position.artikelCode)
    if (artikel) {
      this.getArtikelBestand(position);
    }
    let artikelCode = position.artikelCode;

    this.apiService.createAuftragPosition(beleg, "", <number>artikelCode, <number>position.anzahl, async createdPosition => {
      this.currentBeleg.positionen[positionIndex].deleteOnUpdate = true;
      this.deletedPositions.push(JSON.parse(JSON.stringify(this.currentBeleg.positionen[positionIndex])))
      this.currentBeleg.positionen.splice(positionIndex, 1, createdPosition);
      await this.refreshBestaende();
      this.getArtikelBestand(this.currentBeleg.positionen[positionIndex])
      this.loading = false
    })
  }


  displayCurrencyFormat(value): string {
    return this.shopService.displayCurrencyFormat(value)
  }

  createOrderDefinition() {
    if (this.currentBeleg.projekt != undefined && this.currentBeleg.projekt) {
      localStorage.setItem("w4aShopSelectedProjekt", JSON.stringify(this.currentBeleg.projekt))
    }
    this.openOrderDefinition = new OrderDefinition("");
    $.extend(true, this.openOrderDefinition, this.currentShopDefinition.orderDefinitions[0])
    this.openOrderDefinition.formDefinition = this.shopService.fillFormDefinition(this.currentBeleg, this.openOrderDefinition.formDefinition)
  }



  checkForFormDefinition(input: string) {
    try {
      if (input.startsWith(`{"`)) {
        return true
      }
      else {
        return false
      }
    }
    catch (err) {
      return false
    }
  }

  logout() {
    localStorage.removeItem("loginLevel")
    localStorage.removeItem("apiUrl")
    localStorage.removeItem("w4aShopWarenkorb")
    localStorage.removeItem("setting_ShopDefinition")
    this.authService.logout()
  }

  openArtikelDialog(): void {
    // this.shopService.loadCurrentPflichtartikelGruppen()
    let dialogRef = this.dialog.open(ArtikelDialogComponent, {
      width: '1570px',
      height: '90%',
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {

        result.forEach(item => {
          let found: boolean = false;
          this.currentBeleg.positionen.some((position, positionindex) => {
            if (position.artikelCode == item.artikel.code) {

              this.apiService.createBelegPosition(this.currentBeleg, "", item.artikel.code, position.anzahl + item.menge, async (createdPosition) => {
                this.currentBeleg.positionen[positionindex].deleteOnUpdate = true
                this.deletedPositions.push(JSON.parse(JSON.stringify(this.currentBeleg.positionen[positionindex])))
                this.currentBeleg.positionen[positionindex] = createdPosition
                //this.shopService.setCurrentBeleg(this.currentBeleg)
                await this.getArtikelGruppenFromBeleg()
                await this.refreshBestaende();
                this.getArtikelBestand(this.currentBeleg.positionen[positionindex])
                this.hasRequiredArtikleGroup();
              })

              found = true;
              return true
            }
          })

          if (!found) {

            this.apiService.createBelegPosition(this.currentBeleg, "", item.artikel.code, item.menge, async (createdPosition) => {
              if (item.nichtVerfuegbar == true) {
                createdPosition.stattGPreis = "Auf Anfrage"
              }
              this.currentBeleg.positionen.push(createdPosition)
              //this.shopService.setCurrentBeleg(this.currentBeleg)
              this.artikelliste.push(item.artikel)
              await this.getArtikelGruppenFromBeleg()
              this.shopService.cachingArtikleGroups(this.currentBeleg.positionen);
              await this.refreshBestaende();
              this.getArtikelBestand(this.currentBeleg.positionen[this.currentBeleg.positionen.length - 1])
              this.hasRequiredArtikleGroup();
            })
          }

        })
      }
    });
  }




  projekt: Projekt = null;

  openVeranstaltungAuswahlDialog(): void {
    let dialogRef = this.dialog.open(VeranstaltungAuswahlComponent, {
      autoFocus: false,
      disableClose: true,
      width: "1570px"
    });

    dialogRef.afterClosed().subscribe(projekt => {
      if (projekt != null) {

        localStorage.setItem("w4aShopSelectedProjekt", JSON.stringify(projekt))

        this.currentBeleg.projektCode = projekt.code
        this.currentBeleg.projekt = projekt
        this.currentBeleg.notiz += " | " + projekt.name
        this.currentBeleg.kostenstelle = projekt.kostenStellenCode

        this.currentBeleg.dispositionsbeginn = projekt.vonDatum
        this.currentBeleg.dispositionsende = projekt.bisDatum

        this.openArtikelDialog()
        console.log(this.currentBeleg)
      }
      else{
        this.closeOpenOrder()
      }
    });
  }


  searchByText() {
    this.dataGrid.instance.searchByText(this.searchText)
  }

  onSelectionChanged(e) {
    //this.openBeleg(e.data)
  }

  onRowClick(e) {
    this.openBeleg(e.data)
  }

  onRowDblClicked(e) {
    this.openBeleg(e.data)
  }


  getIndividualFieldData(bzObj, definitionCode): { definitionCode: number, value: string } {
    return new List<{ definitionCode: number, value: string }>(bzObj.individualFieldData).FirstOrDefault(x => x.definitionCode == definitionCode)
  }

  async replaceTextmarken(bzObj, bodyHtml) {
    if (bzObj.projektCode) {
      let projekt = <Projekt>await this.apiService.getProjektByCodeAsync(bzObj.projektCode)
      if (projekt) {
        bodyHtml = bodyHtml.replace(/\[projekt.name\]/gi, projekt.name)
        bodyHtml = bodyHtml.replace(/\[projekt.nummer\]/gi, projekt.nummer)
        bodyHtml = bodyHtml.replace(/\[projekt.start\]/gi, moment(projekt.anfangDatum).format("DD.MM.YYYY"))
        bodyHtml = bodyHtml.replace(/\[projekt.ende\]/gi, moment(projekt.endeDatum).format("DD.MM.YYYY"))
      }
    }

    console.log(this.currentShopDefinition.notificationStartText)

    this.currentShopDefinition.orderDefinitions[0].formDefinition.components.forEach(component => {
      let selector = '\\[bestellung.sonstigeAngaben.' + this.shopService.lowerCamelCase(component.label) + '\\]'
      if (component.mapping && (this.getIndividualFieldData(bzObj, component.mapping.Code) || this.getIndividualFieldData(bzObj, component.mapping.code))) {
        let value = component.mapping.code != undefined ? this.getIndividualFieldData(bzObj, component.mapping.code).value : this.getIndividualFieldData(bzObj, component.mapping.Code).value
        if (component.mapping.Control == "DateType" || component.mapping.control == "DateType") {
          value = moment(value).format("DD.MM.YYYY").toLocaleLowerCase() != "invalid date" ? moment(value).format("DD.MM.YYYY") : "--"
        }
        if (value == null || value == "null" || value.trim().length == 0) {
          value = "--"
        }
        bodyHtml = bodyHtml.replace(new RegExp(selector, "gi"), value)
      }
    })

    if (bzObj.bzObjType == ObjectType.Auftrag) {
      bodyHtml = bodyHtml.replace(/\[bestellung.datum\]/gi, moment(bzObj.auftragsDatum).format("DD.MM.YYYY").toLocaleLowerCase() != "invalid date" ? moment(bzObj.auftragsDatum).format("DD.MM.YYYY") : "--")
      bodyHtml = bodyHtml.replace(/\[bestellung.nummer\]/gi, bzObj.auftragsNummer)
    }
    else {
      bodyHtml = bodyHtml.replace(/\[bestellung.datum\]/gi, moment(bzObj.datum).format("DD.MM.YYYY").toLocaleLowerCase() != "invalid date" ? moment(bzObj.datum).format("DD.MM.YYYY") : "--")
      bodyHtml = bodyHtml.replace(/\[bestellung.nummer\]/gi, bzObj.nummer)
    }
    bodyHtml = bodyHtml.replace(/\[bestellung.notiz\]/gi, bzObj.notiz)
    bodyHtml = bodyHtml.replace(/\[datum\]/gi, moment().format("DD.MM.YYYY"))

    if (localStorage.getItem("ansprechpartner")) {
      let ansprechpartner: Ansprechpartner = JSON.parse(localStorage.getItem("ansprechpartner"))
      bodyHtml = bodyHtml.replace(/\[ansprechpartner.briefanrede\]/gi, ansprechpartner.briefanrede.trim().length > 0 ? ansprechpartner.briefanrede : "--")
      bodyHtml = bodyHtml.replace(/\[ansprechpartner.email\]/gi, ansprechpartner.eMail.trim().length > 0 ? ansprechpartner.eMail : "--")
      bodyHtml = bodyHtml.replace(/\[ansprechpartner.nachname\]/gi, ansprechpartner.name.trim().length > 0 ? ansprechpartner.name : "--")
      bodyHtml = bodyHtml.replace(/\[ansprechpartner.vorname\]/gi, ansprechpartner.vorname.trim().length > 0 ? ansprechpartner.vorname : "--")
      bodyHtml = bodyHtml.replace(/\[ansprechpartner.telefon\]/gi, ansprechpartner.telefon.trim().length > 0 ? ansprechpartner.telefon : "--")
      bodyHtml = bodyHtml.replace(/\[ansprechpartner.funktion\]/gi, ansprechpartner.funktion.trim().length > 0 ? ansprechpartner.funktion : "--")
      bodyHtml = bodyHtml.replace(/\[ansprechpartner.ort\]/gi, ansprechpartner.ort.trim().length > 0 ? ansprechpartner.ort : "--")
      bodyHtml = bodyHtml.replace(/\[ansprechpartner.plz\]/gi, ansprechpartner.plz.trim().length > 0 ? ansprechpartner.plz : "--")
      bodyHtml = bodyHtml.replace(/\[ansprechpartner.strasse\]/gi, ansprechpartner.strasse.trim().length > 0 ? ansprechpartner.strasse : "--")
      bodyHtml = bodyHtml.replace(/\[ansprechpartner.geburtsdatum\]/gi, moment(ansprechpartner.geburtsdatum).format("DD.MM.YYYY"))
    }

    return bodyHtml
  }

  async sendConfirmationMail(bzObj, genehmigung: boolean = null) {


    let cssTH: string = "padding:3px 5px;border-bottom:1px solid silver;vertical-align:top;";
    let cssTD: string = "padding:3px 5px;border-bottom:1px solid silver;vertical-align:top;";

    let subject: string = this.currentShopDefinition.notificationSubject;
    let bodyHtml: string = this.currentShopDefinition.notificationStartText;

    if (genehmigung === true)
      bodyHtml = `<span style='background:#bee6be;      color: green;      padding: 15px;      border: 1px solid green;'>Genehmigung erteilt durch <strong>${this.ansprechpartner.vorname} ${this.ansprechpartner.name} </strong></span>` + bodyHtml
    if (genehmigung === false)
      bodyHtml = `<span style='background: #ffeece;      color: #d88c00;      padding: 15px;      border: 1px solid #d88c00;'>Genehmigung aufgehoben durch <strong>${this.ansprechpartner.vorname} ${this.ansprechpartner.name} </strong></span>` + bodyHtml

    subject = await this.replaceTextmarken(bzObj, subject)
    bodyHtml = await this.replaceTextmarken(bzObj, bodyHtml)


    let positionenHtml = " <table style='width:100%'><tr><th style='" + cssTH + "'>Nr.</th><th style='" + cssTH + "text-align:left;'>Bezeichnung</th><th style='" + cssTH + "'>Menge</th>";
    if (this.currentShopDefinition.displayPreiseInEMail) {
      positionenHtml += "<th style='" + cssTH + "text-align:right;'>Einzelpreis</th><th style='" + cssTH + "text-align:right;'>Gesamtpreis</th>";
    }
    positionenHtml += "</tr>";

    bzObj.positionen.forEach((position, positionIndex) => {
      if (!position.deleteOnUpdate) {
        positionenHtml += "<tr><td style='" + cssTD + "text-align:center;'>" + (positionIndex + 1) + "</td><td  style='" + cssTD + " text-align:left;'>" + position.langtext + "</td><td style='" + cssTD + "text-align:center;'>" + position.anzahl + " " + position.einheit + "</td>";

        if (this.currentShopDefinition.displayPreiseInEMail) {
          positionenHtml += "<td style='" + cssTD + "text-align:right;'>" + this.currencyFormat.transform(position.einzelpreis) + " </td><td style='" + cssTD + "text-align:right;'>" + this.currencyFormat.transform(position.gesamtpreis) + " </td>";
        }
        positionenHtml += "</tr>";
      }
    })

    if (this.currentShopDefinition.displayPreiseInEMail) {
      positionenHtml += "<tr><th colspan='4'  style='" + cssTH + " text-align:right;'>Gesamt<br><small>zzgl. MwSt.</small></th><th style='" + cssTH + " text-align:right;'>" + this.currencyFormat.transform(this.calculateGesamtpreis(bzObj.positionen))  + "</th></tr>";
    }
    positionenHtml += `</table><br>`;


    bodyHtml = bodyHtml.replace(/\[bestellung.positionen\]/gi, positionenHtml)

    let mailrequest: SendMailRequest = new SendMailRequest();
    mailrequest.subject = subject;
    mailrequest.priority = "Normal";
    mailrequest.attachements = [];
    mailrequest.body = bodyHtml;
    mailrequest.bccRecipient = []
    if (this.currentShopDefinition.notificationRecipientEmails.trim().length > 0) {
      mailrequest.bccRecipient = this.currentShopDefinition.notificationRecipientEmails.split(",")
      mailrequest.bccRecipient.forEach((email, emailIndex) => {
        mailrequest.bccRecipient[emailIndex] = email.trim()
      })

    }

    mailrequest.bccRecipient.push(... await this.openMailConfigDialog())

    if (this.loggedUser != null) {
      mailrequest.toRecipient = [this.loggedUser.eMail]
    }

    if (this.ansprechpartner != null) {
      mailrequest.toRecipient = [this.ansprechpartner.eMail]
    }

    mailrequest.senderMailbox = this.currentShopDefinition.notificationSenderEmail
    if (!this.currentShopDefinition.notificationSenderEmail || this.currentShopDefinition.notificationSenderEmail.length == 0) {
      mailrequest.senderMailbox = null
    }

    /*if (this.config.get("sendBackToSender")) {
      mailrequest.toRecipient = [this.config.get("senderEmail")]
    }*/

    this.apiService.sendMail(mailrequest, (success) => {
      this.clearFormValues()
      this.loading = false
    }, (error) => {
      console.log(error)
    })

  }

  clearFormValues() {
    this.currentShopDefinition.orderDefinitions.forEach((def, defIndex) => {
      this.shopService.clearFormValues(def.formDefinition)
    })
    this.shopService.clearWarenkorb()
  }

  async openMailConfigDialog(): Promise<string[]> {

    let dialogRef = this.dialog.open(MailConfigurationDialogComponent, {
      autoFocus: false,
      disableClose: true,
      width: "450px"
    });

    return dialogRef.afterClosed().toPromise()
  }

  async downloadAttachement(item: any): Promise<any> {
    var result: Blob = await this.apiService.downloadFile("ErpAnhang", item.code, item.originalDateiname);
    return result;
  }


  getAdditionalBestaende() {
    this.additionalBestaende = {}
    for (let position of this.currentBeleg.positionen) {
      if (position.artikelCode) {
        this.additionalBestaende[position.artikelCode] = position.anzahl
      }
    }

  }

  async openBeleg(beleg) {
    this.loading = true
    this.currentBeleg = await this.apiService.getBelegByCode(beleg.bzObjType, beleg.code)
    this.getAdditionalBestaende()
    if (this.currentBeleg && this.currentBeleg.projektCode) {
      this.apiService.getProjekteByCodes([this.currentBeleg.projektCode], projekte => {
        if (projekte && projekte.length == 1) {
          this.currentBeleg.projekt = projekte[0]
        }
      })
    }

    //this.openOrderIndex = orderIndex;
    this.createOrderDefinition();


    this.artikelliste = []
    let artikelCodes: number[] = []
    if (this.currentBeleg.positionen.length > 0) {
      artikelCodes = new List<Position>(this.currentBeleg.positionen).Select(x => x.artikelCode).ToArray();
      this.artikelliste = await this.apiService.getArtikelByCodesAsync(artikelCodes);
      this.refreshBestaende();
    }
    //this.getVerfuegbarkeiten();

    this.shopService.setCurrentBeleg(this.currentBeleg);

    this.loading = false
  }



  async getArtikelGruppenFromBeleg() {
    this.artikleGroups = []

    let artikelCodes = new List<Position>(this.currentBeleg.positionen).Select(x => x.artikelCode).ToArray()
    let artikelList = await this.apiService.getArtikelByCodesAsync(artikelCodes);

    for (let artikel of artikelList) {
      this.artikleGroups.push(artikel.grCode);
    }
  }

  hasRequiredArtikleGroup() {
    if(this.requiredArtikleGroups && this.artikleGroups)
    if (this.requiredArtikleGroups.every(r => this.artikleGroups.indexOf(r) > -1)) {
      this.noRequiredGroup = false;
    } else {
      this.noRequiredGroup = true;
    }
  }

  async refreshBestaende() {
    let artikelCodes: number[] = new List(this.artikelliste).Select(x => x.code).ToArray()

    let data = await this.apiService.getBestaendeAsync(artikelCodes)

    this.artikelliste.forEach((artikel, artikelIndex) => {
      let bestandList = data[artikel.code]
      let chargen = []
      if (bestandList != undefined && bestandList) {
        let gesamtBestand = 0;
        for (let bestand of bestandList) {
          gesamtBestand += bestand['bestand']
          chargen.push(...bestand.chargen)
        }
        artikel['bestand'] = gesamtBestand
        artikel['chargen'] = chargen
      }
    });

    if (this.currentBeleg.projekt && this.currentBeleg.projekt != undefined) {

      let verfuegbarkeiten = await this.apiService.getVerfuegbarkeitenAsync(artikelCodes, this.currentBeleg.projekt.anfangDatum, this.currentBeleg.projekt.endeDatum, this.currentShopDefinition.orderDefinitions[0].formDefinition.bzObjType)

      // console.log({verfuegbarkeiten})
      this.artikelliste.forEach(artikel => {
        if (artikel.mietartikel && verfuegbarkeiten[artikel.code] != undefined) {
          artikel['verfuegbar'] = verfuegbarkeiten[artikel.code];
        }

      })
    }
  }


  getArtikelBestand(position) {
    //let position = this.currentBeleg.positionen[index]

    let artikel = new List(this.artikelliste).FirstOrDefault(x => x.code == position.artikelCode)

    if (!artikel) return

    let additionBestand = 0
    if (this.additionalBestaende[artikel.code] != undefined)
      additionBestand = this.additionalBestaende[artikel.code]

    if (!artikel['shopBestellungohneBestand']) {
      if (artikel.mietartikel && artikel['verfuegbar'] != undefined) {
        if (position.anzahl > artikel['verfuegbar'] + additionBestand) {
          position.anzahl = artikel['verfuegbar'] + additionBestand

        }
      } else if (!artikel.mietartikel && artikel['bestand'] != undefined) {
        if (position.anzahl > artikel['bestand'] + additionBestand) {
          position.anzahl = artikel['bestand'] + additionBestand
        }
      }
    }
    if (position.anzahl < 0) {
      position.anzahl = 0
    }
  }
}

import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Projekt, ObjectType, InfofensterDataItem, ShopProjektFavoritenItem, Config } from '@work4all/api';
import { ShopDefinition } from '../../classes/shop-definition';
import { FormDefinition } from '../../classes/form-definition';
import { ProjektKategorieZuordnung } from 'src/app/classes/projekt-kategorie-zuordnung';
import { List } from 'linqts';
export class VeranstaltungComponent {
    constructor(shopService, apiService, dialog, dialogRef, data, config) {
        this.shopService = shopService;
        this.apiService = apiService;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
        this.config = config;
        this.dateAnfang = new FormControl(moment());
        this.dateEnde = new FormControl(moment().add(1, "days"));
        this.dateVon = new FormControl(moment());
        this.dateBis = new FormControl(moment().add(1, "days"));
        this.loginMode = 0;
        this.editMode = false;
        this.loggedUser = null;
        this.projekt = new Projekt();
        this.fieldsValidated = false;
        this.dokumente = [];
        this.dokumenteGeladen = false;
        this.eventFavourite = new ShopProjektFavoritenItem();
        this.projektCode = null;
        this.currentShopDefinition = new ShopDefinition("work4all Shop");
        this.projektKategorien = [];
        this.projektKategorieMode = false;
        this.closed = new EventEmitter();
        this.favouriteMode = new EventEmitter();
        this.api = this.apiService;
        if (data.projekt)
            this.projektCode = data.projekt.code;
        if (localStorage.getItem("ansprechpartner")) {
            this.ansprechpartner = JSON.parse(localStorage.getItem("ansprechpartner"));
            this.kundenCode = this.ansprechpartner.kundenCode;
        }
        else {
            this.kundenCode = config.kundenCode;
        }
        this.eventFavourite = new ShopProjektFavoritenItem;
        this.eventFavourite.projektCodes = [];
        this.apiService.getFavouriteEvent("work4all.Shop.Projekt.Favoriten." + this.kundenCode).then(data => {
            if (data) {
                let object = data;
                this.eventFavourite = JSON.parse(object);
                // console.log(this.eventFavourite)
            }
        });
        // console.log(data)
        if (data.projekt) {
            $.extend(true, this.projekt, data.projekt);
            this.dateVon.setValue(moment(this.projekt.vonDatum));
            this.dateBis.setValue(moment(this.projekt.bisDatum));
            this.dateAnfang.setValue(moment(this.projekt.anfangDatum));
            this.dateEnde.setValue(moment(this.projekt.endeDatum));
        }
        apiService.LoginMode.subscribe(data => {
            this.loginMode = data;
        });
        shopService.CurrentShopDefinition.subscribe(data => {
            $.extend(true, this.currentShopDefinition, data);
            if (!this.dokumenteGeladen) {
                this.getDokumenteByProjektCode();
                this.dokumenteGeladen = true;
            }
            if (!this.currentShopDefinition.projekteFormDefinition) {
                this.currentShopDefinition.projekteFormDefinition = new FormDefinition();
            }
        });
        this.loggedUser = this.apiService.getLoggedUser();
        setTimeout(() => {
            this.isFavourite();
        }, 200);
        this.getProjektKategorien();
    }
    getProjektKategorien() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.projektKategorien = yield this.apiService.getProjektKategorien();
        });
    }
    getDokumenteByProjektCode() {
        this.dokumente = [];
        this.currentShopDefinition.displayInfofensterCodes.forEach(infofensterCode => {
            this.apiService.getInfofensterItems(infofensterCode, this.projekt.code, dokumente => {
                if (dokumente) {
                    this.dokumente.push(...dokumente);
                }
            });
        });
    }
    downloadFile(code, filename) {
        this.apiService.downloadFile("Dokument", code, filename);
    }
    close(i = 0) {
        this.closed.emit(i);
        this.dialogRef.close(i);
    }
    saveProjektKategorien() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let kategorie of this.projektKategorien) {
                if (kategorie['active']) {
                    let zuordnung = new ProjektKategorieZuordnung();
                    zuordnung.projektCode = this.projektCode;
                    zuordnung.kategorieCode = kategorie.code;
                    yield this.apiService.postProjektKategorieZuordnung(zuordnung);
                }
            }
            this.close(1);
        });
    }
    countActiveProjektKategorien() {
        return new List(this.projektKategorien).Where(x => x['active'] == true).Select(x => x).Count();
    }
    save() {
        this.projekt.anfangDatum = this.dateAnfang.value.toISOString();
        this.projekt.endeDatum = this.dateEnde.value.toISOString();
        this.projekt.bCode = this.loggedUser.code;
        if (!this.projekt.kundenCode && localStorage.getItem("sdObjMemberCode"))
            this.projekt.kundenCode = JSON.parse(localStorage.getItem("sdObjMemberCode"));
        if (!this.projekt.code || this.projekt.code == undefined) {
            this.projekt.vonDatum = this.dateAnfang.value.toISOString();
            this.projekt.bisDatum = this.dateEnde.value.toISOString();
            this.projekt.grCode = this.currentShopDefinition.standardProjektGruppeCode;
            this.apiService.insertProjekt(this.projekt, (projektCode) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (this.projektKategorien.length > 1) {
                    this.projektCode = projektCode;
                    this.projektKategorieMode = true;
                }
                else {
                    let zuordnung = new ProjektKategorieZuordnung();
                    zuordnung.projektCode = projektCode;
                    zuordnung.kategorieCode = this.projektKategorien[0].code;
                    yield this.apiService.postProjektKategorieZuordnung(zuordnung);
                    this.close(1);
                }
            }), error => {
                console.log(error);
                this.close(-1);
            });
        }
        else {
            this.projekt.kunde = null;
            this.apiService.updateProjekt(this.projekt, result => {
                // console.log(result)
                this.close(1);
            }, error => {
                console.log(error);
                this.close(-1);
            });
        }
    }
    ngOnInit() {
    }
    validateForm(event) {
        // //console.log($event)
        if (event == null) {
            this.fieldsValidated = false;
        }
        else {
            this.currentShopDefinition.projekteFormDefinition = event;
            this.projekt["shopFormularDaten"] = JSON.stringify(event);
            this.fieldsValidated = true;
        }
    }
    getEnding(dateiPfad) {
        return dateiPfad.split(".")[dateiPfad.split(".").length - 1];
    }
    fillBzObject(bzObj) {
        bzObj.datum = new Date().toISOString();
        bzObj.auftragsDatum = new Date().toISOString();
        if (this.loggedUser.code != undefined && this.loggedUser.code) {
            bzObj.benutzerCode = this.loggedUser.code;
            bzObj.benutzerCode2 = this.loggedUser.code;
        }
        bzObj.webShopOrderDefinitionData = JSON.stringify(this.currentShopDefinition.projekteFormDefinition);
        bzObj = this.shopService.fillBzObject(bzObj, this.currentShopDefinition.projekteFormDefinition);
        let notizComponents = [];
        switch (this.currentShopDefinition.projekteFormDefinition.bzObjType) {
            case ObjectType.Auftrag:
                notizComponents.push("AUFTRAG");
                break;
            case ObjectType.Angebot:
                notizComponents.push("ANGEBOT");
                break;
            case ObjectType.Bedarfsanforderung:
                notizComponents.push("BEDARF");
                break;
            case ObjectType.Bestellung:
                notizComponents.push("BESTELLUNG");
                break;
            case ObjectType.Kalkulation:
                notizComponents.push("KALKULATION");
                break;
            default: break;
        }
        //notizComponents.push("WebShop");
        //notizComponents.push(this.loggedUser.anzeigename);
        let options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        //notizComponents.push(new Date().toLocaleDateString('de-DE', options));
        if (bzObj.positionen.length > 0) {
            notizComponents.push(bzObj.positionen[0].kurztext);
        }
        //notizComponents.push();
        bzObj.notiz = notizComponents.join(" | ") + "\n" + bzObj.notiz;
        return bzObj;
    }
    getSdobjMemberType() {
        let type = this.shopService.getSdObjmemberType();
        switch (type) {
            case "Lieferant":
                this.eventFavourite.sdoObjType = 0;
                break;
            case "Kunde":
                this.eventFavourite.sdoObjType = 1;
                break;
            default:
                break;
        }
    }
    setFavourite() {
        let object;
        this.getSdobjMemberType();
        if (this.eventFavourite != null) {
            this.eventFavourite.sdoObjmemberCode = this.shopService.getSdObjmemberCode();
            if (this.eventFavourite.projektCodes.indexOf(this.projektCode) < 0) {
                this.eventFavourite.projektCodes.push(this.projektCode);
                // console.log(this.eventFavourite.projektCodes);
            }
            else {
                let index = this.eventFavourite.projektCodes.indexOf(this.projektCode);
                this.eventFavourite.projektCodes.splice(index, 1);
            }
        }
        object = {
            'name': "work4all.Shop.Projekt.Favoriten." + this.kundenCode,
            'favouriteObject': this.eventFavourite
        };
        this.apiService.setFavouriteEvent(object, anh => {
            // console.log(anh);
        }, error => {
            console.log(error);
        });
        this.isFavourite();
    }
    isFavourite() {
        if (this.eventFavourite)
            if (this.eventFavourite.projektCodes.indexOf(this.projektCode) < 0) {
                // console.log('false');
                this.favourite = false;
            }
            else {
                // console.log('true');
                this.favourite = true;
            }
    }
}

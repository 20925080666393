
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { VeranstaltungComponent } from '../veranstaltung/veranstaltung.component';
import { MatDialog } from '@angular/material';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment'
import { ShopDefinition } from '../../classes/shop-definition';
import { ShopService } from '../../services/shop.service';
import { Projekt, ShopProjektFavoritenItem, Config } from '@work4all/api';
import { List } from 'linqts';
import { EditableType } from '../../enums/editable-type.enum';

@Component({
  selector: 'app-veranstaltungen',
  templateUrl: './veranstaltungen.component.html',
  styleUrls: ['./veranstaltungen.component.scss']
})
export class VeranstaltungenComponent implements OnInit {

  openVeranstaltung: boolean = false;

  dateVon = new FormControl(moment().subtract(5, 'days'));
  dateBis = new FormControl(moment().add(90, 'days'));

  timeline: Date[] = [];
  projektList;

  suchbegriff: string;
  searchTimeout;
  loading: boolean = false;
  suche: boolean = false;

  tableWidth: number;
  calculatedColumnHeight: number;

  matrix
  viewMode: number = 0;

  eventFavourite: ShopProjektFavoritenItem = new ShopProjektFavoritenItem();

  _columnWidth: number;
  _columnHeight: number = 60;
  columnHeight: number = 60;

  favouriteFilter: boolean = false;

  ansprechpartner;
  kundenCode: number;
  
  EditableType = EditableType
  _apiService = this.apiService

  @Output() confirm: EventEmitter<Projekt> = new EventEmitter()
  @Input() showDetails: boolean = true;

  get columnWidth() {
    return this._columnWidth;
  }

  set columnWidth(value) {
    localStorage.setItem("w4aShopTimelineColumnWidth", JSON.stringify(value));
    this._columnWidth = value;
  }

  get loggedUser(): any {
    return this.apiService.getLoggedUser()
  }

  currentShopDefinition: ShopDefinition = null;

  constructor(protected dialog: MatDialog, protected apiService: ApiService, protected shopService: ShopService, protected config: Config) {
    if(localStorage.getItem("ansprechpartner")) {
      this.ansprechpartner = JSON.parse(localStorage.getItem("ansprechpartner"))
      this.kundenCode = this.ansprechpartner.kundenCode;
    } else {
      this.kundenCode = config.kundenCode
    }
    this.eventFavourite = new ShopProjektFavoritenItem;
    this.eventFavourite.projektCodes = [];
  
    this.apiService.getFavouriteEvent("work4all.Shop.Projekt.Favoriten." + this.kundenCode).then(data => {
      if(data) {
        let object: any = data;
        this.eventFavourite = JSON.parse(object);
        // console.log(this.eventFavourite)
      }
    });
  }

  ngOnInit() {

    //let x = new ShopDefinition("test")
    //console.log(x)
    
    this.shopService.CurrentShopDefinition.subscribe(shopDefinition => {
      this.currentShopDefinition = shopDefinition
    })

    if (localStorage.getItem("w4aShopTimelineColumnWidth"))
      this.columnWidth = JSON.parse(localStorage.getItem("w4aShopTimelineColumnWidth"));
    else
      this.columnWidth = 60

    if (localStorage.getItem("w4aShopTimelineColumnHeight"))
      this.columnHeight = JSON.parse(localStorage.getItem("w4aShopTimelineColumnHeight"));
    else
      this.columnHeight = 20

    this.generateTimeline()

    //setzt den Padding dynamisch zum Header der Veranstaltungen
    var setHeight = function() {
      var top = $('#projektHeader').outerHeight();
      $('.date-body').css({'padding-top': top + 'px'});
    }

    $(window).on('load',function() {
      setHeight();
    });
    
    $(window).resize(function() {
      setHeight();
    });
  }
  
  
  confirmProjekt(projekt) {
    this.confirm.emit(projekt)
  }

  getProjektOffset(vonDatum) {
    if (this.dateVon.value >= moment(vonDatum)) {
      return 0
    }
    if (this.dateVon.value < moment(vonDatum)) {
      return this.dateVon.value.diff(moment(vonDatum), 'days') * -1 + 1
    }
  }

  getProjektDays(projekt) {
    let start = moment(projekt.anfangDatum)
    start.set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0)
    let end = moment(projekt.endeDatum)
    end.set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).add(1, "days")
    let days = start.diff(end, 'days') * -1;
    return days
  }

  checkForToday(day) {
    if (moment(day).format("MMM Do YY") == moment().format("MMM Do YY")) {
      return true
    }
    else {
      return false
    }
  }

  refreshProjektList() {
    this.apiService.getProjekteByRange(this.dateVon.value, this.dateBis.value, data => {
      this.projektList = data;
    })
  }



  getProjekteBySuchbegriff() {
    clearTimeout(this.searchTimeout)
    this.loading = true;
    this.suche = true;
    this.viewMode = 0;
    this.searchTimeout = setTimeout(() => {

      if (this.suchbegriff.trim().length === 0) {
        this.generateTimeline();
      }
      else{
        this.apiService.getProjekteBySuchbegriff(this.suchbegriff, projekte => {
        this.loading = false;
        let loaded = new List<Projekt>();
        let von, bis;
        if (projekte) {
          loaded.AddRange(projekte);

          von = loaded.Where(projekt =>
            projekt.anfangDatum + '' != "0001-01-01T00:00:00")
            .OrderBy(projekt => projekt.anfangDatum)
            .First().anfangDatum;

          bis = loaded.Where(projekt =>
            projekt.endeDatum + '' != "0001-01-01T00:00:00")
            .OrderByDescending(projekt => projekt.endeDatum)
            .First().endeDatum;

          //this.dateBis.setValue(bis);
          //this.dateVon.setValue(von);
          this.projektList = projekte;

        }
      })
      }
      
    }, 400);
  }


  emitProjekt(projekt) {
    //this.dialogRef.close(projekt);
  }

  getVorgangDisplayDays(vorgangStartDate: Date, vorgangEndeDate: Date): number {
    let startDate = moment(vorgangStartDate).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    let endeDate = moment(vorgangEndeDate).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    let timelineStartDate = moment(this.dateVon.value).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    let timelineEndeDate = moment(this.dateBis.value).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);

    let days = endeDate.diff(startDate) / 1000 / 60 / 60 / 24;

    if (startDate < timelineStartDate) {
      days -= timelineStartDate.diff(startDate) / 1000 / 60 / 60 / 24;
    }
    if (endeDate > timelineEndeDate) {
      days -= endeDate.diff(timelineEndeDate) / 1000 / 60 / 60 / 24;
    }

    days++

    if (days < 1) {
      days = 1;
    }

    return days;
  }

  checkVorgangDisplay(currentDate: Date, vorgangStartDate: Date, vorgangEndeDate: Date): number {
    let currentDateString = moment(currentDate).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).format();
    let vorgangStartDateString = moment(vorgangStartDate).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).format();
    let vorgangEndeDateString = moment(vorgangEndeDate).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).format();
    let timelineStartDateString = moment(this.dateVon.value).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).format();

    if (currentDateString == timelineStartDateString && currentDateString >= vorgangStartDateString) {
      return 2
    }
    if (currentDateString == vorgangStartDateString) {
      return 1
    }
    if (currentDateString < vorgangStartDateString) {
      return 0
    }
    if (currentDateString > vorgangEndeDateString) {
      return 0
    }
    if (currentDateString > vorgangStartDateString && currentDateString < vorgangEndeDateString) {
      return -1
    }

    return 0;
  }

  getWeekday(day: Date): string {
    let result: string = moment(day).format('dddd').toLowerCase();

    if (moment(day).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).format() == moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).format()) {
      result += " today"
    }
    return result;
  }

  isMonth(day: Date) {
    if (moment(day).isSame(moment(day).startOf('month'), 'day')) {
        return true;
    }

    if (moment(day).isSame(moment(this.dateBis.value).startOf("month"), 'day')) {
        return true;
    }

    if (moment(day).isSame(moment(this.dateVon.value), 'day')) {
        return true;
    }
}

  getMonthlength(day: Date): number {
    let length;
    if (moment(day).isSame(moment(this.dateVon.value), 'day')) {
        length = moment(day).daysInMonth() - moment(this.dateVon.value).date() + 1;
      return length;
    }
    if (moment(day).isSame(moment(day).startOf('month'), 'day')) { 
      if(moment(day).isSame(moment(this.dateBis.value).startOf("month"), 'month')) {
        
        length = moment(this.dateBis.value).date();
        return length
      } else {
        return moment(day).daysInMonth();
      }
    }
  }

  sortByStartDate(a, b) {
    if (a.startDatum < b.startDatum)
      return -1;
    if (a.startDatum > b.startDatum)
      return 1;
    return 0;
  }

  generateTimeline() {

    this.suchbegriff = "";
    this.suche = false;
    let result: Date[] = [];

    let days: number = moment(this.dateBis.value).diff(moment(this.dateVon.value)) / 1000 / 60 / 60 / 24;
    for (let i = 0; i <= days; i++) {
      result.push(moment(this.dateVon.value).add(i, "days").toDate())
    }
    if (this.favouriteFilter) {
      this.getProjektsByCode(this.eventFavourite.projektCodes, this.dateVon.value, this.dateBis.value);
    }
    else {
      this.timeline = result;
      this.refreshProjektList()
    }

  }


  openVeranstaltungDialog(projekt: Projekt = null): void {
    if(projekt === null || this.editAllowedList(projekt)){
      this.confirmProjekt(projekt)

      if (this.showDetails) {
        let dialogRef = this.dialog.open(VeranstaltungComponent, {
          height: "auto",
          //width: "1570px",
          autoFocus: false,
          disableClose: false,
          data: { projekt: projekt, isFavourite: this.isFavourite}
        });

        dialogRef.afterClosed().subscribe(result => {
          if (this.suchbegriff.length) {
            this.getProjekteBySuchbegriff();
          } else if (this.favouriteFilter == true) {
            this.getProjektsByCode(this.eventFavourite.projektCodes, null, null);
          } else {
            this.generateTimeline();
          }
        })
      }
    }

  }

  getSdobjMemberType() {
    let type = this.shopService.getSdObjmemberType();
    switch (type) {
      case "Lieferant":
        this.eventFavourite.sdoObjType = 0;
        break;
      case "Kunde":
        this.eventFavourite.sdoObjType = 1;
        break;
      default:
        break;
    }
  }

  setFavourite(code) {

    let object: any;
    this.getSdobjMemberType();
    if (this.eventFavourite != null){
      this.eventFavourite.sdoObjmemberCode = this.shopService.getSdObjmemberCode();

      if (this.eventFavourite.projektCodes.indexOf(code) < 0) {
        this.eventFavourite.projektCodes.push(code);
        // console.log(this.eventFavourite.projektCodes);
      } else {
        let index = this.eventFavourite.projektCodes.indexOf(code);
        this.eventFavourite.projektCodes.splice(index, 1);
      }
    }


    object = {
      'name': "work4all.Shop.Projekt.Favoriten." + this.kundenCode,
      'favouriteObject': this.eventFavourite
    }
    this.apiService.setFavouriteEvent(object, anh => {
      // console.log(anh);
    }, error => {
      console.log(error)
      // let index = this.eventFavourite.projektCodes.indexOf(code);
      // this.eventFavourite.projektCodes.splice(index, 1);
    });

  }

  isFavourite(code) {
    if(this.eventFavourite)
      if (this.eventFavourite.projektCodes.indexOf(code) < 0) {
        return false;
      } else {
        return true;
      }
  }

  filterByFavourite(mode) {
    if (mode == 0) {
      this.favouriteFilter = false;
      this.generateTimeline();
      this.refreshProjektList()

    } else {
      this.favouriteFilter = true;
      // this.generateTimeline();
      this.getProjektsByCode(this.eventFavourite.projektCodes, null, null);
    }
  }

  getProjektsByCode(codes, start, end) {

    this.apiService.getProjekteByCodes(codes, projekte => {
      this.loading = false;
      let loaded = new List<Projekt>();
      let von, bis;
      if (projekte) {
        loaded.AddRange(projekte);
        if (!start && !end) {

          von = loaded.Where(projekt =>
            projekt.anfangDatum + '' != "0001-01-01T00:00:00")
            .OrderBy(projekt => projekt.anfangDatum)
            .First().anfangDatum;

          bis = loaded.Where(projekt =>
            projekt.endeDatum + '' != "0001-01-01T00:00:00")
            .OrderByDescending(projekt => projekt.endeDatum)
            .First().endeDatum;

        } else {
          von = start;
          bis = end;
          projekte = [];
          loaded.ForEach(p => {
            if(moment(p.anfangDatum).isSameOrAfter(moment(von)) && moment(p.anfangDatum).isSameOrBefore(bis)){
              projekte.push(p);
            }
          });
          // console.log(projekte);

        }

        this.dateBis.setValue(bis);
        this.dateVon.setValue(von);

        this.projektList = projekte;

      }
    });

  }

  editAllowedList(projekt: Projekt): boolean {
    let result: boolean = false;
    if (this.currentShopDefinition.orderDefinitions[0].editable == EditableType.EditableUntilProjektStart && projekt != undefined) {
      let daysBeforeEnd: number = this.currentShopDefinition.orderDefinitions[0].editableUntil
      let endDate: Date = new Date(projekt.anfangDatum)

      let today = new Date();
      today.setHours(0, 0, 0)
      var timeDiff = endDate.getTime() - today.getTime();
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (diffDays > daysBeforeEnd) {
        result = true
      }
    }
    if(this.showDetails){
      result = true
    }

    return result;
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./footer.component";
import * as i4 from "../../services/api.service";
import * as i5 from "../../services/shop.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0 "]))], null, null); }
function View_FooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], [[8, "href", 4], [8, "target", 0], [4, "color", null]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = (_v.context.index != 0); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.href; var currVal_2 = _v.context.$implicit.target; var currVal_3 = _co.currentShopDefinition.secondaryColor; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.text; _ck(_v, 4, 0, currVal_4); }); }
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "footer", [["class", "align-left container "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "p-y-default"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "float-right"]], [[4, "color", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "footer-logo"], ["href", "https://www.work4all.de"], ["style", "background: rgb(47, 47, 47);      text-transform: uppercase;      padding: 4px 7px 4px;      float: left;      margin-top: 0px;      margin-left: -15px;      box-shadow: 0px -1px 6px #00000036;      font-size: 10px;      border-radius: 3px;      color: rgb(255, 255, 255);"], ["target", "blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", "work4all GmbH"], ["src", "assets/img/w4a_logo_full.png"], ["style", " margin: 2px 5px 5px 0px;height: 13px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" Shop ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.currentShopDefinition.footerLinks; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentShopDefinition.secondaryColor; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.versionName; _ck(_v, 8, 0, currVal_2); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentShopDefinition != undefined); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i3.FooterComponent, [i4.ApiService, i5.ShopService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i3.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };

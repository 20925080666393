<div class="max-height " *ngIf="loggedUser">

  <div class="max-height max-width relative">
    <div id="projektHeader" class="p-default overflow-hidden max-width absolute z-100 background-whitesmoke border-bottom"
      style="padding-bottom:0" *ngIf="currentShopDefinition != undefined">

      <strong class="">{{currentShopDefinition.projekteNavigationTitle}}</strong><br>
      <div class="inline-block" style="max-width:     100px;">
        <mat-form-field >
          <input matInput [matDatepicker]="dateVonPicker" placeholder="Von" [formControl]="dateVon"
            (dateChange)="generateTimeline()" [disabled]="suche">
          <mat-datepicker-toggle matSuffix [for]="dateVonPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateVonPicker>
          </mat-datepicker>
        </mat-form-field>
      </div>&nbsp;
      <div class="inline-block" style="max-width:100px;">
        <mat-form-field>
          <input matInput [matDatepicker]="dateBisPicker" placeholder="Bis" [formControl]="dateBis"
            (dateChange)="generateTimeline()" [disabled]="suche">
          <mat-datepicker-toggle matSuffix [for]="dateBisPicker"></mat-datepicker-toggle>
          <mat-datepicker #dateBisPicker>
          </mat-datepicker>
        </mat-form-field>
      </div>&nbsp;
      <div class="inline-block" style="max-width:49%">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Veranstaltung suchen" [(ngModel)]="suchbegriff"
            (input)="getProjekteBySuchbegriff()">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
      <div class="float-right hidden-xs">
        <button *ngIf="favouriteFilter" mat-button (click)="favouriteFilter = false">Alle</button>
        <button *ngIf="!favouriteFilter" mat-raised-button (click)="favouriteFilter = false">Alle</button>
        <button *ngIf="!favouriteFilter" mat-button (click)="favouriteFilter = true">Favoriten</button>
        <button *ngIf="favouriteFilter" mat-raised-button (click)="favouriteFilter = true">Favoriten</button>

        <button mat-raised-button class="narrow-btn" *ngIf="viewMode == 0" (click)="viewMode = 0">
          Liste
        </button>
        <button mat-button class="narrow-btn" *ngIf="viewMode != 0" (click)="viewMode = 0">
          Liste
        </button>
        <button mat-raised-button [disabled]="suche" class="narrow-btn" *ngIf="viewMode == 1" (click)="viewMode = 1">
          Zeitleiste
        </button>
        <button mat-button [disabled]="suche" class="narrow-btn" *ngIf="viewMode != 1" (click)="viewMode = 1">
          Zeitleiste
        </button>


        <button mat-button *ngIf="currentShopDefinition && currentShopDefinition.projekteInsertable && _apiService.shopRechte.projektErstellen"
          (click)="openVeranstaltungDialog()">
          <i class="fas fa-plus"></i> {{this.currentShopDefinition.projekteNavigationTitleSingular}} hinzufügen
        </button>
      </div>

    </div>

    <div class="max-height max-width date-body" style="padding-top:92px;" *ngIf="projektList != undefined">

      <div class="visible-xs max-height">
        <div class="max-height y-scroll">

          <ng-container *ngFor="let projekt of projektList; let projektIndex = index">
            <div [ngClass]="{'disabled':!editAllowedList(projekt)}" (click)="openVeranstaltungDialog(projekt)" class="p-default border-bottom relative hover-button pointer">
              <strong>{{projekt.name}}</strong><br>
              <small>{{projekt.anfangDatum | date: 'dd.MM.yyyy'}}</small> -
              <small>{{projekt.endeDatum | date: 'dd.MM.yyyy'}}</small>
              <button mat-button class="narrow-btn" style="position: absolute; right: 10px; top: 20px;"
                (click)="setFavourite(projekt.code)">
                <i class="fas fa-star" *ngIf="isFavourite(projekt.code)" style="color:#499ce0;"></i>
                <i class="far fa-star" *ngIf="!isFavourite(projekt.code)"></i>
              </button>
            </div>
          </ng-container>

        </div>
      </div>

      <div class="white-box border-top max-height hidden-xs" *ngIf="currentShopDefinition">
        <!--<drag-scroll class="timeline x-scroll max-height">-->

        <ng-container *ngIf="viewMode == 0">
          <div class="max-height y-scroll">
            <ng-container *ngFor="let projekt of projektList; let projektIndex = index">
              <div [ngClass]="{'disabled':!editAllowedList(projekt)}" class=" border-bottom relative hover-button pointer" *ngIf="!favouriteFilter || isFavourite(projekt.code)">
                <div (click)="openVeranstaltungDialog(projekt)" class="p-default">
                  <strong>{{projekt.name}}</strong><br>
                  <small>{{projekt.anfangDatum | date: 'dd.MM.yyyy'}}</small> -
                  <small>{{projekt.endeDatum | date: 'dd.MM.yyyy'}}</small>
                </div>
                <div>
                  <button mat-button class="narrow-btn p-default" style="position: absolute; right: 10px; top: 20px;"
                    (click)="setFavourite(projekt.code)">
                    <i class="fas fa-star" *ngIf="isFavourite(projekt.code)" style="color:#499ce0;"></i>
                    <i class="far fa-star" *ngIf="!isFavourite(projekt.code)"></i>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="viewMode == 1">

          <div class="max-height x-scroll">
            <div class="timeline-wrap overflow-hidden max-height border-top relative"
              [style.width.px]="columnWidth*timeline.length">
              <div class="absolute z-100" style="left:0;right:0">

                <div class="overflow-hidden">
                  <ng-container *ngFor="let day of timeline; let dateIndex = index">
                    <div class="align-center timeline-cell timeline-head"
                      *ngIf="isMonth(day)"
                      [ngClass]="{'month-start':day.getDate() == 1}"
                      [style.width.px]="columnWidth * getMonthlength(day)">
                      {{day | date:"MMMM"}}
                    </div>
                  </ng-container>
                </div>

                <div class="overflow-hidden">
                  <ng-container *ngFor="let day of timeline; let dateIndex = index">
                    <div class="align-center timeline-cell timeline-head weekday-{{day | date:'EEEE'}}"
                      [ngClass]="{'today':checkForToday(day), 'month-start':day.getDate() == 1}"
                      [style.width.px]="columnWidth">
                      {{day | date:"EEEEEE"}}
                    </div>
                  </ng-container>
                </div>

                <div class="overflow-hidden">
                  <ng-container *ngFor="let day of timeline; let dateIndex = index">
                    <div class="align-center timeline-cell timeline-head weekday-{{day | date:'EEEE'}}"
                      [ngClass]="{'today':checkForToday(day), 'month-start':day.getDate() == 1}"
                      [style.width.px]="columnWidth" title='{{day | date:"dd.MM.yyy"}}'>
                      <div class="inline-block">{{day | date:"dd."}}</div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="max-height y-scroll" style="margin-right: -20px; padding-top:92px">

                <div class="relative">
                  <ng-container *ngFor="let projekt of projektList; let projektIndex = index">
                    <div (click)="openVeranstaltungDialog(projekt)" class="projekt-cell"
                      [style.left.px]="columnWidth*getProjektOffset(projekt.anfangDatum)" title="{{projekt.name}}"
                      [style.top.px]="columnHeight*projektIndex" [style.width.px]="columnWidth*getProjektDays(projekt)"
                      [style.height.px]="columnHeight-2" style="margin-top:1px" *ngIf="!favouriteFilter || isFavourite(projekt.code)">
                      {{projekt.name}}
                    </div>
                  </ng-container>
                </div>

                <ng-container *ngFor="let day of timeline; let dateIndex = index">
                  <div class="align-center timeline-cell timeline-body weekday-{{day | date:'EEEE'}}"
                    [style.width.px]="columnWidth"
                    [ngClass]="{'today':checkForToday(day), 'month-start':day.getDate() == 1}"
                    [style.height.px]="columnHeight*projektList.length" style="min-height:100%"
                    title='{{day | date:"dd.MM.yyy"}}'>
                  </div>
                </ng-container>

              </div>
            </div>
          </div>
        </ng-container>

        <!--</drag-scroll>-->
      </div>

    </div>
  </div>
</div>

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { List } from 'linqts';
export class MailConfigurationDialogComponent {
    constructor(dialogRef, data, apiService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.apiService = apiService;
        this.availableEmailList = [];
        this.chosenEmails = [];
        this.emailControl = new FormControl();
        this.getAnsprechpartner();
    }
    getAnsprechpartner() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let ansprechpartner = null;
            if (localStorage.getItem("ansprechpartner")) {
                ansprechpartner = JSON.parse(localStorage.getItem("ansprechpartner"));
            }
            if (ansprechpartner) {
                let availableAnsprechpartnerList = yield this.apiService.getAnsprechpartnerByKundeCode(ansprechpartner.kundenCode);
                this.availableEmailList = new List(availableAnsprechpartnerList).Select(x => x.eMail).ToArray();
            }
        });
    }
    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    close() {
        this.dialogRef.close(this.chosenEmails);
    }
    ngOnInit() {
        this.filteredEmailList = this.emailControl.valueChanges
            .pipe(startWith(''), map(value => this._filter(value)));
    }
    _filter(name) {
        const filterValue = name.toLowerCase();
        return this.availableEmailList.filter(option => option.toLowerCase().includes(filterValue));
    }
    addEmail() {
        if (this.validateEmail(this.emailControl.value)) {
            this.chosenEmails.push(this.emailControl.value);
            this.emailControl.setValue("");
        }
    }
    removeEmail(index) {
        this.chosenEmails.splice(index, 1);
    }
}

import { PageComponentContentOrientation } from "../enums/page-component-content-orientation.enum";
import { PageComponentContentAlign } from "../enums/page-component-content-align.enum";

export class PageComponentContent {
    title: string
    text: string
    link: string
    target: string
    src: string
    alt: string
    orientation:PageComponentContentOrientation
    align:PageComponentContentAlign
    imageMargin: number
    imageWidth: number

    constructor(){
        this.title = ""
        this.text = ""
        this.link = ""
        this.target = "_self"
        this.src = ""
        this.alt = ""
        this.orientation = PageComponentContentOrientation.ImageTop
        this.imageMargin = 15;
        this.imageWidth = 0;
        this.align = PageComponentContentAlign.Left
    }
}

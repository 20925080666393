<ng-container *ngIf="currentShopDefinition && currentShopDefinition != undefined">
    <header>
        <nav class="p-top-lg-20 p-top-md-20">
            <div class="container max-height white-text">
                <div class="max-height">

                    <div class="nav-links max-height float-left" style="margin:-10px 0">
                        <ng-container *ngIf="currentShopDefinition.logoImagePath1.length > 0">

                            <img src="{{currentShopDefinition.logoImagePath1}}" class="relative pointer"
                                style="z-index:200;" [style.height.px]="currentShopDefinition.logo1Height"
                                [style.marginTop.px]="currentShopDefinition.logo1MarginTop"
                                [style.marginBottom.px]="currentShopDefinition.logo1MarginBottom"
                                [style.marginLeft.px]="currentShopDefinition.logo1MarginLeft"
                                [style.marginRight.px]="currentShopDefinition.logo1MarginRight" [routerLink]="['/']">
                            <span style="padding: 0 10px" [style.color]="currentShopDefinition.secondaryColor"
                                class="hidden-xs">|</span>
                        </ng-container>


                        <a class="pointer hidden-xs" [matMenuTriggerFor]="shopDefinitionsMenu" style="margin-left:-15px"
                            *ngIf="loggedUser != null && loggedUser.master"
                            [style.color]="currentShopDefinition.secondaryColor">
                            {{currentShopDefinition.name}}
                        </a>
                        <a class="pointer hidden-xs" style="margin-left:-15px"
                            *ngIf="ansprechpartner != null || (loggedUser != null && !loggedUser.master)"
                            [style.color]="currentShopDefinition.secondaryColor">
                            {{currentShopDefinition.name}}
                        </a>
                        <a class="pointer " *ngIf="loginMode == 0 && loggedUser != null && loggedUser.master"
                            (click)="openShopDefinitionDialog()" [style.color]="currentShopDefinition.secondaryColor">
                            <i class="fas fa-cog"></i>
                        </a>
                    </div>
                    <div class="float-right max-height nav-links hidden-sm hidden-xs" style="margin:-10px 0">

                        <a class="inline-block pointer" [routerLink]="['/artikel']"
                            *ngIf="currentShopDefinition.displayArtikel"
                            [style.color]="currentShopDefinition.secondaryColor">
                            {{currentShopDefinition.artikelNavigationTitle}}
                        </a>
                        <a class="inline-block pointer" [routerLink]="['/bestellungen']"
                            [style.color]="currentShopDefinition.secondaryColor">
                            {{currentShopDefinition.bestellungenNavigationTitle}}
                        </a>
                        <a class="inline-block pointer" [routerLink]="['/veranstaltungen']"
                            *ngIf="currentShopDefinition.displayProjekte"
                            [style.color]="currentShopDefinition.secondaryColor">
                            {{currentShopDefinition.projekteNavigationTitle}}
                        </a>
                        <a class="inline-block pointer" [matMenuTriggerFor]="userMenu"
                            [style.color]="currentShopDefinition.secondaryColor">
                            <i class="fas fa-user-circle"></i>
                        </a>

                    </div>

                    <div class="float-right max-height nav-links visible-sm visible-xs" style="margin:-10px 0">
                        <a class="inline-block pointer" (click)="openSideNav = true;"
                            [style.color]="currentShopDefinition.secondaryColor">
                            <i class="fas fa-bars"></i>
                        </a>
                    </div>

                    <mat-menu #shopDefinitionsMenu="matMenu" [overlapTrigger]="false">
                        <ng-container *ngFor="let shopDefinition of shopDefinitions;let shopDefinitionIndex = index">
                            <button mat-menu-item (click)="setShopDefinition(shopDefinitionIndex)">
                                <ng-container *ngIf="shopDefinition && shopDefinition != undefined">
                                    {{shopDefinition.name}}
                                </ng-container>
                            </button>
                        </ng-container>

                    </mat-menu>

                    <mat-menu #userMenu="matMenu" [overlapTrigger]="false" [xPosition]="'before'">
                        <div class="border-bottom p-default pointer"
                            style="background:whitesmoke;    margin-top: -7.5px;" [routerLink]="['/bestellungen']">
                            <i class="fas fa-user-circle big"></i>
                            <div style="margin: 4px 0 0 7.5px;" class="inline-block" *ngIf="loggedUser != null">
                                <strong class="">{{loggedUser.anzeigename}}</strong><br>
                                <span class="">{{loggedUser.eMail}}</span>
                            </div>
                            <div style="margin: 4px 0 0 7.5px;" class="inline-block" *ngIf="ansprechpartner != null">
                                <strong class="">{{ansprechpartner.vorname}} {{ansprechpartner.name}}</strong><br>
                                <span class="">{{ansprechpartner.eMail}}</span>
                            </div>
                        </div>
                         <button mat-menu-item (click)="openUserManagement()" *ngIf="_shopService.isShopMaster()" style="margin-bottom: -7.5px;">
                            <i class="fas fa-user-friends"  ></i>&nbsp;
                            ZUGÄNGE VERWALTEN
                        </button> 
                        <button mat-menu-item (click)="logout()" style="color:red;    margin-bottom: -7.5px;">
                            <i class="fas fa-sign-out-alt" style="color:red"></i>&nbsp;
                            ABMELDEN
                        </button>

                    </mat-menu>


                    <div class="float-right max-height nav-links " style="margin:-10px 0;margin-right:-10px"
                        [style.color]="currentShopDefinition.secondaryColor">
                    </div>
                </div>
            </div>
        </nav>
    </header>
    <div class="head-banner absolute max-width" [style.backgroundImage]="getBackgroundImagePath()"
        [style.backgroundColor]="currentShopDefinition.primaryColor">
    </div>

    <div class="sidenav-wrap" *ngIf="openSideNav">
        <div class="sidenav-overlay" (click)="openSideNav = false"></div>
        <div class="sidenav-content fade-right">
            <div class="p-default pointer border-bottom " [routerLink]="['/bestellungen']" (click)="openSideNav = false"
                style="background:whitesmoke">
                <i class="fas fa-user-circle big float-left"></i>

                <div style="margin: 4px 0 0 7.5px;" class="inline-block" *ngIf="loggedUser != null">
                    <strong class="">{{loggedUser.anzeigename}}</strong><br>
                    <span class="">{{loggedUser.eMail}}</span>
                </div>
                <div style="margin: 4px 0 0 7.5px;" class="inline-block" *ngIf="ansprechpartner != null">
                    <strong class="">{{ansprechpartner.vorname}} {{ansprechpartner.name}}</strong><br>
                    <span class="">{{ansprechpartner.eMail}}</span>
                </div>
            </div>

            <div class="p-default pointer border-bottom hover-button" [routerLink]="['/artikel']"
                (click)="openSideNav = false" *ngIf="currentShopDefinition.displayArtikel">
                {{currentShopDefinition.artikelNavigationTitle}}
            </div>
            <div class="p-default pointer border-bottom hover-button" [routerLink]="['/bestellungen']"
                (click)="openSideNav = false">
                {{currentShopDefinition.bestellungenNavigationTitle}}
            </div>
            <div class="p-default pointer border-bottom hover-button" [routerLink]="['/veranstaltungen']"
                *ngIf="currentShopDefinition.displayProjekte" (click)="openSideNav = false">
                {{currentShopDefinition.projekteNavigationTitle}}
            </div>
            <div class="p-default pointer border-bottom hover-button" (click)="logout()" style="color:red">
                <i class="fas fa-sign-out-alt"></i>
                Abmelden
            </div>


        </div>
    </div>

</ng-container>
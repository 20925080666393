import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ShopDefinition } from '../classes/shop-definition';
import { Artikel, Position, Auftrag, ObjectType, Angebot, Bestellung, Bedarf, Kalkulation, Config } from '../../../node_modules/@work4all/api';
import { ApiService } from './api.service';
import { FormDefinition } from '../classes/form-definition';
import { FormComponentType } from '../enums/form-component-type.enum';
import { OrderDefinition } from '../classes/order-definition';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { SucheComponent } from '../dialogs/suche/suche.component';
import { SucheDialogData } from '../classes/suche-dialog-data';
import { ExternalLogin } from '../classes/external-login';
import { ManagedExternalLogin } from '../classes/managed-external-login';
import { List } from 'linqts';
import { GenericDialogComponent } from '../ui/generic-dialog/generic-dialog.component';

@Injectable()
export class ShopService {


  get loggedUser(): any {
    return this.apiService.getLoggedUser();
  }

  constructor(protected http: HttpClient, protected apiService: ApiService, protected config: Config, protected dialog: MatDialog) {

    if (localStorage.getItem('w4aShopWarenkorb') != undefined) {
      this._warenkorb = JSON.parse(localStorage.getItem('w4aShopWarenkorb'));
    } else {
      this._warenkorb = [];
    }

    this.apiService.setLoggedUser();
  }

  versionName = '1.1.9';

  _shopDefinitions: ShopDefinition[] = [];
  private shopDefinitions = new BehaviorSubject<ShopDefinition[]>(null);
  public ShopDefinitions = this.shopDefinitions.asObservable();

  _currentShopDefinition: ShopDefinition;
  private currentShopDefinition = new BehaviorSubject<ShopDefinition>(null);
  public CurrentShopDefinition = this.currentShopDefinition.asObservable();

  _currentShopDefinitionIndex: number;
  private currentShopDefinitionIndex = new BehaviorSubject<number>(null);
  public CurrentShopDefinitionIndex = this.currentShopDefinitionIndex.asObservable();

  _warenkorb: Position[];
  private warenkorb = new BehaviorSubject<Position[]>(null);
  public Warenkorb = this.warenkorb.asObservable();

  _currentBeleg: Angebot | Auftrag | Kalkulation | Bestellung | Bedarf;
  private currentBeleg = new BehaviorSubject<Angebot | Auftrag | Kalkulation | Bestellung | Bedarf>(null);
  public CurrentBeleg = this.currentBeleg.asObservable();

  _currentArtikelGruppen: number[] = [];
  private currentArtikelGruppen = new BehaviorSubject<number[]>(null);
  public CurrentArtikelGruppen = this.currentArtikelGruppen.asObservable();

  _currentPflichtartikelGruppen: number[] = [];
  private currentPflichtartikelGruppen = new BehaviorSubject<number[]>(null);
  public CurrentPflichtartikelGruppen = this.currentPflichtartikelGruppen.asObservable();

  public firstLoadCompleted = false;

  private tmpWarenkorb: number[] = [];

  managedExternalLogins: ManagedExternalLogin[] = [];

  mwstRates: number[] = [0, 0.19, 0.07, 0.16, 0];

  backupBeleg = null;

  async getShopDefinitions() {

    if (localStorage.getItem('loggedUser') != null && localStorage.getItem('loggedUser') != undefined) {
      this.apiService.getShopdefinitions(data => {
        if (data) {
          this._shopDefinitions = data;

          this.checkShopDefinitions();
        }
      });
    } else {
      this.apiService.getShopdefinitionById(null, data => {
        this._shopDefinitions = [data];

        this.checkShopDefinitions();
      });
    }


  }

  getManagedExternalLoginByID(id: string) {
    return new List(this.managedExternalLogins).FirstOrDefault(x => x.loginID == id);
  }

  async getManagedExternalLogins() {
    try {
      this.managedExternalLogins = await this.apiService.getManagedExternalLogins();
    } catch (err) {
      this.managedExternalLogins = [];
    }
  }

  checkShopDefinitions() {
    this._shopDefinitions.forEach((shopDefinition, shopDefinitionIndex) => {
      const newShopDefinition = new ShopDefinition('');
      $.extend(true, newShopDefinition, shopDefinition);
      if (newShopDefinition.orderDefinitions.length == 0) {
        const formDefinition = new FormDefinition();
        const orderDefinition = new OrderDefinition('Standard');
        orderDefinition.formDefinition = formDefinition;
        newShopDefinition.orderDefinitions.push(orderDefinition);
      }
      if (newShopDefinition.footerLinks == undefined || newShopDefinition.footerLinks == null) {
        newShopDefinition.footerLinks = [];
      }
      if (!newShopDefinition.displayInfofensterCodes) {
        newShopDefinition.displayInfofensterCodes = [];
      }

      this._shopDefinitions[shopDefinitionIndex] = newShopDefinition;
    });

    this.saveShopDefinitions();
  }

  async setShopDefinition(index = this.currentShopDefinitionIndex.value) {
    if (index != null || (this._currentShopDefinition == null || this._currentShopDefinition == undefined)) {
      if (index == null) {
        index = 0;
      }

      this._currentShopDefinition = this._shopDefinitions[index];
      this._currentShopDefinitionIndex = index;
      this.saveShopDefinitions();

      if (this._currentShopDefinition != undefined && this._currentShopDefinition.orderDefinitions[this.getFirstActiveOrderDefinitionIndex()].formDefinition.bzObjType == ObjectType.Bedarfsanforderung) {
        this.apiService.setLoggedUser(true);
      } else {
        this.apiService.setLoggedUser(false);
      }
      this.firstLoadCompleted = true;
    }
    return true;
  }

  updateShopDefinition(shopDefinition: ShopDefinition) {
    this._shopDefinitions[this._currentShopDefinitionIndex] = shopDefinition;
    // console.log(shopDefinition)
    this.saveShopDefinitions();

    this.apiService.updateShopDefinition(shopDefinition.id, shopDefinition, success => {
      return success;
    }, error => {
      return error;
    });
  }

  insertShopDefinition(shopDefinition: ShopDefinition) {
    this._shopDefinitions.push(shopDefinition);

    this.apiService.insertShopDefinition(shopDefinition, success => {
      this.getShopDefinitions();
      /*this._currentShopDefinitionIndex = this._shopDefinitions.length - 1
      this.setShopDefinition(this._currentShopDefinitionIndex)*/
      return success;
    }, error => {
      return error;
    });
  }

  deleteShopDefinition() {


    this._shopDefinitions.splice(this._currentShopDefinitionIndex, 1);
    if (this._shopDefinitions.length == 0) {
      const newShopDefinition: ShopDefinition = new ShopDefinition('work4all Shop');
      this._shopDefinitions.push(newShopDefinition);
    }
    this._currentShopDefinitionIndex = 0;
    this.setShopDefinition(this._currentShopDefinitionIndex);
  }

  saveShopDefinitions() {


    this._currentShopDefinition = this._shopDefinitions[this._currentShopDefinitionIndex];
    const newShopDefinition: ShopDefinition = new ShopDefinition('');
    $.extend(true, newShopDefinition, this._currentShopDefinition);

    this.currentShopDefinition.next(newShopDefinition);
    this.currentShopDefinitionIndex.next(this._currentShopDefinitionIndex);
    this.shopDefinitions.next(JSON.parse(JSON.stringify(this._shopDefinitions)));

    this.warenkorb.next(this._warenkorb);

    localStorage.setItem('w4aShopWarenkorb', JSON.stringify(this._warenkorb));
    // localStorage.setItem("w4aShopDefinitions", JSON.stringify(this._shopDefinitions))
  }

  getObjectTypeString(bzObjType: ObjectType): string {
    let result = '';
    switch (bzObjType) {
      case ObjectType.Kunde: result = 'Kunde'; break;
      case ObjectType.Lieferant: result = 'Lieferant'; break;
      default: break;
    }
    return result;
  }

  countActiveOrderDefinitions(shopDefinitionIndex: number = this._currentShopDefinitionIndex) {
    let result = 0;
    this._shopDefinitions[shopDefinitionIndex].orderDefinitions.forEach(orderDefinition => {
      if (!orderDefinition.deleted && orderDefinition.active) {
        result++;
      }
    });

    return result;
  }

  getFirstActiveOrderDefinitionIndex(shopDefinitionIndex: number = this._currentShopDefinitionIndex) {
    let result: number = null;

    if (shopDefinitionIndex != null && this._shopDefinitions[shopDefinitionIndex] != undefined) {
      this._shopDefinitions[shopDefinitionIndex].orderDefinitions.some((orderDefinition, orderDefinitionIndex) => {
        if (!orderDefinition.deleted && orderDefinition.active) {
          result = orderDefinitionIndex;
          return true;
        }
      });
    }
    return result;
  }


  getSdObjmemberCode() {
    const orderDefinition: OrderDefinition = this._currentShopDefinition.orderDefinitions[this.getFirstActiveOrderDefinitionIndex()];


    if (orderDefinition.formDefinition.bzObjType == ObjectType.Bedarfsanforderung || orderDefinition.formDefinition.bzObjType == ObjectType.Bestellung) {
      // Nur als work4all Benutzer möglich
      // return this.loggedUser.fullBenutzerInfo.liferantenCode
      return this.config.get('lieferantenCode');
    } else {
      if (localStorage.getItem('sdObjMemberCode') && localStorage.getItem('sdObjMemberCode') != undefined) {
        // Wenn ShopLogin
        return JSON.parse(localStorage.getItem('sdObjMemberCode'));
      } else {
        // Wenn work4all Benutzer
        return this.config.get('kundenCode');
      }
    }
  }


  getSdObjmemberType() {
    const orderDefinition: OrderDefinition = this._currentShopDefinition.orderDefinitions[this.getFirstActiveOrderDefinitionIndex()];

    if (orderDefinition.formDefinition.bzObjType == ObjectType.Bedarfsanforderung || orderDefinition.formDefinition.bzObjType == ObjectType.Bestellung) {
      // Nur als work4all Benutzer möglich
      return 'Lieferant';
    } else {
      // Wenn work4all Benutzer
      return 'Kunde';
    }
  }

  addPositionToWarenkorb(artikel: Artikel, anzahl: number) {

    // let orderDefinition: OrderDefinition = this._currentShopDefinition.orderDefinitions[this.getFirstActiveOrderDefinitionIndex()]

    const sdObjMemberCode = this.getSdObjmemberCode();
    const sdObjType = this.getSdObjmemberType();

    let found = false;
    this._warenkorb.some((position, positionIndex) => {
      if (position.artikelCode == artikel.code) {
        this._warenkorb[positionIndex].anzahl =  this._warenkorb[positionIndex].anzahl as number + anzahl;

        if (this.countActiveOrderDefinitions() == 1) {

          this.determinePositionType(sdObjMemberCode, sdObjType, artikel,  this._warenkorb[positionIndex].anzahl as number, positionIndex);

        } else {
          this.apiService.createAuftrag(sdObjMemberCode, sdObjType, (beleg) => {
            if (beleg) {
              this.apiService.createAuftragPosition(beleg, '', artikel.code,  this._warenkorb[positionIndex].anzahl as number, position => {
                this.addPosition(position, artikel, positionIndex);
              });
            }
          });
        }

        found = true;
        return true;
      }
    });

    if (!found) {
      this.determinePositionType(sdObjMemberCode, sdObjType, artikel, anzahl, null);
    }

  }

  determinePositionType(sdObjMemberCode: number, sdObjType: string, artikel: Artikel, anzahl: number, positionIndex: number = null) {

    const orderDefinition: OrderDefinition = this._currentShopDefinition.orderDefinitions[this.getFirstActiveOrderDefinitionIndex()];

    switch (orderDefinition.formDefinition.bzObjType) {

      case ObjectType.Auftrag:
        this.apiService.createAuftrag(sdObjMemberCode, sdObjType, (beleg) => {
          if (beleg) {
            this.apiService.createAuftragPosition(beleg, '', artikel.code, anzahl, position => {
              this.addPosition(position, artikel, positionIndex);
            });
          }
        }); break;

      case ObjectType.Angebot:
        this.apiService.createAngebot(sdObjMemberCode, sdObjType, (beleg) => {
          if (beleg) {
            this.apiService.createAngebotPosition(beleg, '', artikel.code, anzahl, position => {
              this.addPosition(position, artikel, positionIndex);
            });
          }
        }); break;

      case ObjectType.Kalkulation:
        this.apiService.createKalkulation(sdObjMemberCode, sdObjType, (beleg) => {
          if (beleg) {
            this.apiService.createKalkulationPosition(beleg, '', artikel.code, anzahl, position => {
              this.addPosition(position, artikel, positionIndex);
            });
          }
        }); break;

      case ObjectType.Bedarfsanforderung:
        this.apiService.createBedarf(sdObjMemberCode, sdObjType, (beleg) => {
          if (beleg) {
            this.apiService.createBedarfPosition(beleg, '', artikel.code, anzahl, position => {
              this.addPosition(position, artikel, positionIndex);
            });
          }
        }); break;

      case ObjectType.Bestellung:
        this.apiService.createBestellung(sdObjMemberCode, sdObjType, (beleg) => {
          if (beleg) {
            this.apiService.createBestellungPosition(beleg, '', artikel.code, anzahl, position => {
              this.addPosition(position, artikel, positionIndex);
            });
          }
        }); break;

      default: break;
    }
  }

  addPosition(position: Position, artikel, positionIndex = null) {
    position.artikel = artikel;

    if (positionIndex != null) {
      this._warenkorb[positionIndex] = new Position();
      $.extend(true, this._warenkorb[positionIndex], position);
    } else {
      this._warenkorb.push(position);
    }

    this.saveShopDefinitions();
  }

  displayCurrencyFormat(value): string {
    return value.toString().replace('.', ',') + ' €';
  }

  deletePosition(positionIndex) {
    this._warenkorb.splice(positionIndex, 1);
    this.saveShopDefinitions();
  }

  changePositionAnzahl(positionIndex, anzahl) {

    const sdObjMemberCode = this.getSdObjmemberCode();
    const sdObjType = this.getSdObjmemberType();

    this.apiService.createAuftrag(sdObjMemberCode, sdObjType, (auftrag) => {
      if (auftrag) {
        this.apiService.createAuftragPosition(auftrag, '', this._warenkorb[positionIndex]['artikel'].code,  this._warenkorb[positionIndex].anzahl as number, position => {
          position['artikel'] = this._warenkorb[positionIndex]['artikel'];
          this._warenkorb[positionIndex] = new Position();
          $.extend(true, this._warenkorb[positionIndex], position);
          this.saveShopDefinitions();
        });
      }
    });

  }



  clearWarenkorb() {
    this._warenkorb = [];
    this.saveShopDefinitions();
  }



  fillIndividualField(bzObj, definitionCode: number, value: string) {
    let found = false;
    if (definitionCode == 730409752) {
      bzObj;
    }
    if (bzObj) {
      bzObj.individualFieldData.some((feld, feldIndex) => {
        if (feld.definitionCode == definitionCode) {
          feld.value = value;
          found = true;
          return true;
        }
      });

      if (!found) {
        bzObj.individualFieldData.push({
          definitionCode,
          value
        });
      }
    }

    return bzObj;

  }

  fillBzObject(bzObj, formDefinition: FormDefinition): any {

    for (const component of formDefinition.components) {
      switch (component.type) {
        case FormComponentType.Individuell: {
          if (component.value == '' || component.value == null) {
            this.fillIndividualField(bzObj, component.mapping.Code, null);
          } else {
            if (component.mapping.Control == 'Text' ||
              component.mapping.Control == 'Numeric' ||
              component.mapping.Control == 'Check' ||
              component.mapping.Control == 'Combo' ||
              component.mapping.Control == 'List' ||
              component.mapping.Control == 'DateType') {
              bzObj = this.fillIndividualField(bzObj, component.mapping.Code, component.value);
            }
          }
          break;
        }

        case FormComponentType.LieferadresseAuswahl: {
          if (component.value.code != undefined) {
            // this.apiService.getBriefanschrift(component.value.sdObjType,component.value.code,0,true).then((briefadresse)=>{
            bzObj.nebenadresse1CompanyCode = component.value.code;
            bzObj.nebenadresse1CompanyType = component.value.sdObjType;
            bzObj.nebenadresse1Text = component.value.lieferadresse;
            // })

          }
          break;
        }

        case FormComponentType.RechnungsadresseAuswahl: {
          if (component.value.code != undefined) {
            bzObj.nebenadresse2CompanyCode = component.value.code;
            bzObj.nebenadresse2CompanyType = component.value.sdObjType;
            bzObj.nebenadresse2Text = component.value.rechnungsadresse;
          }
          break;
        }

        case FormComponentType.Lieferadresse: {
          bzObj.nebenadresse1Text = '';
          if (component.value.lieferadresse != undefined) {
            if (component.value.lieferadresse.firma.trim() != '') {
              bzObj.nebenadresse1Text += component.value.lieferadresse.firma + '\n\r';
            }
            if (component.value.lieferadresse.ansprechpartner.trim() != '') {
              bzObj.nebenadresse1Text += component.value.lieferadresse.ansprechpartner + '\n\r';
            }
            if (component.value.lieferadresse.strasse.trim() != '') {
              bzObj.nebenadresse1Text += component.value.lieferadresse.strasse + '\n\r';
            }
            if (component.value.lieferadresse.plz.trim() != '') {
              bzObj.nebenadresse1Text += component.value.lieferadresse.plz + ' ';
            }
            if (component.value.lieferadresse.ort.trim() != '') {
              bzObj.nebenadresse1Text += component.value.lieferadresse.ort;
            }
          }

          break;
        }
        case FormComponentType.Rechnungsadresse: {
          bzObj.nebenadresse2Text = '';
          if (component.value.rechnungsadresse != undefined) {
            if (component.value.rechnungsadresse.firma.trim() != '') {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.firma + '\n\r';
            }
            if (component.value.rechnungsadresse.ansprechpartner.trim() != '') {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.ansprechpartner + '\n\r';
            }
            if (component.value.rechnungsadresse.strasse.trim() != '') {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.strasse + '\n\r';
            }
            if (component.value.rechnungsadresse.plz.trim() != '') {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.plz + ' ';
            }
            if (component.value.rechnungsadresse.ort.trim() != '') {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.ort;
            }
          }

          break;
        }
        case FormComponentType.LieferRechnungsadresse: {
          bzObj.nebenadresse2Text = '';
          bzObj.nebenadresse1Text = '';
          if (component.value.rechnungsadresse != undefined) {
            if (component.value.rechnungsadresse.firma.trim() != '') {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.firma + '\n\r';
            }
            if (component.value.rechnungsadresse.ansprechpartner.trim() != '') {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.ansprechpartner + '\n\r';
            }
            if (component.value.rechnungsadresse.strasse.trim() != '') {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.strasse + '\n\r';
            }
            if (component.value.rechnungsadresse.plz.trim() != '') {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.plz + ' ';
            }
            if (component.value.rechnungsadresse.ort.trim() != '') {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.ort;
            }
          }
          if (!component.value.lieferadresseIdentischMitRechnungsadresse) {
            if (component.value.lieferadresse != undefined) {
              if (component.value.lieferadresse.firma.trim() != '') {
                bzObj.nebenadresse1Text += component.value.lieferadresse.firma + '\n\r';
              }
              if (component.value.lieferadresse.ansprechpartner.trim() != '') {
                bzObj.nebenadresse1Text += component.value.lieferadresse.ansprechpartner + '\n\r';
              }
              if (component.value.lieferadresse.strasse.trim() != '') {
                bzObj.nebenadresse1Text += component.value.lieferadresse.strasse + '\n\r';
              }
              if (component.value.lieferadresse.plz.trim() != '') {
                bzObj.nebenadresse1Text += component.value.lieferadresse.plz + ' ';
              }
              if (component.value.lieferadresse.ort.trim() != '') {
                bzObj.nebenadresse1Text += component.value.lieferadresse.ort;
              }
            }
          } else {
            bzObj.nebenadresse1Text = bzObj.nebenadresse2Text;
          }
          break;
        }
        case FormComponentType.Projekt: {
          if (component.value.code != undefined) {
            bzObj.projektCode = component.value.code;
          }
          break;
        }
        case FormComponentType.Niederlassung: {
          if (component.value.code != undefined) {
            bzObj.niederlassungCode = component.value.code;
          }
          break;
        }
        case FormComponentType.Kostenstelle: {
          if (component.value.code != undefined) {
            bzObj.kostenstelle = component.value.code;
          }
          break;
        }
        case FormComponentType.Notiz: {
          if (component.value != undefined) {
            bzObj.notiz = component.value;
          }
          break;
        }
        default: break;
      }
    }

    return bzObj;

  }

  getIndividualFieldValue(bzObj, definitionCode: number) {
    let result = null;

    if (bzObj) {
      bzObj.individualFieldData.some((feld) => {
        if (feld.definitionCode == definitionCode) {
          if (feld.Value != undefined) {
            result = feld.Value;
          }
          if (feld.value != undefined) {
            result = feld.value;
          }

          return true;
        }
      });
    }

    return result;

  }

  fillFormDefinition(bzObj, formDefinition: FormDefinition): any {

    formDefinition.components.forEach(component => {


      switch (component.type) {
        case FormComponentType.Individuell: {


          if (component.mapping.Code == undefined) {
            component.mapping.Code = component.mapping.code;
          }
          if (component.mapping.Control == undefined) {
            component.mapping.Control = component.mapping.control;
          }
          if (component.mapping.DisplayOrder == undefined) {
            component.mapping.DisplayOrder = component.mapping.displayOrder;
          }
          if (component.mapping.FieldNumber == undefined) {
            component.mapping.FieldNumber = component.mapping.fieldNumber;
          }
          if (component.mapping.IndividualFieldData == undefined) {
            component.mapping.IndividualFieldData = component.mapping.individualFieldData;
          }
          if (component.mapping.IndividualPageCode == undefined) {
            component.mapping.IndividualPageCode = component.mapping.individualPageCode;
          }
          if (component.mapping.PflichtFeld == undefined) {
            component.mapping.PflichtFeld = component.mapping.pflichtFeld;
          }
          if (component.mapping.TableType == undefined) {
            component.mapping.TableType = component.mapping.tableType;
          }
          if (component.mapping.Title == undefined) {
            component.mapping.Title = component.mapping.title;
          }
          if (component.mapping.Tooltip == undefined) {
            component.mapping.Tooltip = component.mapping.tooltip;
          }

          if (this.getIndividualFieldValue(bzObj, component.mapping.Code)) {
            component.value = this.getIndividualFieldValue(bzObj, component.mapping.Code);
          }
          break;
        }

        case FormComponentType.LieferadresseAuswahl: {
          component.value = {
            code: bzObj.nebenadresse1CompanyCode,
            sdObjType: bzObj.nebenadresse1CompanyType,
            lieferadresse: bzObj.nebenadresse1Text
          };

          break;
        }

        case FormComponentType.RechnungsadresseAuswahl: {
          component.value = {
            code: bzObj.nebenadresse2CompanyCode,
            sdObjType: bzObj.nebenadresse2CompanyType,
            rechnungsadresse: bzObj.nebenadresse2Text
          };
          break;
        }

        /*case FormComponentType.Lieferadresse: {
          bzObj.nebenadresse1Text = "";
          if (component.value.lieferadresse != undefined) {
            if (component.value.lieferadresse.firma.trim() != "") {
              bzObj.nebenadresse1Text += component.value.lieferadresse.firma + "\n\r"
            }
            if (component.value.lieferadresse.ansprechpartner.trim() != "") {
              bzObj.nebenadresse1Text += component.value.lieferadresse.ansprechpartner + "\n\r"
            }
            if (component.value.lieferadresse.strasse.trim() != "") {
              bzObj.nebenadresse1Text += component.value.lieferadresse.strasse + "\n\r"
            }
            if (component.value.lieferadresse.plz.trim() != "") {
              bzObj.nebenadresse1Text += component.value.lieferadresse.plz + " "
            }
            if (component.value.lieferadresse.ort.trim() != "") {
              bzObj.nebenadresse1Text += component.value.lieferadresse.ort
            }
          }

          break;
        }
        case FormComponentType.Rechnungsadresse: {
          bzObj.nebenadresse2Text = "";
          if (component.value.rechnungsadresse != undefined) {
            if (component.value.rechnungsadresse.firma.trim() != "") {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.firma + "\n\r"
            }
            if (component.value.rechnungsadresse.ansprechpartner.trim() != "") {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.ansprechpartner + "\n\r"
            }
            if (component.value.rechnungsadresse.strasse.trim() != "") {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.strasse + "\n\r"
            }
            if (component.value.rechnungsadresse.plz.trim() != "") {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.plz + " "
            }
            if (component.value.rechnungsadresse.ort.trim() != "") {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.ort
            }
          }
          break;
        }
        case FormComponentType.LieferRechnungsadresse: {
          bzObj.nebenadresse2Text = "";
          bzObj.nebenadresse1Text = "";
          if (component.value.rechnungsadresse != undefined) {
            if (component.value.rechnungsadresse.firma.trim() != "") {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.firma + "\n\r"
            }
            if (component.value.rechnungsadresse.ansprechpartner.trim() != "") {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.ansprechpartner + "\n\r"
            }
            if (component.value.rechnungsadresse.strasse.trim() != "") {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.strasse + "\n\r"
            }
            if (component.value.rechnungsadresse.plz.trim() != "") {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.plz + " "
            }
            if (component.value.rechnungsadresse.ort.trim() != "") {
              bzObj.nebenadresse2Text += component.value.rechnungsadresse.ort
            }
          }
          if (!component.value.lieferadresseIdentischMitRechnungsadresse) {
            if (component.value.lieferadresse != undefined) {
              if (component.value.lieferadresse.firma.trim() != "") {
                bzObj.nebenadresse1Text += component.value.lieferadresse.firma + "\n\r"
              }
              if (component.value.lieferadresse.ansprechpartner.trim() != "") {
                bzObj.nebenadresse1Text += component.value.lieferadresse.ansprechpartner + "\n\r"
              }
              if (component.value.lieferadresse.strasse.trim() != "") {
                bzObj.nebenadresse1Text += component.value.lieferadresse.strasse + "\n\r"
              }
              if (component.value.lieferadresse.plz.trim() != "") {
                bzObj.nebenadresse1Text += component.value.lieferadresse.plz + " "
              }
              if (component.value.lieferadresse.ort.trim() != "") {
                bzObj.nebenadresse1Text += component.value.lieferadresse.ort
              }
            }
          }
          else {
            bzObj.nebenadresse1Text = bzObj.nebenadresse2Text
          }
          break;
        }*/

        case FormComponentType.Projekt: {
          if (bzObj.projektCode) {

            this.apiService.getProjektByCode(bzObj.projektCode, data => {
              component.value = data;
            });
          } else {
            component.value = '';
          }
          break;
        }
        case FormComponentType.Niederlassung: {

          if (bzObj.niederlassungCode) {
            this.apiService.getNiederlassungByCode(bzObj.niederlassungCode, data => {
              component.value = data;
            });
          } else {
            component.value = '';
          }

          break;
        }
        case FormComponentType.Kostenstelle: {

          if (bzObj.kostenstelle) {
            this.apiService.getKostenstelleByCode(bzObj.kostenstelle, data => {
              component.value = data;
            });
          } else {
            component.value = '';
          }

          break;
        }
        case FormComponentType.Notiz: {
          component.value = bzObj.notiz;
          break;
        }
        default: break;
      }
    });
    return formDefinition;
  }

  clearFormValues(formDefinition: FormDefinition) {
    if (formDefinition) {
      formDefinition.components.forEach((component, componentIndex) => {
        switch (component.type) {
          case FormComponentType.LieferRechnungsadresse: {
            component.value.rechnungsadresse = {
              firma: '',
              ansprechpartner: '',
              strasse: '',
              plz: '',
              ort: ''
            };
            component.value.lieferadresse = {
              firma: '',
              ansprechpartner: '',
              strasse: '',
              plz: '',
              ort: ''
            };

            break;
          }
          case FormComponentType.Lieferadresse: {
            component.value.lieferadresse = {
              firma: '',
              ansprechpartner: '',
              strasse: '',
              plz: '',
              ort: ''
            };
            break;
          }
          case FormComponentType.Rechnungsadresse: {
            component.value.rechnungsadresse = {
              firma: '',
              ansprechpartner: '',
              strasse: '',
              plz: '',
              ort: ''
            };

            break;
          }
          default: component.value = ''; break;
        }
      });
    }
  }

  // public deleteAnhang(id: string) {
  //   return (this.http.delete(`/Ticket/ticketAnhang/${id}`).toPromise());
  // }

  public getAnhangName(item: any): string {
    let name: string;
    if (item.notiz) {
      name = item.notiz;
    } else {
      name = item.name;
    }
    return name;
  }

  isShopMaster() {
    return localStorage.getItem('shopMaster') && JSON.parse(localStorage.getItem('shopMaster')) == true;
  }

  isAdmin() {
    return localStorage.getItem('loggedUser') && JSON.parse(localStorage.getItem('loggedUser')).master == true;
  }

  lowerCamelCase(str) {

    const words: string[] = str.split(' ');
    words.forEach((word, index) => {
      if (index == 0) {
        if (word.charAt(0) != undefined) {
          words[index] = words[index].charAt(0).toLowerCase() + words[index].substr(1);
        }
      } else {
        if (word.charAt(0) != undefined) {
          words[index] = words[index].charAt(0).toUpperCase() + words[index].substr(1);
        }
      }
    });

    return words.join('');

    /*return str.replace(/(?:^\w|[A-Z]|\b\w)/gi, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/gi, '');*/
  }

  cachingArtikleGroups(cart) {
    this.tmpWarenkorb = [];
    // console.log(cart);
    cart.forEach(element => {
      if (element.artikelCode != undefined) {
        this.apiService.getArtikelByCode(element.artikelCode, result => {
          this.tmpWarenkorb.push(result.grCode);
        });
      } else {
        this.tmpWarenkorb.push(element);
      }
    });
    // console.log(this.tmpWarenkorb)
  }

  getCachedArtikleGroups() {
    return this.tmpWarenkorb;
  }

  setCurrentBeleg(beleg) {
    if (beleg != null) {
      this.currentBeleg.next(beleg);
      this._currentBeleg = beleg;
      this.setBackupBeleg(beleg);
      this.loadGroupCodes();
    }
  }

  openGenericDialog(data): void {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        data.buttons[result].callback();
      }
    });
  }

  async loadGroupCodes() {
    this._currentArtikelGruppen = [];
    if (this._currentBeleg != null) {
      for (const element of this._currentBeleg.positionen) {
        if (element.artikelCode != undefined) {
          const result = await this.apiService.getArtikelByCodeAsync(element.artikelCode);
          if (result != null) {
            this._currentArtikelGruppen.push(result.grCode);
          }

        }
      }
    }
    this.currentArtikelGruppen.next(this._currentArtikelGruppen);
    this.currentPflichtartikelGruppen.next( await this.apiService.getRequiredGroups() as number[]);
  }

  async loadCurrentPflichtartikelGruppen() {
    this.currentPflichtartikelGruppen.next( await this.apiService.getRequiredGroups() as number[]);
  }

  async getCurrentPflichtartikelGruppen() {
    return  await this.apiService.getRequiredGroups() as number[];
  }

  setCurrentArtikelGruppen(gruppen) {
    this.currentArtikelGruppen.next(gruppen);
  }

  setBackupBeleg(beleg) {
    this.backupBeleg = beleg;
  }

  getBackupBeleg() {
    this.currentBeleg.next(this.backupBeleg);
    this.loadGroupCodes();
  }


  async openSucheDialog(data: SucheDialogData): Promise<any> {
    const dialogRef = this.dialog.open(SucheComponent, {
      autoFocus: false,
      disableClose: true,
      data
    });

    return await dialogRef.afterClosed().toPromise();
  }

}

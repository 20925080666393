/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/common";
import * as i7 from "./generic-dialog.component";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../services/api.service";
var styles_GenericDialogComponent = [i0.styles];
var RenderType_GenericDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericDialogComponent, data: {} });
export { RenderType_GenericDialogComponent as RenderType_GenericDialogComponent };
function View_GenericDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", ""], ["mat-button", ""]], [[4, "width", null], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((100 / _co.data.buttons.length) + "%"); var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); var currVal_2 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_3); }); }
export function View_GenericDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "p-default border-bottom background-whitesmoke"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "p-default"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "p-default align-right border-top"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericDialogComponent_1)), i1.ɵdid(7, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data.buttons; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data.message; _ck(_v, 4, 0, currVal_1); }); }
export function View_GenericDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-dialog", [], null, null, null, View_GenericDialogComponent_0, RenderType_GenericDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.GenericDialogComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA, i9.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericDialogComponentNgFactory = i1.ɵccf("app-generic-dialog", i7.GenericDialogComponent, View_GenericDialogComponent_Host_0, {}, {}, []);
export { GenericDialogComponentNgFactory as GenericDialogComponentNgFactory };

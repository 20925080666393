import { Routes } from '@angular/router';
import { Config, LoginComponent } from '@work4all/api';
import { MainComponent } from './ui/main/main.component';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { MeineBestellungenComponent } from './ui/meine-bestellungen/meine-bestellungen.component';
import { VeranstaltungenPageComponent } from './ui/veranstaltungen-page/veranstaltungen-page.component';
import { ArtikelPageComponent } from './ui/artikel-page/artikel-page.component';
import { ResetPasswordComponent } from './ui/reset-password/reset-password.component';
import { UsersComponent } from './ui/users/users.component';
registerLocaleData(localeDe, 'de-DE');
const appRoutes = [
    { path: 'login', component: LoginComponent },
    { path: 'resetpassword', component: ResetPasswordComponent },
    //{ path: '**', component: MainComponent },
    { path: '', component: MainComponent },
    { path: 'bestellungen', component: MeineBestellungenComponent },
    { path: 'artikel', component: ArtikelPageComponent },
    { path: 'veranstaltungen', component: VeranstaltungenPageComponent },
    { path: 'users', component: UsersComponent }
];
const ɵ0 = MAT_MOMENT_DATE_FORMATS, ɵ1 = () => Config.getInstance('config.json');
export class AppModule {
}
export { ɵ0, ɵ1 };

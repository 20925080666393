import { ShopService } from './../../services/shop.service';
import { ApiService } from './../../services/api.service';
import { UUID } from 'angular2-uuid';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { UploadResult, Config, Anhaenge } from '@work4all/api';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {


  showDropBorder: boolean;
  borderTimeout: NodeJS.Timer;
  currentUpload: object = {};
  loggedInUser;
  anhaenge = [];

  @Input() order;

  @Output() tmpAnhaenge = new EventEmitter<any[]>();

  ngOnInit() { }

  constructor(protected apiService: ApiService, protected config: Config, protected shopService: ShopService) {

    let mandantenCode = localStorage.getItem("mandantenCode");

    this.uploader.authToken = `bearer ${localStorage.getItem('AuthToken')}`;
    this.uploader.autoUpload = true;
    this.uploader.options.headers = [{ name: "MandantenCode", value: mandantenCode }];

    this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.fileuploadCompleted(item, response, status, headers);
    };

    this.uploader.onProgressItem = (item: FileItem, progress: any) => {
      this.fileuploadProgress(item, progress);
    };

  }

  get loggedUser(): any {
    return this.apiService.getLoggedUser()
  }

  // File Upload =============================================

  public uploader: FileUploader = new FileUploader({ url: `${this.config.apiUrl}api/file?type=TempDatei` });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  allowDrop(event) {
    if (!this.borderTimeout) {
      this.borderTimeout = setTimeout(() => this.showDropBorder = false, 500)
    } else {
      clearTimeout(this.borderTimeout)
      this.borderTimeout = setTimeout(() => this.showDropBorder = false, 500)
    }
    this.showDropBorder = true;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public dropped(e: any): void {
    this.uploader.uploadAll();
  }

  public fileuploadProgress(item: FileItem, progress: any): void {
    this.currentUpload = { item: item, progress: progress };
  }

  public fileuploadCompleted(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): void {
    if (status !== 200) {
      this.fileuploadFailed(item);

    } else {


      if (this.anhaenge == undefined) {
        this.anhaenge = [];
      }

      let uploadResult = new UploadResult(JSON.parse(response));
      console.log(response);
      for (let att of this.anhaenge) {
        if (att.code === uploadResult.generatedObject) {
          return;
        }
      }

      if (uploadResult.fileStored) {
        this.fileuploadSuccessfull(item, uploadResult.generatedObject);
      } else {
        this.fileuploadFailed(item);
      }

    }
  }

  public deleteTmpAttachment(i) {

    let data = {
      title: "Achtung",
      message: "Möchten Sie den Anhang verbindlich löschen?",
      buttons: [
        {
          title: "NEIN",
          callback: () => {
          }
        },
        {
          title: "JA",
          callback: () => {
            if (i > -1) {
              this.anhaenge.splice(i, 1);
              this.tmpAnhaenge.emit(this.anhaenge)
            }
          }
        }]
    }
    this.shopService.openGenericDialog(data)

    
  }

  public fileuploadSuccessfull(item: FileItem, id: UUID): void {
    this.currentUpload = {};

    if (this.anhaenge == undefined) {
      this.anhaenge = [];
    }

    this.anhaenge.push({
      code: id,
      dateiname: item.file.name,
      bzObjType: this.order.bzObjType,
      bzobjMemberCode: this.order.code
    });
    this.anhaengeUploaded();
  }

  anhaengeUploaded() {
    this.tmpAnhaenge.emit(this.anhaenge)
  }

  public fileuploadFailed(item: FileItem): void {
    this.currentUpload = {};
  }

  // =========================================================

  deleteAttachement(attachement) {
    // console.log(attachement.code)

    let data = {
      title: "Achtung",
      message: "Möchten Sie den Anhang verbindlich löschen?",
      buttons: [
        {
          title: "NEIN",
          callback: () => {
          }
        },
        {
          title: "JA",
          callback: () => {
            if (attachement.code) {
              this.apiService.deleteAnhang(attachement.code);
            } 
            this.order.anhaenge.splice(attachement, 1);
          }
        }]
    }
    this.shopService.openGenericDialog(data)

    
  }

  async downloadAttachement(item: any): Promise<any> {
    console.log(item);
    var result: Blob = await this.apiService.downloadFile("ErpAnhang", item.code, item.originalDateiname);
    return result;
  }

}
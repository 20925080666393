<div id="w4a-auth-wrap" [class.is-loading]="uiIsLoading">
    <div id="w4a-auth-overlay"></div>
    <div id="w4a-auth-container">
        <div id="w4a-auth-loading">
            <div class="w4a-auth-progress">
                <div class="w4a-auth-progress-indeterminate"></div>
            </div>
        </div>
        <div id="w4a-auth-circle"></div>
        <div id="w4a-auth-header">
            <img id="w4a-auth-logo" src="assets/img/Bubble_4.svg" alt="">
            <br> Passwort
        </div>
        <div id="w4a-auth-alert" [class.hide]="!uiLoginErrorMessage">{{uiLoginErrorMessage}}</div>
        <div id="w4a-auth-form">
            <ng-container *ngIf="!finished">
                Bitte vergeben Sie ein Passwort.
                <br><br>
                <div class="w4a-auth-form-row">
                    <label for="w4a-auth-user">Passwort</label>
                    <input type="password" id="w4a-auth-user" [(ngModel)]="password" [disabled]="uiIsLoading"
                        (keyup.enter)="submit()" autocomplete="off">
                </div>
                <div class="w4a-auth-form-row">
                    <label for="w4a-auth-pw">Passwort bestätigen</label>
                    <input type="password" [(ngModel)]="passwordConfirm" [disabled]="uiIsLoading" id="w4a-auth-pw"
                        (keyup.enter)="submit()" autocomplete="off">
                </div>
                <button id="w4a-auth-submit" class="uppercase" [disabled]="passwordTooShort() || passwordNotConfirmed()"
                    (click)="submit()">
                    Passwort ändern
                </button>
            </ng-container>
            <ng-container *ngIf="finished">
                <div class="align-center fade-down">
                    <br>
                    <i class="fas fa-check-circle" style="color:#2196f3;font-size:80px"></i><br>
                </div>
                    <br>
                    Passwort erfolgreich geändert<br>
                    <br>
                <button id="w4a-auth-submit" class="uppercase" [routerLink]="['/login']">
                    Zur Anmeldung
                </button>
            </ng-container>
            <div style="text-align:center">
                <br>
                <label>&copy; work4all GmbH </label>
            </div>
        </div>
    </div>
</div>
<div class="max-height">
  <div class="absolute max-width">
    <app-header></app-header>
  </div>
  <div
    class="main-content max-height relative z-100 p-top-lg-70 p-top-md-70 p-bottom-lg-55 p-bottom-md-55 p-bottom-sm-55 p-bottom-xs-0">
    <div class="container max-height">
      <mat-card class="max-height  overflow-hidden" style="margin:0 -15px">
        <div class="max-height border-top">
          <div class="overflow-hidden max-height">
            <div class="row max-height">
              <div class="col-sm-12 max-height">
                <div class="max-height" >
                  <div class="max-height relative fade-left" *ngIf=" currentBeleg != null">

                    <div class="border-bottom absolute z-100 max-width background-whitesmoke">
                      <button mat-button class=" " (click)="currentBeleg = null ;   editOpenOrder = false"
                        style="padding:7.5px 16px" *ngIf="!editOpenOrder">
                        <i class="fas fa-arrow-left"></i> Zurück <span class="hidden-xs"> zur Liste</span>
                      </button>
                      <div class="float-right"
                        *ngIf="currentShopDefinition.orderDefinitions[0].editable == EditableType.EditableUntilProjektStart">
                        <label *ngIf="!editAllowed() && !editOpenOrder">Der Bearbeitungszeitraum ist abgelaufen</label>
                        <label *ngIf="currentBeleg.shopGenehmigt">Die Bestellung ist bereits genehmigt</label>
                        <button mat-button class="" *ngIf="orderIsEditable() && !editOpenOrder"
                          (click)="editOpenOrder = true" style="padding:7.5px 16px"
                          [disabled]="!editAllowed() || currentBeleg.shopGenehmigt">
                          <i class="fas fa-pen"></i>&nbsp;<span class="hidden-xs">Bearbeiten</span>
                        </button>


                      </div>

                      <ng-container *ngIf="editOpenOrder">

                        <div class="float-right hidden-xs">
                          <button mat-button color="warn" class=" "
                            (click)="cancelOpenOrderChanges();editOpenOrder = false" [disabled]="loading"
                            style="padding:7.5px 16px">
                            <i class="fas fa-times"></i> Abbrechen
                          </button>
                          <button mat-button class="" (click)="saveOpenOrderChanges();editOpenOrder = false"
                            [disabled]="!fieldsValidated || loading || noRequiredGroup" style="padding:7.5px 16px">
                            <i class="fas fa-save"></i> Speichern
                          </button>
                        </div>
                        <div class="visible-xs max-width">
                          <button mat-button color="warn" class="col-xs-6"
                            (click)="cancelOpenOrderChanges();editOpenOrder = false" [disabled]="loading"
                            style="padding:7.5px 16px">
                            <i class="fas fa-times"></i> Abbrechen
                          </button>
                          <button mat-button class="col-xs-6" (click)="saveOpenOrderChanges();editOpenOrder = false"
                            [disabled]="!fieldsValidated || loading || noRequiredGroup" style="padding:7.5px 16px">
                            <i class="fas fa-save"></i> Speichern
                          </button>
                        </div>
                      </ng-container>

                    </div>

                    <div class="max-height y-scroll p-top-lg-50 p-top-md-50">
                      <div class="border-bottom overflow-hidden ">

                        <div class="row">
                          <div class="col-sm-8">
                            <div class="p-default ">
                              <div>
                                <strong
                                  style="font-size:16px;">{{currentShopDefinition.bestellungenNavigationTitleSingular}}</strong>
                              </div>
                              <div>
                                {{currentBeleg.notiz}}
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4" style="margin:-1px 0">
                            <div class="border-left border-bottom">
                              <table class="table">
                                <tr>
                                  <td><label>Nummer</label></td>
                                  <td>
                                    <ng-container *ngIf="currentBeleg.nummer != 0">
                                      {{currentBeleg.nummer}}</ng-container>
                                    <ng-container *ngIf="currentBeleg.auftragsNummer != 0">
                                      {{currentBeleg.auftragsNummer}}</ng-container>

                                  </td>
                                </tr>
                                <tr>
                                  <td><label>Erstellt am</label></td>
                                  <td>
                                    <ng-container *ngIf="currentBeleg.bzObjType != ObjectType.Auftrag">
                                      {{currentBeleg.datum | date:
                                      "dd.MM.yyyy"}}
                                    </ng-container>
                                    <ng-container *ngIf="currentBeleg.bzObjType == ObjectType.Auftrag">
                                      {{currentBeleg.auftragsDatum | date:
                                      "dd.MM.yyyy"}}
                                    </ng-container>
                                  </td>
                                </tr>
                                <tr *ngIf="currentShopDefinition.displayPreise">
                                  <td><label for="">Gesamtpreis</label> </td>
                                  <td>
                                    {{calculateGesamtpreis(currentBeleg.positionen)
                                    | currencyFormat}}
                                  </td>
                                </tr>
                                <!--<tr *ngIf="currentBeleg.freigabeStatus != undefined">
                                  <td><label>Status</label></td>
                                  <td>
                                    <ng-container *ngIf="!currentBeleg.freigabeStatus.genehmigt">
                                      <span style="color:orange">
                                        <i class="fas fa-circle"></i> <span class=" ">Genehmigung
                                          ausstehend</span>
                                      </span>
                                    </ng-container>
                                    <ng-container *ngIf="currentBeleg.freigabeStatus.genehmigt">
                                      <span style="color:green">
                                        <i class="fas fa-circle"></i> <span class=" ">Genehmigung
                                          erteilt</span>
                                      </span>
                                    </ng-container>
                                  </td>
                                </tr>-->
                                <tr
                                  *ngIf="currentShopDefinition.displayProjekte && currentBeleg.projekt != undefined && currentBeleg.projekt">
                                  <td><label>{{currentShopDefinition.projekteNavigationTitleSingular}}</label></td>
                                  <td>
                                    <strong>{{currentBeleg.projekt.name}}</strong>
                                    <div>
                                      {{currentBeleg.projekt.anfangDatum | date: "dd.MM.yyyy"}} -
                                      {{currentBeleg.projekt.endeDatum | date: "dd.MM.yyyy"}}
                                    </div>
                                  </td>
                                </tr>
                                <tr *ngIf="currentBeleg.code">
                                  <td><label>Status</label></td>
                                  <td>
                                    <ng-container *ngIf="!currentBeleg.shopGenehmigt">
                                      <span style="color:orange">

                                        <ng-container
                                          *ngIf="currentBeleg.shopGenehmigtDurchLoginID == '00000000-0000-0000-0000-000000000000'">
                                          <i class="fas fa-circle"></i>&nbsp;<span class=" ">
                                            Genehmigung
                                            ausstehend
                                          </span>
                                        </ng-container>

                                        <ng-container
                                          *ngIf="currentBeleg.shopGenehmigtDurchLoginID != '00000000-0000-0000-0000-000000000000'">
                                          <i class="fas fa-circle"></i>&nbsp;<span class=" ">
                                            Genehmigung
                                            aufgehoben
                                          </span>
                                        </ng-container>
                                      </span>
                                    </ng-container>
                                    <ng-container *ngIf="currentBeleg.shopGenehmigt">
                                      <span style="color:green">
                                        <i class="fas fa-circle"></i>&nbsp;<span class=" ">Genehmigung
                                          erteilt</span>
                                      </span>
                                    </ng-container>
                                    <ng-container
                                      *ngIf="currentBeleg.shopGenehmigtDurchLoginID != '' && currentBeleg.shopGenehmigtDurchLoginID != null && currentBeleg.shopGenehmigtDurchLoginID != '00000000-0000-0000-0000-000000000000'">
                                      <br>
                                      <small>durch
                                        {{this.getManagedExternalLoginByID(currentBeleg.shopGenehmigtDurchLoginID)?.ansprechpartnerAnzeigename}}</small><br>
                                      <small>am {{currentBeleg.shopGenehmigtDatum | date:'dd.MM.yyyy'}}</small>
                                    </ng-container>

                                    <br>
                                    <br>
                                    <button mat-raised-button class=""
                                      *ngIf="_apiService.shopRechte.genehmigungErteilen"
                                      (click)="toggleGenehmigung(currentBeleg)" style="padding:7.5px 16px">

                                      <ng-container *ngIf="!currentBeleg.shopGenehmigt">
                                        <i class="fas fa-check"></i>&nbsp;<span class="hidden-xs">Genehmigen</span>
                                      </ng-container>

                                      <ng-container *ngIf="currentBeleg.shopGenehmigt">
                                        <i class="fas fa-times"></i>&nbsp;<span class="hidden-xs">Genehmigung
                                          aufheben</span>
                                      </ng-container>


                                    </button>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <mat-tab-group class="">
                        <mat-tab [label]="'Positionen ('+currentBeleg.positionen.length+')'" class="">

                          <div *ngIf="editOpenOrder && currentShopDefinition.bestellungenInsertable"
                            class="relative background-whitesmoke max-width align-right border-bottom">
                            <small class="red-text" *ngIf="noRequiredGroup">Sie benötigen noch Artikel aus
                              Pflichtartikelgruppen.</small>
                            <button mat-button class="hidden-xs relative  z-100" (click)="openArtikelDialog()"
                              style="padding:7.5px 16px">
                              <i class="fas fa-plus"></i> Artikel hinzufügen
                            </button>
                            <button mat-button class="visible-xs max-width relative  z-100"
                              (click)="openArtikelDialog()" style="padding:7.5px 16px">
                              <i class="fas fa-plus"></i> Artikel hinzufügen
                            </button>
                          </div>

                          <table class="table table-hover no-margin hidden-xs">
                            <tr>
                              <!--<th style="width: 30px;"></th>
                  <th class="align-center">Nr.</th>-->
                              <!--<th>Artikelnummer</th>-->
                              <th>Bezeichnung</th>
                              <th class="align-center ">Liefertermin</th>
                              <th class="align-center">Menge</th>
                              <!--<th class="align-center hidden-xs" *ngIf="currentShopDefinition.displayPreise">MwSt</th>-->
                              <th class="align-right " *ngIf="currentShopDefinition.displayPreise">Einzelpreis</th>
                              <th class="align-right " *ngIf="currentShopDefinition.displayPreise">Gesamtpreis</th>
                            </tr>
                            <tr *ngIf="currentBeleg.positionen.length == 0">
                              <td colspan="5">
                                <em>Keine Positionen vorhanden</em>
                              </td>
                            </tr>
                            <ng-container *ngFor="let position of currentBeleg.positionen; let positionIndex = index">
                              <tr *ngIf="!position.deleteOnUpdate">
                                <!--<td class="  ">
                                  <ng-container
                                    *ngIf="position.artikelNummer">
                                    {{position.artikelNummer}}
                                  </ng-container>

                                </td>-->
                                <td>
                                  <ng-container *ngIf="editOpenOrder">
                                    <div class="delete-position pointer float-right relative z-100">
                                      <a class="small" *ngIf="position.internerText == ''"
                                        (click)="position.internerText = ' '">
                                        <i class="fas fa-sicky-note float-left" title="Zusatztext hinzufügen"></i>
                                        Zusatztext
                                      </a>
                                    </div>
                                  </ng-container>

                                  <div class="text-one-row" [innerText]="position.langtext"></div>
                                  <mat-form-field class="fade-down" *ngIf="editOpenOrder && position.internerText != ''"
                                    style="margin-top:10px">
                                    <textarea rows="3" matInput placeholder="Zusatztext"
                                      [(ngModel)]="position.internerText"></textarea>
                                  </mat-form-field>

                                  <div *ngIf="!editOpenOrder && position.internerText.trim() != ''"
                                    style="margin-top:10px">
                                    <label for="" class="small">Zusatztext</label>
                                    <div [innerText]="position.internerText"></div>
                                  </div>


                                </td>
                                <td class="align-center ">
                                  <ng-container
                                    *ngIf="position.liefertermin && position.liefertermin != '0001-01-01T00:00:00'">
                                    {{position.liefertermin | date: "dd.MM.yyyy"}}
                                  </ng-container>

                                </td>
                                <td class="align-center" style="min-width: 105px">

                                  <i class="fas fa-trash pointer float-left" *ngIf="editOpenOrder"
                                    title="Position entfernen" (click)="deletePosition(positionIndex)"></i>

                                  <input type="number" min="1" [(ngModel)]="position.anzahl"
                                    (change)="updatePosition(positionIndex)"
                                    style="background: transparent;border:0;width:50px;text-align:center"
                                    *ngIf="editOpenOrder">
                                  <ng-container *ngIf="!editOpenOrder">{{position.anzahl}}</ng-container>
                                  &nbsp;{{position.einheit}}
                                </td>
                                <td class="align-right " *ngIf="currentShopDefinition.displayPreise">
                                  <ng-container
                                    *ngIf="!editOpenOrder || !currentShopDefinition.bestellungenPreiseEditable">{{position.einzelpreis |
                                      currencyFormat}}</ng-container>
                                  <ng-container
                                    *ngIf="editOpenOrder && currentShopDefinition.bestellungenPreiseEditable">

                                    <input type="number" [(ngModel)]="position.einzelpreis"
                                      style="background: transparent;border:0;width:50px;text-align:center"
                                      *ngIf="editOpenOrder"> €</ng-container>

                                </td>
                                <td class="align-right "
                                  *ngIf="currentShopDefinition.displayPreise && position.stattGPreis">
                                  {{position.stattGPreis}}
                                </td>
                                <td class="align-right "
                                  *ngIf="currentShopDefinition.displayPreise && !position.stattGPreis">
                                  <ng-container>{{position.einzelpreis *
                                  position.anzahl |
                                  currencyFormat}}</ng-container>
                                </td>
                              </tr>
                            </ng-container>
                            <tr style="background: #f3f3f3;" *ngIf="currentShopDefinition.displayPreise"
                              class="hidden-xs">
                              <td class="align-right  " colspan="5"
                                style="vertical-align: bottom;border-top: 2px solid silver;">

                                <strong>Gesamt</strong>
                                <br>
                                <small>zzgl. MwSt.</small>
                              </td>
                              <td class="align-right strong"
                                style="vertical-align: top;font-size:16px;border-top: 2px solid silver;">
                                {{calculateGesamtpreis(currentBeleg.positionen)
                                | currencyFormat}}</td>
                            </tr>

                            <tr style="background: #f3f3f3;" *ngIf="currentShopDefinition.displayPreise"
                              class="visible-xs">
                              <td class="align-right  " colspan="5"
                                style="vertical-align: bottom;border-top: 2px solid silver;">
                                <strong>Gesamt</strong>
                                <br>
                                <small>zzgl. MwSt.</small>
                              </td>
                              <td class="align-right strong"
                                style="vertical-align: top;font-size:16px;border-top: 2px solid silver;">
                                {{calculateGesamtpreis(currentBeleg.positionen)
                                | currencyFormat}}</td>
                            </tr>
                          </table>

                          <div class="visible-xs">
                            <ng-container *ngFor="let position of currentBeleg.positionen; let positionIndex = index">

                              <div class="border-bottom hover-button p-default pointer">
                                <div class="p-x-default float-right">

                                  <i class="fas fa-trash p-x-default pointer" *ngIf="editOpenOrder"
                                    title="Position entfernen" (click)="deletePosition(positionIndex)"></i>
                                  <span *ngIf="!editOpenOrder">{{position.anzahl}}&nbsp;{{position.einheit}} </span>
                                  <input type="number" min="0" [(ngModel)]="position.anzahl"
                                    (change)="updatePosition(positionIndex)"
                                    style="background: transparent;border: 1px solid silver;padding: 10px;width: 50px;text-align: right;"
                                    *ngIf="editOpenOrder">
                                </div>
                                <div class="overflow-hidden">
                                  <ng-container
                                    *ngIf="!editOpenOrder || !currentShopDefinition.bestellungenTexteEditable">
                                    <span *ngIf="position.langtext.length > 0" [innerText]="position.langtext"></span>
                                    <span *ngIf="position.langtext.length == 0"><em>Ohne Beschreibung</em></span>
                                  </ng-container>
                                  <ng-container
                                    *ngIf="editOpenOrder && currentShopDefinition.bestellungenTexteEditable">
                                    <textarea type="number" min="0" [(ngModel)]="position.langtext"
                                      style="background: transparent;border: 1px solid silver;padding: 10px;width: 50px;text-align: right;">
                                    </textarea>
                                  </ng-container>

                                </div>
                                <label for="" *ngIf="currentShopDefinition.displayPreise">
                                  <ng-container>{{position.einzelpreis |
                                    currencyFormat}}</ng-container>
                                </label>
                              </div>
                            </ng-container>
                            <div style="background: #f3f3f3;" *ngIf="currentShopDefinition.displayPreise"
                              class="visible-xs">
                              <div class="p-default float-right strong" style="vertical-align: top;font-size:16px;">
                                {{calculateGesamtpreis(currentBeleg.positionen)
                                | currencyFormat}}
                              </div>
                              <div class="p-default float-right" style="vertical-align: bottom;">
                                <strong>Gesamt</strong>
                                <br>
                                <small>zzgl. MwSt.</small>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                          </div>
                        </mat-tab>

                        <mat-tab label="Sonstige Angaben"
                          *ngIf="openOrderDefinition.formDefinition.components.length > 0">
                          <div class="p-x-default" *ngIf="!editOpenOrder">
                            <app-form-generator [viewMode]="true" [formDefinition]="openOrderDefinition.formDefinition">
                            </app-form-generator>
                          </div>
                          <div class="" *ngIf="editOpenOrder">
                            <app-form-generator [fillMode]="true" (formValidated)="validateForm($event)"
                              [formDefinition]="openOrderDefinition.formDefinition"></app-form-generator>
                          </div>
                        </mat-tab>

                        <!-- ANHÄNGE  -->
                        <mat-tab label="Anhänge">

                          <div class="p-x-default" *ngIf="!editOpenOrder">
                            <div class="col-md-6">
                            </div>

                            <div class="col-md-6 attachements" style="margin-bottom: 40px">
                              <h3 style="margin-bottom: 0px;">Hochgeladene Dateien: </h3>
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th width="50%">Name</th>
                                    <th><span class="float-right">Aktion</span></th>
                                  </tr>
                                </thead>
                                <tbody *ngIf="currentBeleg != undefined && currentBeleg.anhaenge != undefined">
                                  <tr *ngFor="let item of currentBeleg.anhaenge; let i = index" style="padding: 8px;">
                                    <td style="padding: 8px;">
                                      <strong>{{ item.originalDateiname }}</strong>
                                    </td>
                                    <td>
                                      <button mat-button class="float-right" (click)="downloadAttachement(item)">
                                        <i class="fa fa-download"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div class="" *ngIf="editOpenOrder">
                            <app-file-upload (tmpAnhaenge)="anhaengeUploaded($event)" [order]="currentBeleg">
                            </app-file-upload>
                          </div>

                        </mat-tab>

                      </mat-tab-group>
                    </div>
                  </div>

                  <div *ngIf="!currentBeleg" class="fade-right max-height relative">
                    <div class="absolute max-width border-bottom background-whitesmoke z-200 overflow-hidden" >
                      <div class="float-right max-height border-left relative"
                        style="margin-top: 0px;margin-bottom: -19px;">
                        <mat-form-field class="no-border" appearance="fill">
                          <mat-label>Suche</mat-label>
                          <input matInput [(ngModel)]="searchText" (input)="searchByText()" autocomplete="false">
                          <div class="fas fa-search" matSuffix></div>
                        </mat-form-field>
                      </div>
                      <button mat-button class="float-right  " style="padding-top:7.5px; padding-bottom:7.5px"
                        *ngIf="currentShopDefinition.bestellungenInsertable && dataSource  && dataSource.length > 0" (click)="addOrder()">
                        <i class="fas fa-plus"></i>
                        <span class="hidden-xs">
                          {{currentShopDefinition.bestellungenNavigationTitleSingular}} hinzufügen
                        </span>
                      </button>
                      <div class="p-default">
                        <strong class="">{{currentShopDefinition.bestellungenNavigationTitle}}</strong>
                      </div>


                    </div>
                    <div class="p-top-lg-50 max-height p-top-md-50 y-scroll" >
                      <ng-container>
                        <div class="align-center p-default" *ngIf="dataSource && dataSource.length == 0 && !loading">
                          <br>
                          <h2 class="">Herzlich willkommen</h2>

                          <button mat-raised-button color="accent" class="fp-default  " (click)="addOrder()"
                            *ngIf="currentShopDefinition.bestellungenInsertable">
                            <i class="fas fa-plus"></i> Jetzt
                            {{currentShopDefinition.bestellungenNavigationTitleSingular}} hinzufügen
                          </button>
                        </div>
                        <ng-container *ngIf="loading">
                          <div class="p-default align-center">
                            <i class="fas fa-circle-notch fa-spin"></i>
                            <br>
                            <br>
                            Daten werden abgerufen...
                          </div>
                        </ng-container>

                        <ng-container *ngIf="dataSource && dataSource.length > 0">



                          <dx-data-grid class="max-height hidden-xs max-width" #dataGrid [dataSource]="dataSource " *ngIf="!loading && dataSource"
                            [columnResizingMode]="" [allowColumnReordering]="true" [allowColumnResizing]="true"
                            [columnAutoWidth]="true" [hoverStateEnabled]="true" [selectedRowKeys]="[]"
                            [showRowLines]="true" [showBorders]="false" [rowAlternationEnabled]="false"
                            (onSelectionChanged)="onSelectionChanged($event)" (onRowClick)="onRowClick($event)"
                            (onRowDblClick)="onRowDblClicked($event)">

                            <dxo-group-panel [visible]="false"></dxo-group-panel>
                            <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
                            <!--<dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage"></dxo-state-storing>
                            <dxo-selection [mode]="selection"></dxo-selection>-->
                            <dxo-sorting mode="multiple"></dxo-sorting>
                            <dxo-scrolling mode="infinite"></dxo-scrolling>
                            <dxo-paging [pageSize]="200"></dxo-paging>
                            <dxo-header-filter [visible]="true"></dxo-header-filter>
                            <dxo-filter-row [visible]="false"></dxo-filter-row>
                            <dxo-search-panel [visible]="false" [width]="240" placeholder="Suche...">
                            </dxo-search-panel>

                            <dxi-column dataField="auftragsNummer" caption="Nummer" dataType="string"
                              *ngIf="dataSource[0].bzObjType == ObjectType.Auftrag" alignment="left" [visible]="true"
                              [allowHiding]="true" [allowHeaderFiltering]="true">
                            </dxi-column>

                            <dxi-column dataField="nummer" caption="Nummer" dataType="string"
                              *ngIf="dataSource[0].bzObjType !== ObjectType.Auftrag" alignment="left" [visible]="true"
                              [allowHiding]="true" [allowHeaderFiltering]="true">
                            </dxi-column>


                            <dxi-column dataField="auftragsDatum" caption="Erstellt" dataType="date"
                              *ngIf="dataSource[0].bzObjType == ObjectType.Auftrag" alignment="left" [visible]="true"
                              [allowHiding]="true" [allowHeaderFiltering]="true" format="dd.MM.yyyy">
                            </dxi-column>

                            <dxi-column dataField="datum" caption="Erstellt" dataType="date"
                              *ngIf="dataSource[0].bzObjType !== ObjectType.Auftrag" alignment="left" [visible]="true"
                              [allowHiding]="true" format="dd.MM.yyyy" [allowHeaderFiltering]="true">
                            </dxi-column>

                            <!--<dxi-column dataField="notiz" caption="Notiz" [width]="200" dataType="string"
                              alignment="left" [visible]="true" [allowHiding]="true" [allowHeaderFiltering]="true">
                            </dxi-column>-->

                            <dxi-column dataField="projektName" [width]="250"
                              [caption]="currentShopDefinition.projekteNavigationTitleSingular"
                              *ngIf="currentShopDefinition.displayProjekte" dataType="string" alignment="left"
                              [visible]="true" [allowHiding]="true" [allowHeaderFiltering]="true">
                            </dxi-column>

                            <dxi-column dataField="projektAnfangDatum" caption="Beginn"
                              *ngIf="currentShopDefinition.displayProjekte" dataType="date" alignment="left"
                              [visible]="true" [allowHiding]="true" format="dd.MM.yyyy" [allowHeaderFiltering]="true">
                            </dxi-column>

                            <dxi-column dataField="projektEndeDatum" caption="Ende"
                              *ngIf="currentShopDefinition.displayProjekte" dataType="date" alignment="left"
                              [visible]="true" [allowHiding]="true" format="dd.MM.yyyy" [allowHeaderFiltering]="true">
                            </dxi-column>

                            <dxi-column dataField="creatorAnzeigeName" caption="Ersteller" dataType="string"
                              alignment="left" [visible]="true" [allowHiding]="true" [allowHeaderFiltering]="true">
                            </dxi-column>

                            <dxi-column dataField="positionen" caption="Positionen" cellTemplate="positionenCell"
                              dataType="number" alignment="right" [visible]="true" [allowHiding]="true"
                              [allowHeaderFiltering]="false">
                            </dxi-column>

                            <dxi-column dataField="wert" caption="Gesamtpreis" cellTemplate="currencyFormatCell"
                              dataType="number" alignment="right" [visible]="true" [allowHiding]="true"
                              [allowHeaderFiltering]="false">
                            </dxi-column>

                            <dxi-column dataField="editable" caption="Bearb." dataType="boolean" alignment="center"
                              cellTemplate="bearbeitbarCell"
                              *ngIf="currentShopDefinition.orderDefinitions[0].editable == EditableType.EditableUntilProjektStart"
                              [visible]="true" [allowHiding]="true" [allowHeaderFiltering]="true">
                            </dxi-column>

                            <dxi-column dataField="shopGenehmigt" caption="Genehm." dataType="boolean"
                              cellTemplate="currencyFormatCell" cellTemplate="genehmigungCell" alignment="center"
                              [visible]="true" [allowHiding]="true" [allowHeaderFiltering]="true">
                            </dxi-column>

                            <dxi-column dataField="anhaenge" caption="Anh." dataType="boolean"
                              cellTemplate="currencyFormatCell" cellTemplate="anhaengeCell" alignment="center"
                              [visible]="true" [allowHiding]="true" [allowHeaderFiltering]="false">
                            </dxi-column>

                            <div *dxTemplate="let cell of 'currencyFormatCell'">
                              {{cell.value | currencyFormat}}
                            </div>

                            <div *dxTemplate="let cell of 'bearbeitbarCell'">
                              <label *ngIf="!cell.value"><span style="color:red">
                                  <i class="fas fa-circle"></i></span></label>
                              <label *ngIf="cell.value"><span style="color:green">
                                  <i class="fas fa-circle"></i></span></label>
                            </div>

                            <div *dxTemplate="let cell of 'genehmigungCell'">
                              <ng-container *ngIf="!cell.value">
                                <span style="color:orange">
                                  <i class="fas fa-circle"></i>
                                  <span class="hidden-xs "> </span>
                                </span>
                              </ng-container>
                              <ng-container *ngIf="cell.value">
                                <span style="color:green">
                                  <i class="fas fa-circle"></i> <span class="hidden-xs "> </span>
                                </span>
                              </ng-container>
                            </div>

                            <div *dxTemplate="let cell of 'anhaengeCell'">
                              <ng-container *ngIf="cell.value.length > 0">
                                <strong class="small"> {{cell.value.length}}</strong>&nbsp;<i
                                  class="fas fa-paperclip"></i>
                              </ng-container>
                            </div>

                            <div *dxTemplate="let cell of 'positionenCell'">
                              {{cell.value.length}}
                            </div>

                          </dx-data-grid>

                          <div class="visible-xs">
                            <ng-container *ngFor="let bestellung of dataSource; let orderIndex = index">
                              <div class="border-bottom hover-button p-default pointer" (click)="openBeleg(bestellung)">
                                <strong>{{currentShopDefinition.orderDefinitions[0].name}}</strong>
                                <strong for="">
                                  |
                                  <ng-container *ngIf="bestellung.bzObjType  != ObjectType.Auftrag">
                                    {{bestellung.nummer}}</ng-container>
                                  <ng-container *ngIf="bestellung.bzObjType == ObjectType.Auftrag">
                                    {{bestellung.auftragsNummer}}</ng-container>
                                </strong>

                                <ng-container
                                  *ngIf="currentShopDefinition.orderDefinitions[0].editable == EditableType.EditableUntilProjektStart">
                                  <label *ngIf="!editAllowedList(bestellung)"><span style="color:red">
                                      <i class="fas fa-circle"></i></span></label>
                                  <label *ngIf="editAllowedList(bestellung)"><span style="color:green">
                                      <i class="fas fa-circle"></i></span></label>
                                </ng-container>
                                <label for="" class="float-right">
                                  <ng-container *ngIf="bestellung.bzObjType != ObjectType.Auftrag">
                                    {{bestellung.datum | date: "dd.MM.yyyy"}}
                                  </ng-container>
                                  <ng-container *ngIf="bestellung.bzObjType == ObjectType.Auftrag">
                                    {{bestellung.auftragsDatum | date: "dd.MM.yyyy"}}
                                  </ng-container>
                                </label>
                                <div class="overflow-hidden">
                                  <span *ngIf="bestellung.notiz.length > 0">{{bestellung.notiz}}</span>
                                  <span *ngIf="bestellung.notiz.length == 0"><em>Ohne Notiz</em></span>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="absolute max-width z-100 hidden-xs" style="margin-top:-55px">
    <app-footer></app-footer>
  </div>
</div>

<div class="max-height">
  <div class="absolute max-width">
    <app-header></app-header>
  </div>
  <div class="main-content max-height relative z-100 p-top-lg-70 p-top-md-70 p-bottom-lg-55 p-bottom-md-55 p-bottom-sm-55 p-bottom-xs-0">
    <div class="container max-height">
      <mat-card class="max-height  overflow-hidden" style="margin:0 -15px">
        <div class="max-height border-top">
          <div class="overflow-hidden max-height">
            <app-veranstaltungen></app-veranstaltungen>

          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="absolute max-width z-100 hidden-xs" style="margin-top:-55px">
    <app-footer></app-footer>
  </div>
</div>
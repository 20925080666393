import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AuthService, TokenInterceptor, Config, ApiModule, LoginComponent  } from '@work4all/api';
import { MainComponent } from './ui/main/main.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import * as $ from 'jquery';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ApiService } from './services/api.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule, MatNativeDateModule, MAT_DATE_LOCALE, MatCheckboxModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FileUploadModule } from 'ng2-file-upload';
import { GenericDialogComponent } from './ui/generic-dialog/generic-dialog.component';
import {  } from '@work4all/api';
import { ArtikelComponent } from './ui/artikel/artikel.component';
import { W4aTreeComponent } from './ui/w4a-tree/w4a-tree.component';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlDe } from './classes/mat-paginator-intl-de';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ShopService } from './services/shop.service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MccColorPickerModule } from 'material-community-components';
import { HeaderComponent } from './ui/header/header.component';
import { FooterComponent } from './ui/footer/footer.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ArtikelDialogComponent } from './ui/artikel-dialog/artikel-dialog.component';
import { VeranstaltungenComponent } from './ui/veranstaltungen/veranstaltungen.component';
import { VeranstaltungComponent } from './ui/veranstaltung/veranstaltung.component';
import { FormGeneratorComponent } from './ui/form-generator/form-generator.component';
import { MeineBestellungenComponent } from './ui/meine-bestellungen/meine-bestellungen.component';
import { VeranstaltungAuswahlComponent } from './ui/veranstaltung-auswahl/veranstaltung-auswahl.component';
import { ShopDefinitionDialogComponent } from './ui/shop-definition-dialog/shop-definition-dialog.component';
import { VeranstaltungenPageComponent } from './ui/veranstaltungen-page/veranstaltungen-page.component';
import { ArtikelPageComponent } from './ui/artikel-page/artikel-page.component';
import { ResetPasswordComponent } from './ui/reset-password/reset-password.component';
import { Md5 } from 'ts-md5/dist/md5';
import { FileUploadComponent } from './ui/file-upload/file-upload.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { DxHtmlEditorModule, DxScrollViewModule, DxDataGridModule } from "devextreme-angular";
import { MailConfigurationDialogComponent } from './dialogs/mail-configuration-dialog/mail-configuration-dialog.component';
import { UsersComponent } from './ui/users/users.component';
import { SucheComponent } from './dialogs/suche/suche.component';

registerLocaleData(localeDe, 'de-DE');

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  //{ path: '**', component: MainComponent },
  { path: '', component: MainComponent },
  { path: 'bestellungen', component: MeineBestellungenComponent },
  { path: 'artikel', component: ArtikelPageComponent },
  { path: 'veranstaltungen', component: VeranstaltungenPageComponent },
  { path: 'users', component: UsersComponent }
];

@NgModule({
  entryComponents: [
    SucheComponent,
    ShopDefinitionDialogComponent,
    VeranstaltungAuswahlComponent,
    ArtikelDialogComponent,
    GenericDialogComponent,
    VeranstaltungComponent,
    MailConfigurationDialogComponent
  ],
  declarations: [
    CurrencyFormatPipe,
    AppComponent,
    MainComponent,
    GenericDialogComponent,
    ArtikelComponent,
    W4aTreeComponent,
    HeaderComponent,
    FooterComponent,
    ArtikelDialogComponent,
    VeranstaltungenComponent,
    VeranstaltungComponent,
    FormGeneratorComponent,
    MeineBestellungenComponent,
    VeranstaltungAuswahlComponent,
    ShopDefinitionDialogComponent,
    VeranstaltungenPageComponent,
    ArtikelPageComponent,
    ResetPasswordComponent,
    FileUploadComponent,
    MailConfigurationDialogComponent,
    UsersComponent,
    SucheComponent
  ],
  imports: [
    ApiModule,
    DxScrollViewModule,
    DxDataGridModule,
    DxHtmlEditorModule,
    FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),
    MccColorPickerModule.forRoot({
      empty_color: 'transparent'
    }),
    //DxDataGridModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatCheckboxModule,
    FileUploadModule,
    MatProgressBarModule,
    MatMenuModule,
    MatTooltipModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTabsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatChipsModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    NoopAnimationsModule,
    BrowserModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, useHash: true } // <-- debugging purposes only
    ),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatCardModule,
    MatNativeDateModule
  ],
  providers: [
    CurrencyFormatPipe,
    //MatPaginatorIntlDe,
    Md5,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    ShopService,
    ApiService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe },
    { provide: Config, useFactory: () => Config.getInstance('config.json') },
    AuthService,
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class=" relative  max-height" *ngIf="currentShopDefinition">



  <div class="y-split max-height">
    <div start class="border-bottom">
      <div class="x-split ">

        <div start class="max-height ">
          <div class="hidden-xs p-default">
            <label class=" hidden-xs">Suche</label>
            <mat-form-field class="no-bottom">
              <input matInput placeholder="Suchbegriff" type="text" [(ngModel)]="suchbegriff"
                (input)="refreshArtikelListBySuchbegriff()">
              <i class="fas fa-search" matSuffix></i>
            </mat-form-field>
          </div>
        </div>

        <div middle class="max-height ">
          <div class="visible-xs p-default">
            <label class=" hidden-xs">Suche</label>
            <mat-form-field class="no-bottom">
              <input matInput placeholder="Suchbegriff" type="text" [(ngModel)]="suchbegriff"
                (input)="refreshArtikelListBySuchbegriff()">
              <i class="fas fa-search" matSuffix></i>
            </mat-form-field>
          </div>
          <div class="p-default border-left border-right max-height  hidden-xs"
            *ngIf="currentShopDefinition.displayProjekte != undefined && currentShopDefinition.displayProjekte">
            <div class="inline-block" style="margin-right:20px">
              <label>Verfügbarkeitszeitraum</label><br>
              <ng-container
                *ngIf="projekteFormControl.value && projekteFormControl.value != null && projekteFormControl.value != undefined">
                <div class="float-left p-right-default" (click)="openVeranstaltungAuswahlDialog()">
                  <strong>{{projekteFormControl.value.name}}</strong><br>
                  {{projekteFormControl.value.nummer}}
                </div>
                <!--<button mat-raised-button (click)="openVeranstaltungAuswahlDialog()" style="    vertical-align: top;"
                  *ngIf="!disableChangeProjekt">Ändern</button>-->
                <button mat-raised-button (click)="removeVerfuegbarkeitsfilter()" *ngIf="!disableChangeProjekt"
                  style="    vertical-align: top;" class="narrow-btn">
                  <i class="fas fa-times"></i>
                </button>


              </ng-container>
              <ng-container
                *ngIf="projekteFormControl.value == null || projekteFormControl.value == undefined && !disableChangeProjekt">
                <button (click)="openVeranstaltungAuswahlDialog()" style="    vertical-align: top;"
                  mat-raised-button>{{currentShopDefinition.projekteNavigationTitleSingular}}
                  wählen</button>
              </ng-container>
            </div>
            <div class="inline-block">

              <div class="inline-block" style="width:100px;vertical-align: top;margin-bottom: -21px;">
                <mat-form-field>
                  <input matInput [matDatepicker]="dateVonPicker" placeholder="Von" [formControl]="dateVon"
                    (dateChange)="removeVerfuegbarkeitsfilter();getVerfuegbarkeiten()">
                  <mat-datepicker-toggle matSuffix [for]="dateVonPicker"></mat-datepicker-toggle>
                  <mat-datepicker #dateVonPicker>
                  </mat-datepicker>
                </mat-form-field>
              </div>
              &nbsp;
              <div class="inline-block" style="width:100px;vertical-align: top;margin-bottom: -21px;">
                <mat-form-field>
                  <input matInput [matDatepicker]="dateBisPicker" placeholder="Bis" [formControl]="dateBis"
                    (dateChange)="removeVerfuegbarkeitsfilter();getVerfuegbarkeiten()">
                  <mat-datepicker-toggle matSuffix [for]="dateBisPicker"></mat-datepicker-toggle>
                  <mat-datepicker #dateBisPicker>
                  </mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>

        <div end class="max-height p-default">
          <mat-menu #appMenu="matMenu">
            <div class="p-default ">
              <label class="">{{currentShopDefinition.artikelNavigationTitleSingular}}gruppen</label>
              <app-w4a-tree *ngIf="artikelGruppen" [treeNode]="artikelGruppen" (click)="$event.stopPropagation()"
                (codeSelected)="selectArtikelGruppe($event)"></app-w4a-tree>
            </div>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="appMenu" if="menu-btn" class="visible-xs">
            <mat-icon>more_vert</mat-icon>
          </button>

          <div class="hidden-xs">
            <label>Darstellung</label>
            <div class="x-split">
              <div>
                <button mat-raised-button class="narrow-btn articel-view-button" style="padding: 0 6px !important;"
                  *ngIf="viewMode == 0">
                  <div class="fas fa-th"></div>
                </button>
                <button mat-button class="narrow-btn articel-view-button" (click)="setViewMode(0)"
                  style="padding: 0 6px !important;" *ngIf="viewMode != 0">
                  <div class="fas fa-th"></div>
                </button>
                <button mat-raised-button class="narrow-btn articel-view-button" style="padding: 0 6px !important;"
                  *ngIf="viewMode == 2">
                  <div class="fas fa-list-ul"></div>
                </button>
                <button mat-button class="narrow-btn articel-view-button" (click)="setViewMode(2)"
                  style="padding: 0 6px !important;" *ngIf="viewMode != 2">
                  <div class="fas fa-list-ul"></div>
                </button>
                &nbsp;
              </div>
              <div>
                <mat-form-field class="inline-block no-bottom">
                  <mat-select placeholder="Sortieren nach" (selectionChange)="artikelListeSortieren($event)">
                    <ng-container *ngFor="let option of sortOptions">
                      <mat-option [value]="option.value">
                        {{option.info}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>

              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
    <div middle>
      <div class="x-split max-height">
        <div start class="max-height y-scroll">
          <div class="p-default hidden-xs">
            <label class="">{{currentShopDefinition.artikelNavigationTitleSingular}}gruppen</label>
            <app-w4a-tree *ngIf="artikelGruppen" [treeNode]="artikelGruppen"
              (codeSelected)="selectArtikelGruppe($event)"></app-w4a-tree>
          </div>
        </div>
        <div middle class="max-height y-scroll">
          <div class="  max-height fade-left " *ngIf="currentArtikelIndex != undefined && currentArtikelIndex != null">
            <div class="max-height">

              <div class="p-default" style="    padding-bottom: 0;">
                <label class="pointer" (click)="currentArtikelIndex = null">
                  <i class="fas fa-arrow-left"></i> Zurück zur Liste
                </label>
              </div>

              <div class="p-default">
                <div class="row">
                  <div class="col-sm-4 col-xs-12   align-center">
                    <div class="p-default border-bottom border-top border-left border-right"
                      style="background:whitesmoke">
                      <img [src]="getArtikelStandardBildByCode(artikelList[currentArtikelIndex].code)"
                        onError="this.src='assets/img/no-img.jpg'" style="max-height:33.33vh" alt=""
                        class="artikel-bild">
                    </div>
                    <div class="row p-top-default">
                      <div class="col-xs-6">

                        <ng-container *ngIf="!disableAddArtikel">
                          <mat-form-field class="">
                            <input matInput placeholder="Menge" class="align-right" min="1" type="number"
                              [(ngModel)]="anzahlNewPosition">

                            <span matSuffix>{{setEinheit(artikelList[currentArtikelIndex].einheitCode)}}</span>
                          </mat-form-field>
                        </ng-container>

                      </div>
                      <div class="col-xs-6">

                        <ng-container *ngIf="!disableAddArtikel">
                          <button mat-raised-button class="max-width"
                            (click)="addPosition(artikelList[currentArtikelIndex])">
                            <i class="fas fa-shopping-cart pointer" matSuffix></i>

                            <span *ngIf="currentShopDefinition.displayPreise">
                              <ng-container
                                *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                <ng-container
                                  *ngIf="artikelList[currentArtikelIndex]['preise'] != undefined && artikelList[currentArtikelIndex]['preise'].einkaufspreise != undefined && getEinkaufspreis(artikelList[currentArtikelIndex]['preise'].einkaufspreise) != null && getEinkaufspreis(artikelList[currentArtikelIndex]['preise'].einkaufspreise).einkaufpreis != 0">
                                  {{getEinkaufspreis(artikelList[currentArtikelIndex]['preise'].einkaufspreise).einkaufpreis
                                | currencyFormat}}
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikelList[currentArtikelIndex].euroNettopreis != 0">
                                {{artikelList[currentArtikelIndex].euroNettopreis | currencyFormat}}
                              </ng-container>
                            </span>
                          </button>
                        </ng-container>

                      </div>
                    </div>
                    <table class="table border-bottom hidden-xs">
                      <tr>
                        <th>Nummer</th>
                        <td class="align-right">{{artikelList[currentArtikelIndex].nummer}}</td>
                      </tr>
                      <tr>
                        <th>Art</th>
                        <td class="align-right">
                          <div class="" *ngIf="artikelList[currentArtikelIndex].mietartikel">Mietartikel</div>
                          <div class="" *ngIf="!artikelList[currentArtikelIndex].mietartikel">Lagerartikel</div>
                        </td>
                      </tr>
                      <tr *ngIf="currentShopDefinition.displayPreise">

                        <th>Einzelpreis</th>
                        <td class="align-right">
                          <ng-container
                            *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                            <ng-container
                              *ngIf="artikelList[currentArtikelIndex]['preise'] != undefined && artikelList[currentArtikelIndex]['preise'].einkaufspreise != undefined && getEinkaufspreis(artikelList[currentArtikelIndex]['preise'].einkaufspreise) != null && getEinkaufspreis(artikelList[currentArtikelIndex]['preise'].einkaufspreise).einkaufpreis != 0">
                              {{getEinkaufspreis(artikelList[currentArtikelIndex]['preise'].einkaufspreise).einkaufpreis
                            | currencyFormat}}
                            </ng-container>
                          </ng-container>
                          <ng-container
                            *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikelList[currentArtikelIndex].euroNettopreis != 0">
                            {{artikelList[currentArtikelIndex].euroNettopreis | currencyFormat}}
                          </ng-container>
                        </td>
                      </tr>
                      <tr
                        *ngIf="currentShopDefinition.displayBestaende && artikelList[currentArtikelIndex] != undefined && artikelList[currentArtikelIndex]['bestand'] != undefined  ">
                        <th
                          *ngIf="artikelList[currentArtikelIndex] != undefined && artikelList[currentArtikelIndex]['bestand'] != undefined ">
                          <ng-container *ngIf="!artikelList[currentArtikelIndex].chargenverwaltung ">
                            Bestand
                          </ng-container>
                          <ng-container *ngIf="artikelList[currentArtikelIndex].chargenverwaltung ">
                            Chargenbestand
                          </ng-container>
                        </th>
                        <td class="align-right">

                          <span
                            *ngIf="!artikelList[currentArtikelIndex].mietartikel && artikelList[currentArtikelIndex]['bestand'] != undefined  ">{{artikelList[currentArtikelIndex]['bestand']}}</span>
                          <span
                            *ngIf="artikelList[currentArtikelIndex].mietartikel && artikelList[currentArtikelIndex]['verfuegbar'] != undefined">
                            {{artikelList[currentArtikelIndex]['verfuegbar']}} /
                            {{artikelList[currentArtikelIndex]['sollBestand']}}</span>
                          {{setEinheit(artikelList[currentArtikelIndex]?.einheitCode)}}
                        </td>
                      </tr>
                      <ng-container
                        *ngIf="currentShopDefinition.displayBestaende && artikelList[currentArtikelIndex]['chargen'] != undefined">
                        <ng-container *ngFor="let charge of artikelList[currentArtikelIndex]['chargen']">

                          <tr *ngIf="charge['bestand'] > 0">
                            <td class="align-left">
                              {{charge.chargennummer}}
                              <small *ngIf="charge['notiz1'] != ''">
                                <br>{{charge['notiz1']}}
                              </small>
                              <small *ngIf="charge['notiz2'] != ''">
                                <br>{{charge['notiz2']}}
                              </small>
                              <small *ngIf="charge['verfallsdatum'] != '0001-01-01T00:00:00'">
                                <br>Verfallsdatum: {{charge['verfallsdatum'] | date:"dd.MM.yyyy"}}
                              </small>
                            </td>
                            <td class="align-right">
                              {{charge['bestand']}} {{setEinheit(artikelList[currentArtikelIndex]?.einheitCode)}}
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                    </table>

                  </div>
                  <div class="clearfix visible-xs"></div>
                  <div class="col-sm-8">
                    <h2>{{artikelList[currentArtikelIndex].name}}</h2>

                    <table class="table border-bottom visible-xs">
                      <tr>
                        <th>Nummer</th>
                        <th>Art</th>
                        <th
                          *ngIf="currentShopDefinition.displayBestaende &&  artikelList[currentArtikelIndex] != undefined && artikelList[currentArtikelIndex]['bestand'] != undefined && artikelList[currentArtikelIndex]['bestand'] != 0">
                          Bestand</th>
                        <th>
                          <span *ngIf="currentShopDefinition.displayPreise">Einzelpreis</span>
                        </th>
                      </tr>
                      <tr>
                        <td class="pointer">{{artikelList[currentArtikelIndex].nummer}}</td>
                        <td class="pointer">
                          <div class="" *ngIf="artikelList[currentArtikelIndex].mietartikel">Mietartikel</div>
                          <div class="" *ngIf="!artikelList[currentArtikelIndex].mietartikel">Lagerartikel</div>
                        </td>
                        <td class="pointer"
                          *ngIf="currentShopDefinition.displayBestaende &&  artikelList[currentArtikelIndex] != undefined && artikelList[currentArtikelIndex]['bestand'] != undefined && artikelList[currentArtikelIndex]['bestand'] != 0">

                          <span
                            *ngIf="!artikelList[currentArtikelIndex].mietartikel && artikelList[currentArtikelIndex]['bestand'] != undefined && artikelList[currentArtikelIndex]['bestand'] != 0">{{artikelList[currentArtikelIndex]['bestand']}}</span>
                          <span
                            *ngIf="artikelList[currentArtikelIndex].mietartikel && artikelList[currentArtikelIndex]['verfuegbar'] != undefined">
                            {{artikelList[currentArtikelIndex]['verfuegbar']}} /
                            {{artikelList[currentArtikelIndex]['sollBestand']}}</span>

                        </td>
                        <td class="pointer">
                          <ng-container *ngIf="currentShopDefinition.displayPreise">
                            <ng-container
                              *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                              <ng-container
                                *ngIf="artikelList[currentArtikelIndex]['preise'] != undefined && artikelList[currentArtikelIndex]['preise'].einkaufspreise != undefined && getEinkaufspreis(artikelList[currentArtikelIndex]['preise'].einkaufspreise) != null && getEinkaufspreis(artikelList[currentArtikelIndex]['preise'].einkaufspreise).einkaufpreis != 0">
                                {{getEinkaufspreis(artikelList[currentArtikelIndex]['preise'].einkaufspreise).einkaufpreis
                            | currencyFormat}}
                              </ng-container>
                            </ng-container>
                            <ng-container
                              *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikelList[currentArtikelIndex].euroNettopreis != 0">
                              {{artikelList[currentArtikelIndex].euroNettopreis | currencyFormat}}
                            </ng-container>
                          </ng-container>
                        </td>
                      </tr>
                    </table>

                    <div class="p-y-default" [innerText]="artikelList[currentArtikelIndex].langtext">
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <ng-container *ngIf="currentArtikelIndex == null">

            <div class="p-default" style="padding-bottom:0">
              <label class="">
                <strong>{{artikelList.length}}</strong> ERGEBNISSE
              </label>
            </div>

            <ng-container *ngIf="loading">
              <div class="p-default align-center">
                <i class="fas fa-circle-notch fa-spin"></i>
                <br>
                <br>

                Daten werden abgerufen...
              </div>
            </ng-container>
            <div class="visible-xs">
              <ng-container *ngFor="let artikel of artikelList;let i = index">
                <div class="p-default border-bottom" (click)="currentArtikelIndex = i"
                  *ngIf="pageEvent && (i+1) <= (pageEvent.pageSize * (pageEvent.pageIndex + 1)) && (i+1) >= ((pageEvent.pageSize * (pageEvent.pageIndex + 1)) - pageEvent.pageSize + 1)">

                  <div class="float-left" style="height:50px;width:50px;margin-right:15px">
                    <img [src]="getArtikelStandardBildByCode(artikel.code)" onError="this.src='assets/img/no-img.jpg'"
                      class="artikel-bild">
                  </div>

                  <div class="one-liner">
                    <strong>{{artikel.name}}</strong>
                  </div>
                  <div class="one-liner">
                    <div class="float-right p-left-default">
                      <span *ngIf="currentShopDefinition.displayPreise">
                        <ng-container
                          *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                          <ng-container
                            *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                            {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                          </ng-container>
                        </ng-container>
                        <ng-container
                          *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                          {{artikel.euroNettopreis | currencyFormat}}
                        </ng-container>
                      </span>
                    </div>
                    <div class=" badge small  inline-block" *ngIf="artikel.mietartikel" title="Mietartikel">
                      Mietartikel
                    </div>
                    <div class=" badge small inline-block" *ngIf="!artikel.mietartikel" title="Lagerartikel">
                      Lagerartikel</div>
                    <small>{{artikel.nummer}}</small>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="p-default" style="overflow: hidden;">
            <div class=" row hidden-xs " *ngIf="!loading">
              <ng-container *ngIf="viewMode == 0">
                <ng-container *ngFor="let artikel of artikelList;let i = index">
                  <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12 artikel-item "
                    *ngIf="pageEvent && (i+1) <= (pageEvent.pageSize * (pageEvent.pageIndex + 1)) && (i+1) >= ((pageEvent.pageSize * (pageEvent.pageIndex + 1)) - pageEvent.pageSize + 1)">
                    <div class="artikel-bild-container">
                      <div class="absolute max-width max-height"
                        style="left: 0;vertical-align: middle;display: inline-flex;">
                        <img [src]="getArtikelStandardBildByCode(artikel.code)"
                          onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild">
                      </div>
                      <img [src]="getArtikelStandardBildByCode(artikel.code)" alt="" class="artikel-bild-blur">
                    </div>
                    <div class="p-y-default">
                      <div style="height: 43px;overflow: hidden;text-overflow: ellipsis;display: block;">
                        <strong class="artikel-name">{{artikel.name}}</strong>
                      </div>
                      <span class="artikel-preis float-right" *ngIf="currentShopDefinition.displayPreise">
                        <ng-container
                          *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                          <ng-container
                            *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null">
                            <ng-container *ngIf="getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                              {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                            </ng-container>
                          </ng-container>
                        </ng-container>
                        <ng-container
                          *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung">
                          <ng-container *ngIf="artikel.euroNettopreis">
                            {{artikel.euroNettopreis | currencyFormat}}
                          </ng-container>
                        </ng-container>
                      </span>
                      <small class="artikel-nummer">{{artikel.nummer}}</small>
                      <br>
                      <div class=" small pointer " *ngIf="artikel.mietartikel" title="Mietartikel">&nbsp;</div>
                      <div class=" small pointer " *ngIf="!artikel.mietartikel" title="Lagerartikel">&nbsp;</div>

                    </div>
                    <div class="artikel-hover">
                      <div class="artikel-bild-container">
                        <div class="absolute max-width max-height"
                          style="left: 0;vertical-align: middle;display: inline-flex;"
                          (click)="currentArtikelIndex = i">
                          <img [src]="getArtikelStandardBildByCode(artikel.code)"
                            onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild">
                        </div>
                        <img [src]="getArtikelStandardBildByCode(artikel.code)"
                          onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild-blur">
                        <div *ngIf="!getArtikelStandardBildByCode(artikel.code)" class="artikel-bild-none">
                          <br>
                          <br>
                          <br>
                          <i class="fas fa-camera" style="font-size: 70px !important;color: silver;"></i>
                        </div>
                      </div>
                      <div class="p-default" style="padding-bottom: 0;">
                        <div class="relative" style="margin-top:-40px;z-index:100;margin-bottom:15px">
                          <div class=" small pointer  " *ngIf="artikel.mietartikel" title="Mietartikel">&nbsp;</div>
                          <div class=" small pointer  " *ngIf="!artikel.mietartikel" title="Lagerartikel">&nbsp;
                          </div>
                        </div>

                        <span class="artikel-preis float-right"
                          *ngIf="currentShopDefinition.displayPreise && disableAddArtikel">
                          <ng-container
                            *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                            <ng-container
                              *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null">
                              <ng-container
                                *ngIf="getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                              </ng-container>
                            </ng-container>
                          </ng-container>
                          <ng-container
                            *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung">
                            <ng-container *ngIf="artikel.euroNettopreis">
                              {{artikel.euroNettopreis | currencyFormat}}
                            </ng-container>
                          </ng-container>
                        </span>

                        <div (click)="currentArtikelIndex = i">

                          <strong class="artikel-name">{{artikel.name}}</strong>
                          <br>
                          <small class="artikel-nummer">{{artikel.nummer}}</small>
                          <div class="p-y-default" [innerHtml]="artikel.langtext"
                            style="max-height:80px;overflow:hidden"></div>

                        </div>
                      </div>
                      <table class="table no-margin table-fixed"
                        *ngIf="currentShopDefinition.displayBestaende && !artikel.mietartikel && !artikel.nichtLagerArtikel && artikel['bestand'] != undefined && artikel['bestand'] != 0">
                        <tr>
                          <th style="width:50%">Bestand</th>
                          <td class="align-center">
                            <span
                              *ngIf="artikel['bestand'] != undefined && artikel['bestand'] != 0">{{artikel['bestand']}}</span>
                          </td>
                        </tr>
                      </table>
                      <div class="p-default" *ngIf="!disableAddArtikel">
                        <div class="row">
                          <div class="col-xs-6">
                            <mat-form-field class="">
                              <input matInput placeholder="Menge" class="align-right" min="1" type="number"
                                [(ngModel)]="anzahlNewPosition">
                              <span matSuffix>{{setEinheit(artikel.einheitCode)}}</span>
                            </mat-form-field>
                          </div>
                          <div class="col-xs-6">
                            <button mat-raised-button class="max-width" (click)="addPosition(artikel)">
                              <i class="fas fa-shopping-cart pointer" matSuffix></i>

                              <span *ngIf="currentShopDefinition.displayPreise">
                                <ng-container
                                  *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                  <ng-container
                                    *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                    {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                  </ng-container>
                                </ng-container>
                                <ng-container
                                  *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                  {{artikel.euroNettopreis | currencyFormat}}
                                </ng-container>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="viewMode == 1">
                <ng-container *ngFor="let artikel of artikelList;let i = index">
                  <div class="col-md-6 col-sm-12 col-xs-12 artikel-item fade-down"
                    *ngIf="pageEvent && (i+1) <= (pageEvent.pageSize * (pageEvent.pageIndex + 1)) && (i+1) >= ((pageEvent.pageSize * (pageEvent.pageIndex + 1)) - pageEvent.pageSize + 1)">
                    <div class="artikel-bild-container float-left" style="height:105px;width:105px;margin-right:15px">
                      <div class="absolute max-width max-height"
                        style="left: 0;vertical-align: middle;display: inline-flex;">
                        <img [src]="getArtikelStandardBildByCode(artikel.code)"
                          onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild">
                      </div>

                      <img [src]="getArtikelStandardBildByCode(artikel.code)" onError="this.src='assets/img/no-img.jpg'"
                        alt="" class="artikel-bild-blur">
                      <div *ngIf="!getArtikelStandardBildByCode(artikel.code)" class="artikel-bild-none"
                        style="padding-top:15px">
                        <i class="fas fa-camera" style="font-size: 70px !important;color: silver;"></i>
                      </div>
                    </div>
                    <div class="p-x-default">
                      <span class="artikel-preis float-right" style="margin-top:0;margin-left:15px"
                        *ngIf="currentShopDefinition.displayPreise">
                        <ng-container
                          *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                          <ng-container
                            *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                            {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                          </ng-container>
                        </ng-container>
                        <ng-container
                          *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                          {{artikel.euroNettopreis | currencyFormat}}
                        </ng-container>
                      </span>
                      <div style="overflow: hidden;text-overflow: ellipsis;display: block;">
                        <strong class="artikel-name">{{artikel.name}}</strong>
                      </div>
                      <small class="artikel-nummer">{{artikel.nummer}}</small>
                      <br>
                      <div class=" small pointer " *ngIf="artikel.mietartikel" title="Mietartikel">&nbsp;</div>
                      <div class=" small pointer " *ngIf="!artikel.mietartikel" title="Lagerartikel">&nbsp;</div>
                    </div>


                    <div class="artikel-hover mode-1">
                      <div class="artikel-bild-container float-left" style="height:131px;width:131px;margin-right:15px"
                        (click)="currentArtikelIndex = i">
                        <div class="absolute max-width max-height"
                          style="left: 0;vertical-align: middle;display: inline-flex;">
                          <img [src]="getArtikelStandardBildByCode(artikel.code)"
                            onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild">
                        </div>

                        <img [src]="getArtikelStandardBildByCode(artikel.code)"
                          onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild-blur">
                        <div *ngIf="!getArtikelStandardBildByCode(artikel.code)" class="artikel-bild-none"
                          style="padding-top:15px">
                          <i class="fas fa-camera" style="font-size: 70px !important;color: silver;"></i>
                        </div>

                      </div>
                      <div class="p-default">

                        <span class="artikel-preis float-right" style="margin-top:0;margin-left:15px"
                          *ngIf="currentShopDefinition.displayPreise && disableAddArtikel">
                          <ng-container
                            *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                            <ng-container
                              *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                              {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                            </ng-container>
                          </ng-container>
                          <ng-container
                            *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                            {{artikel.euroNettopreis | currencyFormat}}
                          </ng-container>
                        </span>


                        <div (click)="currentArtikelIndex = i">

                          <div style="overflow: hidden;text-overflow: ellipsis;display: block;">
                            <strong class="artikel-name">{{artikel.name}}</strong>
                          </div>

                          <div class=" small pointer  inline-block" *ngIf="artikel.mietartikel" title="Mietartikel">
                            &nbsp;</div>
                          <div class=" small pointer  inline-block" *ngIf="!artikel.mietartikel" title="Lagerartikel">
                            &nbsp;</div>
                          <small class="artikel-nummer">{{artikel.nummer}}</small>
                          <div [innerHtml]="artikel.langtext" class="p-top-default"
                            style="max-height:80px;overflow:hidden"></div>

                        </div>
                      </div>


                      <table class="table no-margin table-fixed">
                        <tr>
                          <ng-container
                            *ngIf="currentShopDefinition.displayBestaende && !artikel.mietartikel && !artikel.nichtLagerArtikel && artikel['bestand'] != undefined && artikel['bestand'] != 0">
                            <th>Bestand</th>
                            <td class="align-center">
                              <span
                                *ngIf="artikel['bestand'] != undefined && artikel['bestand'] != 0">{{artikel['bestand']}}</span>

                            </td>
                          </ng-container>
                          <td class="align-right">
                            <div class="inline-block" *ngIf="!disableAddArtikel">
                              <mat-form-field class="" style="width:80px;margin-right:15px">
                                <input matInput placeholder="Menge" class="align-right" [(ngModel)]="anzahlNewPosition"
                                  type="number">
                                <span matSuffix>{{setEinheit(artikel.einheitCode)}}</span>
                              </mat-form-field>
                            </div>
                            <button mat-raised-button class="float-right" (click)="addPosition(artikel)"
                              *ngIf="!disableAddArtikel">
                              <i class="fas fa-shopping-cart pointer" matSuffix></i>
                              <span *ngIf="currentShopDefinition.displayPreise">
                                <ng-container
                                  *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                  <ng-container
                                    *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                    {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                  </ng-container>
                                </ng-container>
                                <ng-container
                                  *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                  {{artikel.euroNettopreis | currencyFormat}}
                                </ng-container>
                              </span>
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </ng-container>
              </ng-container>


              <ng-container *ngIf="viewMode == 21">
                <ng-container *ngFor="let artikel of artikelList;let i = index">
                  <div class="col-md-12 col-sm-12 col-xs-12 artikel-item fade-down" style="margin-bottom:5px"
                    *ngIf="pageEvent && (i+1) <= (pageEvent.pageSize * (pageEvent.pageIndex + 1)) && (i+1) >= ((pageEvent.pageSize * (pageEvent.pageIndex + 1)) - pageEvent.pageSize + 1)">

                    <div class="border-bottom" style="padding-bottom:5px">
                      <span class="artikel-preis float-right" style="margin-top:0"
                        *ngIf="currentShopDefinition.displayPreise">
                        <ng-container
                          *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                          <ng-container
                            *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                            {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                          </ng-container>
                        </ng-container>
                        <ng-container
                          *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                          {{artikel.euroNettopreis | currencyFormat}}
                        </ng-container>
                      </span>
                      <div style="overflow: hidden;text-overflow: ellipsis;display: block;">
                        <strong class="artikel-name">{{artikel.name}}</strong><br>
                        <div class="artikel-column">
                          <div class=" small pointer  inline-block" *ngIf="artikel.mietartikel" title="Mietartikel">
                            &nbsp;</div>
                          <div class=" small pointer  inline-block" *ngIf="!artikel.mietartikel" title="Lagerartikel">
                            &nbsp;</div>
                          <small class="artikel-nummer">{{artikel.nummer}} &nbsp;</small>
                        </div>
                        <div class="artikel-column"
                          *ngIf="currentShopDefinition.displayBestaende && !artikel.mietartikel && !artikel.nichtLagerArtikel && artikel['bestand'] != undefined && artikel['bestand'] != 0">
                          <label class="small" for="">Bestand: </label>
                          <span
                            *ngIf="artikel['bestand'] != undefined && artikel['bestand'] != 0">{{artikel['bestand']}}</span>
                        </div>
                      </div>
                    </div>

                    <div class="artikel-hover mode-1">
                      <div class="artikel-bild-container float-left" style="height:90px;width:90px;margin-right:15px"
                        (click)="currentArtikelIndex = i">
                        <div class="absolute max-width max-height"
                          style="left: 0;vertical-align: middle;display: inline-flex;">
                          <img [src]="getArtikelStandardBildByCode(artikel.code)"
                            onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild">
                        </div>
                        <img [src]="getArtikelStandardBildByCode(artikel.code)"
                          onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild-blur">
                        <div *ngIf="!getArtikelStandardBildByCode(artikel.code)" class="artikel-bild-none">
                          <i class="fas fa-camera" style="font-size: 70px !important;color: silver;"></i>
                        </div>
                      </div>
                      <div class="p-default">
                        <span *ngIf="currentShopDefinition.displayPreise && disableAddArtikel">
                          <ng-container
                            *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                            <ng-container
                              *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                              {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                            </ng-container>
                          </ng-container>
                          <ng-container
                            *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                            {{artikel.euroNettopreis | currencyFormat}}
                          </ng-container>
                        </span>
                        <div class="float-right">
                          <button mat-raised-button class="max-width" (click)="addPosition(artikel)"
                            *ngIf="!disableAddArtikel">
                            <i class="fas fa-shopping-cart pointer" matSuffix></i>
                            <span *ngIf="currentShopDefinition.displayPreise">
                              <ng-container
                                *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                <ng-container
                                  *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                  {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                {{artikel.euroNettopreis | currencyFormat}}
                              </ng-container>
                            </span>
                          </button>
                        </div>
                        <div class="float-right" style="width:80px;margin-right:15px" *ngIf="!disableAddArtikel">
                          <mat-form-field class="">
                            <input matInput placeholder="Menge" [(ngModel)]="anzahlNewPosition" class="align-right"
                              type="number">
                            <span matSuffix>{{setEinheit(artikel.einheitCode)}}</span>
                          </mat-form-field>
                        </div>

                        <div (click)="currentArtikelIndex = i">
                          <div style="overflow: hidden;text-overflow: ellipsis;display: block;">
                            <strong class="artikel-name">{{artikel.name}}</strong>
                          </div>
                          <div class="artikel-column">
                            <div class=" small pointer " *ngIf="artikel.mietartikel" title="Disposition">Disposition
                            </div>
                            <div class=" small pointer " *ngIf="!artikel.mietartikel" title="Lagerartikel">
                              Lagerartikel
                            </div>
                            <small class="artikel-nummer">{{artikel.nummer}}</small>
                          </div>
                          <div class="artikel-column"
                            *ngIf="currentShopDefinition.displayBestaende && !artikel.mietartikel && !artikel.nichtLagerArtikel && artikel['bestand'] != undefined && artikel['bestand'] != 0">
                            <label class="small" for="">Bestand: </label>
                            <span
                              *ngIf="artikel['bestand'] != undefined && artikel['bestand'] != 0">{{artikel['bestand']}}</span>
                          </div>
                          <div [innerHtml]="artikel.langtext"
                            style="max-height:20px;overflow:hidden;text-overflow: ellipsis"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>


              <ng-container *ngIf="viewMode == 2">
                <div class="col-md-12 col-sm-12 col-xs-12 artikel-item fade-down">

                  <table class="table table-hover" *ngIf="!loading">
                    <tr>
                      <th>Nummer</th>
                      <th>Bezeichnung</th>
                      <th class="align-center"></th>
                      <th class="align-center">
                        <span *ngIf="currentShopDefinition.displayBestaende">Verfügbarkeit</span> </th>
                      <th class="align-center">Einheit</th>
                      <th class="align-right">
                        <span *ngIf="currentShopDefinition.displayPreise">Einzelpreis</span>
                      </th>
                    </tr>

                    <ng-container *ngFor="let artikel of artikelList;let i = index">
                      <tr
                        *ngIf="pageEvent && (i+1) <= (pageEvent.pageSize * (pageEvent.pageIndex + 1)) && (i+1) >= ((pageEvent.pageSize * (pageEvent.pageIndex + 1)) - pageEvent.pageSize + 1)">
                        <td class="pointer" (click)="currentArtikelIndex = i">{{artikel.nummer}}</td>
                        <td class="pointer" (click)="currentArtikelIndex = i">{{artikel.name}}</td>
                        <td class="pointer align-center" (click)="currentArtikelIndex = i">
                          <div class=" small pointer  inline-block" *ngIf="artikel.mietartikel" title="Mietartikel">
                            &nbsp;</div>
                          <div class=" small pointer  inline-block" *ngIf="!artikel.mietartikel" title="Lagerartikel">
                            &nbsp;</div>
                        </td>
                        <td class="pointer align-center" (click)="currentArtikelIndex = i">
                          <span
                            *ngIf="currentShopDefinition.displayBestaende && !artikel.mietartikel && (artikel['bestand'] == undefined || artikel['bestand'] == 0)">
                            Auf Anfrage
                          </span>
                          <ng-container *ngIf="currentShopDefinition.displayBestaende">
                            <span
                              *ngIf="!artikel.mietartikel && artikel['bestand'] != undefined && artikel['bestand'] != 0">{{artikel['bestand']}}</span>
                            <span *ngIf="artikel.mietartikel && artikel['verfuegbar'] != undefined">
                              {{artikel['verfuegbar']}} / {{artikel['sollBestand']}}</span>
                          </ng-container>
                        </td>
                        <td class="pointer align-center" (click)="currentArtikelIndex = i">
                          {{setEinheit(artikel.einheitCode)}}
                        </td>
                        <td class="pointer align-right" style="min-width:300px">
                          <div class="mouseout">
                            <span *ngIf="currentShopDefinition.displayPreise">
                              <ng-container
                                *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                <ng-container
                                  *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                  {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                {{artikel.euroNettopreis | currencyFormat}}
                              </ng-container>
                            </span>
                          </div>
                          <div class="mouseover">
                            <span *ngIf="currentShopDefinition.displayPreise && disableAddArtikel">
                              <ng-container
                                *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                <ng-container
                                  *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                  {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                {{artikel.euroNettopreis | currencyFormat}}
                              </ng-container>
                            </span>
                            <div class="float-right" style="margin:-8px -16px -10px 0">
                              <button color="accent" mat-raised-button class="max-width"
                                (click)="addPosition(artikel, (!artikel.mietartikel && (artikel['bestand'] == undefined || artikel['bestand'] == 0)))"
                                *ngIf="!disableAddArtikel">
                                <i class="fas fa-shopping-cart pointer" matSuffix></i>
                                <span *ngIf="currentShopDefinition.displayPreise">
                                  <ng-container
                                    *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                    <ng-container
                                      *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                      {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                    </ng-container>
                                  </ng-container>
                                  <ng-container
                                    *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                    {{artikel.euroNettopreis | currencyFormat}}
                                  </ng-container>
                                </span>
                              </button>
                            </div>
                            <div class="float-right"
                              style="width: 80px;margin-right: 0px;margin-bottom: -27px;margin-top: -9px;"
                              *ngIf="!disableAddArtikel">
                              <mat-form-field class="">
                                <input matInput placeholder="Menge" [(ngModel)]="anzahlNewPosition" class="align-right"
                                  type="number">
                                <span matSuffix>{{setEinheit(artikel.einheitCode)}}</span>
                              </mat-form-field>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </table>
                </div>
              </ng-container>
            </div>
            </div>
          </ng-container>


          <mat-paginator [hidden]="artikelList.length == 0" *ngIf="!loading && artikelList && pageEvent "
            [length]="pageEvent.length" [pageSize]="pageEvent.pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="setPage($event)">
          </mat-paginator>

        </div>
        <div end class="max-height">

        </div>
      </div>
    </div>
    <div end>
    </div>
  </div>
</div>

<!--
<div class="max-height relative  " *ngIf="currentShopDefinition">
  <div class="y-split">
    <div class="y-scroll">
      <div>
        <div class="white-box max-height">
          <div class="row max-height">

            <div class="col-sm-3 hidden-xs max-height y-scroll">
              <div class="p-default ">
                <label class="">{{currentShopDefinition.artikelNavigationTitleSingular}}gruppen</label>
                <app-w4a-tree *ngIf="artikelGruppen" [treeNode]="artikelGruppen"
                  (codeSelected)="selectArtikelGruppe($event)"></app-w4a-tree>
              </div>
            </div>



            <div class="col-sm-9 max-height fade-right" *ngIf="currentArtikelIndex == null">
              <div class=" max-height y-scroll" style="overflow-x: hidden;">
                <div class="p-default" style="padding-bottom:0">
                  <label class="">
                    <strong>{{artikelList.length}}</strong> ERGEBNISSE
                  </label>
                </div>

                <div class="visible-xs">
                  <ng-container *ngFor="let artikel of artikelList;let i = index">
                    <div class="p-default border-bottom" (click)="currentArtikelIndex = i"
                      *ngIf="pageEvent && (i+1) <= (pageEvent.pageSize * (pageEvent.pageIndex + 1)) && (i+1) >= ((pageEvent.pageSize * (pageEvent.pageIndex + 1)) - pageEvent.pageSize + 1)">

                      <div class="float-left" style="height:50px;width:50px;margin-right:15px">
                        <img [src]="getArtikelStandardBildByCode(artikel.code)"
                          onError="this.src='assets/img/no-img.jpg'" class="artikel-bild">
                      </div>

                      <div class="one-liner">
                        <strong>{{artikel.name}}</strong>
                      </div>
                      <div class="one-liner">
                        <div class="float-right p-left-default">
                          <span *ngIf="currentShopDefinition.displayPreise">
                            <ng-container
                              *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                              <ng-container
                                *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                              </ng-container>
                            </ng-container>
                            <ng-container
                              *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                              {{artikel.euroNettopreis | currencyFormat}}
                            </ng-container>
                          </span>
                        </div>
                        <div class=" badge small  inline-block" *ngIf="artikel.mietartikel" title="Mietartikel">
                          Mietartikel
                        </div>
                        <div class=" badge small inline-block" *ngIf="!artikel.mietartikel" title="Lagerartikel">
                          Lagerartikel</div>
                        <small>{{artikel.nummer}}</small>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <ng-container *ngIf="loading">
                  <div class="p-default align-center">
                    <i class="fas fa-circle-notch fa-spin"></i>
                    <br>
                    <br>

                    Daten werden abgerufen...
                  </div>
                </ng-container>

                <div class="row hidden-xs p-default" *ngIf="!loading">
                  <ng-container *ngIf="viewMode == 0">
                    <ng-container *ngFor="let artikel of artikelList;let i = index">
                      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12 artikel-item "
                        *ngIf="pageEvent && (i+1) <= (pageEvent.pageSize * (pageEvent.pageIndex + 1)) && (i+1) >= ((pageEvent.pageSize * (pageEvent.pageIndex + 1)) - pageEvent.pageSize + 1)">
                        <div class="artikel-bild-container">
                          <div class="absolute max-width max-height"
                            style="left: 0;vertical-align: middle;display: inline-flex;">
                            <img [src]="getArtikelStandardBildByCode(artikel.code)"
                              onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild">
                          </div>
                          <img [src]="getArtikelStandardBildByCode(artikel.code)" alt="" class="artikel-bild-blur">
                        </div>
                        <div class="p-y-default">
                          <div style="height: 43px;overflow: hidden;text-overflow: ellipsis;display: block;">
                            <strong class="artikel-name">{{artikel.name}}</strong>
                          </div>
                          <span class="artikel-preis float-right" *ngIf="currentShopDefinition.displayPreise">
                            <ng-container
                              *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                              <ng-container
                                *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null">
                                <ng-container
                                  *ngIf="getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                  {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                </ng-container>
                              </ng-container>
                            </ng-container>
                            <ng-container
                              *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung">
                              <ng-container *ngIf="artikel.euroNettopreis">
                                {{artikel.euroNettopreis | currencyFormat}}
                              </ng-container>
                            </ng-container>
                          </span>
                          <small class="artikel-nummer">{{artikel.nummer}}</small>
                          <br>
                          <div class=" small pointer " *ngIf="artikel.mietartikel" title="Mietartikel">&nbsp;</div>
                          <div class=" small pointer " *ngIf="!artikel.mietartikel" title="Lagerartikel">&nbsp;</div>

                        </div>
                        <div class="artikel-hover">
                          <div class="artikel-bild-container">
                            <div class="absolute max-width max-height"
                              style="left: 0;vertical-align: middle;display: inline-flex;"
                              (click)="currentArtikelIndex = i">
                              <img [src]="getArtikelStandardBildByCode(artikel.code)"
                                onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild">
                            </div>
                            <img [src]="getArtikelStandardBildByCode(artikel.code)"
                              onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild-blur">
                            <div *ngIf="!getArtikelStandardBildByCode(artikel.code)" class="artikel-bild-none">
                              <br>
                              <br>
                              <br>
                              <i class="fas fa-camera" style="font-size: 70px !important;color: silver;"></i>
                            </div>
                          </div>
                          <div class="p-default" style="padding-bottom: 0;">
                            <div class="relative" style="margin-top:-40px;z-index:100;margin-bottom:15px">
                              <div class=" small pointer  " *ngIf="artikel.mietartikel" title="Mietartikel">&nbsp;</div>
                              <div class=" small pointer  " *ngIf="!artikel.mietartikel" title="Lagerartikel">&nbsp;
                              </div>
                            </div>

                            <span class="artikel-preis float-right"
                              *ngIf="currentShopDefinition.displayPreise && disableAddArtikel">
                              <ng-container
                                *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                <ng-container
                                  *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null">
                                  <ng-container
                                    *ngIf="getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                    {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung">
                                <ng-container *ngIf="artikel.euroNettopreis">
                                  {{artikel.euroNettopreis | currencyFormat}}
                                </ng-container>
                              </ng-container>
                            </span>

                            <div (click)="currentArtikelIndex = i">

                              <strong class="artikel-name">{{artikel.name}}</strong>
                              <br>
                              <small class="artikel-nummer">{{artikel.nummer}}</small>
                              <div class="p-y-default" [innerHtml]="artikel.langtext"
                                style="max-height:80px;overflow:hidden"></div>

                            </div>
                          </div>
                          <table class="table no-margin table-fixed"
                            *ngIf="currentShopDefinition.displayBestaende && !artikel.mietartikel && !artikel.nichtLagerArtikel && artikel['bestand'] != undefined && artikel['bestand'] != 0">
                            <tr>
                              <th style="width:50%">Bestand</th>
                              <td class="align-center">
                                <span
                                  *ngIf="artikel['bestand'] != undefined && artikel['bestand'] != 0">{{artikel['bestand']}}</span>
                              </td>
                            </tr>
                          </table>
                          <div class="p-default" *ngIf="!disableAddArtikel">
                            <div class="row">
                              <div class="col-xs-6">
                                <mat-form-field class="">
                                  <input matInput placeholder="Menge" class="align-right" min="1" type="number"
                                    [(ngModel)]="anzahlNewPosition">
                                  <span matSuffix>{{setEinheit(artikel.einheitCode)}}</span>
                                </mat-form-field>
                              </div>
                              <div class="col-xs-6">
                                <button mat-raised-button class="max-width" (click)="addPosition(artikel)">
                                  <i class="fas fa-shopping-cart pointer" matSuffix></i>

                                  <span *ngIf="currentShopDefinition.displayPreise">
                                    <ng-container
                                      *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                      <ng-container
                                        *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                        {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                      </ng-container>
                                    </ng-container>
                                    <ng-container
                                      *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                      {{artikel.euroNettopreis | currencyFormat}}
                                    </ng-container>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="viewMode == 1">
                    <ng-container *ngFor="let artikel of artikelList;let i = index">
                      <div class="col-md-6 col-sm-12 col-xs-12 artikel-item fade-down"
                        *ngIf="pageEvent && (i+1) <= (pageEvent.pageSize * (pageEvent.pageIndex + 1)) && (i+1) >= ((pageEvent.pageSize * (pageEvent.pageIndex + 1)) - pageEvent.pageSize + 1)">
                        <div class="artikel-bild-container float-left"
                          style="height:105px;width:105px;margin-right:15px">
                          <div class="absolute max-width max-height"
                            style="left: 0;vertical-align: middle;display: inline-flex;">
                            <img [src]="getArtikelStandardBildByCode(artikel.code)"
                              onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild">
                          </div>

                          <img [src]="getArtikelStandardBildByCode(artikel.code)"
                            onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild-blur">
                          <div *ngIf="!getArtikelStandardBildByCode(artikel.code)" class="artikel-bild-none"
                            style="padding-top:15px">
                            <i class="fas fa-camera" style="font-size: 70px !important;color: silver;"></i>
                          </div>
                        </div>
                        <div class="p-x-default">
                          <span class="artikel-preis float-right" style="margin-top:0;margin-left:15px"
                            *ngIf="currentShopDefinition.displayPreise">
                            <ng-container
                              *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                              <ng-container
                                *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                              </ng-container>
                            </ng-container>
                            <ng-container
                              *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                              {{artikel.euroNettopreis | currencyFormat}}
                            </ng-container>
                          </span>
                          <div style="overflow: hidden;text-overflow: ellipsis;display: block;">
                            <strong class="artikel-name">{{artikel.name}}</strong>
                          </div>
                          <small class="artikel-nummer">{{artikel.nummer}}</small>
                          <br>
                          <div class=" small pointer " *ngIf="artikel.mietartikel" title="Mietartikel">&nbsp;</div>
                          <div class=" small pointer " *ngIf="!artikel.mietartikel" title="Lagerartikel">&nbsp;</div>
                        </div>


                        <div class="artikel-hover mode-1">
                          <div class="artikel-bild-container float-left"
                            style="height:131px;width:131px;margin-right:15px" (click)="currentArtikelIndex = i">
                            <div class="absolute max-width max-height"
                              style="left: 0;vertical-align: middle;display: inline-flex;">
                              <img [src]="getArtikelStandardBildByCode(artikel.code)"
                                onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild">
                            </div>

                            <img [src]="getArtikelStandardBildByCode(artikel.code)"
                              onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild-blur">
                            <div *ngIf="!getArtikelStandardBildByCode(artikel.code)" class="artikel-bild-none"
                              style="padding-top:15px">
                              <i class="fas fa-camera" style="font-size: 70px !important;color: silver;"></i>
                            </div>

                          </div>
                          <div class="p-default">

                            <span class="artikel-preis float-right" style="margin-top:0;margin-left:15px"
                              *ngIf="currentShopDefinition.displayPreise && disableAddArtikel">
                              <ng-container
                                *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                <ng-container
                                  *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                  {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                {{artikel.euroNettopreis | currencyFormat}}
                              </ng-container>
                            </span>


                            <div (click)="currentArtikelIndex = i">

                              <div style="overflow: hidden;text-overflow: ellipsis;display: block;">
                                <strong class="artikel-name">{{artikel.name}}</strong>
                              </div>

                              <div class=" small pointer  inline-block" *ngIf="artikel.mietartikel" title="Mietartikel">
                                &nbsp;</div>
                              <div class=" small pointer  inline-block" *ngIf="!artikel.mietartikel"
                                title="Lagerartikel">
                                &nbsp;</div>
                              <small class="artikel-nummer">{{artikel.nummer}}</small>
                              <div [innerHtml]="artikel.langtext" class="p-top-default"
                                style="max-height:80px;overflow:hidden"></div>

                            </div>
                          </div>


                          <table class="table no-margin table-fixed">
                            <tr>
                              <ng-container
                                *ngIf="currentShopDefinition.displayBestaende && !artikel.mietartikel && !artikel.nichtLagerArtikel && artikel['bestand'] != undefined && artikel['bestand'] != 0">
                                <th>Bestand</th>
                                <td class="align-center">
                                  <span
                                    *ngIf="artikel['bestand'] != undefined && artikel['bestand'] != 0">{{artikel['bestand']}}</span>

                                </td>
                              </ng-container>
                              <td class="align-right">
                                <div class="inline-block" *ngIf="!disableAddArtikel">
                                  <mat-form-field class="" style="width:80px;margin-right:15px">
                                    <input matInput placeholder="Menge" class="align-right"
                                      [(ngModel)]="anzahlNewPosition" type="number">
                                    <span matSuffix>{{setEinheit(artikel.einheitCode)}}</span>
                                  </mat-form-field>
                                </div>
                                <button mat-raised-button class="float-right" (click)="addPosition(artikel)"
                                  *ngIf="!disableAddArtikel">
                                  <i class="fas fa-shopping-cart pointer" matSuffix></i>
                                  <span *ngIf="currentShopDefinition.displayPreise">
                                    <ng-container
                                      *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                      <ng-container
                                        *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                        {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                      </ng-container>
                                    </ng-container>
                                    <ng-container
                                      *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                      {{artikel.euroNettopreis | currencyFormat}}
                                    </ng-container>
                                  </span>
                                </button>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>


                  <ng-container *ngIf="viewMode == 21">
                    <ng-container *ngFor="let artikel of artikelList;let i = index">
                      <div class="col-md-12 col-sm-12 col-xs-12 artikel-item fade-down" style="margin-bottom:5px"
                        *ngIf="pageEvent && (i+1) <= (pageEvent.pageSize * (pageEvent.pageIndex + 1)) && (i+1) >= ((pageEvent.pageSize * (pageEvent.pageIndex + 1)) - pageEvent.pageSize + 1)">

                        <div class="border-bottom" style="padding-bottom:5px">
                          <span class="artikel-preis float-right" style="margin-top:0"
                            *ngIf="currentShopDefinition.displayPreise">
                            <ng-container
                              *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                              <ng-container
                                *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                              </ng-container>
                            </ng-container>
                            <ng-container
                              *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                              {{artikel.euroNettopreis | currencyFormat}}
                            </ng-container>
                          </span>
                          <div style="overflow: hidden;text-overflow: ellipsis;display: block;">
                            <strong class="artikel-name">{{artikel.name}}</strong><br>
                            <div class="artikel-column">
                              <div class=" small pointer  inline-block" *ngIf="artikel.mietartikel" title="Mietartikel">
                                &nbsp;</div>
                              <div class=" small pointer  inline-block" *ngIf="!artikel.mietartikel"
                                title="Lagerartikel">
                                &nbsp;</div>
                              <small class="artikel-nummer">{{artikel.nummer}} &nbsp;</small>
                            </div>
                            <div class="artikel-column"
                              *ngIf="currentShopDefinition.displayBestaende && !artikel.mietartikel && !artikel.nichtLagerArtikel && artikel['bestand'] != undefined && artikel['bestand'] != 0">
                              <label class="small" for="">Bestand: </label>
                              <span
                                *ngIf="artikel['bestand'] != undefined && artikel['bestand'] != 0">{{artikel['bestand']}}</span>
                            </div>
                          </div>
                        </div>

                        <div class="artikel-hover mode-1">
                          <div class="artikel-bild-container float-left"
                            style="height:90px;width:90px;margin-right:15px" (click)="currentArtikelIndex = i">
                            <div class="absolute max-width max-height"
                              style="left: 0;vertical-align: middle;display: inline-flex;">
                              <img [src]="getArtikelStandardBildByCode(artikel.code)"
                                onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild">
                            </div>
                            <img [src]="getArtikelStandardBildByCode(artikel.code)"
                              onError="this.src='assets/img/no-img.jpg'" alt="" class="artikel-bild-blur">
                            <div *ngIf="!getArtikelStandardBildByCode(artikel.code)" class="artikel-bild-none">
                              <i class="fas fa-camera" style="font-size: 70px !important;color: silver;"></i>
                            </div>
                          </div>
                          <div class="p-default">
                            <span *ngIf="currentShopDefinition.displayPreise && disableAddArtikel">
                              <ng-container
                                *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                <ng-container
                                  *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                  {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                {{artikel.euroNettopreis | currencyFormat}}
                              </ng-container>
                            </span>
                            <div class="float-right">
                              <button mat-raised-button class="max-width" (click)="addPosition(artikel)"
                                *ngIf="!disableAddArtikel">
                                <i class="fas fa-shopping-cart pointer" matSuffix></i>
                                <span *ngIf="currentShopDefinition.displayPreise">
                                  <ng-container
                                    *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                    <ng-container
                                      *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                      {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                    </ng-container>
                                  </ng-container>
                                  <ng-container
                                    *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                    {{artikel.euroNettopreis | currencyFormat}}
                                  </ng-container>
                                </span>
                              </button>
                            </div>
                            <div class="float-right" style="width:80px;margin-right:15px" *ngIf="!disableAddArtikel">
                              <mat-form-field class="">
                                <input matInput placeholder="Menge" [(ngModel)]="anzahlNewPosition" class="align-right"
                                  type="number">
                                <span matSuffix>{{setEinheit(artikel.einheitCode)}}</span>
                              </mat-form-field>
                            </div>

                            <div (click)="currentArtikelIndex = i">
                              <div style="overflow: hidden;text-overflow: ellipsis;display: block;">
                                <strong class="artikel-name">{{artikel.name}}</strong>
                              </div>
                              <div class="artikel-column">
                                <div class=" small pointer " *ngIf="artikel.mietartikel" title="Disposition">Disposition
                                </div>
                                <div class=" small pointer " *ngIf="!artikel.mietartikel" title="Lagerartikel">
                                  Lagerartikel
                                </div>
                                <small class="artikel-nummer">{{artikel.nummer}}</small>
                              </div>
                              <div class="artikel-column"
                                *ngIf="currentShopDefinition.displayBestaende && !artikel.mietartikel && !artikel.nichtLagerArtikel && artikel['bestand'] != undefined && artikel['bestand'] != 0">
                                <label class="small" for="">Bestand: </label>
                                <span
                                  *ngIf="artikel['bestand'] != undefined && artikel['bestand'] != 0">{{artikel['bestand']}}</span>
                              </div>
                              <div [innerHtml]="artikel.langtext"
                                style="max-height:20px;overflow:hidden;text-overflow: ellipsis"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>


                  <ng-container *ngIf="viewMode == 2">
                    <div class="col-md-12 col-sm-12 col-xs-12 artikel-item fade-down">

                      <table class="table table-hover" *ngIf="!loading">
                        <tr>
                          <th>Nummer</th>
                          <th>Bezeichnung</th>
                          <th class="align-center"></th>
                          <th class="align-center">
                            <span *ngIf="currentShopDefinition.displayBestaende">Verfügbarkeit</span> </th>
                          <th class="align-center">Einheit</th>
                          <th class="align-right">
                            <span *ngIf="currentShopDefinition.displayPreise">Einzelpreis</span>
                          </th>
                        </tr>

                        <ng-container *ngFor="let artikel of artikelList;let i = index">
                          <tr
                            *ngIf="pageEvent && (i+1) <= (pageEvent.pageSize * (pageEvent.pageIndex + 1)) && (i+1) >= ((pageEvent.pageSize * (pageEvent.pageIndex + 1)) - pageEvent.pageSize + 1)">
                            <td class="pointer" (click)="currentArtikelIndex = i">{{artikel.nummer}}</td>
                            <td class="pointer" (click)="currentArtikelIndex = i">{{artikel.name}}</td>
                            <td class="pointer align-center" (click)="currentArtikelIndex = i">
                              <div class=" small pointer  inline-block" *ngIf="artikel.mietartikel" title="Mietartikel">
                                &nbsp;</div>
                              <div class=" small pointer  inline-block" *ngIf="!artikel.mietartikel"
                                title="Lagerartikel">
                                &nbsp;</div>
                            </td>
                            <td class="pointer align-center" (click)="currentArtikelIndex = i">
                              <span
                                *ngIf="currentShopDefinition.displayBestaende && !artikel.mietartikel && (artikel['bestand'] == undefined || artikel['bestand'] == 0)">
                                Auf Anfrage
                              </span>
                              <ng-container *ngIf="currentShopDefinition.displayBestaende">
                                <span
                                  *ngIf="!artikel.mietartikel && artikel['bestand'] != undefined && artikel['bestand'] != 0">{{artikel['bestand']}}</span>
                                <span *ngIf="artikel.mietartikel && artikel['verfuegbar'] != undefined">
                                  {{artikel['verfuegbar']}} / {{artikel['sollBestand']}}</span>
                              </ng-container>
                            </td>
                            <td class="pointer align-center" (click)="currentArtikelIndex = i">
                              {{setEinheit(artikel.einheitCode)}}
                            </td>
                            <td class="pointer align-right" style="min-width:300px">
                              <div class="mouseout">
                                <span *ngIf="currentShopDefinition.displayPreise">
                                  <ng-container
                                    *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                    <ng-container
                                      *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                      {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                    </ng-container>
                                  </ng-container>
                                  <ng-container
                                    *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                    {{artikel.euroNettopreis | currencyFormat}}
                                  </ng-container>
                                </span>
                              </div>
                              <div class="mouseover">
                                <span *ngIf="currentShopDefinition.displayPreise && disableAddArtikel">
                                  <ng-container
                                    *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                    <ng-container
                                      *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                      {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                    </ng-container>
                                  </ng-container>
                                  <ng-container
                                    *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                    {{artikel.euroNettopreis | currencyFormat}}
                                  </ng-container>
                                </span>
                                <div class="float-right" style="margin:-8px -16px -10px 0">
                                  <button color="accent" mat-raised-button class="max-width"
                                    (click)="addPosition(artikel, (!artikel.mietartikel && (artikel['bestand'] == undefined || artikel['bestand'] == 0)))"
                                    *ngIf="!disableAddArtikel">
                                    <i class="fas fa-shopping-cart pointer" matSuffix></i>
                                    <span *ngIf="currentShopDefinition.displayPreise">
                                      <ng-container
                                        *ngIf="bzObjType == ObjectType.Bestellung || bzObjType == ObjectType.Bedarfsanforderung">
                                        <ng-container
                                          *ngIf="artikel['preise'] != undefined && artikel['preise'].einkaufspreise != undefined && getEinkaufspreis(artikel['preise'].einkaufspreise) != null && getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis != 0">
                                          {{getEinkaufspreis(artikel['preise'].einkaufspreise).einkaufpreis | currencyFormat}}
                                        </ng-container>
                                      </ng-container>
                                      <ng-container
                                        *ngIf="bzObjType != ObjectType.Bestellung && bzObjType != ObjectType.Bedarfsanforderung && artikel.euroNettopreis != 0">
                                        {{artikel.euroNettopreis | currencyFormat}}
                                      </ng-container>
                                    </span>
                                  </button>
                                </div>
                                <div class="float-right"
                                  style="width: 80px;margin-right: 0px;margin-bottom: -27px;margin-top: -9px;"
                                  *ngIf="!disableAddArtikel">
                                  <mat-form-field class="">
                                    <input matInput placeholder="Menge" [(ngModel)]="anzahlNewPosition"
                                      class="align-right" type="number">
                                    <span matSuffix>{{setEinheit(artikel.einheitCode)}}</span>
                                  </mat-form-field>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                      </table>
                    </div>
                  </ng-container>
                  <mat-paginator [hidden]="artikelList.length == 0" *ngIf="!loading && artikelList && pageEvent "
                    [length]="pageEvent.length" [pageSize]="pageEvent.pageSize" [pageSizeOptions]="pageSizeOptions"
                    (page)="setPage($event)">
                  </mat-paginator>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>

    </div>
  </div>


</div>
-->

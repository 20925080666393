import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  uiIsLoading:boolean = false
  uiLoginErrorMessage:string = null

  password:string = "";
  passwordConfirm:string = "";

  token:string = null;

  finished:boolean = false;

  constructor(protected apiService:ApiService, protected route:ActivatedRoute) { 
    this.route.queryParams.subscribe((params:Params) =>{
      //http://shop.work4all.de/resetpassword/token=WklJbzdWTHFDaXByVlpHQVV3bEJBeHNQR01BWFVaa2pYcldBZUxDYUtvTDZLVldzL1ovRU9nalAyUzI5NWJIM09qQlZwMlJGR2FmbndmNUwwWEtBbXRQdGJXWFRyalNx
      if(params.token != undefined){
        this.token = atob(params.token)
      }

    })
  }

  ngOnInit() {
  }

  passwordNotConfirmed():boolean{
    let result:boolean = false;
    if(this.password != this.passwordConfirm){
      result = true
    }

    return result
  }

  passwordTooShort():boolean{
    let result:boolean = false;
    if(this.password.trim().length < 6){
      result = true
    }

    return result
  }

  submit(){
    this.uiLoginErrorMessage = null
    if(this.passwordTooShort()){
      this.uiLoginErrorMessage = "Das Passwort ist zu kurz"
    }
    else{
      if(this.passwordNotConfirmed()){
        this.uiLoginErrorMessage = "Passwörter stimmen nicht überein"
        this.uiLoginErrorMessage = "Passwörter müssen übereinstimmen"
      }
      else{
        this.uiIsLoading = true;
        this.apiService.resetPassword(Md5.hashStr(this.password),this.token, success =>{
          this.uiIsLoading = false;
          this.finished = true;
        }, error =>{
          this.uiIsLoading = false;
        })
      }
    }
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./w4a-tree.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./w4a-tree.component";
import * as i3 from "../../services/shop.service";
import * as i4 from "../../services/api.service";
import * as i5 from "@angular/common";
var styles_W4aTreeComponent = [i0.styles];
var RenderType_W4aTreeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_W4aTreeComponent, data: {} });
export { RenderType_W4aTreeComponent as RenderType_W4aTreeComponent };
function View_W4aTreeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "w4a-tree-node-arrow float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.subGruppenOpen = !_co.subGruppenOpen) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["style", "border-radius: 100px;width: 5px;height: 5px;margin-left: 6px;background: black;display: inline-block;"]], null, null, null, null, null))], null, null); }
function View_W4aTreeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-w4a-tree", [], null, [[null, "codeSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("codeSelected" === en)) {
        var pd_0 = (_co.nodeClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_W4aTreeComponent_0, RenderType_W4aTreeComponent)), i1.ɵdid(2, 114688, null, 0, i2.W4aTreeComponent, [i3.ShopService, i4.ApiService], { activeNode: [0, "activeNode"], treeNode: [1, "treeNode"] }, { codeSelected: "codeSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeNode; var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_W4aTreeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ul", [["class", "w4a-tree-node first-node"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_4)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "w4a-tree-node-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nodeClicked(_co.treeNode) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "w4a-tree-node-active": 0 }), (_l()(), i1.ɵted(-1, null, ["Alle"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "ul", [["class", "w4a-tree-node fade-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_5)), i1.ɵdid(11, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.treeNode.subGruppen && (_co.treeNode.subGruppen.length > 0)); _ck(_v, 3, 0, currVal_0); var currVal_1 = "w4a-tree-node-name"; var currVal_2 = _ck(_v, 7, 0, ((_co.activeNode && _co.treeNode) && (_co.treeNode.code == _co.activeNode.code))); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _co.treeNode.subGruppen; _ck(_v, 11, 0, currVal_3); }, null); }
function View_W4aTreeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.treeNode.subGruppen; _ck(_v, 2, 0, currVal_0); }, null); }
function View_W4aTreeComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "i", [["class", "fas fa-caret-down"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "red-caret": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fas fa-caret-down"; var currVal_1 = _ck(_v, 3, 0, _co.hasMandatorySubNode(_co.treeNode)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_W4aTreeComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "i", [["class", "fas fa-caret-right"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "red-caret": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fas fa-caret-right"; var currVal_1 = _ck(_v, 3, 0, _co.hasMandatorySubNode(_co.treeNode)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_W4aTreeComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "w4a-tree-node-arrow float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.subGruppenOpen = !_co.subGruppenOpen) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_9)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_10)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subGruppenOpen; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.subGruppenOpen; _ck(_v, 4, 0, currVal_1); }, null); }
function View_W4aTreeComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-circle float-right mandatory-group"]], null, null, null, null, null))], null, null); }
function View_W4aTreeComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-exclamation float-right mandatory-subgroup"]], null, null, null, null, null))], null, null); }
function View_W4aTreeComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-w4a-tree", [], null, [[null, "codeSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("codeSelected" === en)) {
        var pd_0 = (_co.nodeClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_W4aTreeComponent_0, RenderType_W4aTreeComponent)), i1.ɵdid(2, 114688, null, 0, i2.W4aTreeComponent, [i3.ShopService, i4.ApiService], { activeNode: [0, "activeNode"], treeNode: [1, "treeNode"] }, { codeSelected: "codeSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeNode; var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_W4aTreeComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_8)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "w4a-tree-node-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nodeClicked(_co.treeNode) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "w4a-tree-node-active": 0 }), (_l()(), i1.ɵted(7, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_11)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_12)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "ul", [["class", "w4a-tree-node fade-right"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_13)), i1.ɵdid(14, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.treeNode.subGruppen && (_co.treeNode.subGruppen.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = "w4a-tree-node-name"; var currVal_2 = _ck(_v, 6, 0, ((_co.activeNode && _co.treeNode) && (_co.treeNode.code == _co.activeNode.code))); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_4 = _co.isMandatorySubNode(_co.treeNode); _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.hasMandatorySubNode(_co.treeNode); _ck(_v, 11, 0, currVal_5); var currVal_7 = _co.treeNode.subGruppen; _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.treeNode.name; _ck(_v, 7, 0, currVal_3); var currVal_6 = !((_co.treeNode.subGruppen && (_co.treeNode.subGruppen.length > 0)) && _co.subGruppenOpen); _ck(_v, 12, 0, currVal_6); }); }
function View_W4aTreeComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_7)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.treeNode; _ck(_v, 2, 0, currVal_0); }, null); }
function View_W4aTreeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_6)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.treeNode && ((_co.treeNode.name == undefined) || (_co.treeNode.name.length == 0))); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.treeNode && (_co.treeNode.name != undefined)) && (_co.treeNode.name.length > 0)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_W4aTreeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_W4aTreeComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.requiredArtikleGroups != null) && (_co.artikleGroupCodes != null)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_W4aTreeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-w4a-tree", [], null, null, null, View_W4aTreeComponent_0, RenderType_W4aTreeComponent)), i1.ɵdid(1, 114688, null, 0, i2.W4aTreeComponent, [i3.ShopService, i4.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var W4aTreeComponentNgFactory = i1.ɵccf("app-w4a-tree", i2.W4aTreeComponent, View_W4aTreeComponent_Host_0, { activeNode: "activeNode", treeNode: "treeNode" }, { codeSelected: "codeSelected" }, []);
export { W4aTreeComponentNgFactory as W4aTreeComponentNgFactory };

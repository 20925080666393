<div class="max-height">
    <div class="absolute max-width">
        <app-header></app-header>
    </div>
    <div
        class="main-content max-height relative z-100 p-top-lg-70 p-top-md-70 p-bottom-lg-55 p-bottom-md-55 p-bottom-sm-55 p-bottom-xs-0">
        <div class="container max-height">
            <mat-card class="max-height  overflow-hidden" style="margin:0 -15px">

                <div class="y-split max-height">
                    <div>

                        <div class="overflow-hidden border-bottom background-whitesmoke z-200">

                            <ng-container *ngIf="!currentExternalLogin">
                                <div class="max-height fade-right">
                                    <div class="float-right max-height border-left relative"
                                        style="margin-top: 0px;margin-bottom: -19px;">
                                        <mat-form-field class="no-border" appearance="fill">
                                            <mat-label>Suche</mat-label>
                                            <input matInput [(ngModel)]="searchText" (input)="searchByText()"
                                                autocomplete="false">
                                            <div class="fas fa-search" matSuffix></div>
                                        </mat-form-field>
                                    </div>
                                    <button mat-button class="float-right" (click)="addUser()"
                                        style="padding-top:7.5px; padding-bottom:7.5px">
                                        <i class="fas fa-plus"></i>
                                        <span class="hidden-xs">
                                            Zugang hinzufügen
                                        </span>
                                    </button>

                                    <div class="p-default">
                                        <strong class="">Zugänge verwalten</strong>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="currentExternalLogin">
                                <div class="max-height fade-left">
                                    <div class="float-right  ">
                                        <button mat-button color="warn" style="padding-top:7.5px; padding-bottom:7.5px"
                                            (click)="closeEditMode()">
                                            <i class="fas fa-times"></i>
                                            <span class="hidden-xs">
                                                Abbrechen
                                            </span>
                                        </button>
                                        <button mat-button color="default"
                                            style="padding-top:7.5px; padding-bottom:7.5px"
                                            (click)="saveExternalLogin()" [disabled]="savingDisabled()">
                                            <i class="fas fa-save"></i>
                                            <span class="hidden-xs">
                                                Speichern
                                            </span>
                                        </button>
                                    </div>

                                    <div class="p-default">
                                        <strong class="">Shop-Zugang</strong>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                    <div class="max-height">
                        <ng-container *ngIf="loading">

                            <!--<mat-progress-bar mode="indeterminate" [color]="'accent'"></mat-progress-bar>-->
                            <div class="p-default align-center">
                                <i class="fas fa-circle-notch fa-spin"></i>
                                <br>
                                <br>
                                <!--<div class="inline-block">
                                    <mat-progress-spinner [color]="'accent'" [mode]="'indeterminate'" [diameter]="40"
                                        [strokeWidth]="4">
                                    </mat-progress-spinner>
                                </div>-->

                                Einen Moment bitte...
                            </div>
                        </ng-container>

                        <ng-container *ngIf="currentExternalLogin">
                            <div class="max-height-sm fade-left">
                                <div class="col-sm-4 max-height-sm border-right">
                                    <div class="p-default">

                                        <h3> Stammdaten</h3>
                                        <div class="row">
                                            <div class="col-sm-12" *ngIf="_shopService.isAdmin()"> 

                                                <mat-form-field appearance="fill" class="max-width"
                                                    *ngIf="currentExternalLogin.kunde == null"
                                                    (click)="openKundenSuche()">
                                                    <mat-label>Kunde</mat-label>
                                                    <input matInput value="Bitte wählen..." autocomplete="false"
                                                        required>
                                                    <mat-error *ngIf="currentExternalLogin.kunde == undefined">
                                                        Wählen Sie den Kunden
                                                    </mat-error>
                                                </mat-form-field>

                                                <mat-form-field appearance="fill" class="max-width"
                                                    *ngIf="currentExternalLogin.kunde !== null"
                                                    (click)="openKundenSuche()">
                                                    <mat-label>Kunde</mat-label>
                                                    <input matInput [value]="currentExternalLogin.kunde.name"
                                                        autocomplete="false" required>
                                                    <mat-error *ngIf="currentExternalLogin.kunde == undefined">
                                                        Wählen Sie den Kunden
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12">
                                                <mat-form-field appearance="fill" class="max-width">
                                                    <mat-label>Anrede</mat-label>
                                                    <mat-select [(ngModel)]="currentExternalLogin.anrede"
                                                        [disabled]="!currentExternalLogin.new" autocomplete="false"
                                                        required>
                                                        <mat-option value="">Bitte wählen...</mat-option>
                                                        <mat-option value="Herr">Herr</mat-option>
                                                        <mat-option value="Frau">Frau</mat-option>
                                                    </mat-select> 
                                                    <mat-error *ngIf="currentExternalLogin.vorname.length == 0">Geben
                                                        Sie einen Vornamen an</mat-error>
                                                </mat-form-field>

                                            </div>
                                            <div class="col-sm-12">
                                                <mat-form-field appearance="fill" class="max-width">
                                                    <mat-label>Vorname</mat-label>
                                                    <input matInput [(ngModel)]="currentExternalLogin.vorname"
                                                        [disabled]="!currentExternalLogin.new" autocomplete="false"
                                                        required>
                                                    <mat-error *ngIf="currentExternalLogin.vorname.length == 0">Geben
                                                        Sie einen Vornamen an</mat-error>
                                                </mat-form-field>

                                            </div>
                                            <div class="col-sm-12">
                                                <mat-form-field appearance="fill" class="max-width">
                                                    <mat-label>Nachname</mat-label>
                                                    <input matInput [(ngModel)]="currentExternalLogin.name"
                                                        [disabled]="!currentExternalLogin.new" autocomplete="false"
                                                        required>

                                                    <mat-error *ngIf="currentExternalLogin.name.length == 0">Geben Sie
                                                        einen Nachnamen an</mat-error>

                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12">
                                                <mat-form-field appearance="fill" class="max-width">
                                                    <mat-label>E-Mail</mat-label>
                                                    <input matInput [(ngModel)]="currentExternalLogin.eMail" required
                                                        (input)="currentExternalLogin.benutzername = currentExternalLogin.name ? currentExternalLogin.eMail : currentExternalLogin.benutzername"
                                                        [disabled]="!currentExternalLogin.new" autocomplete="false">
                                                    <mat-error *ngIf="currentExternalLogin.eMail.length == 0">Geben Sie
                                                        eine E-Mail-Adresse an an</mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 max-height-sm border-right">
                                    <div class="p-default">
                                        <h3> Zugangsdaten</h3>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <mat-form-field appearance="fill" class="max-width">
                                                    <mat-label>Benutzername</mat-label>
                                                    <input matInput [(ngModel)]="currentExternalLogin.benutzername"
                                                        required autocomplete="new-password">
                                                    <mat-error *ngIf="currentExternalLogin.benutzername.length == 0">
                                                        Geben Sie einen Benutzernamen an</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12">
                                                <mat-form-field appearance="fill" class="max-width">
                                                    <mat-label>Neues Passwort</mat-label>
                                                    <input matInput type="password"
                                                        [required]="currentExternalLogin.new || passwortConfirm.length > 0"
                                                        [(ngModel)]="currentExternalLogin.passwort"
                                                        autocomplete="new-password">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12">
                                                <mat-form-field appearance="fill" class="max-width">
                                                    <mat-label>Neues Passwort bestätigen</mat-label>
                                                    <input matInput type="password" [(ngModel)]="passwortConfirm"
                                                        [required]="currentExternalLogin.new || currentExternalLogin.passwort.length > 0"
                                                        autocomplete="new-password">

                                                </mat-form-field>
                                                <mat-error
                                                    *ngIf="(currentExternalLogin.passwort.length > 0 && !passwordValid()) || ((currentExternalLogin.passwortHash == undefined || currentExternalLogin.passwortHash == null || currentExternalLogin.passwortHash == '') && !passwordValid())">
                                                    Bitte geben Sie ein valides Passwort ein.<br><br>
                                                </mat-error>

                                                <!--<mat-checkbox [(ngModel)]="currentExternalLogin.mustChangePassword"> User
                                                muss sein Passwort beim nächsten Login ändern
                                            </mat-checkbox>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 max-height-sm border-right">
                                    <div class="p-default">
                                        <h3> Optionen</h3>
                                        <strong>Status</strong>
                                        <div class="p-bottom-default">
                                            <mat-checkbox [(ngModel)]="currentExternalLogin.aktiv"> Aktiv</mat-checkbox>
                                            <br>
                                            <mat-checkbox [(ngModel)]="currentExternalLogin.master"> Administrator
                                            </mat-checkbox>
                                        </div>
                                        <div class="p-bottom-default">
                                            <strong> Bestellungen von Kollegen</strong>
                                        </div>
                                        <div class="p-bottom-default">
                                            <mat-checkbox (change)="checkRights(1)"
                                                [(ngModel)]="currentExternalLogin.rechteParsed.bestellungenEinsehen">
                                                User darf Bestellungen von Kollegen einsehen</mat-checkbox>
                                            <br>
                                            <mat-checkbox
                                                [(ngModel)]="currentExternalLogin.rechteParsed.bestellungenBearbeiten"
                                                (change)="checkRights(2)">
                                                User darf Bestellungen von Kollegen bearbeiten</mat-checkbox>
                                            <br>
                                            <mat-checkbox
                                                [(ngModel)]="currentExternalLogin.rechteParsed.genehmigungErteilen"
                                                (change)="checkRights(3)">
                                                User darf Bestellungen von Kollegen genehmigen</mat-checkbox>

                                        </div>
                                        <ng-container *ngIf=" currentShopDefinition.projekteInsertable">
                                            <div class="p-bottom-default">
                                                <strong>Projekte</strong>
                                            </div>
                                            <div class="p-bottom-default">
                                                <mat-checkbox
                                                    [(ngModel)]="currentExternalLogin.rechteParsed.projektErstellen">
                                                    User darf Projekte anlegen </mat-checkbox><br>
                                                <!--<mat-checkbox
                                                [(ngModel)]="currentExternalLogin.rechteParsed.projektLoeschen">
                                                User darf Projekte löschen </mat-checkbox><br>-->
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                        <ng-container *ngIf="!currentExternalLogin">

                            <div class="max-height fade-right">
                                <ng-container *ngIf="!loading">
                                    <dx-data-grid class="max-height  max-width" #dataGrid [dataSource]="dataSource "
                                        [columnResizingMode]="" [allowColumnReordering]="true"
                                        [allowColumnResizing]="true" [columnAutoWidth]="true" [hoverStateEnabled]="true"
                                        [selectedRowKeys]="[]" [showRowLines]="true" [showBorders]="false"
                                        [rowAlternationEnabled]="false"
                                        (onSelectionChanged)="onSelectionChanged($event)"
                                        (onRowClick)="onRowClick($event)" (onRowDblClick)="onRowDblClicked($event)">

                                        <dxo-group-panel [visible]="false"></dxo-group-panel>
                                        <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
                                        <!--<dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage"></dxo-state-storing>
                            <dxo-selection [mode]="selection"></dxo-selection>-->
                                        <dxo-sorting mode="multiple"></dxo-sorting>
                                        <dxo-scrolling mode="infinite"></dxo-scrolling>
                                        <dxo-paging [pageSize]="200"></dxo-paging>
                                        <dxo-header-filter [visible]="true"></dxo-header-filter>
                                        <dxo-filter-row [visible]="false"></dxo-filter-row>
                                        <dxo-search-panel [visible]="false" [width]="240" placeholder="Suche...">
                                        </dxo-search-panel>

                                        <dxi-column dataField="benutzername" caption="Benutzername" dataType="string"
                                            alignment="left" [visible]="true" [allowHiding]="true"
                                            [allowHeaderFiltering]="true">
                                        </dxi-column>

                                        <dxi-column dataField="ansprechpartnerAnzeigename" caption="Ansprechpartner"
                                            dataType="string" alignment="left" [visible]="true" [allowHiding]="true"
                                            [allowHeaderFiltering]="true">
                                        </dxi-column>

                                        <dxi-column dataField="kundeName" caption="Kunde" dataType="string"
                                            [groupIndex]="1" *ngIf="_shopService.isAdmin()" alignment="left"
                                            [visible]="true" [allowHiding]="true" [allowHeaderFiltering]="true">
                                        </dxi-column>

                                        <dxi-column dataField="aktiv" caption="Aktiv" dataType="boolean"
                                            alignment="center" [visible]="true" [allowHiding]="true"
                                            [allowHeaderFiltering]="true">
                                        </dxi-column>

                                        <!--<dxi-column dataField="mustChangePassword" caption="Passwort ändern"
                                            dataType="boolean" alignment="center" [visible]="false" [allowHiding]="true"
                                            [allowHeaderFiltering]="true">
                                        </dxi-column>-->

                                        <dxi-column caption="Projekte" alignment="center"
                                            *ngIf="_shopService.isAdmin()">
                                            <dxi-column dataField="rechtProjektErstellen" caption="anlegen"
                                                dataType="boolean" alignment="center" [visible]="true"
                                                [allowHiding]="true" [allowHeaderFiltering]="true">
                                            </dxi-column>
                                            <dxi-column dataField="rechtProjektLoeschen" caption="löschen"
                                                dataType="boolean" alignment="center" [visible]="true"
                                                [allowHiding]="true" [allowHeaderFiltering]="true">
                                            </dxi-column>
                                        </dxi-column>

                                        <dxi-column caption="Bestellungen von Kollegen" alignment="center">
                                            <dxi-column dataField="rechtBestellungenEinsehen" caption="einsehen"
                                                dataType="boolean" alignment="center" [visible]="true"
                                                [allowHiding]="true" [allowHeaderFiltering]="true">
                                            </dxi-column>

                                            <dxi-column dataField="rechtBestellungenBearbeiten" caption=" bearbeiten"
                                                dataType="boolean" alignment="center" [visible]="true"
                                                [allowHiding]="true" [allowHeaderFiltering]="true">
                                            </dxi-column>
                                            <dxi-column dataField="rechtGenehmigungErteilen" caption="genehmigen"
                                                dataType="boolean" alignment="center" [visible]="true"
                                                [allowHiding]="true" [allowHeaderFiltering]="true">
                                            </dxi-column>
                                        </dxi-column>

                                    </dx-data-grid>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </mat-card>
        </div>
    </div>
    <div class="absolute max-width z-100 hidden-xs" style="margin-top:-55px">
        <app-footer></app-footer>
    </div>
</div>
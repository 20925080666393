import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ObjectType } from '@work4all/api';
import { ManagedExternalLogin } from 'src/app/classes/managed-external-login';
import { Md5 } from 'ts-md5/dist/md5';
import { SucheDialogObjectType } from 'src/app/enums/suche-dialog-object-type.enum';
import { SucheDialogData } from 'src/app/classes/suche-dialog-data';
export class UsersComponent {
    constructor(apiService, shopService) {
        this.apiService = apiService;
        this.shopService = shopService;
        this.dataSource = [];
        this.ObjectType = ObjectType;
        this.loading = false;
        this.searchText = "";
        this.currentExternalLogin = null;
        this._shopService = this.shopService;
        this.currentShopDefinition = null;
        this.ownUsername = null;
        this.passwortConfirm = "";
        this.shopService.CurrentShopDefinition.subscribe(data => {
            this.currentShopDefinition = data;
        });
    }
    ngOnInit() {
        this.getExternalLogins();
    }
    getExternalLogins() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.dataSource = [];
            this.dataSource = yield this.apiService.getManagedExternalLogins();
            this.loading = false;
        });
    }
    searchByText() {
        this.dataGrid.instance.searchByText(this.searchText);
    }
    onSelectionChanged(e) {
    }
    onRowClick(e) {
        let login = e.data;
        this.currentExternalLogin = login;
        this.ownUsername = JSON.parse(JSON.stringify(this.currentExternalLogin.benutzername));
        if (this.currentExternalLogin.passwortHash == undefined || this.currentExternalLogin.passwortHash.length == 0) {
            this.currentExternalLogin.benutzername = login.eMail;
            this.currentExternalLogin.passwort = "";
            this.currentExternalLogin.aktiv = true;
            //this.currentExternalLogin.new = true
        }
        this.passwortConfirm = "";
    }
    checkUsername() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            let usernameValid = yield this.apiService.checkUsername(this.currentExternalLogin.benutzername);
            if (!usernameValid && this.currentExternalLogin.benutzername != this.ownUsername) {
                return false;
            }
            return true;
        });
    }
    onRowDblClicked(e) {
    }
    addUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let licenseStatus = yield this.apiService.getLicenseStatus();
            if (licenseStatus.anzahlAktiveLogins >= licenseStatus.maximaleAktiveLogins) {
                let data = {
                    title: "Achtung",
                    message: "Sie haben die maximale Anzahl an aktiven Logins aufgebraucht. Bitte wenden Sie sich zwecks Aufstockung an Ihren Dienstleister.",
                    buttons: [
                        {
                            title: "OK",
                            callback: () => {
                            }
                        }
                    ]
                };
                this.shopService.openGenericDialog(data);
            }
            else {
                this.currentExternalLogin = new ManagedExternalLogin();
                this.currentExternalLogin.new = true;
                this.currentExternalLogin.aktiv = true;
                if (localStorage.getItem("sdObjMemberCode"))
                    this.currentExternalLogin.sdObjMemberCode = JSON.parse(localStorage.getItem("sdObjMemberCode"));
                this.passwortConfirm = "";
            }
        });
    }
    closeEditMode() {
        this.currentExternalLogin = null;
        this.getExternalLogins();
    }
    savingDisabled() {
        return (this.currentExternalLogin.benutzername.length == 0 ||
            this.currentExternalLogin.name.length == 0 ||
            this.currentExternalLogin.vorname.length == 0 ||
            (this.currentExternalLogin.new && !this.passwordValid()));
    }
    passwordValid() {
        return (this.currentExternalLogin.passwort.length > 0 &&
            this.passwortConfirm == this.currentExternalLogin.passwort);
    }
    saveExternalLogin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.loading = true;
            if (this.currentExternalLogin.passwortHash.length == 0) {
                let licenseStatus = yield this.apiService.getLicenseStatus();
                if (licenseStatus.anzahlAktiveLogins >= licenseStatus.maximaleAktiveLogins) {
                    let data = {
                        title: "Achtung",
                        message: "Sie haben die maximale Anzahl an aktiven Logins aufgebraucht. Bitte wenden Sie sich zwecks Aufstockung an Ihren Dienstleister.",
                        buttons: [
                            {
                                title: "OK",
                                callback: () => {
                                }
                            }
                        ]
                    };
                    this.shopService.openGenericDialog(data);
                    return;
                }
            }
            let usernameValid = yield this.checkUsername();
            if (!usernameValid) {
                let data = {
                    title: "Achtung",
                    message: "Der Benutzername ist bereits vergeben. Bitte wählen Sie einen anderen.",
                    buttons: [
                        {
                            title: "OK",
                            callback: () => {
                            }
                        }
                    ]
                };
                this.shopService.openGenericDialog(data);
                return;
            }
            let hash = this.currentExternalLogin.passwortHash;
            if (this.currentExternalLogin.passwort.length > 0)
                hash = Md5.hashStr(this.currentExternalLogin.passwort);
            this.currentExternalLogin.passwort = undefined;
            if (!this.currentExternalLogin.loginID) {
                this.currentExternalLogin.loginID = "00000000-0000-0000-0000-000000000000";
            }
            if (!this.currentExternalLogin.new) {
                this.currentExternalLogin.passwortHash = hash;
                let result = yield this.apiService.putManagedExternalLogin(this.currentExternalLogin);
            }
            else {
                this.currentExternalLogin.passwortHash = hash;
                let result = yield this.apiService.postManagedExternalLogin(this.currentExternalLogin);
            }
            this.closeEditMode();
        });
    }
    openKundenSuche() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let kunde = yield this.shopService.openSucheDialog(new SucheDialogData(SucheDialogObjectType.Kunde));
            if (!kunde)
                return;
            this.setKunde(kunde);
        });
    }
    checkRights(mode) {
        switch (mode) {
            case 1: {
                if (!this.currentExternalLogin.rechteParsed.bestellungenEinsehen) {
                    this.currentExternalLogin.rechteParsed.bestellungenBearbeiten = false;
                    this.currentExternalLogin.rechteParsed.genehmigungErteilen = false;
                    break;
                }
                break;
            }
            case 2:
            case 3: {
                if (this.currentExternalLogin.rechteParsed.bestellungenBearbeiten || this.currentExternalLogin.rechteParsed.genehmigungErteilen) {
                    this.currentExternalLogin.rechteParsed.bestellungenEinsehen = true;
                }
                break;
            }
            default: break;
        }
    }
    setKunde(kunde) {
        this.currentExternalLogin.kunde = kunde;
        this.currentExternalLogin.kundeName = kunde.name;
        this.currentExternalLogin.kundeNummer = kunde.nummer;
    }
}

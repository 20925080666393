export class PageComponentWidth {
    lg: number
    md: number
    sm: number
    xs: number

    constructor(lg: number, md: number = null, sm: number = null, xs: number = null) {
        this.lg = this.md = this.sm = lg;
        this.xs = 12;
        
        if (md != null) {
            this.md = md
        }

        if (sm != null) {
            this.sm = sm
        }

        if (xs != null) {
            this.xs = xs
        }
    }
}

import { FormComponentType } from "../enums/form-component-type.enum";

export class FormComponent {
    type: FormComponentType;
    label: string;
    value: any;
    options: string[];
    dimensions: number;
    mapping: any;
    mandatory: boolean;

    constructor(type: FormComponentType, label: string = "", value: any = "", options: string[] = [], dimensions: number = 12) {
        this.type = type
        this.label = label
        this.value = value
        this.options = options
        this.dimensions = dimensions
        this.mapping = null;
        this.mandatory = false;

        this.setType();

        //console.log(this)
    }

    setType(type = this.type) {
        switch (type) {
            case FormComponentType.Checkbox:
                if (this.label.length == 0) {
                    this.label = "Checkbox"
                }
                //this.dimensions = 4
                break;
            case FormComponentType.Headline:
                if (this.label.length == 0) {
                    this.label = "Überschrift"
                }
                break;
            case FormComponentType.Text:
                if (this.label.length == 0) {
                    this.label = "Ihr Text"
                }
                break;
            case FormComponentType.RadioButtonGroup:
                if (this.label.length == 0) {
                    this.label = "Optionsfeld"
                }
                if (this.options.length == 0) {
                    this.options = ["Option 1", "Option 2"]
                }
                //this.dimensions = 4
                break;
            case FormComponentType.Select:
                if (this.label.length == 0) {
                    this.label = "Auswahlliste"
                }
                if (this.options.length == 0) {
                    this.options = ["Option 1", "Option 2"]
                }
                //this.dimensions = 4
                break;
            case FormComponentType.Textfield:
                if (this.label.length == 0) {
                    this.label = "Textfeld"
                }
                //this.dimensions = 4
                break;
            case FormComponentType.Textarea:
                if (this.label.length == 0) {
                    this.label = "Mehrzeiliges Textfeld"
                }
                break;
            case FormComponentType.Time:
                if (this.label.length == 0) {
                    this.label = "Uhrzeit"
                }
                break;
            case FormComponentType.Date:
                if (this.label.length == 0) {
                    this.label = "Datum"
                }
                break;
            case FormComponentType.Projekt:
                if (this.label.length == 0) {
                    this.label = "Projekt"
                }
                break;
            case FormComponentType.LieferRechnungsadresse:
                this.value = {
                    rechnungsadresse: {
                        firma: "",
                        ansprechpartner: "",
                        strasse: "",
                        plz: "",
                        ort: ""
                    },
                    lieferadresse: {
                        firma: "",
                        ansprechpartner: "",
                        strasse: "",
                        plz: "",
                        ort: ""
                    },
                    lieferadresseIdentischMitRechnungsadresse: true
                }
                break;
            case FormComponentType.Lieferadresse:
                this.value = {
                    lieferadresse: {
                        firma: "",
                        ansprechpartner: "",
                        strasse: "",
                        plz: "",
                        ort: ""
                    }
                }
                break;
            case FormComponentType.Rechnungsadresse:
                this.value = {
                    rechnungsadresse: {
                        firma: "",
                        ansprechpartner: "",
                        strasse: "",
                        plz: "",
                        ort: ""
                    }
                }
                break;
            case FormComponentType.RechnungsadresseAuswahl:
                this.label = "Rechnungsadresse"
                break;
            case FormComponentType.LieferadresseAuswahl:

                this.label = "Lieferadresse"
                break;
            case FormComponentType.Notiz:
                if (this.label.length == 0) {
                    this.label = "Notiz"
                }
                break;
            case FormComponentType.Niederlassung:
                if (this.label.length == 0) {
                    this.label = "Niederlassung"
                }
                break;
            case FormComponentType.Kostenstelle:
                if (this.label.length == 0) {
                    this.label = "Kostenstelle"
                }
                break;



            default: break
        }
    }
}
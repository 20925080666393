import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
export class ArtikelDialogComponent {
    constructor(dialogRef, data, shopService, apiService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.shopService = shopService;
        this.apiService = apiService;
        this.artikelList = [];
        this.currentShopDefinition = null;
        this.artikleGroups = [];
        this.noRequiredGroup = false;
        this.requiredArtikleGroups = [];
        this.shopService.CurrentShopDefinition.subscribe(data => {
            this.currentShopDefinition = data;
        });
        this.shopService.CurrentBeleg.subscribe(data => {
            this.currentBeleg = data;
        });
        this.shopService.CurrentArtikelGruppen.subscribe(data => {
            if (data) {
                this.artikleGroups = data;
            }
            else {
                this.artikleGroups = [];
            }
        });
        this.shopService.CurrentPflichtartikelGruppen.subscribe(data => {
            if (data) {
                this.requiredArtikleGroups = data;
            }
            else {
                this.requiredArtikleGroups = [];
            }
        });
        this.hasRequiredArtikleGroup();
        this.getEinheiten();
    }
    close(result = null) {
        if (!result) {
            this.shopService.getBackupBeleg();
        }
        else {
            this.shopService.setCurrentBeleg(this.currentBeleg);
        }
        this.dialogRef.close(result);
    }
    ngOnInit() {
    }
    getEinheiten() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.einheiten = yield this.apiService.getEinheiten();
        });
    }
    confirm() {
        this.close(this.artikelList);
    }
    addArtikel(data) {
        let found = false;
        if (data.artikel.code != 0) {
            this.artikelList.some((item, itemindex) => {
                if (item.artikel.code == data.artikel.code) {
                    item.menge += data.menge;
                    found = true;
                    return true;
                }
            });
        }
        if (!found)
            this.artikelList.push(data);
        this.artikleGroups.push(data.artikel.grCode);
        this.shopService.setCurrentArtikelGruppen(this.artikleGroups);
        this.hasRequiredArtikleGroup();
    }
    removeArtikel(item) {
        let index = this.artikelList.indexOf(item);
        let groupIndex = this.artikleGroups.indexOf(item.grCode);
        this.artikleGroups.splice(groupIndex, 1);
        this.artikelList.splice(index, 1);
        this.shopService.setCurrentArtikelGruppen(this.artikleGroups);
        this.hasRequiredArtikleGroup();
    }
    hasRequiredArtikleGroup() {
        if (this.requiredArtikleGroups.every(r => this.artikleGroups.indexOf(r) > -1)) {
            this.noRequiredGroup = false;
        }
        else {
            this.noRequiredGroup = true;
        }
    }
    setEinheit(code) {
        if (this.einheiten.find(e => e.code == code))
            return this.einheiten.filter(element => {
                if (element.code === code && element) {
                    return element;
                }
            })[0].name;
    }
}

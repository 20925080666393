<ng-container *ngIf="currentShopDefinition != undefined">
  <footer class="align-left container ">
    <div class="p-y-default">
      <div class="float-right" [style.color]="currentShopDefinition.secondaryColor">

        <ng-container *ngFor="let link of currentShopDefinition.footerLinks;let linkIndex = index">
          <ng-container *ngIf="linkIndex != 0">
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          </ng-container>
            <a [href]="link.href" [target]="link.target" [style.color]="currentShopDefinition.secondaryColor">{{link.text}}</a>
        </ng-container>
      </div>
      <a href="https://www.work4all.de" target="blank" class="footer-logo"  style="background: rgb(47, 47, 47);      text-transform: uppercase;      padding: 4px 7px 4px;      float: left;      margin-top: 0px;      margin-left: -15px;      box-shadow: 0px -1px 6px #00000036;      font-size: 10px;      border-radius: 3px;      color: rgb(255, 255, 255);">
        <img src="assets/img/w4a_logo_full.png" alt="work4all GmbH"  style=" margin: 2px 5px 5px 0px;height: 13px;">  Shop {{versionName}}
      </a>
    </div>
  </footer>
  <!--
  <div style="position:fixed;bottom:0;z-index:9999;left:0;text-align:center" *ngIf="loginLevel == 0 && loggedUser.master">
    <div class="nav-links " style="color:white;background:rgba(23, 23, 23, 0.92);display:inline-block">
      <a class="uppercase pointer" [ngClass]="{'active-link':loginMode == 0}" (click)="setLoginMode(0)">
        <span>Admin</span>
      </a>
      <a class="uppercase pointer" [ngClass]="{'active-link':loginMode == 1}" (click)="setLoginMode(1);navigateToShop()">
        <span>Intern</span>
      </a>
      <a class="uppercase pointer" [ngClass]="{'active-link':loginMode == 2}" (click)="setLoginMode(2)">
        <span>Extern</span>
      </a>
      <a class="uppercase pointer" (click)="invalidateCache()" style="">
        <span>API CACHE LEEREN</span>
      </a>      
    </div>
  </div>
  -->
</ng-container>


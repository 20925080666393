export enum FormComponentType {
    Textfield = 0,
    Textarea = 1,
    Checkbox = 2,
    RadioButtonGroup = 3,
    Select = 4,
    Date = 5,
    Headline = 6,
    Text = 7,
    Divider = 8,
    LineBreak = 9,
    Time = 10,
    Lieferadresse = 11,
    Rechnungsadresse = 12,
    LieferRechnungsadresse = 13,
    Projekt = 14,
    Kostenstelle = 15,
    Notiz = 16,
    Niederlassung = 17,
    Individuell = 18,
    LieferadresseAuswahl = 19,
    RechnungsadresseAuswahl = 20,
}
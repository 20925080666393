import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-veranstaltung-auswahl',
  templateUrl: './veranstaltung-auswahl.component.html',
  styleUrls: ['./veranstaltung-auswahl.component.scss']
})
export class VeranstaltungAuswahlComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<VeranstaltungAuswahlComponent>, @Inject(MAT_DIALOG_DATA) public data: any, ) {

  }

  close(result = null): void {
    this.dialogRef.close(result);
  }

  ngOnInit() {
  }

  confirm(projekt){
    this.close(projekt)
  }

}

import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { UploadResult, Config } from '@work4all/api';
export class FileUploadComponent {
    constructor(apiService, config, shopService) {
        this.apiService = apiService;
        this.config = config;
        this.shopService = shopService;
        this.currentUpload = {};
        this.anhaenge = [];
        this.tmpAnhaenge = new EventEmitter();
        // File Upload =============================================
        this.uploader = new FileUploader({ url: `${this.config.apiUrl}api/file?type=TempDatei` });
        this.hasBaseDropZoneOver = false;
        this.hasAnotherDropZoneOver = false;
        let mandantenCode = localStorage.getItem("mandantenCode");
        this.uploader.authToken = `bearer ${localStorage.getItem('AuthToken')}`;
        this.uploader.autoUpload = true;
        this.uploader.options.headers = [{ name: "MandantenCode", value: mandantenCode }];
        this.uploader.onCompleteItem = (item, response, status, headers) => {
            this.fileuploadCompleted(item, response, status, headers);
        };
        this.uploader.onProgressItem = (item, progress) => {
            this.fileuploadProgress(item, progress);
        };
    }
    ngOnInit() { }
    get loggedUser() {
        return this.apiService.getLoggedUser();
    }
    allowDrop(event) {
        if (!this.borderTimeout) {
            this.borderTimeout = setTimeout(() => this.showDropBorder = false, 500);
        }
        else {
            clearTimeout(this.borderTimeout);
            this.borderTimeout = setTimeout(() => this.showDropBorder = false, 500);
        }
        this.showDropBorder = true;
    }
    fileOverBase(e) {
        this.hasBaseDropZoneOver = e;
    }
    dropped(e) {
        this.uploader.uploadAll();
    }
    fileuploadProgress(item, progress) {
        this.currentUpload = { item: item, progress: progress };
    }
    fileuploadCompleted(item, response, status, headers) {
        if (status !== 200) {
            this.fileuploadFailed(item);
        }
        else {
            if (this.anhaenge == undefined) {
                this.anhaenge = [];
            }
            let uploadResult = new UploadResult(JSON.parse(response));
            console.log(response);
            for (let att of this.anhaenge) {
                if (att.code === uploadResult.generatedObject) {
                    return;
                }
            }
            if (uploadResult.fileStored) {
                this.fileuploadSuccessfull(item, uploadResult.generatedObject);
            }
            else {
                this.fileuploadFailed(item);
            }
        }
    }
    deleteTmpAttachment(i) {
        let data = {
            title: "Achtung",
            message: "Möchten Sie den Anhang verbindlich löschen?",
            buttons: [
                {
                    title: "NEIN",
                    callback: () => {
                    }
                },
                {
                    title: "JA",
                    callback: () => {
                        if (i > -1) {
                            this.anhaenge.splice(i, 1);
                            this.tmpAnhaenge.emit(this.anhaenge);
                        }
                    }
                }
            ]
        };
        this.shopService.openGenericDialog(data);
    }
    fileuploadSuccessfull(item, id) {
        this.currentUpload = {};
        if (this.anhaenge == undefined) {
            this.anhaenge = [];
        }
        this.anhaenge.push({
            code: id,
            dateiname: item.file.name,
            bzObjType: this.order.bzObjType,
            bzobjMemberCode: this.order.code
        });
        this.anhaengeUploaded();
    }
    anhaengeUploaded() {
        this.tmpAnhaenge.emit(this.anhaenge);
    }
    fileuploadFailed(item) {
        this.currentUpload = {};
    }
    // =========================================================
    deleteAttachement(attachement) {
        // console.log(attachement.code)
        let data = {
            title: "Achtung",
            message: "Möchten Sie den Anhang verbindlich löschen?",
            buttons: [
                {
                    title: "NEIN",
                    callback: () => {
                    }
                },
                {
                    title: "JA",
                    callback: () => {
                        if (attachement.code) {
                            this.apiService.deleteAnhang(attachement.code);
                        }
                        this.order.anhaenge.splice(attachement, 1);
                    }
                }
            ]
        };
        this.shopService.openGenericDialog(data);
    }
    downloadAttachement(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(item);
            var result = yield this.apiService.downloadFile("ErpAnhang", item.code, item.originalDateiname);
            return result;
        });
    }
}

import { FormDefinition } from "./form-definition";
import { UUID } from 'angular2-uuid';
import { EditableType } from "../enums/editable-type.enum";

export class OrderDefinition {
    id: string;
    name: string
    description: string
    matIcon: string
    active: boolean
    editable: EditableType
    editableUntil: number
    formDefinition: FormDefinition
    deleted:boolean;
    reportCode: number;

    constructor(name: string, description:string = "", matIcon:string = null) {
        this.id = UUID.UUID()
        this.name = name 
        this.description = description 
        this.matIcon = matIcon ? matIcon : "local_shipping"
        this.active = true
        this.editable = EditableType.NotEditable
        this.editableUntil = 60
        this.formDefinition = new FormDefinition();
        this.deleted = false;
        this.reportCode = 0;
    }
}

import { Component, OnInit, Inject } from '@angular/core';
import { SucheDialogData } from 'src/app/classes/suche-dialog-data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SucheDialogObjectType } from 'src/app/enums/suche-dialog-object-type.enum';

@Component({
  selector: 'app-suche',
  templateUrl: './suche.component.html',
  styleUrls: ['./suche.component.scss']
})
export class SucheComponent implements OnInit {

  title:string = ""

  constructor(public dialogRef: MatDialogRef<SucheComponent>, @Inject(MAT_DIALOG_DATA) public data: SucheDialogData) { }

  ngOnInit() {
    this.setTitle()
  }

  setTitle(){
    if(this.data.title){
      this.title = this.data.title
    }
    else{
      this.title = SucheDialogObjectType[this.data.type]
    }
  }
  
  close(e = null): void {
    this.dialogRef.close(e);
  }

}


import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ShopDefinition } from '../../classes/shop-definition';
import { ShopService } from '../../services/shop.service';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  versionName:string = ""
  currentShopDefinition: ShopDefinition = null;

  constructor(protected apiService: ApiService, protected shopService: ShopService) {
  }

  ngOnInit() {
    this.shopService.CurrentShopDefinition.subscribe(data => {
      this.currentShopDefinition = data
    })
    this.versionName = this.shopService.versionName
  }

  invalidateCache() {
    let cachesToInvalidate = ["benutzer", "login", "shopdefinition", "artikelansichtsrechte"];

    cachesToInvalidate.forEach(typeName => {
      this.apiService.invalidateCache(typeName, success => {
        console.log("Cache invalidated:", typeName)
      }, error => {
        console.log("Cache invalidation failed for :", typeName)
      })
    })

  }
}

<div class="p-default border-bottom">
  <strong>BESTELLUNG GESPEICHERT</strong>
</div>
<div class="p-default border-bottom">
  Eine Kopie Ihrer Bestellung wird Ihnen per E-Mail zugesendet.<br>
  Möchten Sie diese an weitere Empfänger senden?
  <br>
  <br>
  <div class="relative">
    <button mat-raised-button color="accent" style="    height: 50px; margin-right: -7.5px;"
      [disabled]="!validateEmail(emailControl.value)" (click)="addEmail()"
      style="position: absolute;    height: 40px;    right: 0;    z-index: 100;">HINZUFÜGEN</button>

    <mat-form-field>
      <input type="text" placeholder="Weiterer Empfänger" matInput [formControl]="emailControl" [matAutocomplete]="auto"
        (optionSelected)="addEmail()" (keyup.enter)="addEmail()">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredEmailList | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>


</div>

<ng-container *ngFor="let email of chosenEmails;let emailIndex = index">
  <button class="narow-btn float-right" (click)="removeEmail(emailIndex)" mat-button style="height: 50px;">
    <div class="fas fa-times"></div>
  </button>
  <div class="p-default border-bottom">
    {{email}}
  </div>
</ng-container>
<div class="p-default">

  <button class="max-width" (click)="close()" mat-button color="accent">
    Abschließen
  </button>
</div>
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ShopDefinition } from '../../classes/shop-definition';
import { ShopService } from '../../services/shop.service';
import { ApiService } from '../../services/api.service';
import { Angebot, Auftrag, Kalkulation, Bestellung, Bedarf, Einheit } from '@work4all/api';

@Component({
  selector: 'app-artikel-dialog',
  templateUrl: './artikel-dialog.component.html',
  styleUrls: ['./artikel-dialog.component.scss']
})
export class ArtikelDialogComponent implements OnInit {

  artikelList: any[] = [];
  currentShopDefinition: ShopDefinition = null;
  artikleGroups: number[] = [];
  noRequiredGroup: boolean = false;
  requiredArtikleGroups: number[] = [];
  currentBeleg: Angebot | Auftrag | Kalkulation | Bestellung | Bedarf;
  einheiten: Einheit[];

  constructor(public dialogRef: MatDialogRef<ArtikelDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, protected shopService: ShopService, protected apiService: ApiService) {
    this.shopService.CurrentShopDefinition.subscribe(data => {
      this.currentShopDefinition = data;
    })

    this.shopService.CurrentBeleg.subscribe(data => {
      this.currentBeleg = data; 
    });
    

    this.shopService.CurrentArtikelGruppen.subscribe(data => {
      if(data){
        this.artikleGroups = data; 
      } else {
        this.artikleGroups = []
      }
    });
    
    this.shopService.CurrentPflichtartikelGruppen.subscribe(data => {
      if(data){
        this.requiredArtikleGroups = data; 
      } else {
        this.requiredArtikleGroups = []
      }
    });

    this.hasRequiredArtikleGroup();
    this.getEinheiten();
    
  }

  close(result = null): void {

    if(!result){
      this.shopService.getBackupBeleg()
    }
    else{
      this.shopService.setCurrentBeleg(this.currentBeleg)
    }

    this.dialogRef.close(result);
  }

  ngOnInit() {
  }


  async getEinheiten() {
    this.einheiten = await this.apiService.getEinheiten();
  }

  confirm() {
    this.close(this.artikelList)
  }

  addArtikel(data) {

    let found: boolean = false;
    if (data.artikel.code != 0) {
      this.artikelList.some((item, itemindex) => {
        if (item.artikel.code == data.artikel.code) {
          item.menge += data.menge
          found = true;
          return true
        }
      })
    }

    if (!found)
      this.artikelList.push(data)
      this.artikleGroups.push(data.artikel.grCode);
      this.shopService.setCurrentArtikelGruppen(this.artikleGroups);
      this.hasRequiredArtikleGroup();
  }

  removeArtikel(item) {
    let index = this.artikelList.indexOf(item);
    let groupIndex = this.artikleGroups.indexOf(item.grCode);
    this.artikleGroups.splice(groupIndex, 1);
    this.artikelList.splice(index, 1)
    this.shopService.setCurrentArtikelGruppen(this.artikleGroups);
    this.hasRequiredArtikleGroup();
  }

  hasRequiredArtikleGroup() {
    if (this.requiredArtikleGroups.every(r => this.artikleGroups.indexOf(r) > -1)) {
      this.noRequiredGroup = false;
    } else {
      this.noRequiredGroup = true;
    }
  }

  setEinheit(code) {
    if(this.einheiten.find(e => e.code == code))
      return this.einheiten.filter(element => {
        if (element.code === code && element) {
          return element
        }
      })[0].name
  }
}

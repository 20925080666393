<div class="overflow-hidden">
  <ng-container *ngIf="editFormOfOrderDefinitionIndex == null && !editFormOfProjekte">

    <div class="fade-right" *ngIf="navigation == null">
      <div class="p-default border-bottom">
        <strong>SHOP EINSTELLUNGEN</strong>
      </div>
      <div class="hover-button border-bottom p-default pointer" (click)="navigation = 0">
        Generelles
      </div>
      <div class="hover-button border-bottom p-default pointer" (click)="navigation = 1">
        <i class="fas fa-exclamation-circle red-text float-right"
          *ngIf="shopDefinition.footerLinks && shopDefinition.footerLinks.length == 0"></i>
        Branding
      </div>
      <!--
      <div class="hover-button border-bottom p-default pointer" (click)="navigation = 2">
        Startseite
      </div>
      -->
      <div class="hover-button border-bottom p-default pointer" (click)="navigation = 5">
        Artikel
      </div>
      <div class="hover-button border-bottom p-default pointer" (click)="navigation = 3">
        Projekte
      </div>
      <div class="hover-button border-bottom p-default pointer" (click)="navigation = 4">
        Bestellungen
      </div>
      <div class="hover-button  p-default pointer" (click)="navigation = 6">
        Benachrichtigungen
      </div>
    </div>

    <div *ngIf="navigation == 0">
      <div class="fade-left">
        <div class="p-default border-bottom ">
          <button class="btn-narrow " style="margin-right:15px" mat-button (click)="navigation = null">
            <i class="fas fa-arrow-left"></i>
          </button>
          <strong>GENERELLES</strong>
        </div>
        <div class="p-default clearfix border-bottom">

          <mat-form-field>
            <input matInput type="text" placeholder="Shop-Bezeichnung" [(ngModel)]="shopDefinition.name">
          </mat-form-field>
          <div class="form-field-error" *ngIf="shopDefinition.name.trim() && shopDefinition.name.trim().length == 0">
            Dies ist ein Pflichtfeld</div>

        </div>
        <div class="p-default">
          <label for="" class="small">Optionen</label><br>
          <div class="inline-block">
            <mat-checkbox [(ngModel)]="shopDefinition.displayPreise">Preise anzeigen</mat-checkbox>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div class="inline-block">
            <mat-checkbox [(ngModel)]="shopDefinition.displayBestaende">Bestände anzeigen</mat-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="fade-left" *ngIf="navigation == 1" style="width:800px;max-width:100%">
      <div class="p-default border-bottom">
        <button class="btn-narrow " style="margin-right:15px" mat-button (click)="navigation = null">
          <i class="fas fa-arrow-left"></i>
        </button>
        <strong>Branding</strong>
      </div>

      <div class="p-default clearfix border-bottom">

        <strong>Farben</strong>
        <div class="row p-top-default">
          <div class="col-md-3">
            <mcc-color-picker [hideUsedColors]="colorPickerOptions.hideUsedColors"
              [btnCancel]="colorPickerOptions.btnCancel" [btnConfirm]="colorPickerOptions.btnConfirm"
              [usedColorLabel]="colorPickerOptions.usedColorLabel" [selectedColor]="shopDefinition.primaryColor"
              (selected)="setShopDefinitionPrimaryColor($event)"></mcc-color-picker>
            &nbsp;&nbsp;&nbsp;Primärfarbe
          </div>
          <div class="col-md-3">
            <mcc-color-picker [hideUsedColors]="colorPickerOptions.hideUsedColors"
              [btnCancel]="colorPickerOptions.btnCancel" [btnConfirm]="colorPickerOptions.btnConfirm"
              [usedColorLabel]="colorPickerOptions.usedColorLabel" [selectedColor]="shopDefinition.secondaryColor"
              (selected)="setShopDefinitionSecondaryColor($event)"></mcc-color-picker>
            &nbsp;&nbsp;&nbsp;Sekundärfarbe
          </div>

        </div>
      </div>
      <div class="p-default clearfix border-bottom">
        <strong>Hintergrundbild</strong>
        <div class="row p-top-default">
          <div class="col-md-7">
            <mat-form-field>
              <input matInput type="text" placeholder="Bildadresse" [(ngModel)]="shopDefinition.backgroundImagePath">
            </mat-form-field>
          </div>
          <div class="col-md-5">
            <ng-container *ngIf="shopDefinition.backgroundImagePath && shopDefinition.backgroundImagePath.length > 0">
              <label for="" class="small">Vorschau:</label>
              <div class="inline-block"
                style="max-width: 100%;background:whitesmoke;border:1px dashed silver;padding: 3px;">
                <img [src]="shopDefinition.backgroundImagePath" style="max-height:40px;" alt="">
              </div>

            </ng-container>
          </div>
        </div>
      </div>
      <div class="p-default clearfix border-bottom">
        <strong>Logo</strong>
        <div class="row p-top-default">
          <div class="col-md-10">
            <mat-form-field>
              <input matInput type="text" placeholder="Bildadresse" [(ngModel)]="shopDefinition.logoImagePath1">
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field>
              <input matInput class="align-right" type="number" placeholder="Höhe"
                [(ngModel)]="shopDefinition.logo1Height">
              <span matSuffix>px</span>
            </mat-form-field>
          </div>
          <div class="clearfix"></div>

          <div class="col-md-12 align-center">


            <div class="inline-block">
              <table cellpadding="0" cellspacing="0" border="0">
                <tr>
                  <td></td>
                  <td align="center" valign="middle">
                    <mat-form-field style="width:100px;margin-bottom: -19px;">
                      <input matInput class="align-right" type="number" placeholder="Oben"
                        [(ngModel)]="shopDefinition.logo1MarginTop">
                      <span matSuffix>px</span>
                    </mat-form-field>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td align="right" valign="middle">
                    <mat-form-field style="width:100px;margin-bottom: -19px;">
                      <input matInput class="align-right" type="number" placeholder="Links"
                        [(ngModel)]="shopDefinition.logo1MarginLeft">
                      <span matSuffix>px</span>
                    </mat-form-field>
                  </td>
                  <td align="center" valign="middle" class="p-default">
                    <div class="inline-block"
                      style="max-width: 100%;background:whitesmoke;border:1px dashed silver;padding: 3px;">
                      <img [src]="shopDefinition.logoImagePath1" alt="" [style.height.px]="shopDefinition.logo1Height">
                    </div>
                  </td>
                  <td align="left" valign="middle">
                    <mat-form-field style="width:100px;margin-bottom: -19px;">
                      <input matInput class="align-right" type="number" placeholder="Rechts"
                        [(ngModel)]="shopDefinition.logo1MarginRight">
                      <span matSuffix>px</span>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td align="center" valign="middle">
                    <mat-form-field style="width:100px;margin-bottom: -19px;">
                      <input matInput class="align-right" type="number" placeholder="Unten"
                        [(ngModel)]="shopDefinition.logo1MarginBottom">
                      <span matSuffix>px</span>
                    </mat-form-field>
                  </td>
                  <td></td>
                </tr>
              </table>
            </div>

          </div>
        </div>
      </div>

      <div class="p-default ">

        <strong>Footer-Links</strong>
        <div class="clearfix p-y-default">Diese Links werden im Footer-Bereich des Shops angezeigt.</div>
        <mat-card style="background: whitesmoke;" *ngIf="shopDefinition.footerLinks">
          <ng-container *ngFor="let link of shopDefinition.footerLinks;let linkIndex = index">
            <div class="p-default border-bottom" style="padding-bottom: 0">
              <div class="row">
                <div class="col-xs-4">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Text" [(ngModel)]="link.text">
                  </mat-form-field>
                </div>
                <div class="col-xs-4">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Adresse" [(ngModel)]="link.href">
                  </mat-form-field>
                </div>
                <div class="col-xs-3">
                  <mat-form-field>
                    <mat-select [(ngModel)]="link.target" placeholder="Ziel">
                      <mat-option [value]="'_self'">
                        Im selben Fenster
                      </mat-option>
                      <mat-option [value]="'_blank'">
                        In neuem Fenster
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-xs-1">
                  <button class="float-right narrow-btn" mat-button (click)="removeFooterLink(linkIndex)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>

        </mat-card>
        <div class="p-top-default"></div>
        <button class=" " mat-button (click)="addFooterLink()" style="height: 51px;">
          <i class="fas fa-plus"></i> Footer-Link hinzufügen
        </button>
      </div>
      <div class="p-x-default" *ngIf="shopDefinition.footerLinks && shopDefinition.footerLinks.length == 0">
        <div class="alert alert-danger">
          <strong>
            <i class="fas fa-exclamation-circle"></i> Achtung
          </strong>
          <div>
            Wir empfehlen, Links zu Ihrem Impressum und Datenschutzbestimmungen zu hinterlegen.
          </div>
        </div>
      </div>

    </div>


    <div class="fade-left" *ngIf="navigation == 2" style="width:300px;max-width:100%">
      <div class="p-default border-bottom">
        <button class="btn-narrow " style="margin-right:15px" mat-button (click)="navigation = null">
          <i class="fas fa-arrow-left"></i>
        </button>
        <strong>STARTSEITE</strong>
      </div>
      <div class="clearfix"></div>

      <div class="border-bottom p-default">
        Definieren Sie die Startseite des Shops:<br>
        <br>
        <mat-form-field>
          <mat-select [(ngModel)]="shopDefinition.startseite">
            <mat-option [value]="PageType.StartSeite">
              Standard Startseite
            </mat-option>
            <mat-option [value]="PageType.ArtikelSeite" [disabled]="!shopDefinition.displayArtikel">
              {{shopDefinition.artikelNavigationTitle}}
            </mat-option>
            <mat-option [value]="PageType.BestellungenSeite" [disabled]="!shopDefinition.displayBestellungen">
              {{shopDefinition.bestellungenNavigationTitle}}
            </mat-option>
            <mat-option [value]="PageType.ProjektSeite" [disabled]="!shopDefinition.displayProjekte">
              {{shopDefinition.projekteNavigationTitle}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div *ngIf="navigation == 5">
      <div class="fade-left">
        <div class="p-default border-bottom ">
          <button class="btn-narrow " style="margin-right:15px" mat-button (click)="navigation = null">
            <i class="fas fa-arrow-left"></i>
          </button>
          <strong>ARTIKEL</strong>
        </div>
        <div class="p-default clearfix border-bottom">

          <mat-checkbox [(ngModel)]="shopDefinition.displayArtikel">Artikelseite anzeigen</mat-checkbox>

        </div>
        <div class="p-default border-bottom">
          <label for="" class="small">Bezeichnung im Shop</label>
          <div class="row">
            <div class="col-sm-6">
              <mat-form-field class="max-width">
                <input type="text" id="reportsInput" placeholder="Singular" matInput
                  [(ngModel)]="shopDefinition.artikelNavigationTitleSingular">
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field class="max-width">
                <input type="text" id="reportsInput" placeholder="Plural" matInput
                  [(ngModel)]="shopDefinition.artikelNavigationTitle">
              </mat-form-field>
            </div>
          </div>


        </div>
      </div>
    </div>

    <div class="fade-left" *ngIf="navigation == 3">
      <div class="p-default border-bottom">
        <button class="btn-narrow " style="margin-right:15px" mat-button (click)="navigation = null">
          <i class="fas fa-arrow-left"></i>
        </button>
        <strong class=""> PROJEKTE</strong>
      </div>
      <div class="clearfix"></div>
      <div class="p-default border-bottom">
        <label for="" class="small">Allgemein</label><br>
        <mat-checkbox [(ngModel)]="shopDefinition.displayProjekte">Projektseite anzeigen</mat-checkbox>
      </div>

      <div class="p-default border-bottom">
        <label for="" class="small">Bezeichnung im Shop</label>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field class="max-width">
              <input type="text" id="reportsInput" placeholder="Singular" matInput
                [(ngModel)]="shopDefinition.projekteNavigationTitleSingular">
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="max-width">
              <input type="text" id="reportsInput" placeholder="Plural" matInput
                [(ngModel)]="shopDefinition.projekteNavigationTitle">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="p-default border-bottom">
        <label for="" class="small">Felder und Bearbeitungsrechte für Shop-User</label><br>
        <div class="row">
          <div class="col-sm-6">
            <div style="padding-top:5px">
              <mat-checkbox [(ngModel)]="shopDefinition.projekteInsertable"
                [disabled]="!shopDefinition.displayProjekte">Anlegen</mat-checkbox>&nbsp;&nbsp;
              <mat-checkbox [(ngModel)]="shopDefinition.projekteEditable" [disabled]="!shopDefinition.displayProjekte">
                Bearbeiten</mat-checkbox>&nbsp;&nbsp;
              <!-- <mat-checkbox [(ngModel)]="shopDefinition.projekteDeletable" [disabled]="!shopDefinition.displayProjekte">Löschen</mat-checkbox> -->
            </div>
          </div>
          <div class="col-sm-6">
            <mat-form-field>
              <mat-select placeholder="Standard-Projektgruppe" [(ngModel)]="shopDefinition.standardProjektGruppeCode">
                <mat-option *ngFor="let projektGruppe of projektGruppeList" [value]="projektGruppe['code']">
                  {{projektGruppe['name']}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!--<div class="col-sm-6">
            <button class="" mat-raised-button (click)="editFormOfProjekte = true" color="primary">
              <i class="fab fa-wpforms"></i>
              Formular bearbeiten
            </button>
          </div>-->
        </div>
        
      </div>
      <div class="p-default">
        <mat-menu #infofensterMenu="matMenu" [overlapTrigger]="false">
          <ng-container *ngFor="let infofenster of infofensterList;let infofensterIndex = index">
            <button mat-menu-item
              *ngIf="infofenster.sdObjType == ObjectType.Projekt && infofenster.refObject == 'Dokumente'"
              (click)="addInfofensterCode(infofenster.code)"
              [disabled]="infofensterChosen(infofenster.code)">{{infofenster.titel}}</button>
          </ng-container>
        </mat-menu>
        <div><strong>Infofenster-Freigaben</strong></div>
        <div class="p-y-default">Dateien aus freigegebenen Dokumenten-Infofenstern auf Projekt-Ebene werden Shop-Usern
          angezeigt.</div>
        <div class="clearfix"></div>
        <mat-card>
          <ng-container
            *ngFor="let infofensterCode of shopDefinition.displayInfofensterCodes;let infofensterIndex = index">
            <button mat-button (click)="removeInfofensterCode(infofensterIndex)" style="height: 51px;"
              class="float-right narrow-btn">
              <i class="fas fa-trash"></i> </button>
            <div class="p-default border-bottom " style="background: whitesmoke;">
              {{getInfofensterByCode(infofensterCode)?.titel}}
            </div>
          </ng-container>
        </mat-card>
        <div class="p-top-default"></div>
        <button mat-button [matMenuTriggerFor]="infofensterMenu" class="" style="height: 51px;"><i
            class="fas fa-plus"></i>
          Infofenster freigeben</button>
      </div>


    </div>

    <div class="fade-left" *ngIf="navigation == 4">
      <div class="p-default border-bottom">
        <button class="btn-narrow " style="margin-right:15px" mat-button (click)="navigation = null">
          <i class="fas fa-arrow-left"></i>
        </button>
        <strong>BESTELLUNGEN</strong>
      </div>
      <div class="clearfix"></div>

      <div class="p-default border-bottom">

        <label for="" class="small">Bezeichnung im Shop</label>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field class="max-width">
              <input type="text" id="reportsInput" placeholder="Singular" matInput
                [(ngModel)]="shopDefinition.bestellungenNavigationTitleSingular">
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="max-width">
              <input type="text" id="reportsInput" placeholder="Plural" matInput
                [(ngModel)]="shopDefinition.bestellungenNavigationTitle">
            </mat-form-field>
          </div>
        </div>


      </div>

<!--
      <div class="p-default border-bottom">

        <label for="" class="small">User-Rechte</label><br>
        <div class="row">
          <div class="col-sm-6">
            <mat-checkbox [(ngModel)]="shopDefinition.bestellungenInsertable">Bestellungen anlegen</mat-checkbox>
          </div>
          <div class="col-sm-6">
            <mat-checkbox [(ngModel)]="shopDefinition.bestellungenPreiseEditable">Positions-Preise bearbeiten
            </mat-checkbox>
          </div>
          <div class="col-sm-6">
            <mat-checkbox [(ngModel)]="shopDefinition.bestellungenMengeEditable">Positions-Menge bearbeiten
            </mat-checkbox>
          </div>
          <div class="col-sm-6">
            <mat-checkbox [(ngModel)]="shopDefinition.bestellungenTexteEditable">Positions-Texte bearbeiten
            </mat-checkbox>
          </div>
        </div>



      </div>-->
      <div class="p-default" *ngIf="!shopDefinition.orderDefinitions || shopDefinition.orderDefinitions.length == 0">
        <button mat-button class="float-left " (click)="addOrderDefinition()">
          <i class="fas fa-plus"></i>
          Bestellmodus hinzufügen
        </button>
      </div>
      <ng-container *ngIf="shopDefinition.orderDefinitions && shopDefinition.orderDefinitions.length > 0">
        <ng-container *ngFor="let orderDefinition of shopDefinition.orderDefinitions; let orderDefinitionIndex = index">

          <ng-container *ngIf="!orderDefinition.deleted">
            <ng-container>
              <div class="p-default border-bottom">
                <label for="" class="small">work4all Einstellungen</label><br>
                <div class="row">
                  <div class="col-md-6 ">
                    <mat-form-field>
                      <mat-select placeholder="ERP-Objekt" [(ngModel)]="orderDefinition.formDefinition.bzObjType"
                        [disabled]="!orderDefinition.active">
                        <mat-optgroup label="Extern">
                          <mat-option [value]="ObjectType.Angebot">
                            Angebot
                          </mat-option>
                          <mat-option [value]="ObjectType.Auftrag">
                            Auftrag
                          </mat-option>
                          <mat-option [value]="ObjectType.Kalkulation">
                            Kalkulation
                          </mat-option>
                        </mat-optgroup>
                        <mat-optgroup label="Intern">
                          <mat-option [value]="ObjectType.Bedarfsanforderung">
                            Bedarf
                          </mat-option>
                          <mat-option [value]="ObjectType.Bestellung">
                            Bestellung
                          </mat-option>
                        </mat-optgroup>
                      </mat-select>
                      <mat-hint>
                        Resultierendes Objekt in work4all
                      </mat-hint>
                    </mat-form-field>



                  </div>
                  <div class="col-md-6 ">

                    <mat-form-field class="no-bottom max-width">
                      <input type="text" id="reportsInput" (input)="getReportsBySuchbegriff()" placeholder="Report"
                        [formControl]="reportsFormControl" matInput [matAutocomplete]="reportsAutocomplete">
                      <mat-autocomplete (optionSelected)="setReportCode($event, orderDefinitionIndex)"
                        #reportsAutocomplete="matAutocomplete" [displayWith]="displayFnReports">
                        <ng-container *ngFor="let option of filteredReportsOptions | async">
                          <mat-option [value]="option">
                            {{ option.notiz }}
                          </mat-option>
                        </ng-container>

                      </mat-autocomplete>
                      <mat-hint>
                        Zum Download für den Kunden
                      </mat-hint>
                    </mat-form-field>
                  </div>

                </div>
              </div>

              <div class="p-default border-bottom">
                <label for="" class="small">Formular Einstellungen "Sonstige Angaben"</label><br>
                <button class=" " mat-raised-button  color="primary"
                  (click)="editFormOfOrderDefinitionIndex = orderDefinitionIndex">
                  <i class="fab fa-wpforms"></i>
                  Formular bearbeiten
                </button>
              </div>

              <div class="p-default">

                <label class="small">NACHTRÄGLICHE BEARBEITUNG DER BESTELLUNG</label><br>
                <mat-radio-group class="" [(ngModel)]="orderDefinition.editable">
                  <mat-radio-button class="" [value]="EditableType.NotEditable">
                    Nicht erlaubt
                  </mat-radio-button><br>
                  <!--<mat-radio-button class="" [value]="EditableType.EditableUntilSeized">
                    Erlaubt solange externe Bearbeitung aktiviert
                  </mat-radio-button><br>-->
                  <mat-radio-button class="" [value]="EditableType.EditableUntilProjektStart"
                    [disabled]="!shopDefinition.displayProjekte">
                    Erlaubt bis <input type="number" [(ngModel)]="orderDefinition.editableUntil"
                      style="width:40px;text-align:right">
                    Tage vor Veranstaltungsbeginn
                  </mat-radio-button>
                </mat-radio-group>
              </div>

            </ng-container>

          </ng-container>


        </ng-container>
      </ng-container>
    </div>

    <div class="fade-left" *ngIf="navigation == 6">
      <div class="p-default " style="    width: 800px;">
        <button class="btn-narrow " style="margin-right:15px" mat-button (click)="navigation = null">
          <i class="fas fa-arrow-left"></i>
        </button>
        <strong>BENACHRICHTIGUNGEN</strong>
      </div>
      <div class="clearfix"></div>
      <div class=" border-top no-padding col-sm-4">
        <div class="p-default">
          <label for="" class="small">Kunde benachrichtigen bei </label><br>
          <mat-checkbox [(ngModel)]="shopDefinition.notificationNew">Neuer Bestellung</mat-checkbox><br>
          <mat-checkbox [(ngModel)]="shopDefinition.notificationEdit">Editierter Bestellung</mat-checkbox>
          <br>
          <br>
          <label for="" class="small">Optionen </label><br>
          <mat-checkbox [(ngModel)]="shopDefinition.displayPreiseInEMail">Preise in E-Mail anzeigen</mat-checkbox>
        </div>


      </div>
      <div class="no-padding border-left col-sm-8">
        <div class="p-default border-top" style="padding-bottom:0">

          <label for="" class="small">Absender</label>
          <div class="row">
            <!--<div class="col-sm-6">
              <mat-form-field class=" max-width">
                <input type="text" placeholder="Name" [(ngModel)]="shopDefinition.notificationSenderName" matInput>
              </mat-form-field>
            </div>-->
            <div class="col-sm-12">
              <mat-form-field class=" max-width">
                <input type="text" placeholder="E-Mail" [(ngModel)]="shopDefinition.notificationSenderEmail" matInput>
              </mat-form-field>
            </div>
          </div>

        </div>
        <div class="p-default border-top">

          <label for="" class="small">Zusätzliche Empfänger</label><br>
          <mat-form-field class=" max-width">
            <input type="text" placeholder="Adressen" [(ngModel)]="shopDefinition.notificationRecipientEmails" matInput>
            <mat-hint>Geben Sie alle zusätzlichen Empfänger kommagetrennt an</mat-hint>
          </mat-form-field>

        </div>
        <div class="p-default border-top" style="padding-bottom: 0">

          <label for="" class="small">Betreff</label><br>
          <mat-form-field class=" max-width">
            <input type="text" [(ngModel)]="shopDefinition.notificationSubject" matInput>
          </mat-form-field>
          <!--<mat-form-field class=" max-width">
            <textarea type="text" placeholder="Nachricht" [(ngModel)]="shopDefinition.notificationStartText" matInput
              style="height:150px"></textarea>
          </mat-form-field>
          <div class="p-bottom-default">
            <mat-checkbox [(ngModel)]="shopDefinition.notificationAddPositions">Positionen in der Nachricht aufführen</mat-checkbox>
          </div>

          <mat-form-field class=" max-width">
            <textarea type="text" placeholder="Schlusstext" [(ngModel)]="shopDefinition.notificationEndText" matInput
              style="height:150px"></textarea>
          </mat-form-field>-->


          <mat-menu #textmarkenMenu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="ansprechpartnerTextmarkenMenu">Ansprechpartner</button>
            <button mat-menu-item [matMenuTriggerFor]="bestellungTextmarkenMenu">Bestellung</button>
            <button mat-menu-item [matMenuTriggerFor]="projektTextmarkenMenu">Projekt</button>
          </mat-menu>

          <mat-menu #ansprechpartnerTextmarkenMenu="matMenu">
            <button mat-menu-item *ngFor="let item of ansprechpartnerTextmarken"
              (click)="addTextmarke('ansprechpartner.'+item.value)">{{item.key}}</button>
          </mat-menu>

          <mat-menu #bestellungTextmarkenMenu="matMenu">
            <button mat-menu-item *ngFor="let item of bestellungTextmarken"
              (click)="addTextmarke('bestellung.'+item.value)">{{item.key}}</button>
            <button mat-menu-item [matMenuTriggerFor]="sonstigeAngabenTextmarkenMenu">Sonstige Angaben</button>
          </mat-menu>

          <mat-menu #projektTextmarkenMenu="matMenu">
            <button mat-menu-item *ngFor="let item of projektTextmarken"
              (click)="addTextmarke('projekt.'+item.value)">{{item.key}}</button>
          </mat-menu>

          <mat-menu #sonstigeAngabenTextmarkenMenu="matMenu">
            <button mat-menu-item *ngFor="let item of sonstigeAngabenTextmarken"
              (click)="addTextmarke('bestellung.sonstigeAngaben.'+item.value)">{{item.key}}</button>
          </mat-menu>


          <div class="pull-right">


          <!--<label for="" class="small pointer " (click)="editorView = editorView == 'Markdown' ? 'html' : 'Markdown'" >Ansicht: {{editorView}}</label>-->
          <label for="" class="small pointer " [matMenuTriggerFor]="textmarkenMenu">Textmarken</label>
          </div>
          <label for="" class="small">Nachricht</label><br>
          <!--<div id="editor" [froalaEditor]="froalaOptions" [(froalaModel)]="shopDefinition.notificationStartText" style="margin:0 -16px" ></div>-->
          <dx-html-editor [valueType]="editorView" 
            [(ngModel)]="shopDefinition.notificationStartText">
            <dxo-toolbar>
              <dxi-item formatName="undo"></dxi-item>
              <dxi-item formatName="redo"></dxi-item>
              <dxi-item formatName="separator"></dxi-item>
              <dxi-item formatName="size" [formatValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']">
              </dxi-item>
              <dxi-item formatName="font"
                [formatValues]="['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']">
              </dxi-item>
              <dxi-item formatName="separator"></dxi-item>
              <dxi-item formatName="bold"></dxi-item>
              <dxi-item formatName="italic"></dxi-item>
              <dxi-item formatName="strike"></dxi-item>
              <dxi-item formatName="underline"></dxi-item>
              <dxi-item formatName="separator"></dxi-item>
              <dxi-item formatName="alignLeft"></dxi-item>
              <dxi-item formatName="alignCenter"></dxi-item>
              <dxi-item formatName="alignRight"></dxi-item>
              <dxi-item formatName="alignJustify"></dxi-item>
              <dxi-item formatName="separator"></dxi-item>
              <dxi-item formatName="color"></dxi-item>
              <dxi-item formatName="background"></dxi-item>
              <dxi-item formatName="separator"></dxi-item>
              <dxi-item formatName="link"></dxi-item>
              <dxi-item formatName="image"></dxi-item>
              <dxi-item formatName="separator"></dxi-item>
              <dxi-item formatName="clear"></dxi-item>
              <dxi-item formatName="codeBlock"></dxi-item>
              <dxi-item formatName="blockquote"></dxi-item>
            </dxo-toolbar>
          </dx-html-editor>

        </div>
      </div>
      <div class="clearfix"></div>
    </div>

    <hr class="no-margin">

    <div class="p-default overflow-hidden align-right">
      <button mat-button (click)="close()">ABBRECHEN</button>
      <button mat-button class="" (click)="save()"
        [disabled]="shopDefinition.name.trim() && shopDefinition.name.trim().length == 0 ">SPEICHERN</button>
    </div>
    <!--<div class="p-default border-top align-center">
      <small>
        <img src="assets/img/w4a-bubble.png" style="height:16px;margin-right:5px" alt=""> work4all Shop {{versionName}}
      </small>
    </div>-->

  </ng-container>

  <div *ngIf="editFormOfOrderDefinitionIndex != null" style="width:1570px;max-width:100%">
    <div class="p-default">
      <button class="btn-narrow float-left" style="margin-right:15px" mat-button
        (click)="editFormOfOrderDefinitionIndex = null">
        <i class="fas fa-arrow-left"></i>
      </button>
      <div class="inline-block">
        <strong>BESTELLUNG FORMULAR BEARBEITEN
        </strong><br>
        <label for="" class="small">{{shopDefinition.orderDefinitions[0]?.formDefinition?.components?.length}}
          <ng-container
            *ngIf="shopDefinition.orderDefinitions[0].formDefinition.components && shopDefinition.orderDefinitions[0].formDefinition.components.length == 1">
            Feld</ng-container>
          <ng-container
            *ngIf="shopDefinition.orderDefinitions[0].formDefinition.components && shopDefinition.orderDefinitions[0].formDefinition.components.length != 1">
            Felder</ng-container>
          definiert
        </label>
      </div>

    </div>
    <div class="p-default"
      *ngIf="shopDefinition.orderDefinitions[0].formDefinition.components && shopDefinition.orderDefinitions[0].formDefinition.components.length == 0">
    </div>
    <app-form-generator [formDefinition]="shopDefinition.orderDefinitions[0].formDefinition"
      (formDefinitionUpdated)="setBestellungFormDefinition($event)" [editMode]="true"></app-form-generator>

  </div>

  <div *ngIf="editFormOfProjekte" style="width:1570px;max-width:100%">
    <div class="p-default">
      <button class="btn-narrow float-left" style="margin-right:15px" mat-button (click)="editFormOfProjekte = false;">
        <i class="fas fa-arrow-left"></i>
      </button>
      <div class="inline-block">
        <strong>PROJEKT FORMULAR BEARBEITEN
        </strong><br>
        <label for="" class="small"
          *ngIf="shopDefinition.projekteFormDefinition">{{shopDefinition?.projekteFormDefinition?.components?.length}}
          <ng-container
            *ngIf="shopDefinition.projekteFormDefinition.components && shopDefinition.projekteFormDefinition.components.length == 1">
            Feld</ng-container>
          <ng-container
            *ngIf="shopDefinition.projekteFormDefinition.components && shopDefinition.projekteFormDefinition.components.length != 1">
            Felder</ng-container>
          definiert
        </label>
      </div>
    </div>

    <app-form-generator *ngIf="shopDefinition.projekteFormDefinition"
      [formDefinition]="shopDefinition.projekteFormDefinition" individualFieldType="Projekte"
      (formDefinitionUpdated)="setProjektFormDefinition($event)" [editMode]="true"></app-form-generator>

  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ShopService } from '../../services/shop.service';
import { ShopDefinition } from '../../classes/shop-definition';

@Component({
  selector: 'app-artikel-page',
  templateUrl: './artikel-page.component.html',
  styleUrls: ['./artikel-page.component.scss']
})
export class ArtikelPageComponent implements OnInit {
  currentShopDefinition:ShopDefinition
  constructor(protected router:Router, protected shopService:ShopService, protected route:ActivatedRoute) { }

  ngOnInit() {
    console.log("init", this)
    console.log("artikel route", this.route)
    this.shopService.CurrentShopDefinition.subscribe(data=>{
      this.currentShopDefinition = data
      if(this.currentShopDefinition != undefined && !this.currentShopDefinition.displayArtikel && this.route.url["value"][0].path == "artikel"){
        this.currentShopDefinition 
        this.router.navigate(["/"])
      }
    })
  }

  

}

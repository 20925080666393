import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, ArtikelbildPipe, Seriennummer, Eingangslieferschein, Lieferschein, Config, ApiFilter, ApiSearchInfo, ApiSearchRequest, ApiSearchField, ApiFilterOperator, ApiSortOrderStyle, ObjectType, Kunde, Projekt, Lieferant, Artikel, Position, Buchung, Lagerort, Auftrag, Kalkulation, Bestellung, Bedarf, Angebot, SdObjType, ArtikelStandardBildPipe, SendMailRequest, CreateReportRequest, ReportPrintOptions, Report, FreigabeStatus, Dokument, Einkaufspreis, Infofenster, InfofensterDataItem, Anhaenge, Einheit, ProjektGruppe, Ansprechpartner } from '@work4all/api';
//import { ArtikelbildPipe } from '../pipes/artikelbild.pipe';
import { BehaviorSubject } from 'rxjs';
import { ShopDefinition } from '../classes/shop-definition';
import * as moment from 'moment';
import { ExternalLogin } from '../classes/external-login';
import { List } from 'linqts';
import { ExternalLoginRechte } from '../classes/external-login-rechte';
import { ManagedExternalLogin } from '../classes/managed-external-login';
import { ProjektVerteiler } from '../classes/projekt-verteiler';
import { ProjektVerteilerEintrag } from '../classes/projekt-verteiler-eintrag';
import { ProjektKategorieZuordnung } from '../classes/projekt-kategorie-zuordnung';
import { ProjektKategorie } from '../classes/projekt-kategorie';

@Injectable()
export class ApiService {

  private lieferscheinList = new BehaviorSubject<Lieferschein[]>(null);
  public LieferscheinList = this.lieferscheinList.asObservable();

  private eingangslieferscheinList = new BehaviorSubject<Eingangslieferschein[]>(null);
  public EingangslieferscheinList = this.eingangslieferscheinList.asObservable();

  private loginMode = new BehaviorSubject<number>(0);
  public LoginMode = this.loginMode.asObservable();

  private loginLevel = new BehaviorSubject<number>(0);
  public LoginLevel = this.loginLevel.asObservable();

  private shopMaster = new BehaviorSubject<boolean>(false);
  public ShopMaster = this.shopMaster.asObservable();

  public shopRechte: ExternalLoginRechte = new ExternalLoginRechte()
  public loginId: string = null

  constructor(protected http: HttpClient, protected route: ActivatedRoute, protected authService: AuthService, protected router: Router, protected config: Config) {
    this.setLoginLevel()
  }

  setLoginLevel() {
    this.loginLevel.next(JSON.parse(localStorage.getItem("loginLevel")))
    this.shopMaster.next(JSON.parse(localStorage.getItem("shopMaster")))
  }

  setLoginMode(value: number) {
    this.loginMode.next(value)
  }

  public getObjectTypeString(objType: ObjectType, plural: boolean = false): string {
    let result: string = "";

    if (!plural)
      switch (objType) {
        case ObjectType.Angebot: result = "Angebot"; break;
        case ObjectType.Aufgabe: result = "Aufgabe"; break;
        case ObjectType.Auftrag: result = "Auftrag"; break;
        case ObjectType.Bedarfsanforderung: result = "Bedarfsanforderung"; break;
        case ObjectType.Bestellung: result = "Bestellung"; break;
        case ObjectType.Besuchsbericht: result = "Besuchsbericht"; break;
        case ObjectType.Brief: result = "Brief"; break;
        case ObjectType.Checklisten: result = "Checkliste"; break;
        case ObjectType.Dokument: result = "Dokument"; break;
        case ObjectType.Eingangslieferschein: result = "Eingangslieferschein"; break;
        case ObjectType.EMail: result = "E-Mail"; break;
        case ObjectType.Gutschriften: result = "Gutschrift"; break;
        case ObjectType.Kalkulation: result = "Kalkulation"; break;
        case ObjectType.Kassenbeleg: result = "Kassenbeleg"; break;
        case ObjectType.Kunde: result = "Kunde"; break;
        case ObjectType.Lieferant: result = "Lieferant"; break;
        case ObjectType.LieferantenBewertung: result = "Lieferantenbewertung"; break;
        case ObjectType.Lieferschein: result = "Lieferschein"; break;
        case ObjectType.Notiz: result = "Notiz"; break;
        case ObjectType.ObjectLock: result = "ObjectLock"; break;
        case ObjectType.Produktionsauftrag: result = "Produktionsauftrag"; break;
        case ObjectType.ProduktionsSchritt: result = "Produktionsschritt"; break;
        case ObjectType.ProformaKostenbeleg: result = "Proforma-Kostenbeleg"; break;
        case ObjectType.Projekt: result = "Projekt"; break;
        case ObjectType.Rechnung: result = "Rechnung"; break;
        case ObjectType.Rechnungseingaenge: result = "Rechnungseingang"; break;
        case ObjectType.Reisekostenbeleg: result = "Reisekostenbeleg"; break;
        case ObjectType.Telefonat: result = "Telefonat"; break;
        case ObjectType.Termin: result = "Termin"; break;
        case ObjectType.Ticket: result = "Ticket"; break;
        case ObjectType.Verkaufschancen: result = "Verkaufschance"; break;
        case ObjectType.Wartungsobjekt: result = "Wartungsobjekt"; break;
        default: break;
      }

    if (plural)
      switch (objType) {

        case ObjectType.Angebot: result = "Angebote"; break;
        case ObjectType.Aufgabe: result = "Aufgaben"; break;
        case ObjectType.Auftrag: result = "Aufträge"; break;
        case ObjectType.Bedarfsanforderung: result = "Bedarfsanforderungen"; break;
        case ObjectType.Bestellung: result = "Bestellungen"; break;
        case ObjectType.Besuchsbericht: result = "Besuchsberichte"; break;
        case ObjectType.Brief: result = "Briefe"; break;
        case ObjectType.Checklisten: result = "Checklisten"; break;
        case ObjectType.Dokument: result = "Dokumente"; break;
        case ObjectType.Eingangslieferschein: result = "Eingangslieferscheine"; break;
        case ObjectType.EMail: result = "E-Mails"; break;
        case ObjectType.Gutschriften: result = "Gutschriften"; break;
        case ObjectType.Kalkulation: result = "Kalkulationen"; break;
        case ObjectType.Kassenbeleg: result = "Kassenbelege"; break;
        case ObjectType.Kunde: result = "Kunden"; break;
        case ObjectType.Lieferant: result = "Lieferanten"; break;
        case ObjectType.LieferantenBewertung: result = "Lieferantenbewertungen"; break;
        case ObjectType.Lieferschein: result = "Lieferscheine"; break;
        case ObjectType.Notiz: result = "Notizen"; break;
        case ObjectType.ObjectLock: result = "ObjectLocks"; break;
        case ObjectType.Produktionsauftrag: result = "Produktionsaufträge"; break;
        case ObjectType.ProduktionsSchritt: result = "Produktionsschritte"; break;
        case ObjectType.ProformaKostenbeleg: result = "Proforma-Kostenbelege"; break;
        case ObjectType.Projekt: result = "Projekte"; break;
        case ObjectType.Rechnung: result = "Rechnungen"; break;
        case ObjectType.Rechnungseingaenge: result = "Rechnungseingänge"; break;
        case ObjectType.Reisekostenbeleg: result = "Reisekostenbelege"; break;
        case ObjectType.Telefonat: result = "Telefonate"; break;
        case ObjectType.Termin: result = "Termine"; break;
        case ObjectType.Ticket: result = "Tickets"; break;
        case ObjectType.Verkaufschancen: result = "Verkaufschancen"; break;
        case ObjectType.Wartungsobjekt: result = "Wartungsobjekte"; break;
        default: break;
      }

    return result;
  }


  getVerfuegbarkeiten(artikelCodes: number[], dateStart: Date, dateEnd: Date, objectType: ObjectType, success: (result: any) => any, error: (result: any) => any) {

    let url: string = `/erp/dispositiontimeline/minimal?auftragsArten=${objectType}`

    artikelCodes.forEach((artikelcode) => {
      url += `&artikelcodes=${artikelcode}`
    })

    if (dateStart == dateEnd) {
      dateEnd = moment(dateEnd).set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).add("days", 1).toDate()
    }

    url += `&von=${moment(dateStart).toISOString()}`
    url += `&bis=${moment(dateEnd).toISOString()}`

    this.http.get(url, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
      let result: any[] = [];
      if (data && $.isArray(data)) {
        data.forEach(element => {
          result.push(element);
        });
      }
      else {
        result = []
      }
      success(data)

    }).catch((data) => {
      console.error("Error @ getVerfuegbarkeiten", data);
      error(data)
    });
  }


  async getVerfuegbarkeitenAsync(artikelCodes: number[], dateStart: Date, dateEnd: Date, objectType: ObjectType) {

    if (artikelCodes.length == 0)
      return []

    let url: string = `/erp/dispositiontimeline/minimal?auftragsArten=${objectType}`

    artikelCodes.forEach((artikelcode) => {
      url += `&artikelcodes=${artikelcode}`
    })

    url += `&von=${moment(dateStart).toISOString()}`
    url += `&bis=${moment(dateEnd).toISOString()}`

    return await this.http.get(url, { headers: { 'loadComplexity': '3' } }).toPromise()
  }



  getIndividuelleFelder(type, success: (result: any) => any, error: (result: any) => any) {
    this.http.get('/IndividuelleFelder?tableType=' + type, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
      let result: any[] = [];
      if (data && $.isArray(data)) {
        data.forEach(element => {
          result.push(element);
        });
      }
      else {
        result = []
      }
      success(data)

    }).catch((data) => {
      console.error("Error @ getIndividuelleFelder", data);
      error(data)
    });
  }


  getBzObjTypeString(bzObjType: ObjectType): string {
    let result: string = "";

    switch (bzObjType) {
      case ObjectType.Auftrag: result = "Auftrag"; break;
      case ObjectType.Angebot: result = "Angebot"; break;
      case ObjectType.Kalkulation: result = "Kalkulation"; break;
      case ObjectType.Bedarfsanforderung: result = "Bedarfsanforderung"; break;
      case ObjectType.Bestellung: result = "Bestellung"; break;
      default: break;
    }

    return result;
  }

  public getReports(callback: (reports: Report[]) => any): void {
    this.http.get('/Report/list?reportType=Rechnung').toPromise().then(data => {
      let reports = [];
      if (data) {
        for (var item of data as Array<Report>) {
          let obj: Report = new Report();
          jQuery.extend(true, obj, item);
          reports.push(obj);
        }
      }
      if (callback) callback(reports);
    }).catch(data => {
      console.error("Error @ getReports", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });
  }


  public getInfofensterDefinitionen(callback: (infofenster: Infofenster[]) => any): void {
    this.http.get('/Infofenster/definitionen').toPromise().then(data => {
      let result = [];
      if (data) {
        for (var item of data as Array<Infofenster>) {
          let obj: Infofenster = new Infofenster();
          jQuery.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getInfofensterDefinitionen", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });
  }

  public getInfofensterItems(infofensterCode: number, sdObjMemberCode: number, callback: (infofenster: InfofensterDataItem[]) => any): void {
    this.http.get(`/Infofenster/items?definitionCode=${infofensterCode}&sdObjMemberCode=${sdObjMemberCode}`).toPromise().then(data => {
      let result = [];
      if (data) {
        for (var item of data as Array<InfofensterDataItem>) {
          let obj: InfofensterDataItem = new InfofensterDataItem();
          jQuery.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getInfofensterItems", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });
  }

  public createReport(bzObjType: ObjectType, bzObjCode: Number, reportCode: number, callback: (success: any) => any, errorCallback: (err: string) => any): void {
    let request = new CreateReportRequest();
    request.BzObjType = this.getBzObjTypeString(bzObjType);
    request.ReportSource.Code = reportCode;
    request.ObjCode = bzObjCode;
    request.ExtendedRecordSelektionFormular = "";

    request.FormularCollection = [];

    request.PrintReport = new ReportPrintOptions();
    request.PrintReport.PrinterName = "";
    //console.log("request", request);
    this.http.post('/SoapApiProxy/createreport', request).toPromise()
      .then(data => {
        if (callback)
          callback(data);
      }).catch(data => {
        console.error("Error @ createReport", data);
        if (errorCallback)
          errorCallback(data);
      });
  }


  sendMail(mailRequest: SendMailRequest, success: (result: any) => any, error: (result: any) => any) {

    this.http.post('/Mail/send', mailRequest, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {

      success(data)

    }).catch((data) => {

      error(data)
      console.error("Error @ getAuftraegeByProjektCode", data);
    });
  }


  getAuftraegeByProjektCode(projektCode, success: (result: Auftrag[]) => any) {
    let filter = new ApiFilter();
    filter.Max = 200;
    filter.AddFilterItem("ProjektCode", ApiFilterOperator.Equal, projektCode);
    filter.SortOrder = ApiSortOrderStyle.Descending;
    filter.SortOrderFieldName = "AuftragsDatum";

    this.http.post('/Auftrag/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
      let result: Auftrag[] = [];
      if (data && $.isArray(data)) {
        data.forEach(element => {
          result.push(<Auftrag>element);
        });
      }
      else {
        result = []
      }
      success(result)

    }).catch((data) => {
      console.error("Error @ getAuftraegeByProjektCode", data);
    });
  }


  getAuftraegeByDispoZeitraum(start: Date, ende: Date, success: (result: Auftrag[]) => any) {
    let filter = new ApiFilter();
    filter.Max = 200;
    filter.AddFilterItem("Dispositionsbeginn", ApiFilterOperator.SmallerOrEqual, ende);
    filter.AddFilterItem("Dispositionsende", ApiFilterOperator.GreaterOrEqual, start);
    filter.SortOrder = ApiSortOrderStyle.Descending;
    filter.SortOrderFieldName = "AuftragsDatum";

    this.http.post('/Auftrag/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
      let result: Auftrag[] = [];
      if (data && $.isArray(data)) {
        data.forEach(element => {
          result.push(<Auftrag>element);
        });
      }
      else {
        result = []
      }
      success(result)

    }).catch((data) => {
      console.error("Error @ getAuftraegeByDispoZeitraum", data);
    });
  }

  getAngeboteByBenutzerCode(benutzerCode, shopDefinitionId, success: (result: any) => any) {
    let filter = new ApiFilter();
    filter.Max = 200;
    if (benutzerCode) {
      filter.AddFilterItem("BenutzerCode", ApiFilterOperator.Equal, benutzerCode);
    }
    if (this.loginId && !this.shopRechte.bestellungenEinsehen) {
      filter.AddFilterItem("CreatedByLoginId", ApiFilterOperator.Equal, this.loginId);
    }
    filter.AddFilterItem("WebShopOrderDefinitionData", ApiFilterOperator.Equal, shopDefinitionId);
    filter.SortOrder = ApiSortOrderStyle.Descending;
    filter.SortOrderFieldName = "Datum";

    this.http.post('/Angebot/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
      let result: Angebot[] = [];
      if (data && $.isArray(data)) {
        data.forEach(element => {
          result.push(<Angebot>element);
        });
      }
      else {
        result = []
      }
      success(result)

    }).catch((data) => {
      console.error("Error @ getAngeboteByBenutzerCode", data);
    });
  }

  getAuftraegeByBenutzerCode(benutzerCode, shopDefinitionId, success: (result: any) => any) {
    let filter = new ApiFilter();
    filter.Max = 200;
    if (benutzerCode != null) {
      filter.AddFilterItem("BenutzerCode", ApiFilterOperator.Equal, benutzerCode);
    }
    if (this.loginId != null && !this.shopRechte.bestellungenEinsehen) {
      filter.AddFilterItem("CreatedByLoginId", ApiFilterOperator.Equal, this.loginId);
    }
    filter.AddFilterItem("WebShopOrderDefinitionData", ApiFilterOperator.Equal, shopDefinitionId);
    filter.SortOrder = ApiSortOrderStyle.Descending;
    filter.SortOrderFieldName = "AuftragsDatum";

    this.http.post('/Auftrag/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
      let result: Auftrag[] = [];
      if (data && $.isArray(data)) {
        data.forEach(element => {
          result.push(<Auftrag>element);
        });
      }
      else {
        result = []
      }
      success(result)

    }).catch((data) => {
      console.error("Error @ getAuftraegeByBenutzerCode", data);
    });
  }

  getBedarfsanforderungenByBenutzerCode(benutzerCode, shopDefinitionId, success: (result: any) => any) {
    let filter = new ApiFilter();
    filter.Max = 200;
    if (benutzerCode != null) {
      filter.AddFilterItem("BenutzerCode", ApiFilterOperator.Equal, benutzerCode);
    }
    if (this.loginId != null && !this.shopRechte.bestellungenEinsehen) {
      filter.AddFilterItem("CreatedByLoginId", ApiFilterOperator.Equal, this.loginId);
    }
    filter.AddFilterItem("WebShopOrderDefinitionData", ApiFilterOperator.Equal, shopDefinitionId);
    filter.SortOrder = ApiSortOrderStyle.Descending;
    filter.SortOrderFieldName = "Datum";

    this.http.post('/Bedarfsanforderung/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
      let result: Auftrag[] = [];
      if (data && $.isArray(data)) {
        data.forEach(element => {
          result.push(<Auftrag>element);
        });
      }
      else {
        result = []
      }
      success(result)

    }).catch((data) => {
      console.error("Error @ getBedarfsanforderungenByBenutzerCode", data);
    });
  }

  getBestellungenByBenutzerCode(benutzerCode, shopDefinitionId, success: (result: any) => any) {
    let filter = new ApiFilter();
    filter.Max = 200;

    if (benutzerCode != null) {
      filter.AddFilterItem("BenutzerCode", ApiFilterOperator.Equal, benutzerCode);
    }
    if (this.loginId != null && !this.shopRechte.bestellungenEinsehen) {
      filter.AddFilterItem("CreatedByLoginId", ApiFilterOperator.Equal, this.loginId);
    }

    filter.AddFilterItem("WebShopOrderDefinitionData", ApiFilterOperator.Equal, shopDefinitionId);
    filter.SortOrder = ApiSortOrderStyle.Descending;
    filter.SortOrderFieldName = "Datum";

    this.http.post('/Bestellung/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
      let result: Bestellung[] = [];
      if (data && $.isArray(data)) {
        data.forEach(element => {
          result.push(<Bestellung>element);
        });
      }
      else {
        result = []
      }
      success(result)

    }).catch((data) => {
      console.error("Error @ getBestellungenByBenutzerCode", data);
    });
  }

  getKalkulationenByBenutzerCode(benutzerCode, shopDefinitionId, success: (result: any) => any) {
    let filter = new ApiFilter();
    filter.Max = 200;
    if (benutzerCode != null) {
      filter.AddFilterItem("BenutzerCode", ApiFilterOperator.Equal, benutzerCode);
    }
    if (this.loginId != null && !this.shopRechte.bestellungenEinsehen) {
      filter.AddFilterItem("CreatedByLoginId", ApiFilterOperator.Equal, this.loginId);
    }
    filter.AddFilterItem("WebShopOrderDefinitionData", ApiFilterOperator.Equal, shopDefinitionId);
    filter.SortOrder = ApiSortOrderStyle.Descending;
    filter.SortOrderFieldName = "Datum";

    this.http.post('/Kalkulation/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
      let result: Auftrag[] = [];
      if (data && $.isArray(data)) {
        data.forEach(element => {
          result.push(<Auftrag>element);
        });
      }
      else {
        result = []
      }
      success(result)

    }).catch((data) => {
      console.error("Error @ getKalkulationenByBenutzerCode", data);
    });
  }




  public getLoggedUser(): any {
    return this.loggedUser
  }

  loggedUser = null;

  public setLoggedUser(fullInfo: boolean = false) {

    let result: any = {};
    if (localStorage.getItem("loggedUser")) {
      result = JSON.parse(localStorage.getItem("loggedUser"))

    }

    if (localStorage.getItem("sdObjMemberCode"))
      result.sdObjMemberCode = localStorage.getItem("sdObjMemberCode");
    if (localStorage.getItem("sdObjType"))
      result.sdObjType = localStorage.getItem("sdObjType");
    if (localStorage.getItem("loginLevel"))
      result.loginLevel = localStorage.getItem("loginLevel")
    if (localStorage.getItem("shopMaster"))
      result.shopMaster = localStorage.getItem("shopMaster")
    if (localStorage.getItem("apCode"))
      result.apCode = localStorage.getItem("apCode")

    if (localStorage.getItem("shopRechte") && localStorage.getItem("shopRechte").length > 0) {
      let rechte = JSON.parse(localStorage.getItem("shopRechte"))
      if (rechte && rechte != undefined) {
        $.extend(true, this.shopRechte, rechte)
      }
    }
    else {
      this.shopRechte = new ExternalLoginRechte()
    }

    if (localStorage.getItem("loginId"))
      this.loginId = localStorage.getItem("loginId")


    this.loggedUser = result;

    if (fullInfo) {
      this.getFullBenutzer(this.loggedUser.code, data => {
        this.loggedUser.fullBenutzerInfo = data
      }, error => {

      })
    }
  }

  public getArtikelGruppen(callback: (result: any) => any) {
    let url: string = "/artikel/gruppen";

    this.http.get(url).toPromise().then((gruppen) => {

      if (gruppen) {
        if (callback) callback(gruppen);
      }
      else {
        if (callback) callback(null);
      }

    }).catch((data) => {
      console.error("Error @ getArtikelGruppen", data);
      //return null;
    });;
  }

  public refreshObjectLock(objectLocks, callback: (result: any) => any) {
    let url: string = "/ObjectLock/refreshlocks";

    this.http.post(url, objectLocks, { headers: { 'loadComplexity': '3' } }).toPromise().then((objectLock) => {

      if (objectLock) {
        if (callback) callback(objectLock);
      }
      else {
        if (callback) callback(null);
      }

    }).catch((data) => {
      console.error("Error @ refreshObjectLock", data);
      //return null;
    });;
  }

  public checkObjectLock(bzObject: any, callback: (result: any) => any) {
    let url: string = "/ObjectLock/check";

    if (bzObject.posCode != undefined) {
      // Position
      url += "?objectType=Position";
    }
    else {
      switch (bzObject.bzObjType) {
        case ObjectType.Eingangslieferschein: url += "?objectType=Eingangslieferschein"; break;
        case ObjectType.Lieferschein: url += "?objectType=Lieferschein"; break;
        default: break;
      }
    }


    url += "&keyValue=" + bzObject.code;

    this.http.get(url, { headers: { 'loadComplexity': '3' } }).toPromise().then((objectLock) => {

      if (objectLock) {
        if (callback) callback(objectLock);
      }
      else {
        if (callback) callback(null);
      }

    }).catch((data) => {
      console.error("Error @ checkObjectLock", data);
      //return null;
    });;
  }

  public setObjectLock(bzObject: any, callback: (result: any) => any) {
    let url: string = "/ObjectLock";

    if (bzObject.posCode != undefined) {
      // Position
      url += "?objectType=Position";
    }
    else {
      switch (bzObject.bzObjType) {
        case ObjectType.Eingangslieferschein: url += "?objectType=Eingangslieferschein"; break;
        case ObjectType.Lieferschein: url += "?objectType=Lieferschein"; break;
        default: break;
      }
    }

    url += "&keyValue=" + bzObject.code;

    this.http.get(url, { headers: { 'loadComplexity': '3' } }).toPromise().then((objectLock) => {

      if (objectLock) {
        if (callback) callback(objectLock);
      }
      else {
        if (callback) callback(null);
      }

    }).catch((data) => {
      console.error("Error @ setObjectLock", data);
      //return null;
    });;
  }

  public deleteObjectLock(bzObject: any, callback: (result: any) => any) {
    let url: string = "/ObjectLock";
    if (bzObject.posCode != undefined) {
      url += "?objectType=Position";
    }
    else {
      switch (bzObject.bzObjType) {
        case ObjectType.Eingangslieferschein: url += "?objectType=Eingangslieferschein"; break;
        case ObjectType.Lieferschein: url += "?objectType=Lieferschein"; break;
        default: break;
      }
    }


    url += "&keyValue=" + bzObject.code;

    this.http.delete(url, { headers: { 'loadComplexity': '3' } }).toPromise().then((objectLock) => {

      if (objectLock) {
        if (callback) callback(objectLock);
      }
      else {
        if (callback) callback(null);
      }

    }).catch((data) => {
      console.error("Error @ deleteObjectLock", data);
      //return null;
    });;
  }


  public searchBelegByScan(scannedString: string, callback: (result: any) => any) {

    let searchComplete: boolean = false;

    if (scannedString.substring(0, 3) == ".el") {
      let scan = scannedString.substring(3)
      this.getBelegByNummer(ObjectType.Eingangslieferschein, scan, (belegList) => {
        if (callback) {
          callback(belegList)
        }
      })
      searchComplete = true;
    }
    if (scannedString.substring(0, 2) == ".l") {
      let scan = scannedString.substring(2)
      this.getBelegByNummer(ObjectType.Lieferschein, scan, (belegList) => {
        if (callback) {
          callback(belegList)
        }
      })
      searchComplete = true;
    }

    if (!searchComplete) {
      this.getBelegByNummer(ObjectType.Eingangslieferschein, scannedString, (belegList) => {
        if (callback) {
          if (belegList.length > 0) {
            callback(belegList)
          }
          else {
            this.getBelegByNummer(ObjectType.Lieferschein, scannedString, (belegList) => {
              if (belegList) {
                callback(belegList)
              }
            })
          }
        }
      })
    }

  }

  public async getBelegByCode(objectType: ObjectType, code: number): Promise<any> {
    let filter = new ApiFilter();
    filter.Max = 1;
    //filter.AddFilterItem("HauptadresseCompanyType", ApiFilterOperator.Equal, sdObjType);
    filter.AddFilterItem("Code", ApiFilterOperator.Equal, code);
    filter.SortOrder = ApiSortOrderStyle.Descending;
    //filter.SortOrderFieldName = "Datum";

    let url: string = "";

    switch (objectType) {
      case ObjectType.Bedarfsanforderung: url = '/Bedarfsanforderung/query'; break
      case ObjectType.Auftrag: url = '/Auftrag/query'; break
      case ObjectType.Angebot: url = '/Angebot/query'; break
      case ObjectType.Kalkulation: url = '/Kalkulation/query'; break
      case ObjectType.Bestellung: url = '/Bestellung/query'; break
      default: break;
    }

    let result = await this.http.post(url, filter, { headers: { 'loadComplexity': '3' } }).toPromise()
    if (result[0] != undefined) {
      return result[0]
    }
    else {
      return null
    }
  }


  public saveBeleg(beleg: any, callback: (code: number) => void, onError: (error: Error) => void): void {
    let url;

    switch (beleg.bzObjType) {
      case ObjectType.Eingangslieferschein: url = `/Eingangslieferschein`; break;
      case ObjectType.Lieferschein: url = `/Lieferschein`; break;

      default: break;
    }

    if (beleg.code == 0) {
      beleg.positionen.forEach((element, index) => {
        beleg.positionen[index].code = 0;
        //beleg.positionen[index].bzObjMemberCode = 0;
      });
      this.http.post(url, beleg).toPromise().then((data: any) => {
        if (callback) callback(data);
      }).catch((error: Error) => {
        console.error("Error @ saveBeleg", error);
        if (onError) onError(error);
      });
    }
    else {
      this.http.put(url, beleg).toPromise().then((data: any) => {
        if (callback) callback(data);
      }).catch((error: Error) => {
        console.error("Error @ saveBeleg", error);
        if (onError) onError(error);
      });
    }

  }

  public getBelegByNummer(objectType: ObjectType, nummerString: string, callback: (result: any) => any): void {
    let filter = new ApiFilter();
    filter.Max = 1;
    filter.AddFilterItem("HauptadresseCompanyType", ApiFilterOperator.Equal, 'Kunde');
    filter.SortOrder = ApiSortOrderStyle.Descending;
    filter.SortOrderFieldName = "Datum";
    let nummer = parseInt(nummerString)
    let url: string = "";

    switch (objectType) {
      case ObjectType.Eingangslieferschein:
        url = '/Eingangslieferschein/query';
        filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, nummer);
        break;
      case ObjectType.Lieferschein:
        url = '/Lieferschein/query';
        filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, nummer);
        break;
      case ObjectType.Auftrag: url = '/Auftrag/query';
        filter.AddFilterItem("AuftragsNummer", ApiFilterOperator.Equal, nummer);
        break;
      case ObjectType.Projekt:
        url = '/Projekt/query';
        filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, nummer);
        break;
      default: break;
    }

    this.http.post(url, filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {

      let result = [];
      if (data && $.isArray(data)) {
        for (var item of data as Array<any>) {
          let obj: any = {};
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
        if (callback) callback(result);
      }
      else {
        if (callback) callback(null);
      }

    }).catch((data) => {
      console.error("Error @ getBelegByNummer", data);
      //return null;
    });;

    //return result[0];
  }

  public saveBuchungen(buchungen: any, callback: (code: number) => void, onError: (error: Error) => void): void {

    this.http.post("/lager/einbuchung", buchungen).toPromise().then((data: any) => {
      if (callback) callback(data);
    }).catch((error: Error) => {
      console.error("Error @ saveBuchungen", error);
      if (onError) onError(error);
    });


  }


  public async checkUsername(username: string): Promise<boolean> {
    return <boolean>await this.http.get("/ExternalLogin/managend/loginnamevalid?loginName=" + username).toPromise()
  }

  public async getLicenseStatus(): Promise<{
    anzahlAktiveLogins: number
    maximaleAktiveLogins: number
  }> {
    return <{
      anzahlAktiveLogins: number
      maximaleAktiveLogins: number
    }>await this.http.get("/externallogin/managend/status").toPromise()
  }





  public getBuchungenByBzObjAndPosition(bzObject: any, positionCode: Number, callback: (result: Buchung[]) => any): void {

    var type: string = "";

    switch (bzObject.bzObjType) {
      case ObjectType.Eingangslieferschein: type = "Eingangslieferschein"; break;
      case ObjectType.Lieferschein: type = "Lieferschein"; break;
      default: break;
    }

    this.http.get("/lager/buchungen/" + type + "/" + bzObject.code + "?positionCode=" + positionCode, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Buchung[] = [];
      if (data) {
        for (var item of data as Array<Buchung>) {
          let obj: Buchung = new Buchung();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getBuchungenByBzObjAndPosition", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public getSeriennummerInfo(seriennummerCode, callback: (result: Seriennummer) => any): void {

    this.http.get("/lager/seriennummerinfo?code=" + seriennummerCode, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Seriennummer = new Seriennummer();
      if (data) {
        //obj = JSON.parse(JSON.stringify(item));
        $.extend(true, result, data);
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getSeriennummerInfo", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public getSeriennummerInfoBySeriennummerAndArtikelCode(seriennummerCode: any, artikelCode: any, callback: (result: Seriennummer) => any): void {

    this.http.get("/lager/seriennummerinfo/" + seriennummerCode + "/" + artikelCode, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Seriennummer = new Seriennummer();
      if (data) {
        //obj = JSON.parse(JSON.stringify(item));
        $.extend(true, result, data);
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getSeriennummerInfoBySeriennummerAndArtikelCode", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  saveBelegEntwurf(beleg) {
    //console.log("saving Beleg Entwurf")
    if (beleg.bzObjType == ObjectType.Eingangslieferschein)
      localStorage.setItem("unsavedEingangslieferschein", JSON.stringify(beleg));
    if (beleg.bzObjType == ObjectType.Lieferschein)
      localStorage.setItem("unsavedLieferschein", JSON.stringify(beleg));
  }

  public createLieferschein(companyCode: number, companyType: string, callback: (result: Lieferschein) => any): void {

    this.http.get("/Lieferschein/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Lieferschein = new Lieferschein();
      if (data) {
        $.extend(true, result, data);
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ createLieferschein", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public createLieferscheinAsync(companyCode: number, companyType: string): Promise<Lieferschein> {
    let data;
    this.http.get("/Lieferschein/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Lieferschein = new Lieferschein();
      if (data) {
        $.extend(true, result, data);
      }
      data = result;
    }).catch(data => {
      console.error("Error @ createLieferscheinAsync", data);
      data = null;
      // todo: ErrorHandling...
    });
    return data
  }

  public createLieferscheinPosition(beleg: Lieferschein, positionsArt: string, artikelCode: number, anzahl: number, callback: (result: Position) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    this.http.post("/Lieferschein/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Position[] = [];
      if (data) {
        for (var item of data as Array<Position>) {
          let obj: Position = new Position();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result[0]);
    }).catch(data => {
      console.error("Error @ createLieferscheinPosition", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }


  public createBelegPosition(beleg: any, positionsArt: string, artikelCode: number, anzahl: number, callback: (result: Position) => any): void {

    switch (beleg.bzObjType) {
      case ObjectType.Auftrag: this.createAuftragPosition(beleg, positionsArt, artikelCode, anzahl, callback); break;
      case ObjectType.Angebot: this.createAngebotPosition(beleg, positionsArt, artikelCode, anzahl, callback); break;
      case ObjectType.Kalkulation: this.createKalkulationPosition(beleg, positionsArt, artikelCode, anzahl, callback); break;
      case ObjectType.Bestellung: this.createBestellungPosition(beleg, positionsArt, artikelCode, anzahl, callback); break;
      case ObjectType.Bedarfsanforderung: this.createBedarfPosition(beleg, positionsArt, artikelCode, anzahl, callback); break;
      default: break;
    }
  }


  public createAuftragPosition(beleg: Auftrag, positionsArt: string, artikelCode: number, anzahl: number, callback: (result: Position) => any): void {

    beleg = this.bereinigeBeleg(beleg)

    this.http.post("/Auftrag/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Position[] = [];
      if (data) {
        for (var item of data as Array<Position>) {
          let obj: Position = new Position();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result[0]);
    }).catch(data => {
      console.error("Error @ createAuftragPosition", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public createAngebotPosition(beleg: Angebot, positionsArt: string, artikelCode: number, anzahl: number, callback: (result: Position) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    this.http.post("/Angebot/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Position[] = [];
      if (data) {
        for (var item of data as Array<Position>) {
          let obj: Position = new Position();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result[0]);
    }).catch(data => {
      console.error("Error @ createAngebotPosition", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public createAuftrag(companyCode: number, companyType: string, callback: (result: Auftrag) => any): void {

    this.http.get("/Auftrag/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Auftrag = new Auftrag();
      if (data) {
        $.extend(true, result, data);
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ createAuftrag", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public createAngebot(companyCode: number, companyType: string, callback: (result: Angebot) => any): void {

    this.http.get("/Angebot/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Angebot = new Angebot();
      if (data) {
        $.extend(true, result, data);
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ createAngebot", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public createBedarf(companyCode: number, companyType: string, callback: (result: Bedarf) => any): void {

    this.http.get("/Bedarfsanforderung/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Bedarf = new Bedarf();
      if (data) {
        $.extend(true, result, data);
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ createBedarf", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public createBestellung(companyCode: number, companyType: string, callback: (result: Bestellung) => any): void {

    this.http.get("/Bestellung/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Bestellung = new Bestellung();
      if (data) {
        $.extend(true, result, data);
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ createBestellung", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public createKalkulation(companyCode: number, companyType: string, callback: (result: Kalkulation) => any): void {

    this.http.get("/Kalkulation/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Kalkulation = new Kalkulation();
      if (data) {
        $.extend(true, result, data);
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ createKalkulation", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }


  public async createAuftragAsync(companyCode: number, companyType: string): Promise<Auftrag> {
    return <Auftrag>await this.http.get("/Auftrag/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  public async createAngebotAsync(companyCode: number, companyType: string): Promise<Angebot> {
    return <Angebot>await this.http.get("/Angebot/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise()
  }


  public async createBedarfAsync(companyCode: number, companyType: string): Promise<Bedarf> {
    return <Bedarf>await this.http.get("/Bedarfsanforderung/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  public async createBestellungAsync(companyCode: number, companyType: string): Promise<Bestellung> {
    return <Bestellung>await this.http.get("/Bestellung/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  public async createKalkulationAsync(companyCode: number, companyType: string): Promise<Kalkulation> {
    return <Kalkulation>await this.http.get("/Kalkulation/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  public createEingangslieferschein(companyCode: number, companyType: string, callback: (result: Eingangslieferschein) => any): void {

    this.http.get("/Eingangslieferschein/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Eingangslieferschein = new Eingangslieferschein();
      if (data) {
        $.extend(true, result, data);
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ createEingangslieferschein", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }



  public createEingangslieferscheinPosition(beleg: Eingangslieferschein, positionsArt: string, artikelCode: number, anzahl: number, callback: (result: Position) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    this.http.post("/Eingangslieferschein/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Position[] = [];
      if (data) {
        for (var item of data as Array<Position>) {
          let obj: Position = new Position();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result[0]);
    }).catch(data => {
      console.error("Error @ createEingangslieferscheinPosition", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }



  public createBedarfPosition(beleg: Bedarf, positionsArt: string, artikelCode: number, anzahl: number, callback: (result: Position) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    this.http.post("/Bedarfsanforderung/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Position[] = [];
      if (data) {
        for (var item of data as Array<Position>) {
          let obj: Position = new Position();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result[0]);
    }).catch(data => {
      console.error("Error @ createBedarfPosition", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }




  public createBestellungPosition(beleg: Bestellung, positionsArt: string, artikelCode: number, anzahl: number, callback: (result: Position) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    this.http.post("/Bedarfsanforderung/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Position[] = [];
      if (data) {
        for (var item of data as Array<Position>) {
          let obj: Position = new Position();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result[0]);
    }).catch(data => {
      console.error("Error @ createBestellungPosition", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }



  public createKalkulationPosition(beleg: Kalkulation, positionsArt: string, artikelCode: number, anzahl: number, callback: (result: Position) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.post("/Kalkulation/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Position[] = [];
      if (data) {
        for (var item of data as Array<Position>) {
          let obj: Position = new Position();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result[0]);
    }).catch(data => {
      console.error("Error @ createKalkulationPosition", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }





  public postEingangslieferschein(beleg: Eingangslieferschein, callback: (belegCode: any) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.post('/Eingangslieferschein', beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {

      if (callback) callback(data);
    }).catch(data => {
      console.error("Error @ postEingangslieferschein", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }



  public updateShopDefinition(id: string, shopDefinition: ShopDefinition, success: (result: any) => any, error: (result: any) => any): void {

    this.http.put("/shop/shopdefinition?beschreibung=&id=" + id, shopDefinition).toPromise().then(() => {
      if (success) success(true);
    }).catch(data => {
      console.error("Error @ updateShopDefinition", data);
      if (error) error(false);
      // todo: ErrorHandling...
    });

  }


  public insertShopDefinition(shopDefinition: ShopDefinition, success: (result: any) => any, error: (result: any) => any): void {

    this.http.post("/shop/shopdefinition?beschreibung=", shopDefinition).toPromise().then(() => {
      if (success) success(true);
    }).catch(data => {
      console.error("Error @ insertShopDefinition", data);
      if (error) error(false);
      // todo: ErrorHandling...
    });

  }



  public updateAuftrag(beleg: Auftrag, success: (result: any) => any, error: (result: any) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.put("/Auftrag", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ updateAuftrag", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }

  public insertAuftrag(beleg: Auftrag, success: (result: any) => any, error: (result: any) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.post("/Auftrag", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ insertAuftrag", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }

  public insertAngebot(beleg: Angebot, success: (result: any) => any, error: (result: any) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.post("/Angebot", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ insertAngebot", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }




  public updateAngebot(beleg: Angebot, success: (result: any) => any, error: (result: any) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.put("/Angebot", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ updateAngebot", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }

  public insertBedarf(beleg: Bedarf, success: (result: any) => any, error: (result: any) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.post("/Bedarfsanforderung", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ insertBedarf", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }



  public updateBedarf(beleg: Bedarf, success: (result: any) => any, error: (result: any) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.put("/Bedarf", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ updateBedarf", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }

  public insertBestellung(beleg: Bestellung, success: (result: any) => any, error: (result: any) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.post("/Bestellung", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ insertBestellung", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }

  public updateBestellung(beleg: Bestellung, success: (result: any) => any, error: (result: any) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.put("/Bestellung", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ updateBestellung", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }

  public insertKalkulation(beleg: Kalkulation, success: (result: any) => any, error: (result: any) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.post("/Kalkulation", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ insertKalkulation", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }

  public updateKalkulation(beleg: Kalkulation, success: (result: any) => any, error: (result: any) => any): void {
    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.put("/Kalkulation", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ updateKalkulation", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }

  public async getKalkulation(code): Promise<Kalkulation> {
    return <Kalkulation>await this.http.get("/Kalkulation/" + code, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  public async getAngebot(code): Promise<Angebot> {
    return <Angebot>await this.http.get("/Angebot/" + code, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  public async getBestellung(code): Promise<Bestellung> {
    return <Bestellung>await this.http.get("/Bestellung/" + code, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  public async getAuftrag(code): Promise<Auftrag> {
    return <Auftrag>await this.http.get("/Auftrag/" + code, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  public async getBedarf(code): Promise<Bedarf> {
    return <Bedarf>await this.http.get("/Bedarf/" + code, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  jsonfy(value) {
    return JSON.parse(JSON.stringify(value))
  }

  bereinigeBeleg(beleg) {
    beleg = this.jsonfy(beleg)
    beleg.positionen.forEach(position => {
      position.artikel = undefined
    })

    return beleg
  }

  public insertProjekt(projekt: Projekt, success: (result: any) => any, error: (result: any) => any): void {

    projekt = this.cleanDates(projekt)

    this.http.post("/Projekt", projekt, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ insertProjekt", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }

  public updateProjekt(projekt: Projekt, success: (result: any) => any, error: (result: any) => any): void {

    projekt = this.cleanDates(projekt)
    this.http.put("/Projekt", projekt, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ updateProjekt", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });

  }


  public postLieferschein(beleg: Lieferschein, callback: (belegCode: any) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    beleg = this.cleanDates(beleg)
    this.http.post('/Lieferschein', beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {

      if (callback) callback(data);
    }).catch(data => {
      console.error("Error @ postLieferschein", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }


  public postEinbuchung(buchungen: Buchung[], callback: (buchungenResponse: any) => any): void {

    this.http.post('/lager/einbuchung', buchungen, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {

      if (callback) callback(data);
    }).catch(data => {
      console.error("Error @ postEinbuchung", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }


  public postAusbuchung(buchungen: Buchung[], callback: (buchungenResponse: any) => any): void {

    this.http.post('/lager/ausbuchung', buchungen, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {

      if (callback) callback(data);
    }).catch(data => {
      console.error("Error @ postAusbuchung", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public createRechnungPosition(beleg: any, artikelCode: number, anzahl: Number, positionsArt: any, callback: (result: Position) => any): void {

    beleg = this.bereinigeBeleg(beleg)
    this.http.post("/Rechnung/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Position[] = [];
      if (data) {
        for (var item of data as Array<Position>) {
          let obj: Position = new Position();
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result[0]);
    }).catch(data => {
      console.error("Error @ createRechnungPosition", data);
      if (callback) callback(null);
    });

  }

  public getKundenBySuchbegriff(suchbegriff: any, callback: (result: Kunde[]) => any): void {
    let filter = new ApiFilter();
    filter.Max = 200;
    filter.SortOrderFieldName = "Name"

    if (suchbegriff != null) {

      if (isNaN(suchbegriff))
        filter.AddFilterItem("Name", ApiFilterOperator.Contains, suchbegriff, "", "Or");
      else
        filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");


      this.http.post('/kunde/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
        let result: Kunde[] = [];
        if (data) {
          for (var item of data as Array<Kunde>) {
            let obj: Kunde = new Kunde();
            //obj = JSON.parse(JSON.stringify(item));
            $.extend(true, obj, item);
            result.push(obj);
          }
        }
        if (callback) callback(result);
      }).catch(data => {
        console.error("Error @ getKundenBySuchbegriff", data);
        if (callback) callback(null);
        // todo: ErrorHandling...
      });
    }
  }

  public getKundeByCode(code: number, callback: (result: Kunde) => any): void {
    let filter = new ApiFilter();
    filter.Max = 1;

    filter.AddFilterItem("Code", ApiFilterOperator.Equal, code);

    this.http.post('/kunde/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Kunde[] = [];
      if (data) {
        for (var item of data as Array<Kunde>) {
          let obj: Kunde = new Kunde();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result[0]);
    }).catch(data => {
      console.error("Error @ getKundeByCode", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public async getKundenByCodes(codes: number[]): Promise<Kunde[]> {
    let filter = new ApiFilter();
    filter.AddFilterItem("Code", ApiFilterOperator.InValueList, codes);
    return <Kunde[]>await this.http.post('/kunde/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  public async getKundenAnsprechpartnerByCodes(codes: number[]): Promise<Ansprechpartner[]> {
    let filter = new ApiFilter();
    filter.AddFilterItem("Code", ApiFilterOperator.InValueList, codes);
    return <Ansprechpartner[]>await this.http.post('/ansprechpartner/kunde/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  public async getLieferantenByCodes(codes: number[]): Promise<Lieferant[]> {
    let filter = new ApiFilter();
    filter.AddFilterItem("Code", ApiFilterOperator.InValueList, codes);
    return <Lieferant[]>await this.http.post('/lieferant/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise()
  }

  public getKostenstellen(callback: (result: any) => any): void {
    this.http.get('/erp/kostenstellen', { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: any;
      if (data) {
        result = <any>data;
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getKostenstellen", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });
  }

  public getLookup(lookupType: string, callback: (result: any) => any): void {
    this.http.get('/Lookup?lookupType=' + lookupType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: any;
      if (data) {
        result = <any>data;
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getLookup", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });
  }

  public getLieferantenBySuchbegriff(suchbegriff: any, callback: (result: Lieferant[]) => any): void {
    let filter = new ApiFilter();
    filter.Max = 200;

    if (suchbegriff != null) {
      if (isNaN(suchbegriff))
        filter.AddFilterItem("Name", ApiFilterOperator.Contains, suchbegriff, "", "Or");
      else
        filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");


      this.http.post('/lieferant/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
        let result: Lieferant[] = [];
        if (data) {
          for (var item of data as Array<Lieferant>) {
            let obj: Lieferant = new Lieferant();
            //obj = JSON.parse(JSON.stringify(item));
            $.extend(true, obj, item);
            result.push(obj);
          }
        }
        if (callback) callback(result);
      }).catch(data => {
        console.error("Error @ getLieferantBySuchbegriff", data);
        if (callback) callback(null);
        // todo: ErrorHandling...
      });
    }
  }

  public getLieferantByCode(code: number, callback: (result: Lieferant) => any): void {
    let filter = new ApiFilter();
    filter.Max = 20;

    filter.AddFilterItem("Code", ApiFilterOperator.Equal, code);

    this.http.post('/lieferant/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Lieferant[] = [];
      if (data) {
        for (var item of data as Array<Lieferant>) {
          let obj: Lieferant = new Lieferant();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result[0]);
    }).catch(data => {
      console.error("Error @ getLieferantByCode", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }


  getDateString(date): any {
    let result = moment(date).format('YYYY-MM-DD') + "T00:00:00.000"
    return result
  }

  cleanDates(obj: any) {
    //return obj

    let keyNames = [
      "anfangDatum",
      "endeDatum",
      "vonDatum",
      "bisDatum",
      "letzteÄnderung",
      "auftragsDatum",
      "datum"
    ]

    for (let key of keyNames) {
      if (obj[key] != undefined) {
        let value: string = obj[key]
        obj[key] = this.getDateString(value)
      }
    }
    return obj
  }

  public getProjekteBySuchbegriff(suchbegriff: any, callback: (result: Projekt[]) => any): void {
    let filter = new ApiFilter();
    filter.Max = 200;

    if (suchbegriff != null) {
      if (isNaN(suchbegriff)) {
        filter.AddFilterItem("Name", ApiFilterOperator.Contains, suchbegriff, "", "And");
      }
      else {
        //filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");
        filter.AddFilterItem("Code", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");
        filter.AddFilterItem("KundenCode", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");
      }
      //filter.AddFilterItem("AnfangDatum", ApiFilterOperator.NotEqual, new Date(0), "", "And");

      this.http.post('/projekt/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then(data => {
        let result: Projekt[] = [];
        if (data) {
          for (var item of data as Array<Projekt>) {
            let obj: Projekt = new Projekt();
            //obj = JSON.parse(JSON.stringify(item));
            $.extend(true, obj, item);
            result.push(this.cleanDates(obj));
          }
        }
        if (callback) callback(result);
      }).catch(data => {
        console.error("Error @ getProjekteBySuchbegriff", data);
        if (callback) callback(null);
        // todo: ErrorHandling...
      });
    }
  }

  public getNiederlassungByCode(code: any, callback: (result) => any): void {

    this.getLookup("Niederlassung", data => {
      data.forEach(element => {
        if (element.code == code) {
          if (callback) callback(element);
        }
      });
    })

  }

  public getKostenstelleByCode(code: any, callback: (result) => any): void {

    this.getKostenstellen(data => {
      data.forEach(element => {
        if (element.code == code) {
          if (callback) callback(element);
        }
      });
    })

  }

  public getProjektByCode(code: any, callback: (result: Projekt) => any): void {
    let filter = new ApiFilter();
    filter.Max = 1;

    filter.AddFilterItem("Code", ApiFilterOperator.Equal, code);


    this.http.post('/projekt/query', filter, { headers: { 'loadComplexity': '1' } }).toPromise().then(data => {
      let result: Projekt[] = [];
      if (data) {
        for (var item of data as Array<Projekt>) {
          let obj: Projekt = new Projekt();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(this.cleanDates(obj));
        }
      }
      if (callback) callback(result[0]);
    }).catch(data => {
      console.error("Error @ getProjektByCode", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  async listManagedExternalLogins(sdObjMemberCode): Promise<ManagedExternalLogin[]> {
    let result = <ManagedExternalLogin[]>(await this.http.get('/ExternalLogin/managed?sdObjMemberCode=' + sdObjMemberCode + "&sdObjType=Kunde", { headers: { 'loadComplexity': '1' } }).toPromise());
    return result
  }


  public async getManagedExternalLogins(): Promise<ManagedExternalLogin[]> {
    let externalLogins = <ExternalLogin[]>(await this.http.post('/ExternalLogin/query', new ApiFilter(), { headers: { 'loadComplexity': '1' } }).toPromise());

    let kundenCodes = new List(externalLogins).Where(x => x.sdObjMemberCode != 0 && x.sdObjMemberCode != null).Select(x => x.sdObjMemberCode).Distinct().ToArray()
    let kunden: Kunde[] = await this.getKundenByCodes(kundenCodes)

    let apList: Ansprechpartner[] = await this.getAnsprechpartnerByKundenCodes(kundenCodes)
    let managedLogins: ManagedExternalLogin[] = []
    for (let code of kundenCodes) {
      if (code)
        managedLogins.push(...await this.listManagedExternalLogins(code))
    }

    let result: ManagedExternalLogin[] = []
    for (let ap of apList) {

      let login = new ManagedExternalLogin()
      let loginReference: ManagedExternalLogin = new List(managedLogins).FirstOrDefault(x => x.ansprechpartnerCode == ap.code)

      if (!loginReference || loginReference == undefined) {
        login.eMail = ap.eMail
        login.name = ap.name
        login.vorname = ap.vorname
        login.ansprechpartnerCode = ap.code
        login.aktiv = false
        login.sdObjMemberCode = ap['kundenCode']
        let rechte = new ExternalLoginRechte()
        login.rechte = JSON.stringify(rechte)
      }
      else {
        $.extend(true, login, loginReference)
      }

      let rechte = new ExternalLoginRechte()
      try {
        $.extend(true, rechte, JSON.parse(login.rechte))
      }
      catch (e) {

      }

      login.rechteParsed = rechte
      login.rechtBestellungenBearbeiten = rechte.bestellungenBearbeiten
      login.rechtBestellungenEinsehen = rechte.bestellungenEinsehen
      login.rechtGenehmigungErteilen = rechte.genehmigungErteilen
      login.rechtProjektErstellen = rechte.projektErstellen
      login.rechtProjektLoeschen = rechte.projektLoeschen

      login.ansprechpartnerAnzeigename = ap.vorname + " " + ap.name

      let kunde = new List(kunden).FirstOrDefault(x => x.code == login.sdObjMemberCode)
      if (kunde) {
        login.kunde = kunde
        login.kundeName = kunde.name
        login.kundeNummer = kunde.nummer
      }
      else {
        login
      }

      login.ansprechpartner = ap
      login.ansprechpartnerAnzeigename = ap.vorname + " " + ap.name

      result.push(login)
    }

    return result
  }

  public async postExternalLogin(item: ExternalLogin): Promise<number> {
    item.rechte = JSON.stringify(item.rechteParsed)
    return <number>await this.http.post('/ExternalLogin', item, { headers: { 'loadComplexity': '1' } }).toPromise();
  }

  public async putExternalLogin(item: ExternalLogin): Promise<number> {
    item.rechte = JSON.stringify(item.rechteParsed)
    return <number>await this.http.put('/ExternalLogin', item, { headers: { 'loadComplexity': '1' } }).toPromise();
  }

  public async postManagedExternalLogin(item: ManagedExternalLogin): Promise<number> {
    item.rechte = JSON.stringify(item.rechteParsed)
    return <number>await this.http.post('/ExternalLogin/managed?shopLizenz=true', item, { headers: { 'loadComplexity': '1' } }).toPromise();
  }

  public async putManagedExternalLogin(item: ManagedExternalLogin): Promise<number> {
    item.rechte = JSON.stringify(item.rechteParsed)
    return <number>await this.http.put('/ExternalLogin/managed', item, { headers: { 'loadComplexity': '1' } }).toPromise();
  }

  public async getProjektByCodeAsync(code: any): Promise<Projekt> {
    let filter = new ApiFilter();
    filter.Max = 1;
    filter.AddFilterItem("Code", ApiFilterOperator.Equal, code);
    return this.cleanDates(<Projekt>(await this.http.post('/projekt/query', filter, { headers: { 'loadComplexity': '1' } }).toPromise())[0]);
  }

  public async getAnsprechpartnerByKundeCode(code: any): Promise<Ansprechpartner[]> {
    return <Ansprechpartner[]>await this.http.get('/ansprechpartner/kunde?kundenCode=' + code, { headers: { 'loadComplexity': '1' } }).toPromise();
  }

  public async getAnsprechpartnerByKundenCodes(codes: number[]): Promise<Ansprechpartner[]> {

    if (codes.length == 0) {
      return []
    }

    let filter = new ApiFilter();
    filter.AddFilterItem("Entlassen", ApiFilterOperator.Equal, false, "", "AND");
    //filter.AddFilterItem("GeschaeftspartnerCode", ApiFilterOperator.InValueList, codes,"","AND");
    filter.AddFilterItem("KundenCode", ApiFilterOperator.InValueList, codes, "", "AND");
    if (codes.length > 1)
      return <Ansprechpartner[]>await this.http.post('/ansprechpartner/kunde/query', filter, { headers: { 'loadComplexity': '1' } }).toPromise()
    else
      return <Ansprechpartner[]>await this.http.get('/ansprechpartner/kunde/?kundenCode=' + codes[0], { headers: { 'loadComplexity': '1' } }).toPromise()
  }


  public getProjekteByCodes(codes: number[], callback: (result: Projekt[]) => any): void {

    if (codes.length > 0) {
      let filter = new ApiFilter();
      filter.Max = 200;

      filter.AddFilterItem("Code", ApiFilterOperator.InValueList, codes);

      this.http.post('/projekt/query', filter, { headers: { 'loadComplexity': '1' } }).toPromise().then(data => {
        let result: Projekt[] = [];
        if (data) {
          for (var item of data as Array<Projekt>) {
            let obj: Projekt = new Projekt();
            //obj = JSON.parse(JSON.stringify(item));
            $.extend(true, obj, item);
            result.push(this.cleanDates(obj));
          }
        }
        if (callback) callback(result);
      }).catch(data => {
        console.error("Error @ getProjekteByCodes", data);
        if (callback) callback(null);
        // todo: ErrorHandling...
      });
    }
    else {
      callback([])
    }


  }

  public async getProjektVerteiler(): Promise<ProjektVerteiler[]> {
    return <ProjektVerteiler[]>await this.http.get("/projekt/verteiler/validlist").toPromise()
  }

  public async postProjektVerteilerEintrag(eintrag: ProjektVerteilerEintrag): Promise<number> {
    return <number>await this.http.post("/projekt/verteiler/list", eintrag).toPromise()
  }

  public async getProjektKategorien(): Promise<ProjektKategorie[]> {
    return <ProjektKategorie[]>await this.http.get("/projekt/kategorien/validlist").toPromise()
  }

  public async postProjektKategorieZuordnung(eintrag: ProjektKategorieZuordnung): Promise<number> {
    return <number>await this.http.post("/projekt/kategorien/zuordnung", eintrag).toPromise()
  }

  public async getProjektGruppen(): Promise<ProjektGruppe[]> {
    return <ProjektGruppe[]>await this.http.get("/projekt/gruppen").toPromise()
  }

  public getProjekteByRange(vonDatum, bisDatum, callback: (result: Projekt[]) => any): void {
    let filter = new ApiFilter();
    filter.Max = 200;
    filter.SortOrderFieldName = "AnfangDatum"

    //filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");
    //filter.AddFilterItem("BCode", ApiFilterOperator.Equal, code, "", "And");
    if (vonDatum)
      filter.AddFilterItem("EndeDatum", ApiFilterOperator.GreaterOrEqual, vonDatum, "", "");
    if (bisDatum)
      filter.AddFilterItem("AnfangDatum", ApiFilterOperator.SmallerOrEqual, bisDatum, "", "And");



    this.http.post('/projekt/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then(data => {
      let result: Projekt[] = [];
      if (data) {
        for (var item of data as Array<Projekt>) {
          let obj: Projekt = new Projekt();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getProjekteByRange", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public refreshArtikelListByGruppenCodes(codes: number[], callback: (result: Artikel[]) => any): void {
    let filter = new ApiFilter();
    filter.Max = 500;

    if (codes != null) {
      codes.forEach(code => {

      });

      filter.AddFilterItem("GrCode", ApiFilterOperator.InValueList, codes, "", "");
      filter.AddFilterItem("Stillgelegt", ApiFilterOperator.Equal, false, "", "And");

      this.http.post('/artikel/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then(data => {
        let result: Artikel[] = [];
        if (data) {
          for (var item of data as Array<Artikel>) {
            let obj: Artikel = new Artikel();
            //obj = JSON.parse(JSON.stringify(item));
            $.extend(true, obj, item);
            result.push(obj);
          }
        }
        if (callback) callback(result);
      }).catch(data => {
        console.error("Error @ refreshArtikelListByGruppenCode", data);
        if (callback) callback(null);
        // todo: ErrorHandling...
      });
    }
  }


  public getArtikelBySuchbegriff(suchbegriff: any, callback: (result: Artikel[]) => any): void {
    let filter = new ApiFilter();
    filter.Max = 200;
    filter.SortOrderFieldName = "Name"
    filter.SortOrder = ApiSortOrderStyle.Ascending

    if (suchbegriff != null) {
      filter.AddFilterItem("Name", ApiFilterOperator.Contains, suchbegriff, "", "Or");
      filter.FilterItems[0].AddFilterItem("Nummer", ApiFilterOperator.Equal, suchbegriff, "", "Or");
      filter.AddFilterItem("Stillgelegt", ApiFilterOperator.Equal, false, "", "And");

      this.http.post('/artikel/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then(data => {
        let result: Artikel[] = [];
        if (data) {
          for (var item of data as Array<Artikel>) {
            let obj: Artikel = new Artikel();
            //obj = JSON.parse(JSON.stringify(item));
            $.extend(true, obj, item);
            result.push(obj);
          }
        }
        if (callback) callback(result);
      }).catch(data => {
        console.error("Error @ getArtikelBySuchbegriff", data);
        if (callback) callback(null);
        // todo: ErrorHandling...
      });
    }
  }

  public getArtikelByCode(code: any, callback: (result: Artikel) => any): void {
    if (code != null) {
      this.http.get('/Artikel/' + code, { headers: { 'loadComplexity': '3' } }).toPromise().then(data => {
        let result: Artikel;
        if (data) {
          result = <Artikel>data;
        }
        if (callback) callback(result);
      }).catch(data => {
        console.error("Error @ getArtikelByCode", data);
        if (callback) callback(null);
        // todo: ErrorHandling...
      });
    }
  }

  public async getArtikelByCodeAsync(code: any): Promise<any> {
    if (code != null) {
      return await this.http.get('/Artikel/' + code, { headers: { 'loadComplexity': '3' } }).toPromise();
    }
    return null
  }


  public async getArtikelByCodesAsync(codes: number[]): Promise<any> {

    if (codes != null && codes.length > 0) {
      let filter = new ApiFilter;
      filter.AddFilterItem("Code", ApiFilterOperator.InValueList, codes)
      return await this.http.post('/Artikel/query', filter, { headers: { 'loadComplexity': '1' } }).toPromise();
    }
    return null
  }

  public getArtikelByNummer(nummer: string, callback: (result: Artikel) => any): void {
    let filter = new ApiFilter();
    filter.Max = 1;
    filter.SortOrderFieldName = "Name"
    filter.SortOrder = ApiSortOrderStyle.Ascending

    if (nummer != null) {

      filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, nummer, "", "Or");

      this.http.post('/Artikel/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
        let result: Artikel;
        if (data) {
          result = <Artikel>data[0];
        }
        if (callback) callback(result);
      }).catch(data => {
        console.error("Error @ getArtikelByNummer", data);
        if (callback) callback(null);
        // todo: ErrorHandling...
      });
    }
  }


  public getArtikelBildByCode(code): any {
    return new ArtikelbildPipe(this.config).transform(code);
  }

  public getArtikelStandardBildByCode(code): any {
    return new ArtikelStandardBildPipe(this.config).transform(code);
  }

  public getLagerorte(callback: (result: Lagerort[]) => any): void {
    this.http.get('/lager/lagerorte').toPromise().then(data => {
      let result: Lagerort[] = [];
      if (data) {
        for (var item of data as Array<Lagerort>) {
          let obj: Lagerort = new Lagerort();
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, item);
          result.push(obj);
        }
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getLagerorte", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  public getBestaende(artikelCodes: number[], callback: (result: any[]) => any, error: (result: any[]) => any): void {
    if (artikelCodes.length > 0) {
      let artikelCodeString: string = "code=" + artikelCodes.join("&code=")
      this.http.get('/lager/bestand/artikellagerbestand?' + artikelCodeString).toPromise().then(data => {
        let result: any = data
        if (callback) callback(result);
      }).catch(data => {
        console.error("Error @ getBestaende", data);
        if (error) error(null);
        // todo: ErrorHandling...
      });
    }
    else {
      callback([])
    }

  }

  public async getBestaendeAsync(artikelCodes: number[]): Promise<any> {
    if (artikelCodes.length > 0) {
      let artikelCodeString: string = "code=" + artikelCodes.join("&code=")
      return await this.http.get('/lager/bestand/artikellagerbestand?' + artikelCodeString).toPromise()
    }
    else {
      return []
    }

  }


  public getLagerbuchungen(artikelCode: number, callback: (result: any[]) => any): void {
    this.http.get('/lager/buchungen?code=' + artikelCode).toPromise().then(data => {
      let result: Buchung[] = [];
      if (data) {
        for (var itemOuter of data as Array<any>) {
          for (var itemInner of itemOuter as Array<Buchung>) {
            let obj: Buchung = new Buchung();
            //obj = JSON.parse(JSON.stringify(item));
            $.extend(true, obj, itemInner);
            result.push(obj);
          }
        }
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getLagerbuchungen", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }


  public getArtikellagerbestand(artikelCode: number, callback: (result: any) => any): void {
    this.http.get('/lager/bestand/artikellagerbestand?code=' + artikelCode).toPromise().then(data => {
      let result: any = [];
      if (data) {
        result = data;
        /*for (var itemOuter of data as Array<any>) {
            for (var itemInner of itemOuter as Array<any>) {
                result.push(itemInner);
            }
        }*/
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getArtikellagerbestand", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }


  public getShopdefinitions(callback: (result: ShopDefinition[]) => any): void {
    this.http.get('/shop/shopdefinition/list').toPromise().then(data => {
      let result: ShopDefinition[] = [];
      if (data) {
        for (var item of data as Array<any>) {
          let obj: ShopDefinition = new ShopDefinition("");
          //obj = JSON.parse(JSON.stringify(item));
          $.extend(true, obj, JSON.parse(item.content))
          obj.id = item.id
          result.push(obj);
        }
      }
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getShopdefinitions", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }


  public getShopdefinitionById(id: string = null, callback: (result: ShopDefinition) => any): void {
    let request = ""
    if (id)
      request = "?id=" + id
    this.http.get('/shop/shopdefinition' + request).toPromise().then(data => {
      let result = <ShopDefinition>data;
      if (callback) callback(result);
    }).catch(data => {
      console.error("Error @ getShopdefinitionById", data);
      if (callback) callback(null);
      // todo: ErrorHandling...
    });

  }

  getFullBenutzer(benutzerCode, success: (result: any) => any, error: (result: any) => any) {
    this.http.get(`/work4all/benutzer/full/${benutzerCode}`).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ getFullBenutzer", data);
      if (error) error(data);
      // todo: ErrorHandling...
    });
  }


  public getBriefanschrift(sdObjType: SdObjType, sdObjMemberCode: number, ansprechpartnerCode: number, mitLand: boolean, abteilungCode: number = 0, anPostfach: boolean = false, anPrivat: boolean = null): Promise<string> {
    let promise = new Promise<string>((success, fail) => {
      var url = `/${(sdObjType == SdObjType.Kunde ? 'Kunde' : 'Lieferant')}/briefanschrift/${sdObjMemberCode}?ansprechpartnerCode=${ansprechpartnerCode}&anPostfach=${anPostfach}&anPrivat=${anPrivat}&mitLand=${mitLand}&abteilungCode=${abteilungCode}`
      this.http.get(url).toPromise().then((adresse: string) => {
        success(adresse);
      }).catch((error: Error) => {
        fail(error);
      })
    });
    return promise;
  }

  public getFreigabeStatus(objekte: any[], success: (result: FreigabeStatus[]) => any, error: (result) => any): void {
    if (objekte.length > 0) {
      this.http.post('/erp/freigaben', objekte, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
        let result: FreigabeStatus[] = [];
        if ($.isArray(data)) {
          for (var item of data as Array<FreigabeStatus>) {
            result.push(item);
          }
        }
        if (success) success(result);
      }).catch(data => {
        console.error("Error @ getFreigabeStatus", data);
        if (error) error(null);
        // todo: ErrorHandling...
      });
    }
    else {
      success([])
    }
  }

  public getDokumenteByProjektCodes(projektCodes: number[], success: (result: Dokument[]) => any, error: (result) => any): void {

    let filter = new ApiFilter();
    filter.Max = 100;

    projektCodes.forEach(projektCode => {
      filter.AddFilterItem("ProjektCode", ApiFilterOperator.Equal, projektCode, "", "");
    });

    this.http.post('/Dokument/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Dokument[] = [];
      if ($.isArray(data)) {
        for (var item of data as Array<Dokument>) {
          result.push(item);
        }
      }
      if (success) success(result);
    }).catch(data => {
      console.error("Error @ getDokumenteByProjektCodes", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });
  }

  public getEinkaufspreiseByArtikelCodes(artikelCodes: number[], success: (result: Einkaufspreis[]) => any, error: (result) => any): void {

    let filter = new ApiFilter();
    filter.Max = 100;

    artikelCodes.forEach(artikelCode => {
      filter.AddFilterItem("ArtikelCode", ApiFilterOperator.Equal, artikelCode, "", "Or");
    });


    this.http.post('/Einkaufspreis/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      let result: Einkaufspreis[] = [];
      if ($.isArray(data)) {
        for (var item of data as Array<Einkaufspreis>) {
          result.push(item);
        }
      }
      if (success) success(result);
    }).catch(data => {
      console.error("Error @ getEinkaufspreiseByArtikelCodes", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });
  }


  public async getFileDownloadInfo(type, codeOrId: string): Promise<Blob> {

    let result = null;

    if (codeOrId) {
      let mandantenNummer = this.config.mandantenNummer;
      let token = localStorage.getItem("AuthToken");
      let url = `/File/download`;

      let headers = new HttpHeaders();
      headers.append('Access-Control-Allow-Headers', 'Content-Disposition');
      result = await this.http.get(url, { headers: headers, params: { type: type, codeOrId: codeOrId }, responseType: "blob", observe: "response" }).toPromise();
      return result['body']
    }

    return result;
  }

  public async downloadFile(type: string, codeOrId: any, saveAsFilename: string): Promise<any> {
    var blob: Blob = await this.getFileDownloadInfo(type, codeOrId)
    this.downloadBlobwithFilename(saveAsFilename, blob);

  }

  downloadBlobwithFilename(filename: string, blob: Blob) {
    var anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = window.URL.createObjectURL(blob);
    anchor.dataset.downloadurl = [blob.type, anchor.download, anchor.href].join(':');
    anchor.click();
  }

  // downloadFile(type: string, codeOrId: any, success: (result) => any, error: (result) => any) {
  //     this.http.get(`/File/download?type=${type}&codeOrId=${codeOrId}`).toPromise().then(data => {
  //         if (success) success(data);
  //     }).catch(data => {
  //         console.error("Error @ downloadFile", data);
  //         if (error) error(null);
  //         todo: ErrorHandling...
  //     });
  // }

  invalidateCache(typeName: string, success: (result) => any, error: (result) => any) {
    this.http.get(`/cacheservice/invalidate?typeName=${typeName}`).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ invalidateCache", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });
  }

  public saveAnhangPermanently(anhaenge, success: (result: any) => any, error: (result) => any): void {

    let anhang: Anhaenge;
    let tmpCode = anhaenge.code;
    anhaenge.code = 0;
    anhang = anhaenge;

    this.http.post(`/erp/anhang?tempFileId=${tmpCode}`, anhang, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
      console.log(data)
      let result: Anhaenge = new Anhaenge();
      result.code = Number(data);
      if (success) success(result);
    }).catch(data => {
      console.error("Error @ saveAnhangPermanently", data);
      if (error) error(null);
    });
  }

  public deleteAnhang(id) {
    return (this.http.delete(`/erp/anhang/${id}`).toPromise());
  }

  public resetPassword(newPasswortHash: string | Int32Array, resetToken: string, success: (result: boolean) => any, error: (result) => any): void {
    let pwData = {
      newPasswortHash: newPasswortHash,
      resetToken: resetToken
    };
    this.http.post('/Auth/resetpassword', pwData).toPromise().then(data => {
      if (success) success(<boolean>data);
    }).catch(data => {
      console.error("Error @ resetPassword", data);
      if (error) error(null);
      // todo: ErrorHandling...
    });
  }

  setFavouriteEvent(object: any, success: (result: any) => any, error: (result: any) => any) {
    var favouriteObject = "'" + JSON.stringify(object.favouriteObject) + "'";
    return this.http.put(`/vault/shared/${object.name}`, favouriteObject, { headers: { 'loadComplexity': '2', 'Content-Type': 'application/json' } }).toPromise().then(data => {
      if (success) success(data);
    }).catch(data => {
      console.error("Error @ setFavouriteEvent", data);
      if (error) error(data);
    });
  }

  getFavouriteEvent(query: any) {
    return this.http.get(`/vault/shared/${query}`).toPromise();
  }

  deleteFavouriteEvent(object: any) {
    return this.http.delete(`/vault/shared/${object.name}`).toPromise();
  }

  async getRequiredGroups() {
    return this.http.get('/shop/pflichtartikelgruppen').toPromise();
  }

  async getEinheiten(): Promise<Einheit[]> {
    return <Einheit[]>await this.http.post(`/Einheit/query`, {}).toPromise();
  }

}

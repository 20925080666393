import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { AuthService, ArtikelbildPipe, Seriennummer, Eingangslieferschein, Lieferschein, Config, ApiFilter, ApiFilterOperator, ApiSortOrderStyle, ObjectType, Kunde, Projekt, Lieferant, Artikel, Position, Buchung, Lagerort, Auftrag, Kalkulation, Bestellung, Bedarf, Angebot, SdObjType, ArtikelStandardBildPipe, SendMailRequest, CreateReportRequest, ReportPrintOptions, Report, FreigabeStatus, Dokument, Einkaufspreis, Infofenster, InfofensterDataItem, Anhaenge, Einheit, ProjektGruppe, Ansprechpartner } from '@work4all/api';
//import { ArtikelbildPipe } from '../pipes/artikelbild.pipe';
import { BehaviorSubject } from 'rxjs';
import { ShopDefinition } from '../classes/shop-definition';
import * as moment from 'moment';
import { List } from 'linqts';
import { ExternalLoginRechte } from '../classes/external-login-rechte';
import { ManagedExternalLogin } from '../classes/managed-external-login';
export class ApiService {
    constructor(http, route, authService, router, config) {
        this.http = http;
        this.route = route;
        this.authService = authService;
        this.router = router;
        this.config = config;
        this.lieferscheinList = new BehaviorSubject(null);
        this.LieferscheinList = this.lieferscheinList.asObservable();
        this.eingangslieferscheinList = new BehaviorSubject(null);
        this.EingangslieferscheinList = this.eingangslieferscheinList.asObservable();
        this.loginMode = new BehaviorSubject(0);
        this.LoginMode = this.loginMode.asObservable();
        this.loginLevel = new BehaviorSubject(0);
        this.LoginLevel = this.loginLevel.asObservable();
        this.shopMaster = new BehaviorSubject(false);
        this.ShopMaster = this.shopMaster.asObservable();
        this.shopRechte = new ExternalLoginRechte();
        this.loginId = null;
        this.loggedUser = null;
        this.setLoginLevel();
    }
    setLoginLevel() {
        this.loginLevel.next(JSON.parse(localStorage.getItem("loginLevel")));
        this.shopMaster.next(JSON.parse(localStorage.getItem("shopMaster")));
    }
    setLoginMode(value) {
        this.loginMode.next(value);
    }
    getObjectTypeString(objType, plural = false) {
        let result = "";
        if (!plural)
            switch (objType) {
                case ObjectType.Angebot:
                    result = "Angebot";
                    break;
                case ObjectType.Aufgabe:
                    result = "Aufgabe";
                    break;
                case ObjectType.Auftrag:
                    result = "Auftrag";
                    break;
                case ObjectType.Bedarfsanforderung:
                    result = "Bedarfsanforderung";
                    break;
                case ObjectType.Bestellung:
                    result = "Bestellung";
                    break;
                case ObjectType.Besuchsbericht:
                    result = "Besuchsbericht";
                    break;
                case ObjectType.Brief:
                    result = "Brief";
                    break;
                case ObjectType.Checklisten:
                    result = "Checkliste";
                    break;
                case ObjectType.Dokument:
                    result = "Dokument";
                    break;
                case ObjectType.Eingangslieferschein:
                    result = "Eingangslieferschein";
                    break;
                case ObjectType.EMail:
                    result = "E-Mail";
                    break;
                case ObjectType.Gutschriften:
                    result = "Gutschrift";
                    break;
                case ObjectType.Kalkulation:
                    result = "Kalkulation";
                    break;
                case ObjectType.Kassenbeleg:
                    result = "Kassenbeleg";
                    break;
                case ObjectType.Kunde:
                    result = "Kunde";
                    break;
                case ObjectType.Lieferant:
                    result = "Lieferant";
                    break;
                case ObjectType.LieferantenBewertung:
                    result = "Lieferantenbewertung";
                    break;
                case ObjectType.Lieferschein:
                    result = "Lieferschein";
                    break;
                case ObjectType.Notiz:
                    result = "Notiz";
                    break;
                case ObjectType.ObjectLock:
                    result = "ObjectLock";
                    break;
                case ObjectType.Produktionsauftrag:
                    result = "Produktionsauftrag";
                    break;
                case ObjectType.ProduktionsSchritt:
                    result = "Produktionsschritt";
                    break;
                case ObjectType.ProformaKostenbeleg:
                    result = "Proforma-Kostenbeleg";
                    break;
                case ObjectType.Projekt:
                    result = "Projekt";
                    break;
                case ObjectType.Rechnung:
                    result = "Rechnung";
                    break;
                case ObjectType.Rechnungseingaenge:
                    result = "Rechnungseingang";
                    break;
                case ObjectType.Reisekostenbeleg:
                    result = "Reisekostenbeleg";
                    break;
                case ObjectType.Telefonat:
                    result = "Telefonat";
                    break;
                case ObjectType.Termin:
                    result = "Termin";
                    break;
                case ObjectType.Ticket:
                    result = "Ticket";
                    break;
                case ObjectType.Verkaufschancen:
                    result = "Verkaufschance";
                    break;
                case ObjectType.Wartungsobjekt:
                    result = "Wartungsobjekt";
                    break;
                default: break;
            }
        if (plural)
            switch (objType) {
                case ObjectType.Angebot:
                    result = "Angebote";
                    break;
                case ObjectType.Aufgabe:
                    result = "Aufgaben";
                    break;
                case ObjectType.Auftrag:
                    result = "Aufträge";
                    break;
                case ObjectType.Bedarfsanforderung:
                    result = "Bedarfsanforderungen";
                    break;
                case ObjectType.Bestellung:
                    result = "Bestellungen";
                    break;
                case ObjectType.Besuchsbericht:
                    result = "Besuchsberichte";
                    break;
                case ObjectType.Brief:
                    result = "Briefe";
                    break;
                case ObjectType.Checklisten:
                    result = "Checklisten";
                    break;
                case ObjectType.Dokument:
                    result = "Dokumente";
                    break;
                case ObjectType.Eingangslieferschein:
                    result = "Eingangslieferscheine";
                    break;
                case ObjectType.EMail:
                    result = "E-Mails";
                    break;
                case ObjectType.Gutschriften:
                    result = "Gutschriften";
                    break;
                case ObjectType.Kalkulation:
                    result = "Kalkulationen";
                    break;
                case ObjectType.Kassenbeleg:
                    result = "Kassenbelege";
                    break;
                case ObjectType.Kunde:
                    result = "Kunden";
                    break;
                case ObjectType.Lieferant:
                    result = "Lieferanten";
                    break;
                case ObjectType.LieferantenBewertung:
                    result = "Lieferantenbewertungen";
                    break;
                case ObjectType.Lieferschein:
                    result = "Lieferscheine";
                    break;
                case ObjectType.Notiz:
                    result = "Notizen";
                    break;
                case ObjectType.ObjectLock:
                    result = "ObjectLocks";
                    break;
                case ObjectType.Produktionsauftrag:
                    result = "Produktionsaufträge";
                    break;
                case ObjectType.ProduktionsSchritt:
                    result = "Produktionsschritte";
                    break;
                case ObjectType.ProformaKostenbeleg:
                    result = "Proforma-Kostenbelege";
                    break;
                case ObjectType.Projekt:
                    result = "Projekte";
                    break;
                case ObjectType.Rechnung:
                    result = "Rechnungen";
                    break;
                case ObjectType.Rechnungseingaenge:
                    result = "Rechnungseingänge";
                    break;
                case ObjectType.Reisekostenbeleg:
                    result = "Reisekostenbelege";
                    break;
                case ObjectType.Telefonat:
                    result = "Telefonate";
                    break;
                case ObjectType.Termin:
                    result = "Termine";
                    break;
                case ObjectType.Ticket:
                    result = "Tickets";
                    break;
                case ObjectType.Verkaufschancen:
                    result = "Verkaufschancen";
                    break;
                case ObjectType.Wartungsobjekt:
                    result = "Wartungsobjekte";
                    break;
                default: break;
            }
        return result;
    }
    getVerfuegbarkeiten(artikelCodes, dateStart, dateEnd, objectType, success, error) {
        let url = `/erp/dispositiontimeline/minimal?auftragsArten=${objectType}`;
        artikelCodes.forEach((artikelcode) => {
            url += `&artikelcodes=${artikelcode}`;
        });
        if (dateStart == dateEnd) {
            dateEnd = moment(dateEnd).set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).add("days", 1).toDate();
        }
        url += `&von=${moment(dateStart).toISOString()}`;
        url += `&bis=${moment(dateEnd).toISOString()}`;
        this.http.get(url, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
            let result = [];
            if (data && $.isArray(data)) {
                data.forEach(element => {
                    result.push(element);
                });
            }
            else {
                result = [];
            }
            success(data);
        }).catch((data) => {
            console.error("Error @ getVerfuegbarkeiten", data);
            error(data);
        });
    }
    getVerfuegbarkeitenAsync(artikelCodes, dateStart, dateEnd, objectType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (artikelCodes.length == 0)
                return [];
            let url = `/erp/dispositiontimeline/minimal?auftragsArten=${objectType}`;
            artikelCodes.forEach((artikelcode) => {
                url += `&artikelcodes=${artikelcode}`;
            });
            url += `&von=${moment(dateStart).toISOString()}`;
            url += `&bis=${moment(dateEnd).toISOString()}`;
            return yield this.http.get(url, { headers: { 'loadComplexity': '3' } }).toPromise();
        });
    }
    getIndividuelleFelder(type, success, error) {
        this.http.get('/IndividuelleFelder?tableType=' + type, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
            let result = [];
            if (data && $.isArray(data)) {
                data.forEach(element => {
                    result.push(element);
                });
            }
            else {
                result = [];
            }
            success(data);
        }).catch((data) => {
            console.error("Error @ getIndividuelleFelder", data);
            error(data);
        });
    }
    getBzObjTypeString(bzObjType) {
        let result = "";
        switch (bzObjType) {
            case ObjectType.Auftrag:
                result = "Auftrag";
                break;
            case ObjectType.Angebot:
                result = "Angebot";
                break;
            case ObjectType.Kalkulation:
                result = "Kalkulation";
                break;
            case ObjectType.Bedarfsanforderung:
                result = "Bedarfsanforderung";
                break;
            case ObjectType.Bestellung:
                result = "Bestellung";
                break;
            default: break;
        }
        return result;
    }
    getReports(callback) {
        this.http.get('/Report/list?reportType=Rechnung').toPromise().then(data => {
            let reports = [];
            if (data) {
                for (var item of data) {
                    let obj = new Report();
                    jQuery.extend(true, obj, item);
                    reports.push(obj);
                }
            }
            if (callback)
                callback(reports);
        }).catch(data => {
            console.error("Error @ getReports", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getInfofensterDefinitionen(callback) {
        this.http.get('/Infofenster/definitionen').toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Infofenster();
                    jQuery.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getInfofensterDefinitionen", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getInfofensterItems(infofensterCode, sdObjMemberCode, callback) {
        this.http.get(`/Infofenster/items?definitionCode=${infofensterCode}&sdObjMemberCode=${sdObjMemberCode}`).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new InfofensterDataItem();
                    jQuery.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getInfofensterItems", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createReport(bzObjType, bzObjCode, reportCode, callback, errorCallback) {
        let request = new CreateReportRequest();
        request.BzObjType = this.getBzObjTypeString(bzObjType);
        request.ReportSource.Code = reportCode;
        request.ObjCode = bzObjCode;
        request.ExtendedRecordSelektionFormular = "";
        request.FormularCollection = [];
        request.PrintReport = new ReportPrintOptions();
        request.PrintReport.PrinterName = "";
        //console.log("request", request);
        this.http.post('/SoapApiProxy/createreport', request).toPromise()
            .then(data => {
            if (callback)
                callback(data);
        }).catch(data => {
            console.error("Error @ createReport", data);
            if (errorCallback)
                errorCallback(data);
        });
    }
    sendMail(mailRequest, success, error) {
        this.http.post('/Mail/send', mailRequest, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
            success(data);
        }).catch((data) => {
            error(data);
            console.error("Error @ getAuftraegeByProjektCode", data);
        });
    }
    getAuftraegeByProjektCode(projektCode, success) {
        let filter = new ApiFilter();
        filter.Max = 200;
        filter.AddFilterItem("ProjektCode", ApiFilterOperator.Equal, projektCode);
        filter.SortOrder = ApiSortOrderStyle.Descending;
        filter.SortOrderFieldName = "AuftragsDatum";
        this.http.post('/Auftrag/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
            let result = [];
            if (data && $.isArray(data)) {
                data.forEach(element => {
                    result.push(element);
                });
            }
            else {
                result = [];
            }
            success(result);
        }).catch((data) => {
            console.error("Error @ getAuftraegeByProjektCode", data);
        });
    }
    getAuftraegeByDispoZeitraum(start, ende, success) {
        let filter = new ApiFilter();
        filter.Max = 200;
        filter.AddFilterItem("Dispositionsbeginn", ApiFilterOperator.SmallerOrEqual, ende);
        filter.AddFilterItem("Dispositionsende", ApiFilterOperator.GreaterOrEqual, start);
        filter.SortOrder = ApiSortOrderStyle.Descending;
        filter.SortOrderFieldName = "AuftragsDatum";
        this.http.post('/Auftrag/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
            let result = [];
            if (data && $.isArray(data)) {
                data.forEach(element => {
                    result.push(element);
                });
            }
            else {
                result = [];
            }
            success(result);
        }).catch((data) => {
            console.error("Error @ getAuftraegeByDispoZeitraum", data);
        });
    }
    getAngeboteByBenutzerCode(benutzerCode, shopDefinitionId, success) {
        let filter = new ApiFilter();
        filter.Max = 200;
        if (benutzerCode) {
            filter.AddFilterItem("BenutzerCode", ApiFilterOperator.Equal, benutzerCode);
        }
        if (this.loginId && !this.shopRechte.bestellungenEinsehen) {
            filter.AddFilterItem("CreatedByLoginId", ApiFilterOperator.Equal, this.loginId);
        }
        filter.AddFilterItem("WebShopOrderDefinitionData", ApiFilterOperator.Equal, shopDefinitionId);
        filter.SortOrder = ApiSortOrderStyle.Descending;
        filter.SortOrderFieldName = "Datum";
        this.http.post('/Angebot/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
            let result = [];
            if (data && $.isArray(data)) {
                data.forEach(element => {
                    result.push(element);
                });
            }
            else {
                result = [];
            }
            success(result);
        }).catch((data) => {
            console.error("Error @ getAngeboteByBenutzerCode", data);
        });
    }
    getAuftraegeByBenutzerCode(benutzerCode, shopDefinitionId, success) {
        let filter = new ApiFilter();
        filter.Max = 200;
        if (benutzerCode != null) {
            filter.AddFilterItem("BenutzerCode", ApiFilterOperator.Equal, benutzerCode);
        }
        if (this.loginId != null && !this.shopRechte.bestellungenEinsehen) {
            filter.AddFilterItem("CreatedByLoginId", ApiFilterOperator.Equal, this.loginId);
        }
        filter.AddFilterItem("WebShopOrderDefinitionData", ApiFilterOperator.Equal, shopDefinitionId);
        filter.SortOrder = ApiSortOrderStyle.Descending;
        filter.SortOrderFieldName = "AuftragsDatum";
        this.http.post('/Auftrag/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
            let result = [];
            if (data && $.isArray(data)) {
                data.forEach(element => {
                    result.push(element);
                });
            }
            else {
                result = [];
            }
            success(result);
        }).catch((data) => {
            console.error("Error @ getAuftraegeByBenutzerCode", data);
        });
    }
    getBedarfsanforderungenByBenutzerCode(benutzerCode, shopDefinitionId, success) {
        let filter = new ApiFilter();
        filter.Max = 200;
        if (benutzerCode != null) {
            filter.AddFilterItem("BenutzerCode", ApiFilterOperator.Equal, benutzerCode);
        }
        if (this.loginId != null && !this.shopRechte.bestellungenEinsehen) {
            filter.AddFilterItem("CreatedByLoginId", ApiFilterOperator.Equal, this.loginId);
        }
        filter.AddFilterItem("WebShopOrderDefinitionData", ApiFilterOperator.Equal, shopDefinitionId);
        filter.SortOrder = ApiSortOrderStyle.Descending;
        filter.SortOrderFieldName = "Datum";
        this.http.post('/Bedarfsanforderung/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
            let result = [];
            if (data && $.isArray(data)) {
                data.forEach(element => {
                    result.push(element);
                });
            }
            else {
                result = [];
            }
            success(result);
        }).catch((data) => {
            console.error("Error @ getBedarfsanforderungenByBenutzerCode", data);
        });
    }
    getBestellungenByBenutzerCode(benutzerCode, shopDefinitionId, success) {
        let filter = new ApiFilter();
        filter.Max = 200;
        if (benutzerCode != null) {
            filter.AddFilterItem("BenutzerCode", ApiFilterOperator.Equal, benutzerCode);
        }
        if (this.loginId != null && !this.shopRechte.bestellungenEinsehen) {
            filter.AddFilterItem("CreatedByLoginId", ApiFilterOperator.Equal, this.loginId);
        }
        filter.AddFilterItem("WebShopOrderDefinitionData", ApiFilterOperator.Equal, shopDefinitionId);
        filter.SortOrder = ApiSortOrderStyle.Descending;
        filter.SortOrderFieldName = "Datum";
        this.http.post('/Bestellung/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
            let result = [];
            if (data && $.isArray(data)) {
                data.forEach(element => {
                    result.push(element);
                });
            }
            else {
                result = [];
            }
            success(result);
        }).catch((data) => {
            console.error("Error @ getBestellungenByBenutzerCode", data);
        });
    }
    getKalkulationenByBenutzerCode(benutzerCode, shopDefinitionId, success) {
        let filter = new ApiFilter();
        filter.Max = 200;
        if (benutzerCode != null) {
            filter.AddFilterItem("BenutzerCode", ApiFilterOperator.Equal, benutzerCode);
        }
        if (this.loginId != null && !this.shopRechte.bestellungenEinsehen) {
            filter.AddFilterItem("CreatedByLoginId", ApiFilterOperator.Equal, this.loginId);
        }
        filter.AddFilterItem("WebShopOrderDefinitionData", ApiFilterOperator.Equal, shopDefinitionId);
        filter.SortOrder = ApiSortOrderStyle.Descending;
        filter.SortOrderFieldName = "Datum";
        this.http.post('/Kalkulation/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
            let result = [];
            if (data && $.isArray(data)) {
                data.forEach(element => {
                    result.push(element);
                });
            }
            else {
                result = [];
            }
            success(result);
        }).catch((data) => {
            console.error("Error @ getKalkulationenByBenutzerCode", data);
        });
    }
    getLoggedUser() {
        return this.loggedUser;
    }
    setLoggedUser(fullInfo = false) {
        let result = {};
        if (localStorage.getItem("loggedUser")) {
            result = JSON.parse(localStorage.getItem("loggedUser"));
        }
        if (localStorage.getItem("sdObjMemberCode"))
            result.sdObjMemberCode = localStorage.getItem("sdObjMemberCode");
        if (localStorage.getItem("sdObjType"))
            result.sdObjType = localStorage.getItem("sdObjType");
        if (localStorage.getItem("loginLevel"))
            result.loginLevel = localStorage.getItem("loginLevel");
        if (localStorage.getItem("shopMaster"))
            result.shopMaster = localStorage.getItem("shopMaster");
        if (localStorage.getItem("apCode"))
            result.apCode = localStorage.getItem("apCode");
        if (localStorage.getItem("shopRechte") && localStorage.getItem("shopRechte").length > 0) {
            let rechte = JSON.parse(localStorage.getItem("shopRechte"));
            if (rechte && rechte != undefined) {
                $.extend(true, this.shopRechte, rechte);
            }
        }
        else {
            this.shopRechte = new ExternalLoginRechte();
        }
        if (localStorage.getItem("loginId"))
            this.loginId = localStorage.getItem("loginId");
        this.loggedUser = result;
        if (fullInfo) {
            this.getFullBenutzer(this.loggedUser.code, data => {
                this.loggedUser.fullBenutzerInfo = data;
            }, error => {
            });
        }
    }
    getArtikelGruppen(callback) {
        let url = "/artikel/gruppen";
        this.http.get(url).toPromise().then((gruppen) => {
            if (gruppen) {
                if (callback)
                    callback(gruppen);
            }
            else {
                if (callback)
                    callback(null);
            }
        }).catch((data) => {
            console.error("Error @ getArtikelGruppen", data);
            //return null;
        });
        ;
    }
    refreshObjectLock(objectLocks, callback) {
        let url = "/ObjectLock/refreshlocks";
        this.http.post(url, objectLocks, { headers: { 'loadComplexity': '3' } }).toPromise().then((objectLock) => {
            if (objectLock) {
                if (callback)
                    callback(objectLock);
            }
            else {
                if (callback)
                    callback(null);
            }
        }).catch((data) => {
            console.error("Error @ refreshObjectLock", data);
            //return null;
        });
        ;
    }
    checkObjectLock(bzObject, callback) {
        let url = "/ObjectLock/check";
        if (bzObject.posCode != undefined) {
            // Position
            url += "?objectType=Position";
        }
        else {
            switch (bzObject.bzObjType) {
                case ObjectType.Eingangslieferschein:
                    url += "?objectType=Eingangslieferschein";
                    break;
                case ObjectType.Lieferschein:
                    url += "?objectType=Lieferschein";
                    break;
                default: break;
            }
        }
        url += "&keyValue=" + bzObject.code;
        this.http.get(url, { headers: { 'loadComplexity': '3' } }).toPromise().then((objectLock) => {
            if (objectLock) {
                if (callback)
                    callback(objectLock);
            }
            else {
                if (callback)
                    callback(null);
            }
        }).catch((data) => {
            console.error("Error @ checkObjectLock", data);
            //return null;
        });
        ;
    }
    setObjectLock(bzObject, callback) {
        let url = "/ObjectLock";
        if (bzObject.posCode != undefined) {
            // Position
            url += "?objectType=Position";
        }
        else {
            switch (bzObject.bzObjType) {
                case ObjectType.Eingangslieferschein:
                    url += "?objectType=Eingangslieferschein";
                    break;
                case ObjectType.Lieferschein:
                    url += "?objectType=Lieferschein";
                    break;
                default: break;
            }
        }
        url += "&keyValue=" + bzObject.code;
        this.http.get(url, { headers: { 'loadComplexity': '3' } }).toPromise().then((objectLock) => {
            if (objectLock) {
                if (callback)
                    callback(objectLock);
            }
            else {
                if (callback)
                    callback(null);
            }
        }).catch((data) => {
            console.error("Error @ setObjectLock", data);
            //return null;
        });
        ;
    }
    deleteObjectLock(bzObject, callback) {
        let url = "/ObjectLock";
        if (bzObject.posCode != undefined) {
            url += "?objectType=Position";
        }
        else {
            switch (bzObject.bzObjType) {
                case ObjectType.Eingangslieferschein:
                    url += "?objectType=Eingangslieferschein";
                    break;
                case ObjectType.Lieferschein:
                    url += "?objectType=Lieferschein";
                    break;
                default: break;
            }
        }
        url += "&keyValue=" + bzObject.code;
        this.http.delete(url, { headers: { 'loadComplexity': '3' } }).toPromise().then((objectLock) => {
            if (objectLock) {
                if (callback)
                    callback(objectLock);
            }
            else {
                if (callback)
                    callback(null);
            }
        }).catch((data) => {
            console.error("Error @ deleteObjectLock", data);
            //return null;
        });
        ;
    }
    searchBelegByScan(scannedString, callback) {
        let searchComplete = false;
        if (scannedString.substring(0, 3) == ".el") {
            let scan = scannedString.substring(3);
            this.getBelegByNummer(ObjectType.Eingangslieferschein, scan, (belegList) => {
                if (callback) {
                    callback(belegList);
                }
            });
            searchComplete = true;
        }
        if (scannedString.substring(0, 2) == ".l") {
            let scan = scannedString.substring(2);
            this.getBelegByNummer(ObjectType.Lieferschein, scan, (belegList) => {
                if (callback) {
                    callback(belegList);
                }
            });
            searchComplete = true;
        }
        if (!searchComplete) {
            this.getBelegByNummer(ObjectType.Eingangslieferschein, scannedString, (belegList) => {
                if (callback) {
                    if (belegList.length > 0) {
                        callback(belegList);
                    }
                    else {
                        this.getBelegByNummer(ObjectType.Lieferschein, scannedString, (belegList) => {
                            if (belegList) {
                                callback(belegList);
                            }
                        });
                    }
                }
            });
        }
    }
    getBelegByCode(objectType, code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let filter = new ApiFilter();
            filter.Max = 1;
            //filter.AddFilterItem("HauptadresseCompanyType", ApiFilterOperator.Equal, sdObjType);
            filter.AddFilterItem("Code", ApiFilterOperator.Equal, code);
            filter.SortOrder = ApiSortOrderStyle.Descending;
            //filter.SortOrderFieldName = "Datum";
            let url = "";
            switch (objectType) {
                case ObjectType.Bedarfsanforderung:
                    url = '/Bedarfsanforderung/query';
                    break;
                case ObjectType.Auftrag:
                    url = '/Auftrag/query';
                    break;
                case ObjectType.Angebot:
                    url = '/Angebot/query';
                    break;
                case ObjectType.Kalkulation:
                    url = '/Kalkulation/query';
                    break;
                case ObjectType.Bestellung:
                    url = '/Bestellung/query';
                    break;
                default: break;
            }
            let result = yield this.http.post(url, filter, { headers: { 'loadComplexity': '3' } }).toPromise();
            if (result[0] != undefined) {
                return result[0];
            }
            else {
                return null;
            }
        });
    }
    saveBeleg(beleg, callback, onError) {
        let url;
        switch (beleg.bzObjType) {
            case ObjectType.Eingangslieferschein:
                url = `/Eingangslieferschein`;
                break;
            case ObjectType.Lieferschein:
                url = `/Lieferschein`;
                break;
            default: break;
        }
        if (beleg.code == 0) {
            beleg.positionen.forEach((element, index) => {
                beleg.positionen[index].code = 0;
                //beleg.positionen[index].bzObjMemberCode = 0;
            });
            this.http.post(url, beleg).toPromise().then((data) => {
                if (callback)
                    callback(data);
            }).catch((error) => {
                console.error("Error @ saveBeleg", error);
                if (onError)
                    onError(error);
            });
        }
        else {
            this.http.put(url, beleg).toPromise().then((data) => {
                if (callback)
                    callback(data);
            }).catch((error) => {
                console.error("Error @ saveBeleg", error);
                if (onError)
                    onError(error);
            });
        }
    }
    getBelegByNummer(objectType, nummerString, callback) {
        let filter = new ApiFilter();
        filter.Max = 1;
        filter.AddFilterItem("HauptadresseCompanyType", ApiFilterOperator.Equal, 'Kunde');
        filter.SortOrder = ApiSortOrderStyle.Descending;
        filter.SortOrderFieldName = "Datum";
        let nummer = parseInt(nummerString);
        let url = "";
        switch (objectType) {
            case ObjectType.Eingangslieferschein:
                url = '/Eingangslieferschein/query';
                filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, nummer);
                break;
            case ObjectType.Lieferschein:
                url = '/Lieferschein/query';
                filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, nummer);
                break;
            case ObjectType.Auftrag:
                url = '/Auftrag/query';
                filter.AddFilterItem("AuftragsNummer", ApiFilterOperator.Equal, nummer);
                break;
            case ObjectType.Projekt:
                url = '/Projekt/query';
                filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, nummer);
                break;
            default: break;
        }
        this.http.post(url, filter, { headers: { 'loadComplexity': '3' } }).toPromise().then((data) => {
            let result = [];
            if (data && $.isArray(data)) {
                for (var item of data) {
                    let obj = {};
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
                if (callback)
                    callback(result);
            }
            else {
                if (callback)
                    callback(null);
            }
        }).catch((data) => {
            console.error("Error @ getBelegByNummer", data);
            //return null;
        });
        ;
        //return result[0];
    }
    saveBuchungen(buchungen, callback, onError) {
        this.http.post("/lager/einbuchung", buchungen).toPromise().then((data) => {
            if (callback)
                callback(data);
        }).catch((error) => {
            console.error("Error @ saveBuchungen", error);
            if (onError)
                onError(error);
        });
    }
    checkUsername(username) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/ExternalLogin/managend/loginnamevalid?loginName=" + username).toPromise();
        });
    }
    getLicenseStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/externallogin/managend/status").toPromise();
        });
    }
    getBuchungenByBzObjAndPosition(bzObject, positionCode, callback) {
        var type = "";
        switch (bzObject.bzObjType) {
            case ObjectType.Eingangslieferschein:
                type = "Eingangslieferschein";
                break;
            case ObjectType.Lieferschein:
                type = "Lieferschein";
                break;
            default: break;
        }
        this.http.get("/lager/buchungen/" + type + "/" + bzObject.code + "?positionCode=" + positionCode, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Buchung();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getBuchungenByBzObjAndPosition", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getSeriennummerInfo(seriennummerCode, callback) {
        this.http.get("/lager/seriennummerinfo?code=" + seriennummerCode, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = new Seriennummer();
            if (data) {
                //obj = JSON.parse(JSON.stringify(item));
                $.extend(true, result, data);
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getSeriennummerInfo", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getSeriennummerInfoBySeriennummerAndArtikelCode(seriennummerCode, artikelCode, callback) {
        this.http.get("/lager/seriennummerinfo/" + seriennummerCode + "/" + artikelCode, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = new Seriennummer();
            if (data) {
                //obj = JSON.parse(JSON.stringify(item));
                $.extend(true, result, data);
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getSeriennummerInfoBySeriennummerAndArtikelCode", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    saveBelegEntwurf(beleg) {
        //console.log("saving Beleg Entwurf")
        if (beleg.bzObjType == ObjectType.Eingangslieferschein)
            localStorage.setItem("unsavedEingangslieferschein", JSON.stringify(beleg));
        if (beleg.bzObjType == ObjectType.Lieferschein)
            localStorage.setItem("unsavedLieferschein", JSON.stringify(beleg));
    }
    createLieferschein(companyCode, companyType, callback) {
        this.http.get("/Lieferschein/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = new Lieferschein();
            if (data) {
                $.extend(true, result, data);
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ createLieferschein", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createLieferscheinAsync(companyCode, companyType) {
        let data;
        this.http.get("/Lieferschein/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = new Lieferschein();
            if (data) {
                $.extend(true, result, data);
            }
            data = result;
        }).catch(data => {
            console.error("Error @ createLieferscheinAsync", data);
            data = null;
            // todo: ErrorHandling...
        });
        return data;
    }
    createLieferscheinPosition(beleg, positionsArt, artikelCode, anzahl, callback) {
        beleg = this.bereinigeBeleg(beleg);
        this.http.post("/Lieferschein/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Position();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result[0]);
        }).catch(data => {
            console.error("Error @ createLieferscheinPosition", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createBelegPosition(beleg, positionsArt, artikelCode, anzahl, callback) {
        switch (beleg.bzObjType) {
            case ObjectType.Auftrag:
                this.createAuftragPosition(beleg, positionsArt, artikelCode, anzahl, callback);
                break;
            case ObjectType.Angebot:
                this.createAngebotPosition(beleg, positionsArt, artikelCode, anzahl, callback);
                break;
            case ObjectType.Kalkulation:
                this.createKalkulationPosition(beleg, positionsArt, artikelCode, anzahl, callback);
                break;
            case ObjectType.Bestellung:
                this.createBestellungPosition(beleg, positionsArt, artikelCode, anzahl, callback);
                break;
            case ObjectType.Bedarfsanforderung:
                this.createBedarfPosition(beleg, positionsArt, artikelCode, anzahl, callback);
                break;
            default: break;
        }
    }
    createAuftragPosition(beleg, positionsArt, artikelCode, anzahl, callback) {
        beleg = this.bereinigeBeleg(beleg);
        this.http.post("/Auftrag/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Position();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result[0]);
        }).catch(data => {
            console.error("Error @ createAuftragPosition", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createAngebotPosition(beleg, positionsArt, artikelCode, anzahl, callback) {
        beleg = this.bereinigeBeleg(beleg);
        this.http.post("/Angebot/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Position();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result[0]);
        }).catch(data => {
            console.error("Error @ createAngebotPosition", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createAuftrag(companyCode, companyType, callback) {
        this.http.get("/Auftrag/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = new Auftrag();
            if (data) {
                $.extend(true, result, data);
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ createAuftrag", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createAngebot(companyCode, companyType, callback) {
        this.http.get("/Angebot/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = new Angebot();
            if (data) {
                $.extend(true, result, data);
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ createAngebot", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createBedarf(companyCode, companyType, callback) {
        this.http.get("/Bedarfsanforderung/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = new Bedarf();
            if (data) {
                $.extend(true, result, data);
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ createBedarf", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createBestellung(companyCode, companyType, callback) {
        this.http.get("/Bestellung/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = new Bestellung();
            if (data) {
                $.extend(true, result, data);
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ createBestellung", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createKalkulation(companyCode, companyType, callback) {
        this.http.get("/Kalkulation/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = new Kalkulation();
            if (data) {
                $.extend(true, result, data);
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ createKalkulation", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createAuftragAsync(companyCode, companyType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/Auftrag/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    createAngebotAsync(companyCode, companyType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/Angebot/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    createBedarfAsync(companyCode, companyType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/Bedarfsanforderung/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    createBestellungAsync(companyCode, companyType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/Bestellung/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    createKalkulationAsync(companyCode, companyType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/Kalkulation/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    createEingangslieferschein(companyCode, companyType, callback) {
        this.http.get("/Eingangslieferschein/create?companyCode=" + companyCode + "&companyType=" + companyType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = new Eingangslieferschein();
            if (data) {
                $.extend(true, result, data);
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ createEingangslieferschein", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createEingangslieferscheinPosition(beleg, positionsArt, artikelCode, anzahl, callback) {
        beleg = this.bereinigeBeleg(beleg);
        this.http.post("/Eingangslieferschein/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Position();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result[0]);
        }).catch(data => {
            console.error("Error @ createEingangslieferscheinPosition", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createBedarfPosition(beleg, positionsArt, artikelCode, anzahl, callback) {
        beleg = this.bereinigeBeleg(beleg);
        this.http.post("/Bedarfsanforderung/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Position();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result[0]);
        }).catch(data => {
            console.error("Error @ createBedarfPosition", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createBestellungPosition(beleg, positionsArt, artikelCode, anzahl, callback) {
        beleg = this.bereinigeBeleg(beleg);
        this.http.post("/Bedarfsanforderung/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Position();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result[0]);
        }).catch(data => {
            console.error("Error @ createBestellungPosition", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createKalkulationPosition(beleg, positionsArt, artikelCode, anzahl, callback) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.post("/Kalkulation/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Position();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result[0]);
        }).catch(data => {
            console.error("Error @ createKalkulationPosition", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    postEingangslieferschein(beleg, callback) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.post('/Eingangslieferschein', beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (callback)
                callback(data);
        }).catch(data => {
            console.error("Error @ postEingangslieferschein", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    updateShopDefinition(id, shopDefinition, success, error) {
        this.http.put("/shop/shopdefinition?beschreibung=&id=" + id, shopDefinition).toPromise().then(() => {
            if (success)
                success(true);
        }).catch(data => {
            console.error("Error @ updateShopDefinition", data);
            if (error)
                error(false);
            // todo: ErrorHandling...
        });
    }
    insertShopDefinition(shopDefinition, success, error) {
        this.http.post("/shop/shopdefinition?beschreibung=", shopDefinition).toPromise().then(() => {
            if (success)
                success(true);
        }).catch(data => {
            console.error("Error @ insertShopDefinition", data);
            if (error)
                error(false);
            // todo: ErrorHandling...
        });
    }
    updateAuftrag(beleg, success, error) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.put("/Auftrag", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ updateAuftrag", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    insertAuftrag(beleg, success, error) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.post("/Auftrag", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ insertAuftrag", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    insertAngebot(beleg, success, error) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.post("/Angebot", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ insertAngebot", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    updateAngebot(beleg, success, error) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.put("/Angebot", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ updateAngebot", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    insertBedarf(beleg, success, error) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.post("/Bedarfsanforderung", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ insertBedarf", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    updateBedarf(beleg, success, error) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.put("/Bedarf", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ updateBedarf", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    insertBestellung(beleg, success, error) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.post("/Bestellung", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ insertBestellung", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    updateBestellung(beleg, success, error) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.put("/Bestellung", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ updateBestellung", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    insertKalkulation(beleg, success, error) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.post("/Kalkulation", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ insertKalkulation", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    updateKalkulation(beleg, success, error) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.put("/Kalkulation", beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ updateKalkulation", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    getKalkulation(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/Kalkulation/" + code, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    getAngebot(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/Angebot/" + code, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    getBestellung(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/Bestellung/" + code, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    getAuftrag(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/Auftrag/" + code, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    getBedarf(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/Bedarf/" + code, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    jsonfy(value) {
        return JSON.parse(JSON.stringify(value));
    }
    bereinigeBeleg(beleg) {
        beleg = this.jsonfy(beleg);
        beleg.positionen.forEach(position => {
            position.artikel = undefined;
        });
        return beleg;
    }
    insertProjekt(projekt, success, error) {
        projekt = this.cleanDates(projekt);
        this.http.post("/Projekt", projekt, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ insertProjekt", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    updateProjekt(projekt, success, error) {
        projekt = this.cleanDates(projekt);
        this.http.put("/Projekt", projekt, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ updateProjekt", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    postLieferschein(beleg, callback) {
        beleg = this.bereinigeBeleg(beleg);
        beleg = this.cleanDates(beleg);
        this.http.post('/Lieferschein', beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (callback)
                callback(data);
        }).catch(data => {
            console.error("Error @ postLieferschein", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    postEinbuchung(buchungen, callback) {
        this.http.post('/lager/einbuchung', buchungen, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (callback)
                callback(data);
        }).catch(data => {
            console.error("Error @ postEinbuchung", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    postAusbuchung(buchungen, callback) {
        this.http.post('/lager/ausbuchung', buchungen, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            if (callback)
                callback(data);
        }).catch(data => {
            console.error("Error @ postAusbuchung", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    createRechnungPosition(beleg, artikelCode, anzahl, positionsArt, callback) {
        beleg = this.bereinigeBeleg(beleg);
        this.http.post("/Rechnung/createPosition?artikelCode=" + artikelCode + "&anzahl=" + anzahl + "&positionsArt=" + positionsArt, beleg, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Position();
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result[0]);
        }).catch(data => {
            console.error("Error @ createRechnungPosition", data);
            if (callback)
                callback(null);
        });
    }
    getKundenBySuchbegriff(suchbegriff, callback) {
        let filter = new ApiFilter();
        filter.Max = 200;
        filter.SortOrderFieldName = "Name";
        if (suchbegriff != null) {
            if (isNaN(suchbegriff))
                filter.AddFilterItem("Name", ApiFilterOperator.Contains, suchbegriff, "", "Or");
            else
                filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");
            this.http.post('/kunde/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
                let result = [];
                if (data) {
                    for (var item of data) {
                        let obj = new Kunde();
                        //obj = JSON.parse(JSON.stringify(item));
                        $.extend(true, obj, item);
                        result.push(obj);
                    }
                }
                if (callback)
                    callback(result);
            }).catch(data => {
                console.error("Error @ getKundenBySuchbegriff", data);
                if (callback)
                    callback(null);
                // todo: ErrorHandling...
            });
        }
    }
    getKundeByCode(code, callback) {
        let filter = new ApiFilter();
        filter.Max = 1;
        filter.AddFilterItem("Code", ApiFilterOperator.Equal, code);
        this.http.post('/kunde/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Kunde();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result[0]);
        }).catch(data => {
            console.error("Error @ getKundeByCode", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getKundenByCodes(codes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let filter = new ApiFilter();
            filter.AddFilterItem("Code", ApiFilterOperator.InValueList, codes);
            return yield this.http.post('/kunde/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    getKundenAnsprechpartnerByCodes(codes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let filter = new ApiFilter();
            filter.AddFilterItem("Code", ApiFilterOperator.InValueList, codes);
            return yield this.http.post('/ansprechpartner/kunde/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    getLieferantenByCodes(codes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let filter = new ApiFilter();
            filter.AddFilterItem("Code", ApiFilterOperator.InValueList, codes);
            return yield this.http.post('/lieferant/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise();
        });
    }
    getKostenstellen(callback) {
        this.http.get('/erp/kostenstellen', { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result;
            if (data) {
                result = data;
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getKostenstellen", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getLookup(lookupType, callback) {
        this.http.get('/Lookup?lookupType=' + lookupType, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result;
            if (data) {
                result = data;
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getLookup", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getLieferantenBySuchbegriff(suchbegriff, callback) {
        let filter = new ApiFilter();
        filter.Max = 200;
        if (suchbegriff != null) {
            if (isNaN(suchbegriff))
                filter.AddFilterItem("Name", ApiFilterOperator.Contains, suchbegriff, "", "Or");
            else
                filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");
            this.http.post('/lieferant/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
                let result = [];
                if (data) {
                    for (var item of data) {
                        let obj = new Lieferant();
                        //obj = JSON.parse(JSON.stringify(item));
                        $.extend(true, obj, item);
                        result.push(obj);
                    }
                }
                if (callback)
                    callback(result);
            }).catch(data => {
                console.error("Error @ getLieferantBySuchbegriff", data);
                if (callback)
                    callback(null);
                // todo: ErrorHandling...
            });
        }
    }
    getLieferantByCode(code, callback) {
        let filter = new ApiFilter();
        filter.Max = 20;
        filter.AddFilterItem("Code", ApiFilterOperator.Equal, code);
        this.http.post('/lieferant/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Lieferant();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result[0]);
        }).catch(data => {
            console.error("Error @ getLieferantByCode", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getDateString(date) {
        let result = moment(date).format('YYYY-MM-DD') + "T00:00:00.000";
        return result;
    }
    cleanDates(obj) {
        //return obj
        let keyNames = [
            "anfangDatum",
            "endeDatum",
            "vonDatum",
            "bisDatum",
            "letzteÄnderung",
            "auftragsDatum",
            "datum"
        ];
        for (let key of keyNames) {
            if (obj[key] != undefined) {
                let value = obj[key];
                obj[key] = this.getDateString(value);
            }
        }
        return obj;
    }
    getProjekteBySuchbegriff(suchbegriff, callback) {
        let filter = new ApiFilter();
        filter.Max = 200;
        if (suchbegriff != null) {
            if (isNaN(suchbegriff)) {
                filter.AddFilterItem("Name", ApiFilterOperator.Contains, suchbegriff, "", "And");
            }
            else {
                //filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");
                filter.AddFilterItem("Code", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");
                filter.AddFilterItem("KundenCode", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");
            }
            //filter.AddFilterItem("AnfangDatum", ApiFilterOperator.NotEqual, new Date(0), "", "And");
            this.http.post('/projekt/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then(data => {
                let result = [];
                if (data) {
                    for (var item of data) {
                        let obj = new Projekt();
                        //obj = JSON.parse(JSON.stringify(item));
                        $.extend(true, obj, item);
                        result.push(this.cleanDates(obj));
                    }
                }
                if (callback)
                    callback(result);
            }).catch(data => {
                console.error("Error @ getProjekteBySuchbegriff", data);
                if (callback)
                    callback(null);
                // todo: ErrorHandling...
            });
        }
    }
    getNiederlassungByCode(code, callback) {
        this.getLookup("Niederlassung", data => {
            data.forEach(element => {
                if (element.code == code) {
                    if (callback)
                        callback(element);
                }
            });
        });
    }
    getKostenstelleByCode(code, callback) {
        this.getKostenstellen(data => {
            data.forEach(element => {
                if (element.code == code) {
                    if (callback)
                        callback(element);
                }
            });
        });
    }
    getProjektByCode(code, callback) {
        let filter = new ApiFilter();
        filter.Max = 1;
        filter.AddFilterItem("Code", ApiFilterOperator.Equal, code);
        this.http.post('/projekt/query', filter, { headers: { 'loadComplexity': '1' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Projekt();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(this.cleanDates(obj));
                }
            }
            if (callback)
                callback(result[0]);
        }).catch(data => {
            console.error("Error @ getProjektByCode", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    listManagedExternalLogins(sdObjMemberCode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = (yield this.http.get('/ExternalLogin/managed?sdObjMemberCode=' + sdObjMemberCode + "&sdObjType=Kunde", { headers: { 'loadComplexity': '1' } }).toPromise());
            return result;
        });
    }
    getManagedExternalLogins() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let externalLogins = (yield this.http.post('/ExternalLogin/query', new ApiFilter(), { headers: { 'loadComplexity': '1' } }).toPromise());
            let kundenCodes = new List(externalLogins).Where(x => x.sdObjMemberCode != 0 && x.sdObjMemberCode != null).Select(x => x.sdObjMemberCode).Distinct().ToArray();
            let kunden = yield this.getKundenByCodes(kundenCodes);
            let apList = yield this.getAnsprechpartnerByKundenCodes(kundenCodes);
            let managedLogins = [];
            for (let code of kundenCodes) {
                if (code)
                    managedLogins.push(...yield this.listManagedExternalLogins(code));
            }
            let result = [];
            for (let ap of apList) {
                let login = new ManagedExternalLogin();
                let loginReference = new List(managedLogins).FirstOrDefault(x => x.ansprechpartnerCode == ap.code);
                if (!loginReference || loginReference == undefined) {
                    login.eMail = ap.eMail;
                    login.name = ap.name;
                    login.vorname = ap.vorname;
                    login.ansprechpartnerCode = ap.code;
                    login.aktiv = false;
                    login.sdObjMemberCode = ap['kundenCode'];
                    let rechte = new ExternalLoginRechte();
                    login.rechte = JSON.stringify(rechte);
                }
                else {
                    $.extend(true, login, loginReference);
                }
                let rechte = new ExternalLoginRechte();
                try {
                    $.extend(true, rechte, JSON.parse(login.rechte));
                }
                catch (e) {
                }
                login.rechteParsed = rechte;
                login.rechtBestellungenBearbeiten = rechte.bestellungenBearbeiten;
                login.rechtBestellungenEinsehen = rechte.bestellungenEinsehen;
                login.rechtGenehmigungErteilen = rechte.genehmigungErteilen;
                login.rechtProjektErstellen = rechte.projektErstellen;
                login.rechtProjektLoeschen = rechte.projektLoeschen;
                login.ansprechpartnerAnzeigename = ap.vorname + " " + ap.name;
                let kunde = new List(kunden).FirstOrDefault(x => x.code == login.sdObjMemberCode);
                if (kunde) {
                    login.kunde = kunde;
                    login.kundeName = kunde.name;
                    login.kundeNummer = kunde.nummer;
                }
                else {
                    login;
                }
                login.ansprechpartner = ap;
                login.ansprechpartnerAnzeigename = ap.vorname + " " + ap.name;
                result.push(login);
            }
            return result;
        });
    }
    postExternalLogin(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            item.rechte = JSON.stringify(item.rechteParsed);
            return yield this.http.post('/ExternalLogin', item, { headers: { 'loadComplexity': '1' } }).toPromise();
        });
    }
    putExternalLogin(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            item.rechte = JSON.stringify(item.rechteParsed);
            return yield this.http.put('/ExternalLogin', item, { headers: { 'loadComplexity': '1' } }).toPromise();
        });
    }
    postManagedExternalLogin(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            item.rechte = JSON.stringify(item.rechteParsed);
            return yield this.http.post('/ExternalLogin/managed?shopLizenz=true', item, { headers: { 'loadComplexity': '1' } }).toPromise();
        });
    }
    putManagedExternalLogin(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            item.rechte = JSON.stringify(item.rechteParsed);
            return yield this.http.put('/ExternalLogin/managed', item, { headers: { 'loadComplexity': '1' } }).toPromise();
        });
    }
    getProjektByCodeAsync(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let filter = new ApiFilter();
            filter.Max = 1;
            filter.AddFilterItem("Code", ApiFilterOperator.Equal, code);
            return this.cleanDates((yield this.http.post('/projekt/query', filter, { headers: { 'loadComplexity': '1' } }).toPromise())[0]);
        });
    }
    getAnsprechpartnerByKundeCode(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get('/ansprechpartner/kunde?kundenCode=' + code, { headers: { 'loadComplexity': '1' } }).toPromise();
        });
    }
    getAnsprechpartnerByKundenCodes(codes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (codes.length == 0) {
                return [];
            }
            let filter = new ApiFilter();
            filter.AddFilterItem("Entlassen", ApiFilterOperator.Equal, false, "", "AND");
            //filter.AddFilterItem("GeschaeftspartnerCode", ApiFilterOperator.InValueList, codes,"","AND");
            filter.AddFilterItem("KundenCode", ApiFilterOperator.InValueList, codes, "", "AND");
            if (codes.length > 1)
                return yield this.http.post('/ansprechpartner/kunde/query', filter, { headers: { 'loadComplexity': '1' } }).toPromise();
            else
                return yield this.http.get('/ansprechpartner/kunde/?kundenCode=' + codes[0], { headers: { 'loadComplexity': '1' } }).toPromise();
        });
    }
    getProjekteByCodes(codes, callback) {
        if (codes.length > 0) {
            let filter = new ApiFilter();
            filter.Max = 200;
            filter.AddFilterItem("Code", ApiFilterOperator.InValueList, codes);
            this.http.post('/projekt/query', filter, { headers: { 'loadComplexity': '1' } }).toPromise().then(data => {
                let result = [];
                if (data) {
                    for (var item of data) {
                        let obj = new Projekt();
                        //obj = JSON.parse(JSON.stringify(item));
                        $.extend(true, obj, item);
                        result.push(this.cleanDates(obj));
                    }
                }
                if (callback)
                    callback(result);
            }).catch(data => {
                console.error("Error @ getProjekteByCodes", data);
                if (callback)
                    callback(null);
                // todo: ErrorHandling...
            });
        }
        else {
            callback([]);
        }
    }
    getProjektVerteiler() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/projekt/verteiler/validlist").toPromise();
        });
    }
    postProjektVerteilerEintrag(eintrag) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post("/projekt/verteiler/list", eintrag).toPromise();
        });
    }
    getProjektKategorien() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/projekt/kategorien/validlist").toPromise();
        });
    }
    postProjektKategorieZuordnung(eintrag) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post("/projekt/kategorien/zuordnung", eintrag).toPromise();
        });
    }
    getProjektGruppen() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.get("/projekt/gruppen").toPromise();
        });
    }
    getProjekteByRange(vonDatum, bisDatum, callback) {
        let filter = new ApiFilter();
        filter.Max = 200;
        filter.SortOrderFieldName = "AnfangDatum";
        //filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, parseInt(suchbegriff), "", "Or");
        //filter.AddFilterItem("BCode", ApiFilterOperator.Equal, code, "", "And");
        if (vonDatum)
            filter.AddFilterItem("EndeDatum", ApiFilterOperator.GreaterOrEqual, vonDatum, "", "");
        if (bisDatum)
            filter.AddFilterItem("AnfangDatum", ApiFilterOperator.SmallerOrEqual, bisDatum, "", "And");
        this.http.post('/projekt/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Projekt();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getProjekteByRange", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    refreshArtikelListByGruppenCodes(codes, callback) {
        let filter = new ApiFilter();
        filter.Max = 500;
        if (codes != null) {
            codes.forEach(code => {
            });
            filter.AddFilterItem("GrCode", ApiFilterOperator.InValueList, codes, "", "");
            filter.AddFilterItem("Stillgelegt", ApiFilterOperator.Equal, false, "", "And");
            this.http.post('/artikel/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then(data => {
                let result = [];
                if (data) {
                    for (var item of data) {
                        let obj = new Artikel();
                        //obj = JSON.parse(JSON.stringify(item));
                        $.extend(true, obj, item);
                        result.push(obj);
                    }
                }
                if (callback)
                    callback(result);
            }).catch(data => {
                console.error("Error @ refreshArtikelListByGruppenCode", data);
                if (callback)
                    callback(null);
                // todo: ErrorHandling...
            });
        }
    }
    getArtikelBySuchbegriff(suchbegriff, callback) {
        let filter = new ApiFilter();
        filter.Max = 200;
        filter.SortOrderFieldName = "Name";
        filter.SortOrder = ApiSortOrderStyle.Ascending;
        if (suchbegriff != null) {
            filter.AddFilterItem("Name", ApiFilterOperator.Contains, suchbegriff, "", "Or");
            filter.FilterItems[0].AddFilterItem("Nummer", ApiFilterOperator.Equal, suchbegriff, "", "Or");
            filter.AddFilterItem("Stillgelegt", ApiFilterOperator.Equal, false, "", "And");
            this.http.post('/artikel/query', filter, { headers: { 'loadComplexity': '3' } }).toPromise().then(data => {
                let result = [];
                if (data) {
                    for (var item of data) {
                        let obj = new Artikel();
                        //obj = JSON.parse(JSON.stringify(item));
                        $.extend(true, obj, item);
                        result.push(obj);
                    }
                }
                if (callback)
                    callback(result);
            }).catch(data => {
                console.error("Error @ getArtikelBySuchbegriff", data);
                if (callback)
                    callback(null);
                // todo: ErrorHandling...
            });
        }
    }
    getArtikelByCode(code, callback) {
        if (code != null) {
            this.http.get('/Artikel/' + code, { headers: { 'loadComplexity': '3' } }).toPromise().then(data => {
                let result;
                if (data) {
                    result = data;
                }
                if (callback)
                    callback(result);
            }).catch(data => {
                console.error("Error @ getArtikelByCode", data);
                if (callback)
                    callback(null);
                // todo: ErrorHandling...
            });
        }
    }
    getArtikelByCodeAsync(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (code != null) {
                return yield this.http.get('/Artikel/' + code, { headers: { 'loadComplexity': '3' } }).toPromise();
            }
            return null;
        });
    }
    getArtikelByCodesAsync(codes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (codes != null && codes.length > 0) {
                let filter = new ApiFilter;
                filter.AddFilterItem("Code", ApiFilterOperator.InValueList, codes);
                return yield this.http.post('/Artikel/query', filter, { headers: { 'loadComplexity': '1' } }).toPromise();
            }
            return null;
        });
    }
    getArtikelByNummer(nummer, callback) {
        let filter = new ApiFilter();
        filter.Max = 1;
        filter.SortOrderFieldName = "Name";
        filter.SortOrder = ApiSortOrderStyle.Ascending;
        if (nummer != null) {
            filter.AddFilterItem("Nummer", ApiFilterOperator.Equal, nummer, "", "Or");
            this.http.post('/Artikel/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
                let result;
                if (data) {
                    result = data[0];
                }
                if (callback)
                    callback(result);
            }).catch(data => {
                console.error("Error @ getArtikelByNummer", data);
                if (callback)
                    callback(null);
                // todo: ErrorHandling...
            });
        }
    }
    getArtikelBildByCode(code) {
        return new ArtikelbildPipe(this.config).transform(code);
    }
    getArtikelStandardBildByCode(code) {
        return new ArtikelStandardBildPipe(this.config).transform(code);
    }
    getLagerorte(callback) {
        this.http.get('/lager/lagerorte').toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new Lagerort();
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, item);
                    result.push(obj);
                }
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getLagerorte", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getBestaende(artikelCodes, callback, error) {
        if (artikelCodes.length > 0) {
            let artikelCodeString = "code=" + artikelCodes.join("&code=");
            this.http.get('/lager/bestand/artikellagerbestand?' + artikelCodeString).toPromise().then(data => {
                let result = data;
                if (callback)
                    callback(result);
            }).catch(data => {
                console.error("Error @ getBestaende", data);
                if (error)
                    error(null);
                // todo: ErrorHandling...
            });
        }
        else {
            callback([]);
        }
    }
    getBestaendeAsync(artikelCodes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (artikelCodes.length > 0) {
                let artikelCodeString = "code=" + artikelCodes.join("&code=");
                return yield this.http.get('/lager/bestand/artikellagerbestand?' + artikelCodeString).toPromise();
            }
            else {
                return [];
            }
        });
    }
    getLagerbuchungen(artikelCode, callback) {
        this.http.get('/lager/buchungen?code=' + artikelCode).toPromise().then(data => {
            let result = [];
            if (data) {
                for (var itemOuter of data) {
                    for (var itemInner of itemOuter) {
                        let obj = new Buchung();
                        //obj = JSON.parse(JSON.stringify(item));
                        $.extend(true, obj, itemInner);
                        result.push(obj);
                    }
                }
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getLagerbuchungen", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getArtikellagerbestand(artikelCode, callback) {
        this.http.get('/lager/bestand/artikellagerbestand?code=' + artikelCode).toPromise().then(data => {
            let result = [];
            if (data) {
                result = data;
                /*for (var itemOuter of data as Array<any>) {
                    for (var itemInner of itemOuter as Array<any>) {
                        result.push(itemInner);
                    }
                }*/
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getArtikellagerbestand", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getShopdefinitions(callback) {
        this.http.get('/shop/shopdefinition/list').toPromise().then(data => {
            let result = [];
            if (data) {
                for (var item of data) {
                    let obj = new ShopDefinition("");
                    //obj = JSON.parse(JSON.stringify(item));
                    $.extend(true, obj, JSON.parse(item.content));
                    obj.id = item.id;
                    result.push(obj);
                }
            }
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getShopdefinitions", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getShopdefinitionById(id = null, callback) {
        let request = "";
        if (id)
            request = "?id=" + id;
        this.http.get('/shop/shopdefinition' + request).toPromise().then(data => {
            let result = data;
            if (callback)
                callback(result);
        }).catch(data => {
            console.error("Error @ getShopdefinitionById", data);
            if (callback)
                callback(null);
            // todo: ErrorHandling...
        });
    }
    getFullBenutzer(benutzerCode, success, error) {
        this.http.get(`/work4all/benutzer/full/${benutzerCode}`).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ getFullBenutzer", data);
            if (error)
                error(data);
            // todo: ErrorHandling...
        });
    }
    getBriefanschrift(sdObjType, sdObjMemberCode, ansprechpartnerCode, mitLand, abteilungCode = 0, anPostfach = false, anPrivat = null) {
        let promise = new Promise((success, fail) => {
            var url = `/${(sdObjType == SdObjType.Kunde ? 'Kunde' : 'Lieferant')}/briefanschrift/${sdObjMemberCode}?ansprechpartnerCode=${ansprechpartnerCode}&anPostfach=${anPostfach}&anPrivat=${anPrivat}&mitLand=${mitLand}&abteilungCode=${abteilungCode}`;
            this.http.get(url).toPromise().then((adresse) => {
                success(adresse);
            }).catch((error) => {
                fail(error);
            });
        });
        return promise;
    }
    getFreigabeStatus(objekte, success, error) {
        if (objekte.length > 0) {
            this.http.post('/erp/freigaben', objekte, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
                let result = [];
                if ($.isArray(data)) {
                    for (var item of data) {
                        result.push(item);
                    }
                }
                if (success)
                    success(result);
            }).catch(data => {
                console.error("Error @ getFreigabeStatus", data);
                if (error)
                    error(null);
                // todo: ErrorHandling...
            });
        }
        else {
            success([]);
        }
    }
    getDokumenteByProjektCodes(projektCodes, success, error) {
        let filter = new ApiFilter();
        filter.Max = 100;
        projektCodes.forEach(projektCode => {
            filter.AddFilterItem("ProjektCode", ApiFilterOperator.Equal, projektCode, "", "");
        });
        this.http.post('/Dokument/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if ($.isArray(data)) {
                for (var item of data) {
                    result.push(item);
                }
            }
            if (success)
                success(result);
        }).catch(data => {
            console.error("Error @ getDokumenteByProjektCodes", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    getEinkaufspreiseByArtikelCodes(artikelCodes, success, error) {
        let filter = new ApiFilter();
        filter.Max = 100;
        artikelCodes.forEach(artikelCode => {
            filter.AddFilterItem("ArtikelCode", ApiFilterOperator.Equal, artikelCode, "", "Or");
        });
        this.http.post('/Einkaufspreis/query', filter, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            let result = [];
            if ($.isArray(data)) {
                for (var item of data) {
                    result.push(item);
                }
            }
            if (success)
                success(result);
        }).catch(data => {
            console.error("Error @ getEinkaufspreiseByArtikelCodes", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    getFileDownloadInfo(type, codeOrId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = null;
            if (codeOrId) {
                let mandantenNummer = this.config.mandantenNummer;
                let token = localStorage.getItem("AuthToken");
                let url = `/File/download`;
                let headers = new HttpHeaders();
                headers.append('Access-Control-Allow-Headers', 'Content-Disposition');
                result = yield this.http.get(url, { headers: headers, params: { type: type, codeOrId: codeOrId }, responseType: "blob", observe: "response" }).toPromise();
                return result['body'];
            }
            return result;
        });
    }
    downloadFile(type, codeOrId, saveAsFilename) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var blob = yield this.getFileDownloadInfo(type, codeOrId);
            this.downloadBlobwithFilename(saveAsFilename, blob);
        });
    }
    downloadBlobwithFilename(filename, blob) {
        var anchor = document.createElement('a');
        anchor.download = filename;
        anchor.href = window.URL.createObjectURL(blob);
        anchor.dataset.downloadurl = [blob.type, anchor.download, anchor.href].join(':');
        anchor.click();
    }
    // downloadFile(type: string, codeOrId: any, success: (result) => any, error: (result) => any) {
    //     this.http.get(`/File/download?type=${type}&codeOrId=${codeOrId}`).toPromise().then(data => {
    //         if (success) success(data);
    //     }).catch(data => {
    //         console.error("Error @ downloadFile", data);
    //         if (error) error(null);
    //         todo: ErrorHandling...
    //     });
    // }
    invalidateCache(typeName, success, error) {
        this.http.get(`/cacheservice/invalidate?typeName=${typeName}`).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ invalidateCache", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    saveAnhangPermanently(anhaenge, success, error) {
        let anhang;
        let tmpCode = anhaenge.code;
        anhaenge.code = 0;
        anhang = anhaenge;
        this.http.post(`/erp/anhang?tempFileId=${tmpCode}`, anhang, { headers: { 'loadComplexity': '2' } }).toPromise().then(data => {
            console.log(data);
            let result = new Anhaenge();
            result.code = Number(data);
            if (success)
                success(result);
        }).catch(data => {
            console.error("Error @ saveAnhangPermanently", data);
            if (error)
                error(null);
        });
    }
    deleteAnhang(id) {
        return (this.http.delete(`/erp/anhang/${id}`).toPromise());
    }
    resetPassword(newPasswortHash, resetToken, success, error) {
        let pwData = {
            newPasswortHash: newPasswortHash,
            resetToken: resetToken
        };
        this.http.post('/Auth/resetpassword', pwData).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ resetPassword", data);
            if (error)
                error(null);
            // todo: ErrorHandling...
        });
    }
    setFavouriteEvent(object, success, error) {
        var favouriteObject = "'" + JSON.stringify(object.favouriteObject) + "'";
        return this.http.put(`/vault/shared/${object.name}`, favouriteObject, { headers: { 'loadComplexity': '2', 'Content-Type': 'application/json' } }).toPromise().then(data => {
            if (success)
                success(data);
        }).catch(data => {
            console.error("Error @ setFavouriteEvent", data);
            if (error)
                error(data);
        });
    }
    getFavouriteEvent(query) {
        return this.http.get(`/vault/shared/${query}`).toPromise();
    }
    deleteFavouriteEvent(object) {
        return this.http.delete(`/vault/shared/${object.name}`).toPromise();
    }
    getRequiredGroups() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get('/shop/pflichtartikelgruppen').toPromise();
        });
    }
    getEinheiten() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(`/Einheit/query`, {}).toPromise();
        });
    }
}

import { FormComponent } from "./form-component";
import { ObjectType } from "@work4all/api";
import { FormComponentType } from "../enums/form-component-type.enum";

export class FormDefinition {
    code: number
    components: FormComponent[] = []
    bzObjType: ObjectType

    constructor() {
        this.components = []
    }

    
    
}
<ng-container *ngIf="requiredArtikleGroups != null && artikleGroupCodes != null">
  <ng-container *ngIf="treeNode && (treeNode.name == undefined || treeNode.name.length == 0)">
    <ul class="w4a-tree-node first-node" *ngIf="treeNode.subGruppen">
      <li>
        <div class="w4a-tree-node-arrow float-left" *ngIf="treeNode.subGruppen && treeNode.subGruppen.length > 0"
          (click)="subGruppenOpen = !subGruppenOpen">
          <div
            style="border-radius: 100px;width: 5px;height: 5px;margin-left: 6px;background: black;display: inline-block;">
          </div>
        </div>
        <div (click)="nodeClicked(treeNode)"
          [ngClass]="{'w4a-tree-node-active': activeNode && treeNode && treeNode.code == activeNode.code}"
          class="w4a-tree-node-name">Alle</div>
        <ul class="w4a-tree-node fade-right">
          <ng-container *ngFor="let subGruppe of treeNode.subGruppen">
            <app-w4a-tree [treeNode]="subGruppe" (codeSelected)="nodeClicked($event)" [activeNode]="activeNode">
            </app-w4a-tree>
          </ng-container>
        </ul>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="treeNode && treeNode.name != undefined && treeNode.name.length > 0">
    <li *ngIf="treeNode">
      <!--<div class="float-right">
            {{activeNode?.code}}
          </div>-->

      <div class="w4a-tree-node-arrow float-left" *ngIf="treeNode.subGruppen && treeNode.subGruppen.length > 0"
        (click)="subGruppenOpen = !subGruppenOpen">
        <i class="fas fa-caret-down" *ngIf="subGruppenOpen"
          [ngClass]="{'red-caret': hasMandatorySubNode(treeNode)}"></i>
        <i class="fas fa-caret-right" *ngIf="!subGruppenOpen"
          [ngClass]="{'red-caret': hasMandatorySubNode(treeNode)}"></i>
      </div>

      <div (click)="nodeClicked(treeNode)"
        [ngClass]="{'w4a-tree-node-active': activeNode && treeNode && treeNode.code == activeNode.code}"
        class="w4a-tree-node-name">{{treeNode.name}}
        <i class="fas fa-circle float-right mandatory-group" *ngIf="isMandatorySubNode(treeNode)"></i>
        <i class="fas fa-exclamation float-right mandatory-subgroup" *ngIf="hasMandatorySubNode(treeNode)"></i>
      </div>
      <ul class="w4a-tree-node fade-right"
        [hidden]="!(treeNode.subGruppen && treeNode.subGruppen.length > 0 && subGruppenOpen)">
        <ng-container *ngFor="let subGruppe of treeNode.subGruppen">
          <app-w4a-tree [treeNode]="subGruppe" (codeSelected)="nodeClicked($event)" [activeNode]="activeNode">
          </app-w4a-tree>
        </ng-container>
      </ul>
    </li>
  </ng-container>
</ng-container>
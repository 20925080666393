import { PageComponentType } from "../enums/page-component-type.enum";
import { PageComponentContent } from "./page-component-content";
import { PageComponentWidth } from "./page-component-width";
import { PageComponentVisibility } from "./page-component-visibility";

export class PageComponent {
    type:PageComponentType
    content: PageComponentContent
    artikelCodes:number[]
    artikelAmount: number
    width: PageComponentWidth
    visible: PageComponentVisibility
    marginTop:number
    marginBottom:number
    marginLeft:number
    marginRight:number
    paddingTop:number
    paddingBottom:number
    paddingRight:number
    paddingLeft:number
    borderWidthTop:number
    borderWidthBottom:number
    borderWidthRight:number
    borderWidthLeft:number
    borderStyle:string
    borderColor: string;
    backgroundColor: string;

    constructor(type:PageComponentType){
        this.type = type 
        this.content = new PageComponentContent()
        this.artikelCodes = []
        this.artikelAmount = 0
        this.width = new PageComponentWidth(12)
        this.visible = new PageComponentVisibility()
        
        this.marginTop = 0        
        this.marginBottom = 0
        this.marginLeft = 0
        this.marginRight = 0
        this.paddingTop = 0
        this.paddingBottom = 0
        this.paddingLeft = 0
        this.paddingRight = 0
        this.borderWidthTop = 0
        this.borderWidthBottom = 0
        this.borderWidthLeft = 0
        this.borderWidthRight = 0
        this.borderStyle = "solid"
        this.borderColor = "#8a8a8a"
        this.backgroundColor = "#FFFFFF"
    }
}

<div *ngIf="viewMode" class="form-generator">
  <div class="form-view">
    <div class="row relative">
      <ng-container *ngIf="formDefinition && formDefinitionEdit.components.length > 0">
        <ng-container *ngFor="let component of formDefinitionEdit.components; let componentIndex = index">
          <ng-container [ngSwitch]="component.type">

            <div class="p-bottom-default {{getDimensionsClass(componentIndex)}}">


              <ng-container *ngSwitchCase="FormComponentType.Headline">
                <div class="p-default strong " style="margin: 0px -15px -15px -15px;">{{component.label}}</div>
              </ng-container>

              <ng-container *ngSwitchCase="FormComponentType.Text">
                <div [innerText]="component.label"></div>
              </ng-container>

              <ng-container *ngSwitchCase="FormComponentType.Divider">
                <hr style="margin:0px -15px">
              </ng-container>


              <ng-container *ngSwitchCase="FormComponentType.Projekt">
                <!--
                <div class="p-default background-whitesmoke border-top " style="margin: 0px -15px;">{{component.label}}</div>
                <div class="p-top-default">
                  <ng-container *ngIf="component.value.code != undefined && component.value.code != 0">
                    <strong>{{component.value?.name}}</strong><br>
                    {{component.value?.nummer}}
                    <div>
                      {{component.value?.anfangDatum | date: 'dd.MM.yyyy'}} - {{component.value?.endeDatum | date:
                      'dd.MM.yyyy'}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="component.value.code == undefined || component.value.code == 0">
                    <em>Keine Angabe</em>
                  </ng-container>
                </div>-->
              </ng-container>


              <ng-container *ngSwitchCase="FormComponentType.LieferadresseAuswahl">



                <!--<label class="small">{{component.label}}</label>
                <div [innerText]="component.value.name" *ngIf="component.value.name != undefined">
                </div>
                <div class="value">
                  <em *ngIf="component.value.name == undefined || component.value.name.length == 0">--</em>
                </div>-->

                <div class="p-default strong " style="margin: 0px -15px;">{{component.label}}</div>
                <div class="">
                  <ng-container *ngIf="component.value.code != undefined">
                    <!--<strong>{{component.value?.firma1}}</strong><br>
                    {{component.value?.strasse}}
                    <div>
                      {{component.value?.plz}} - {{component.value?.ort}}
                      </div>-->
                    <div [innerText]="component.value.lieferadresse" *ngIf="component.value.lieferadresse != undefined"></div>
                  </ng-container>
                  <ng-container *ngIf="component.value.code == undefined ">
                    <em>Keine Angabe</em>
                  </ng-container>
                </div>
              </ng-container>


              <ng-container *ngSwitchCase="FormComponentType.RechnungsadresseAuswahl">


                <!--<label class="small">{{component.label}}</label>
                <div [innerText]="component.value.name" *ngIf="component.value.name != undefined">
                </div>
                <div class="value">
                  <em *ngIf="component.value.name == undefined || component.value.name.length == 0">--</em>
                </div>-->

                <div class="p-default strong " style="margin: 0px -15px;">{{component.label}}</div>
                <div class="">
                  <ng-container *ngIf="component.value.code != undefined">
                    <!--<strong>{{component.value?.firma1}}</strong><br>
                    {{component.value?.strasse}}
                    <div>
                      {{component.value?.plz}} - {{component.value?.ort}}
                      </div>-->
                    <div [innerText]="component.value.rechnungsadresse" *ngIf="component.value.rechnungsadresse != undefined"></div>
                  </ng-container>
                  <ng-container *ngIf="component.value.code == undefined ">
                    <em>Keine Angabe</em>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="FormComponentType.Niederlassung">
                <div class="p-default strong " style="margin: 0px -15px;">{{component.label}}</div>
                <div class="">
                  <ng-container *ngIf="component.value.code != undefined">
                    <!--<strong>{{component.value?.firma1}}</strong><br>
                      {{component.value?.strasse}}
                      <div>
                        {{component.value?.plz}} - {{component.value?.ort}}
                        </div>-->
                    <span [innerText]="component.value.name" *ngIf="component.value.name != undefined"></span>
                  </ng-container>
                  <ng-container *ngIf="component.value.code == undefined ">
                    <em>Keine Angabe</em>
                  </ng-container>
                </div>

                <!--<label class="small">{{component.label}}</label>
                <div [innerText]="component.value.name" *ngIf="component.value.name != undefined">
                </div>
                <div class="value">
                  <em *ngIf="component.value.name != undefined && component.value.name.length == 0">--</em>
                </div>-->
              </ng-container>

              <ng-container *ngSwitchCase="FormComponentType.Kostenstelle">

                <div class="p-default strong " style="margin: 0px -15px;">{{component.label}}</div>
                <div class="">
                  <ng-container *ngIf="component.value.code != undefined">
                    <!--<strong>{{component.value?.firma1}}</strong><br>
                      {{component.value?.strasse}}
                      <div>
                        {{component.value?.plz}} - {{component.value?.ort}}
                        </div>-->
                    <span [innerText]="component.value.name" *ngIf="component.value.name != undefined"></span>
                  </ng-container>
                  <ng-container *ngIf="component.value.code == undefined ">
                    <em>Keine Angabe</em>
                  </ng-container>
                </div>

                <!--<label class="small">{{component.label}}</label>
                <div [innerText]="component.value.name" *ngIf="component.value.name != undefined">
                </div>
                <div class="value">
                  <em *ngIf="component.value.name != undefined && component.value.name.length == 0">--</em>
                </div>-->
              </ng-container>

              <ng-container *ngSwitchCase="FormComponentType.Lieferadresse">
                <div class="p-default strong " style="margin: 0px -15px;">LIEFERADRESSE</div>

                <div class="" *ngIf="component.value.lieferadresse != undefined">

                  <label class="small p-top-default">Firma (optional)</label>
                  <div [innerText]="component.value.lieferadresse.firma">
                  </div>
                  <div class="value">
                    <em *ngIf="component.value.lieferadresse.firma.length == 0">--</em>
                  </div>

                  <label class="small p-top-default">Ansprechpartner</label>
                  <div [innerText]="component.value.lieferadresse.ansprechpartner">
                  </div>
                  <div class="value">
                    <em *ngIf="component.value.lieferadresse.ansprechpartner.length == 0">--</em>
                  </div>

                  <label class="small p-top-default">Straße</label>
                  <div [innerText]="component.value.lieferadresse.strasse">
                  </div>
                  <div class="value">
                    <em *ngIf="component.value.lieferadresse.strasse.length == 0">--</em>
                  </div>

                  <label class="small p-top-default">Straße</label>
                  <div [innerText]="component.value.lieferadresse.strasse">
                  </div>
                  <div class="value">
                    <em *ngIf="component.value.lieferadresse.strasse.length == 0">--</em>
                  </div>

                  <div class="row">
                    <div class="col-md-4">

                      <label class="small p-top-default">PLZ</label>
                      <div [innerText]="component.value.lieferadresse.plz">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.lieferadresse.plz.length == 0">--</em>
                      </div>

                    </div>
                    <div class="col-md-8">

                      <label class="small p-top-default">Ort</label>
                      <div [innerText]="component.value.lieferadresse.ort">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.lieferadresse.ort.length == 0">--</em>
                      </div>

                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="FormComponentType.Rechnungsadresse">
                <div class="p-default strong " style="margin: 0px -15px;">Rechnungsadresse</div>

                <div *ngIf="component.value.rechnungsadresse != undefined">

                  <label class="small ">Firma (optional)</label>
                  <div [innerText]="component.value.rechnungsadresse.firma">
                  </div>
                  <div class="value">
                    <em *ngIf="component.value.rechnungsadresse.firma.length == 0">--</em>
                  </div>

                  <label class="small p-top-default">Ansprechpartner</label>
                  <div [innerText]="component.value.rechnungsadresse.ansprechpartner">
                  </div>
                  <div class="value">
                    <em *ngIf="component.value.rechnungsadresse.ansprechpartner.length == 0">--</em>
                  </div>

                  <label class="small p-top-default">Straße</label>
                  <div [innerText]="component.value.rechnungsadresse.strasse">
                  </div>
                  <div class="value">
                    <em *ngIf="component.value.rechnungsadresse.strasse.length == 0">--</em>
                  </div>

                  <label class="small p-top-default">Straße</label>
                  <div [innerText]="component.value.rechnungsadresse.strasse">
                  </div>
                  <div class="value">
                    <em *ngIf="component.value.rechnungsadresse.strasse.length == 0">--</em>
                  </div>

                  <div class="row">
                    <div class="col-md-4">

                      <label class="small p-top-default">PLZ</label>
                      <div [innerText]="component.value.rechnungsadresse.plz">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.rechnungsadresse.plz.length == 0">--</em>
                      </div>

                    </div>
                    <div class="col-md-8">

                      <label class="small p-top-default">Ort</label>
                      <div [innerText]="component.value.rechnungsadresse.ort">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.rechnungsadresse.ort.length == 0">--</em>
                      </div>

                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="FormComponentType.LieferRechnungsadresse">
                <div class="row">
                  <div class="col-md-6">
                    <div class="p-default strong " style="margin: 0px -15px;">Rechnungsadresse</div>

                    <div *ngIf="component.value.rechnungsadresse != undefined">

                      <label class="small p-top-default">Firma (optional)</label>
                      <div [innerText]="component.value.rechnungsadresse.firma">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.rechnungsadresse.firma.length == 0">--</em>
                      </div>

                      <label class="small p-top-default">Ansprechpartner</label>
                      <div [innerText]="component.value.rechnungsadresse.ansprechpartner">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.rechnungsadresse.ansprechpartner.length == 0">--</em>
                      </div>

                      <label class="small p-top-default">Straße</label>
                      <div [innerText]="component.value.rechnungsadresse.strasse">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.rechnungsadresse.strasse.length == 0">--</em>
                      </div>

                      <div class="row">
                        <div class="col-md-4">

                          <label class="small p-top-default">PLZ</label>
                          <div [innerText]="component.value.rechnungsadresse.plz">
                          </div>
                          <div class="value">
                            <em *ngIf="component.value.rechnungsadresse.plz.length == 0">--</em>
                          </div>

                        </div>
                        <div class="col-md-8">

                          <label class="small p-top-default">Ort</label>
                          <div [innerText]="component.value.rechnungsadresse.ort">
                          </div>
                          <div class="value">
                            <em *ngIf="component.value.rechnungsadresse.ort.length == 0">--</em>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="p-default strong " style="margin: 0px -15px;">LIEFERADRESSE</div>
                    <div *ngIf="component.value.lieferadresseIdentischMitRechnungsadresse && component.value.rechnungsadresse != undefined">

                      <label class="small ">Firma (optional)</label>
                      <div [innerText]="component.value.rechnungsadresse.firma">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.rechnungsadresse.firma.length == 0">--</em>
                      </div>

                      <label class="small p-top-default">Ansprechpartner</label>
                      <div [innerText]="component.value.rechnungsadresse.ansprechpartner">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.rechnungsadresse.ansprechpartner.length == 0">--</em>
                      </div>

                      <label class="small p-top-default">Straße</label>
                      <div [innerText]="component.value.rechnungsadresse.strasse">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.rechnungsadresse.strasse.length == 0">--</em>
                      </div>

                      <div class="row">
                        <div class="col-md-4">

                          <label class="small p-top-default">PLZ</label>
                          <div [innerText]="component.value.rechnungsadresse.plz">
                          </div>
                          <div class="value">
                            <em *ngIf="component.value.rechnungsadresse.plz.length == 0">--</em>
                          </div>

                        </div>
                        <div class="col-md-8">

                          <label class="small p-top-default">Ort</label>
                          <div [innerText]="component.value.rechnungsadresse.ort">
                          </div>
                          <div class="value">
                            <em *ngIf="component.value.rechnungsadresse.ort.length == 0">--</em>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div *ngIf="!component.value.lieferadresseIdentischMitRechnungsadresse && component.value.lieferadresse != undefined">

                      <label class="small p-top-default">Firma (optional)</label>
                      <div [innerText]="component.value.lieferadresse.firma">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.lieferadresse.firma.length == 0">--</em>
                      </div>

                      <label class="small p-top-default">Ansprechpartner</label>
                      <div [innerText]="component.value.lieferadresse.ansprechpartner">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.lieferadresse.ansprechpartner.length == 0">--</em>
                      </div>

                      <label class="small p-top-default">Straße</label>
                      <div [innerText]="component.value.lieferadresse.strasse">
                      </div>
                      <div class="value">
                        <em *ngIf="component.value.lieferadresse.strasse.length == 0">--</em>
                      </div>

                      <div class="row">
                        <div class="col-md-4">

                          <label class="small p-top-default">PLZ</label>
                          <div [innerText]="component.value.lieferadresse.plz">
                          </div>
                          <div class="value">
                            <em *ngIf="component.value.lieferadresse.plz.length == 0">--</em>
                          </div>

                        </div>
                        <div class="col-md-8">

                          <label class="small p-top-default">Ort</label>
                          <div [innerText]="component.value.lieferadresse.ort">
                          </div>
                          <div class="value">
                            <em *ngIf="component.value.lieferadresse.ort.length == 0">--</em>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="FormComponentType.LineBreak">
                <div class="clearfix"></div>
              </ng-container>


              <ng-container *ngSwitchDefault>
                <div class="value-container">
                  <label class="small uppercase" style="font-size: 9px !important;margin:0;padding: 0 4.5px;">{{component.label}}</label>
                  <div *ngIf="(''+component.value).length > 0 && (component.mapping != undefined && component.mapping.Control == 'DateType')" class="value">
                    {{component.value | date: 'dd.MM.yyyy'}}
                  </div>
                  <div [innerText]="component.value" class="value" *ngIf="(''+component.value).length > 0 && (component.mapping == undefined || component.mapping.Control != 'DateType')">
                  </div>
                  <div class="value" *ngIf="(''+component.value).length == 0">
                    <em>--</em>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<table cellpadding="0" cellspacing="0" style="border:0;width:100%">
  <tr>
    <td width="315" *ngIf="editMode && activeFormComponentIndex != null">

      <div class="form-component-editor " *ngIf="editMode && activeFormComponentIndex != null">
        <div class="row">
          <ng-container *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.LieferRechnungsadresse">
            <div class="col-md-12" *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Select || formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Checkbox || formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Date || formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.RadioButtonGroup || formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Text || formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Textfield || formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Textarea || formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Divider || formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Time || formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Headline || formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.LineBreak">
              <mat-form-field>
                <mat-select [(value)]="formDefinitionEdit.components[activeFormComponentIndex].type" placeholder="Typ"
                  (selectionChange)="changeFormComponentType($event)">
                  <!--<mat-option [value]="FormComponentType.Select">{{getFormComponentTypeName(FormComponentType.Select)}}</mat-option>
                      <mat-option [value]="FormComponentType.Checkbox">{{getFormComponentTypeName(FormComponentType.Checkbox)}}</mat-option>
                      <mat-option [value]="FormComponentType.Date">{{getFormComponentTypeName(FormComponentType.Date)}}</mat-option>
                      <mat-option [value]="FormComponentType.RadioButtonGroup">{{getFormComponentTypeName(FormComponentType.RadioButtonGroup)}}</mat-option>
                      <mat-option [value]="FormComponentType.Textfield">{{getFormComponentTypeName(FormComponentType.Textfield)}}</mat-option>
                      <mat-option [value]="FormComponentType.Textarea">{{getFormComponentTypeName(FormComponentType.Textarea)}}</mat-option>
                      <mat-option [value]="FormComponentType.Time">{{getFormComponentTypeName(FormComponentType.Time)}}</mat-option>-->
                  <mat-option [value]="FormComponentType.Text">{{getFormComponentTypeName(FormComponentType.Text)}}</mat-option>
                  <mat-option [value]="FormComponentType.Divider">{{getFormComponentTypeName(FormComponentType.Divider)}}</mat-option>
                  <mat-option [value]="FormComponentType.Headline">{{getFormComponentTypeName(FormComponentType.Headline)}}</mat-option>
                  <mat-option [value]="FormComponentType.LineBreak">{{getFormComponentTypeName(FormComponentType.LineBreak)}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.LieferRechnungsadresse && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.Rechnungsadresse && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.Lieferadresse && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.Text && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.LineBreak && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.Divider">
              <mat-form-field>
                <input matInput type="text" placeholder="Bezeichnung" [(ngModel)]="formDefinitionEdit.components[activeFormComponentIndex].label">
              </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.Headline && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.LineBreak && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.Divider  && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.LieferRechnungsadresse">
              <mat-form-field>
                <mat-select [(value)]="formDefinitionEdit.components[activeFormComponentIndex].dimensions" placeholder="Breite">
                  <mat-option [value]="12">100 %</mat-option>
                  <mat-option [value]="10">84 %</mat-option>
                  <mat-option [value]="9">75 %</mat-option>
                  <mat-option [value]="8">66 %</mat-option>
                  <mat-option [value]="6">50 %</mat-option>
                  <mat-option [value]="4">33 %</mat-option>
                  <mat-option [value]="3">25 %</mat-option>
                  <mat-option [value]="2">16 %</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.Lieferadresse && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.Rechnungsadresse && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.LieferRechnungsadresse && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.RadioButtonGroup && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.Text && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.Headline && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.Divider && formDefinitionEdit.components[activeFormComponentIndex].type != FormComponentType.LineBreak">

              <mat-checkbox *ngIf="formDefinitionEdit.components[activeFormComponentIndex].mapping != null && formDefinitionEdit.components[activeFormComponentIndex].mapping.Code == undefined"
                [(ngModel)]="formDefinitionEdit.components[activeFormComponentIndex].mandatory">Pflichtfeld</mat-checkbox>
              <ng-container *ngIf="formDefinitionEdit.components[activeFormComponentIndex].mapping != null && formDefinitionEdit.components[activeFormComponentIndex].mapping.Code != undefined">
                <mat-checkbox [value]="formDefinitionEdit.components[activeFormComponentIndex].mandatory" disabled>Pflichtfeld</mat-checkbox><br>
                <!--<label class="small absolute">Einstellbar in work4all</label>-->

              </ng-container>
            </div>
            <div class="col-md-12" *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Text">
              <mat-form-field>
                <textarea matInput rows="3" placeholder="Text" [(ngModel)]="formDefinitionEdit.components[activeFormComponentIndex].label"></textarea>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Select || formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.RadioButtonGroup">
          <label>Optionen</label>
          <ol style="padding-left: 15px;">
            <li class="form-component-option" *ngFor="let option of formDefinitionEdit.components[activeFormComponentIndex].options; let optionIndex = index">
                <i class="fas fa-trash pointer float-right" matSuffix (click)="deleteOptionFromFormComponent(activeFormComponentIndex, optionIndex)"></i>
              {{option}}
            </li>
          </ol>

          <mat-form-field>
            <input matInput type="text" placeholder="Neue Option" [(ngModel)]="newOptionText" (keyup.enter)="addOptionToFormComponent(activeFormComponentIndex, newOptionText);newOptionText = ''">
            <i class="fas fa-plus" matSuffix></i>
          </mat-form-field>
        </ng-container>

        <div class="row">
          <div class="col-md-12">
            <label class="small">Position</label><br>
            <button mat-button class="float-left btn-narrow" style="width:50% !important" (click)="moveComponentToPosition(activeFormComponentIndex, activeFormComponentIndex-1)"
              [disabled]="activeFormComponentIndex == 0">
              <i class="fas fa-arrow-left" ></i>
            </button>
            <button mat-button class="float-left btn-narrow" style="width:50% !important" (click)="moveComponentToPosition(activeFormComponentIndex, activeFormComponentIndex+1)"
              [disabled]="activeFormComponentIndex == formDefinitionEdit.components.length-1">
              <i class="fas fa-arrow-right" ></i>
            </button>
          </div>
          <div class="col-md-12">
            <!--<mat-form-field *ngIf="component.type == FormComponentType.Select || component.type == FormComponentType.Checkbox || component.type == FormComponentType.Date || component.type == FormComponentType.RadioButtonGroup || component.type == FormComponentType.Textfield || component.type == FormComponentType.Textarea || component.type == FormComponentType.Time ">
                  <mat-select [(value)]="component.mapping" placeholder="work4all Field-Mapping">
                    <mat-option [value]="null">Ohne</mat-option>
                    <ng-container *ngFor="let feld of individuelleFelder; let feldIndex = index">
                      <mat-option [value]="feld" [disabled]="individuellesFeldIsTaken(component, feld)">{{feld.title}}</mat-option>
                    </ng-container>

                  </mat-select>
                </mat-form-field>-->
            <!-- <mat-form-field *ngIf="component.type == FormComponentType.LieferRechnungsadresse">
                  <input matInput type="text" value="Liefer-/Rechnungsadresse" placeholder="work4all Field-Mapping" disabled>
                </mat-form-field>
                <mat-form-field *ngIf="component.type == FormComponentType.Lieferadresse">
                  <input matInput type="text" value="Lieferadresse" placeholder="work4all Field-Mapping" disabled>
                </mat-form-field>
                <mat-form-field *ngIf="component.type == FormComponentType.Rechnungsadresse">
                  <input matInput type="text" value="Rechnungsadresse" placeholder="work4all Field-Mapping" disabled>
                </mat-form-field>
                <mat-form-field *ngIf="component.type == FormComponentType.Projekt">
                  <input matInput type="text" value="Projekt" placeholder="work4all Field-Mapping" disabled>
                </mat-form-field>
                <mat-form-field *ngIf="component.type == FormComponentType.Notiz">
                  <input matInput type="text" value="Notiz" placeholder="work4all Field-Mapping" disabled>
                </mat-form-field>
                <mat-form-field *ngIf="component.type == FormComponentType.Kostenstelle">
                  <input matInput type="text" value="Kostenstelle" placeholder="work4all Field-Mapping" disabled>
                </mat-form-field>
                <mat-form-field *ngIf="component.type == FormComponentType.Niederlassung">
                  <input matInput type="text" value="Niederlassung" placeholder="work4all Field-Mapping" disabled>
                </mat-form-field>
                <mat-form-field *ngIf="component.type == FormComponentType.Individuell">
                  <input matInput type="text" value="Niederlassung" placeholder="work4all Field-Mapping" disabled>
                </mat-form-field>-->
            <div class="mapping-info" *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.LieferRechnungsadresse">
              <label class="small">work4all Felder</label><br>
              <strong>
                Lieferadresse, Rechnungsadresse
              </strong>
            </div>
            <div class="mapping-info" *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Lieferadresse">
              <label class="small">work4all Feld</label><br>
              <strong>
                Lieferadresse
              </strong>
            </div>
            <div class="mapping-info" *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Rechnungsadresse">
              <label class="small">work4all Feld</label><br>
              <strong>
                Rechnungsadresse
              </strong>
            </div>
            <div class="mapping-info" *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Kostenstelle">
              <label class="small">work4all Feld</label><br>
              <strong>
                Kostenstelle
              </strong>
            </div>
            <!--<div class="mapping-info" *ngIf="component.type == FormComponentType.Notiz">
                  <label class="small">work4all Feld</label><br>
                  <strong>
                    Notiz
                  </strong>
                </div>
                <div class="mapping-info" *ngIf="component.type == FormComponentType.Projekt">
                  <label class="small">work4all Feld</label><br>
                  <strong>
                    Projekt
                  </strong>
                </div>-->
            <div class="mapping-info" *ngIf="formDefinitionEdit.components[activeFormComponentIndex].type == FormComponentType.Niederlassung">
              <label class="small">work4all Feld</label><br>
              <strong>
                Niederlassung
              </strong>
            </div>
            <div class="mapping-info" *ngIf="formDefinitionEdit.components[activeFormComponentIndex].mapping != null && formDefinitionEdit.components[activeFormComponentIndex].mapping.Title != undefined">
              <label class="small">work4all Feld</label><br>
              <strong>
                {{formDefinitionEdit.components[activeFormComponentIndex].mapping.Title}}
              </strong>
            </div>

          </div>
          <div class="col-md-12 align-right">
            <div style="height:36px"></div>
            <button mat-button class="float-right" (click)="activeFormComponentIndex = null">OK</button>
            <button mat-button class="float-right" (click)="deleteFormComponent(activeFormComponentIndex);activeFormComponentIndex = null"
              color="warn">ENTFERNEN</button>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>

    </td>
    <td valign="top">

      <div *ngIf="!viewMode && formDefinitionEdit != null" [ngClass]="{'form-edit':editMode}" class="form-generator">
        <div class="p-x-default ">
          <div class="row ">
            <ng-container *ngIf="formDefinition && formDefinitionEdit.components.length > 0">
              <ng-container *ngFor="let component of formDefinitionEdit.components; let componentIndex = index">
                <div class="form-component-wrap {{getDimensionsClass(componentIndex)}}" [ngClass]="{'form-component-edit': componentIndex == activeFormComponentIndex}">
                  <div class="relative">
                    <label class="form-component-label">{{getFormComponentTypeName(component.type)}}</label>
                    <div *ngIf="component.type == FormComponentType.Textfield" class=" form-component form-component-textfield"
                      id="form-component-{{componentIndex}}">
                      <mat-form-field>
                        <input matInput type="text" [placeholder]="component.label" [(ngModel)]="component.value"
                          (input)="validateFields()" [required]="component.mandatory">
                        <mat-error *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                          ist
                          ein Pflichtfeld</mat-error>
                      </mat-form-field>
                      <div class="form-field-error" *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                        ist ein Pflichtfeld</div>
                    </div>

                    <div *ngIf="component.type == FormComponentType.Textarea" class=" form-component form-component-textarea"
                      id="form-component-{{componentIndex}}">
                      <mat-form-field>
                        <textarea rows="3" matInput type="text" [placeholder]="component.label" [(ngModel)]="component.value"
                          (input)="validateFields()" [required]="component.mandatory"></textarea>
                        <mat-error *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                          ist
                          ein Pflichtfeld</mat-error>
                      </mat-form-field>
                      <div class="form-field-error" *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                        ist ein Pflichtfeld</div>
                    </div>

                    <div *ngIf="component.type == FormComponentType.Checkbox" class=" form-component form-component-checkbox"
                      id="form-component-{{componentIndex}}">
                      <mat-checkbox class="example-margin" [(ngModel)]="component.value" (change)="validateFields()"
                        [required]="component.mandatory">{{component.label}}</mat-checkbox>
                      <div class="form-field-error" style="margin-top: -9px;margin-left: 28px;" *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                        ist ein Pflichtfeld</div>
                    </div>

                    <div *ngIf="component.type == FormComponentType.RadioButtonGroup" class=" form-component form-component-radiobuttongroup"
                      id="form-component-{{componentIndex}}">
                      <label class="small">{{component.label}}</label>
                      <br>
                      <mat-radio-group [(ngModel)]="component.value">
                        <ng-container *ngFor="let option of component.options; let optionIndex = index">
                          <mat-radio-button [value]="option">{{option}}</mat-radio-button>
                          <br>
                        </ng-container>
                      </mat-radio-group>
                    </div>

                    <div *ngIf="component.type == FormComponentType.Select" class=" form-component form-component-select"
                      id="form-component-{{componentIndex}}">
                      <mat-form-field>
                        <mat-select [(ngModel)]="component.value" [placeholder]="component.label" (selectionChange)="validateFields()"
                          [required]="component.mandatory">
                          <mat-option *ngFor="let option of component.options; let optionIndex = index" [value]="option">
                            {{option}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                          ist
                          ein Pflichtfeld</mat-error>
                      </mat-form-field>
                      <div class="form-field-error" *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                        ist ein Pflichtfeld</div>
                    </div>

                    <div *ngIf="component.type == FormComponentType.Date" class=" form-component form-component-date"
                      id="form-component-{{componentIndex}}">
                      <mat-form-field>
                        <input matInput type="date" pattern="\d{4}-\d{2}-\d{2}" [placeholder]="component.label" [(ngModel)]="component.value"
                          (input)="validateFields()" [required]="component.mandatory">
                        <mat-error *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                          ist
                          ein Pflichtfeld</mat-error>
                      </mat-form-field>
                      <div class="form-field-error" *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                        ist ein Pflichtfeld</div>
                    </div>

                    <div *ngIf="component.type == FormComponentType.Time" class=" form-component form-component-time"
                      id="form-component-{{componentIndex}}">
                      <mat-form-field>
                        <input matInput type="time" [placeholder]="component.label" [(ngModel)]="component.value"
                          (input)="validateFields()" [required]="component.mandatory">
                        <mat-error *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                          ist
                          ein Pflichtfeld</mat-error>
                      </mat-form-field>
                      <div class="form-field-error" *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                        ist ein Pflichtfeld</div>
                    </div>

                    <div *ngIf="component.type == FormComponentType.Headline" class="form-component form-component-headline"
                      id="form-component-{{componentIndex}}">
                      <div class="p-default strong " style="margin: 0px -15px;">{{component.label}}</div>
                    </div>

                    <div *ngIf="component.type == FormComponentType.Text" class="form-component form-component-text" id="form-component-{{componentIndex}}"
                      [innerText]="component.label">
                    </div>

                    <div *ngIf="component.type == FormComponentType.LineBreak" class="form-component form-component-linebreak"
                      id="form-component-{{componentIndex}}">
                    </div>

                    <div *ngIf="component.type == FormComponentType.Divider" class="clearfix form-component form-component-divider"
                      id="form-component-{{componentIndex}}">
                      <hr style="margin: 0px -15px;">
                    </div>

                    <div *ngIf="component.type == FormComponentType.Projekt" class=" form-component form-component-projekt"
                      id="form-component-{{componentIndex}}">
                      <!--
                <div class="p-default background-whitesmoke border-top " style="margin: 0px -15px;">
                  {{component.label}}
                </div>
                <div class="p-y-default">
                  <ng-container *ngIf="component.value.code != undefined">
                    <div class="" style="margin-right:15px" *ngIf="component.value.code != undefined">
                      <strong>{{component.value?.name}}</strong>
                      <div>{{component.value?.nummer}}</div>
                      <div>{{component.value?.anfangDatum | date: 'dd.MM.yyyy'}} -
                        {{component.value?.endeDatum
                        | date: 'dd.MM.yyyy'}}</div>
                    </div>
                    <br>
                    <button mat-raised-button *ngIf="component.value.code != undefined" (click)="component.value='';validateFields()"
                      class="btn-narrow">
                      <i class="fas fa-pen" ></i> ÄNDERN
                    </button>
                  </ng-container>

                  <ng-container *ngIf="component.value.code == undefined">
                    <mat-form-field class="max-width">
                      <input type="text" id="projekteInput" (input)="getProjekteBySuchbegriff()" placeholder="Geben Sie einen Suchbegriff ein"
                        [formControl]="projekteFormControl" matInput [matAutocomplete]="projekteAutocomplete"
                        [required]="component.mandatory">
                      <mat-autocomplete #projekteAutocomplete="matAutocomplete" [displayWith]="displayFnProjekte"
                        (optionSelected)="assignProjekt(component,$event);validateFields()">
                        <mat-option *ngFor="let option of filteredProjekteOptions | async" [value]="option">
                            {{ option.nummer }} | {{ option.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="component.mandatory && component.value.code == undefined">Dies ist
                        ein Pflichtfeld</mat-error>
                    </mat-form-field>
                  </ng-container>
                </div>
-->
                    </div>

                    <div *ngIf="component.type == FormComponentType.LieferadresseAuswahl" class=" form-component form-component-lieferadresse-auswahl"
                      id="form-component-{{componentIndex}}">

                      <div class="p-default strong " style="margin: 0px -15px;">
                        {{component.label}}
                      </div>
                      <div class="p-bottom-default">
                        <ng-container *ngIf="component.value.code != undefined">
                          <!--<div class="" style="margin-right:15px" *ngIf="component.value.code != undefined">
                      <strong>{{component.value?.firma1}}</strong><br>
                      {{component.value?.strasse}}<br>
                      {{component.value?.plz}} {{component.value?.ort}}
                    </div>-->
                          <div *ngIf="component.value.lieferadresse != undefined" [innerText]="component.value.lieferadresse"></div>
                          <br>
                          <button mat-raised-button *ngIf="component.value.code != undefined" (click)="component.value='';validateFields()"
                            class="btn-narrow">
                            <i class="fas fa-pen" ></i> ÄNDERN
                          </button>
                        </ng-container>

                        <ng-container *ngIf="component.value.code == undefined">
                          <mat-form-field class="max-width">
                            <input type="text" id="lieferadresseAuswahlInput" (input)="getLieferadressenBySuchbegriff()"
                              placeholder="Geben Sie einen Suchbegriff ein" [formControl]="lieferadressenFormControl"
                              matInput [matAutocomplete]="lieferadressenAutocomplete" [required]="component.mandatory">
                            <mat-autocomplete #lieferadressenAutocomplete="matAutocomplete" [displayWith]="displayFnLieferadresse"
                              (optionSelected)="assignLieferadresse(component,$event);validateFields()">
                              <mat-option *ngFor="let option of filteredLieferadressenOptions | async" [value]="option">
                                {{ option.nummer }} | {{ option.name }}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="component.mandatory && component.value.code == undefined">Dies ist
                              ein Pflichtfeld</mat-error>
                          </mat-form-field>
                        </ng-container>
                      </div>

                    </div>

                    <div *ngIf="component.type == FormComponentType.RechnungsadresseAuswahl" class=" form-component form-component-rechnungsadresse-auswahl"
                      id="form-component-{{componentIndex}}">

                      <div class="p-default strong " style="margin: 0px -15px;">
                        {{component.label}}
                      </div>
                      <div class="p-bottom-default">
                        <ng-container *ngIf="component.value.code != undefined">
                          <!--<div class="" style="margin-right:15px" *ngIf="component.value.code != undefined">
                      <strong>{{component.value?.firma1}}</strong><br>
                      {{component.value?.strasse}}<br>
                      {{component.value?.plz}} {{component.value?.ort}}
                    </div>-->
                          <div *ngIf="component.value.rechnungsadresse != undefined" [innerText]="component.value.rechnungsadresse"></div>
                          <br>
                          <button mat-raised-button *ngIf="component.value.code != undefined" (click)="component.value='';validateFields()"
                            class="btn-narrow">
                            <i class="fas fa-pen" ></i> ÄNDERN
                          </button>
                        </ng-container>

                        <ng-container *ngIf="component.value.code == undefined">
                          <mat-form-field class="max-width">
                            <input type="text" id="rechnungsadresseAuswahlInput" (input)="getRechnungsadressenBySuchbegriff()"
                              placeholder="Geben Sie einen Suchbegriff ein" [formControl]="rechnungsadressenFormControl"
                              matInput [matAutocomplete]="rechnungsadressenAutocomplete" [required]="component.mandatory">
                            <mat-autocomplete #rechnungsadressenAutocomplete="matAutocomplete" [displayWith]="displayFnRechnungsadresse"
                              (optionSelected)="assignRechnungsadresse(component,$event);validateFields()">
                              <mat-option *ngFor="let option of filteredRechnungsadressenOptions | async" [value]="option">
                                {{ option.nummer }} | {{ option.name }}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="component.mandatory && component.value.code == undefined">Dies ist
                              ein Pflichtfeld</mat-error>
                          </mat-form-field>
                        </ng-container>
                      </div>

                    </div>

                    <div *ngIf="component.type == FormComponentType.Kostenstelle" class=" form-component form-component-kostenstelle"
                      id="form-component-{{componentIndex}}">

                      <div class="p-default strong " style="margin: 0px -15px;">
                        {{component.label}}
                      </div>
                      <div class="p-bottom-default">
                        <ng-container *ngIf="component.value.code != undefined">
                          <!--<div class="" style="margin-right:15px" *ngIf="component.value.code != undefined">
                        <strong>{{component.value?.firma1}}</strong><br>
                        {{component.value?.strasse}}<br>
                        {{component.value?.plz}} {{component.value?.ort}}
                      </div>-->
                          <span [innerText]="component.value.name"></span>
                          <div [innerText]="component.value.nummer"></div>
                          <br>
                          <button mat-raised-button *ngIf="component.value.code != undefined" (click)="component.value='';validateFields()"
                            class="btn-narrow">
                            <i class="fas fa-pen" ></i> ÄNDERN
                          </button>
                        </ng-container>

                        <ng-container *ngIf="component.value.code == undefined">
                          <mat-form-field class="max-width">
                            <input type="text" id="kostenstellenAuswahlInput" (input)="getKostenstellenBySuchbegriff()"
                              placeholder="Geben Sie einen Suchbegriff ein" [formControl]="kostenstellenFormControl"
                              matInput [matAutocomplete]="kostenstelleAutocomplete" [required]="component.mandatory">
                            <mat-autocomplete #kostenstelleAutocomplete="matAutocomplete" [displayWith]="displayFnKostenstellen"
                              (optionSelected)="assignKostenstelle(component,$event);validateFields()">
                              <mat-option *ngFor="let option of filteredKostenstellenOptions | async" [value]="option">
                                {{ option.nummer }} | {{ option.name }}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="component.mandatory && component.value.code == undefined">Dies ist
                              ein Pflichtfeld</mat-error>
                          </mat-form-field>
                        </ng-container>
                      </div>


                      <!--<mat-form-field>
                  <mat-select [(ngModel)]="component.value" placeholder="{{component.label}}" (change)="validateFields()"
                    [required]="component.mandatory">
                    <mat-option *ngFor="let option of kostenstellenList; let optionIndex = index" [value]="option">
                      {{option.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="component.mandatory && component.value.code == undefined">Dies ist
                    ein Pflichtfeld</mat-error>
                </mat-form-field>
                <div class="form-field-error" *ngIf="component.mandatory && component.value.code == undefined"> Dies
                  ist ein Pflichtfeld</div>-->
                    </div>

                    <div *ngIf="component.type == FormComponentType.Niederlassung" class=" form-component form-component-niederlassung"
                      id="form-component-{{componentIndex}}">

                      <div class="p-default strong " style="margin: 0px -15px;">
                        {{component.label}}
                      </div>
                      <div class="p-bottom-default">
                        <ng-container *ngIf="component.value.code != undefined">
                          <!--<div class="" style="margin-right:15px" *ngIf="component.value.code != undefined">
                        <strong>{{component.value?.firma1}}</strong><br>
                        {{component.value?.strasse}}<br>
                        {{component.value?.plz}} {{component.value?.ort}}
                      </div>-->
                          <span *ngIf="component.value.name != undefined" [innerText]="component.value.name"></span>
                          <br>
                          <br>
                          <br>
                          <button mat-raised-button *ngIf="component.value.code != undefined" (click)="component.value='';validateFields()"
                            class="btn-narrow">
                            <i class="fas fa-pen" ></i> ÄNDERN
                          </button>
                        </ng-container>

                        <ng-container *ngIf="component.value.code == undefined">
                          <mat-form-field class="max-width">
                            <input type="text" id="niederlassungenAuswahlInput" (input)="getNiederlassungenBySuchbegriff()"
                              placeholder="Geben Sie einen Suchbegriff ein" [formControl]="niederlassungenFormControl"
                              matInput [matAutocomplete]="niederlassungAutocomplete" [required]="component.mandatory">
                            <mat-autocomplete #niederlassungAutocomplete="matAutocomplete" [displayWith]="displayFnNiederlassungen"
                              (optionSelected)="assignNiederlassung(component,$event);validateFields()">
                              <mat-option *ngFor="let option of filteredNiederlassungenOptions | async" [value]="option">
                                {{ option.name }}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="component.mandatory && component.value.code == undefined">Dies ist
                              ein Pflichtfeld</mat-error>
                          </mat-form-field>
                        </ng-container>
                      </div>


                      <!--<mat-form-field>
                  <mat-select [(ngModel)]="component.value" [placeholder]="component.label" (change)="validateFields()"
                    [required]="component.mandatory">
                    <mat-option *ngFor="let option of niederlassungenList; let optionIndex = index" [value]="option">
                      {{option.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="component.mandatory && component.value.code == undefined">Dies ist
                    ein Pflichtfeld</mat-error>
                </mat-form-field>
                <div class="form-field-error" *ngIf="component.mandatory && component.value.code == undefined">Dies
                  ist ein Pflichtfeld</div>-->
                    </div>


                    <div *ngIf="component.type == FormComponentType.Notiz" class=" form-component form-component-notiz"
                      id="form-component-{{componentIndex}}">
                      <!--<mat-form-field>
                  <textarea rows="3" matInput type="text" [placeholder]="component.label" [(ngModel)]="component.value"
                    (input)="validateFields()" [required]="component.mandatory"></textarea>
                  <mat-error *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                    ist
                    ein Pflichtfeld</mat-error>
                </mat-form-field>
                <div class="form-field-error" *ngIf="component.mandatory && (component.value == '' || component.value == false)">Dies
                  ist ein Pflichtfeld</div>-->
                    </div>




                    <div *ngIf="component.type == FormComponentType.Individuell" class=" form-component form-component-individuell"
                      id="form-component-{{componentIndex}}">
                      <ng-container *ngIf="component.mapping != null && component.mapping.Control != 'Check' && component.mapping.Control != 'Combo' && component.mapping.Control != 'List'">
                        <mat-form-field>
                          <input matInput [type]="getIndividualFieldInputType(component.mapping)" [placeholder]="component.label"
                            [(ngModel)]="component.value" (input)="validateFields()" [required]="component.mandatory">
                          <mat-error *ngIf="component.mandatory && (component.value == '' || component.value == false)">
                            Dies ist ein Pflichtfeld</mat-error>
                        </mat-form-field>
                      </ng-container>

                      <ng-container *ngIf="component.mapping != null && component.mapping.Control == 'Combo'">
                        <mat-form-field>
                          <mat-select [placeholder]="component.label" [(ngModel)]="component.value" (selectionChange)="validateFields()"
                            [required]="component.mandatory">
                            <ng-container *ngFor="let option of component.options">
                              <mat-option [value]="option">
                                {{option}}
                              </mat-option>
                            </ng-container>
                          </mat-select>
                          <mat-error *ngIf="component.mandatory && (component.value == '' || component.value == false)">
                            Dies ist ein Pflichtfeld</mat-error>
                        </mat-form-field>
                      </ng-container>

                      <ng-container *ngIf="component.mapping != null && component.mapping.Control == 'Check'">
                        <mat-checkbox matInput [(ngModel)]="component.value" (change)="validateFields()" [required]="component.mandatory">{{component.label}}</mat-checkbox>

                      </ng-container>

                    </div>


                    <div *ngIf="component.type == FormComponentType.LieferRechnungsadresse && component.value.lieferadresseIdentischMitRechnungsadresse != undefined"
                      class="clearfix form-component form-component-liefer-rechnungsadresse" id="form-component-{{componentIndex}}">

                      <div class="p-default background-whitesmoke border-top" style="margin: 0px -15px;" *ngIf="!editMode">
                        <div class="row">
                          <div class="col-md-6">
                            RECHNUNGSADRESSE
                          </div>
                          <div class="col-md-6">
                            <mat-checkbox class="float-right" [(ngModel)]="component.value.lieferadresseIdentischMitRechnungsadresse"
                              (change)="validateFields()" style="margin-bottom: -10px;    margin-top: -3px;">
                              Identisch mit Rechnungsadresse
                            </mat-checkbox>
                            LIEFERADRESSE
                          </div>
                        </div>
                      </div>

                      <div class="p-default background-whitesmoke border-top" style="margin: 0px -23px;margin-top: -12px;"
                        *ngIf="editMode">
                        <div class="row">
                          <div class="col-md-6">
                            RECHNUNGSADRESSE
                          </div>
                          <div class="col-md-6">
                            <mat-checkbox class="float-right" [(ngModel)]="component.value.lieferadresseIdentischMitRechnungsadresse"
                              (change)="validateFields()" style="margin-bottom: -10px;    margin-top: -3px;">
                              Identisch mit Rechnungsadresse
                            </mat-checkbox>
                            LIEFERADRESSE
                          </div>
                        </div>
                      </div>



                      <div class="p-y-default">
                        <div class="row">
                          <div class="col-md-6" *ngIf="component.value.rechnungsadresse != undefined">
                            <div>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Firma (optional)" [(ngModel)]="component.value.rechnungsadresse.firma">
                              </mat-form-field>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Ansprechpartner" [(ngModel)]="component.value.rechnungsadresse.ansprechpartner"
                                  required (input)="validateFields()">
                                <mat-error *ngIf="component.value.rechnungsadresse.ansprechpartner.length == 0">Dies
                                  ist ein Pflichtfeld</mat-error>
                              </mat-form-field>
                              <div class="form-field-error" *ngIf="component.value.rechnungsadresse.ansprechpartner.length == 0">Dies
                                ist ein Pflichtfeld
                              </div>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Straße" [(ngModel)]="component.value.rechnungsadresse.strasse"
                                  (input)="validateFields()" required>
                                <mat-error *ngIf="component.value.rechnungsadresse.strasse.length == 0">Dies
                                  ist ein Pflichtfeld</mat-error>
                              </mat-form-field>
                              <div class="form-field-error" *ngIf="component.value.rechnungsadresse.strasse.length == 0">Dies
                                ist ein Pflichtfeld</div>
                              <div class="row">
                                <div class="col-md-4">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="PLZ" [(ngModel)]="component.value.rechnungsadresse.plz"
                                      (input)="validateFields()" required>
                                    <mat-error *ngIf="component.value.rechnungsadresse.plz.length == 0">Dies
                                      ist ein Pflichtfeld</mat-error>
                                  </mat-form-field>
                                  <div class="form-field-error" *ngIf="component.value.rechnungsadresse.plz.length == 0">Dies
                                    ist ein Pflichtfeld</div>
                                </div>
                                <div class="col-md-8">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="Ort" [(ngModel)]="component.value.rechnungsadresse.ort"
                                      (input)="validateFields()" required>
                                    <mat-error *ngIf="component.value.rechnungsadresse.ort.length == 0">Dies
                                      ist ein Pflichtfeld</mat-error>
                                  </mat-form-field>
                                  <div class="form-field-error" *ngIf="component.value.rechnungsadresse.ort.length == 0">Dies
                                    ist ein Pflichtfeld</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6" *ngIf="component.value.rechnungsadresse != undefined">

                            <div *ngIf="component.value.lieferadresseIdentischMitRechnungsadresse">
                              <mat-form-field>
                                <input matInput type="text" placeholder="Firma (optional)" [(ngModel)]="component.value.rechnungsadresse.firma"
                                  disabled>
                              </mat-form-field>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Ansprechpartner" [(ngModel)]="component.value.rechnungsadresse.ansprechpartner"
                                  disabled>
                              </mat-form-field>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Straße" [(ngModel)]="component.value.rechnungsadresse.strasse"
                                  disabled>
                              </mat-form-field>
                              <div class="row">
                                <div class="col-md-4">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="PLZ" [(ngModel)]="component.value.rechnungsadresse.plz"
                                      disabled>
                                  </mat-form-field>
                                </div>
                                <div class="col-md-8">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="Ort" [(ngModel)]="component.value.rechnungsadresse.ort"
                                      disabled>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="!component.value.lieferadresseIdentischMitRechnungsadresse && component.value.lieferadresse != undefined">
                              <mat-form-field>
                                <input matInput type="text" placeholder="Firma (optional)" [(ngModel)]="component.value.lieferadresse.firma"
                                  (input)="validateFields()">
                              </mat-form-field>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Ansprechpartner" [(ngModel)]="component.value.lieferadresse.ansprechpartner"
                                  (input)="validateFields()" required>
                                <mat-error *ngIf="component.value.lieferadresse.ansprechpartner.length == 0">Dies
                                  ist ein Pflichtfeld</mat-error>
                              </mat-form-field>
                              <div class="form-field-error" *ngIf="component.value.lieferadresse.ansprechpartner.length == 0"
                                (input)="validateFields()">
                                Dies ist ein Pflichtfeld</div>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Straße" [(ngModel)]="component.value.lieferadresse.strasse"
                                  (input)="validateFields()" required>
                                <mat-error *ngIf="component.value.lieferadresse.strasse.length == 0">Dies
                                  ist ein Pflichtfeld</mat-error>
                              </mat-form-field>
                              <div class="form-field-error" *ngIf="component.value.lieferadresse.strasse.length == 0"
                                (input)="validateFields()">Dies
                                ist ein Pflichtfeld</div>
                              <div class="row">
                                <div class="col-md-4">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="PLZ" [(ngModel)]="component.value.lieferadresse.plz"
                                      (input)="validateFields()" required>
                                    <mat-error *ngIf="component.value.lieferadresse.plz.length == 0">Dies
                                      ist ein Pflichtfeld</mat-error>
                                  </mat-form-field>
                                  <div class="form-field-error" *ngIf="component.value.lieferadresse.plz.length == 0">Dies
                                    ist ein Pflichtfeld</div>
                                </div>
                                <div class="col-md-8">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="Ort" [(ngModel)]="component.value.lieferadresse.ort"
                                      (input)="validateFields()" required>
                                    <mat-error *ngIf="component.value.lieferadresse.ort.length == 0">Dies
                                      ist ein Pflichtfeld</mat-error>
                                  </mat-form-field>
                                  <div class="form-field-error" *ngIf="component.value.lieferadresse.ort.length == 0">Dies
                                    ist ein Pflichtfeld</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="component.type == FormComponentType.Lieferadresse" class="clearfix form-component form-component-lieferadresse"
                      id="form-component-{{componentIndex}}">

                      <div class="p-default background-whitesmoke border-top" style="margin: 0px -15px;" *ngIf="!editMode">
                        <div class="row">
                          <div class="col-md-12">
                            LIEFERADRESSE
                          </div>
                        </div>
                      </div>

                      <div class="p-default background-whitesmoke border-top" style="margin: 0px -23px;margin-top: -12px;"
                        *ngIf="editMode">
                        <div class="row">
                          <div class="col-md-12">
                            LIEFERADRESSE
                          </div>
                        </div>
                      </div>


                      <div class="p-y-default">
                        <div class="row">
                          <div class="col-md-12" *ngIf="component.value.lieferadresse != undefined">
                            <div>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Firma (optional)" [(ngModel)]="component.value.lieferadresse.firma">
                              </mat-form-field>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Ansprechpartner" [(ngModel)]="component.value.lieferadresse.ansprechpartner"
                                  (input)="validateFields()" required>
                                <mat-error *ngIf="component.value.lieferadresse.ansprechpartner.length == 0">Dies
                                  ist ein Pflichtfeld</mat-error>
                              </mat-form-field>
                              <div class="form-field-error" *ngIf="component.value.lieferadresse.ansprechpartner.length == 0">Dies
                                ist ein Pflichtfeld
                              </div>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Straße" [(ngModel)]="component.value.lieferadresse.strasse"
                                  (input)="validateFields()" required>
                                <mat-error *ngIf="component.value.lieferadresse.strasse.length == 0">Dies
                                  ist ein Pflichtfeld</mat-error>
                              </mat-form-field>
                              <div class="form-field-error" *ngIf="component.value.lieferadresse.strasse.length == 0">Dies
                                ist ein Pflichtfeld</div>
                              <div class="row">
                                <div class="col-md-4">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="PLZ" [(ngModel)]="component.value.lieferadresse.plz"
                                      (input)="validateFields()" required>
                                    <mat-error *ngIf="component.value.lieferadresse.plz.length == 0">Dies
                                      ist ein Pflichtfeld</mat-error>
                                  </mat-form-field>
                                  <div class="form-field-error" *ngIf="component.value.lieferadresse.plz.length == 0">Dies
                                    ist ein Pflichtfeld</div>
                                </div>
                                <div class="col-md-8">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="Ort" [(ngModel)]="component.value.lieferadresse.ort"
                                      (input)="validateFields()" required>
                                    <mat-error *ngIf="component.value.lieferadresse.ort.length == 0">Dies
                                      ist ein Pflichtfeld</mat-error>
                                  </mat-form-field>
                                  <div class="form-field-error" *ngIf="component.value.lieferadresse.ort.length == 0">Dies
                                    ist ein Pflichtfeld</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="component.type == FormComponentType.Rechnungsadresse" class="clearfix form-component form-component-rechnungsadresse"
                      id="form-component-{{componentIndex}}">

                      <div class="p-default background-whitesmoke border-top" style="margin: 0px -15px;" *ngIf="!editMode">
                        <div class="row">
                          <div class="col-md-12">
                            RECHNUNGSADRESSE
                          </div>
                        </div>
                      </div>

                      <div class="p-default background-whitesmoke border-top" style="margin: 0px -23px;margin-top: -12px;"
                        *ngIf="editMode">
                        <div class="row">
                          <div class="col-md-12">
                            RECHNUNGSADRESSE
                          </div>
                        </div>
                      </div>



                      <div class="p-y-default">
                        <div class="row">
                          <div class="col-md-12" *ngIf="component.value.rechnungsadresse != undefined">
                            <div>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Firma (optional)" [(ngModel)]="component.value.rechnungsadresse.firma">
                              </mat-form-field>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Ansprechpartner" [(ngModel)]="component.value.rechnungsadresse.ansprechpartner"
                                  (input)="validateFields()" required>
                                <mat-error *ngIf="component.value.rechnungsadresse.ansprechpartner.length == 0">Dies
                                  ist ein Pflichtfeld</mat-error>
                              </mat-form-field>
                              <div class="form-field-error" *ngIf="component.value.rechnungsadresse.ansprechpartner.length == 0">Dies
                                ist ein Pflichtfeld
                              </div>
                              <mat-form-field>
                                <input matInput type="text" placeholder="Straße" [(ngModel)]="component.value.rechnungsadresse.strasse"
                                  (input)="validateFields()" required>
                                <mat-error *ngIf="component.value.rechnungsadresse.strasse.length == 0">Dies
                                  ist ein Pflichtfeld</mat-error>
                              </mat-form-field>
                              <div class="form-field-error" *ngIf="component.value.rechnungsadresse.strasse.length == 0">Dies
                                ist ein Pflichtfeld</div>
                              <div class="row">
                                <div class="col-md-4">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="PLZ" [(ngModel)]="component.value.rechnungsadresse.plz"
                                      (input)="validateFields()" required>
                                    <mat-error *ngIf="component.value.rechnungsadresse.plz.length == 0">Dies
                                      ist ein Pflichtfeld</mat-error>
                                  </mat-form-field>
                                  <div class="form-field-error" *ngIf="component.value.rechnungsadresse.plz.length == 0">Dies
                                    ist ein Pflichtfeld</div>
                                </div>
                                <div class="col-md-8">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="Ort" [(ngModel)]="component.value.rechnungsadresse.ort"
                                      (input)="validateFields()" required>
                                    <mat-error *ngIf="component.value.rechnungsadresse.ort.length == 0">Dies
                                      ist ein Pflichtfeld</mat-error>
                                  </mat-form-field>
                                  <div class="form-field-error" *ngIf="component.value.rechnungsadresse.ort.length == 0">Dies
                                    ist ein Pflichtfeld</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="form-component-hitbox" *ngIf="editMode" (click)="activeFormComponentIndex = componentIndex"></div>
                  </div>


                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

    </td>
  </tr>
</table>

<!--<div *ngIf="editMode && activeFormComponentIndex != null" [style.height.px]="getFormComponentEditorHeight()"></div>-->
<hr class="no-margin clearfix" *ngIf="loginMode == 0 && !editMode && !fillMode && !viewMode">
<div class="p-default float-left" *ngIf="loginMode == 0 && !editMode && !fillMode && !viewMode">
  <button class=" float-right" mat-button (click)="toggleEditMode()">
      <i class="fas fa-pen" ></i>
    Formular bearbeiten
  </button>
</div>

<mat-menu #componentMenu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="standardComponentMenu" *ngIf="individualFieldType != 'Projekte'">Allgemeine
    Felder</button>
  <button mat-menu-item [matMenuTriggerFor]="individualComponentMenu" *ngIf="individuelleFelder != undefined && individuelleFelder && individuelleFelder.length > 0">Individuelle
    Felder</button>
  <button mat-menu-item [matMenuTriggerFor]="myComponentMenu">Freie Felder</button>
</mat-menu>


<mat-menu #individualComponentMenu="matMenu">
  <ng-container *ngFor="let feld of individuelleFelder; let feldIndex = index">
    <button mat-menu-item [value]="feld" [disabled]="individuellesFeldIsTaken(feld)" (click)="addIndividualFormComponent(feld)"
      *ngIf="checkValidFieldType(feld)">{{sanitizeIndividualFieldTitle(feld.title)}}</button>
  </ng-container>
</mat-menu>

<mat-menu #myComponentMenu="matMenu">
  <!--<button mat-menu-item (click)="addFormComponent(FormComponentType.Select)">{{getFormComponentTypeName(FormComponentType.Select)}}</button>
  <button mat-menu-item (click)="addFormComponent(FormComponentType.Checkbox)">{{getFormComponentTypeName(FormComponentType.Checkbox)}}</button>
  <button mat-menu-item (click)="addFormComponent(FormComponentType.Date)">{{getFormComponentTypeName(FormComponentType.Date)}}</button>
  <button mat-menu-item (click)="addFormComponent(FormComponentType.RadioButtonGroup)">{{getFormComponentTypeName(FormComponentType.RadioButtonGroup)}}</button>-->
  <button mat-menu-item (click)="addFormComponent(FormComponentType.Text)">{{getFormComponentTypeName(FormComponentType.Text)}}</button>
  <!--<button mat-menu-item (click)="addFormComponent(FormComponentType.Textarea)">{{getFormComponentTypeName(FormComponentType.Textarea)}}</button>
  <button mat-menu-item (click)="addFormComponent(FormComponentType.Textfield)">{{getFormComponentTypeName(FormComponentType.Textfield)}}</button>
  <button mat-menu-item (click)="addFormComponent(FormComponentType.Time)">{{getFormComponentTypeName(FormComponentType.Time)}}</button>-->
  <button mat-menu-item (click)="addFormComponent(FormComponentType.Headline)">{{getFormComponentTypeName(FormComponentType.Headline)}}</button>
  <button mat-menu-item (click)="addFormComponent(FormComponentType.Divider)">{{getFormComponentTypeName(FormComponentType.Divider)}}</button>
  <button mat-menu-item (click)="addFormComponent(FormComponentType.LineBreak)">{{getFormComponentTypeName(FormComponentType.LineBreak)}}</button>
</mat-menu>

<mat-menu #standardComponentMenu="matMenu">
  <ng-container *ngIf="formDefinitionEdit">
    <button mat-menu-item (click)="addFormComponent(FormComponentType.Kostenstelle)" [disabled]="getFormComponentCount(FormComponentType.Kostenstelle) > 0">{{getFormComponentTypeName(FormComponentType.Kostenstelle)}}</button>
    <!--<button mat-menu-item (click)="addFormComponent(FormComponentType.Lieferadresse)" *ngIf="formDefinitionEdit.bzObjType != ObjectType.Projekt"
      [disabled]="getFormComponentCount(FormComponentType.Lieferadresse) > 0 || getFormComponentCount(FormComponentType.LieferRechnungsadresse) > 0">{{getFormComponentTypeName(FormComponentType.Lieferadresse)}}</button>-->
    <button mat-menu-item (click)="addFormComponent(FormComponentType.LieferadresseAuswahl)" *ngIf="formDefinitionEdit.bzObjType != ObjectType.Projekt"
      [disabled]="getFormComponentCount(FormComponentType.LieferadresseAuswahl) > 0 ">{{getFormComponentTypeName(FormComponentType.LieferadresseAuswahl)}}</button>
    <!--<button mat-menu-item (click)="addFormComponent(FormComponentType.LieferRechnungsadresse)" *ngIf="formDefinitionEdit.bzObjType != ObjectType.Projekt"
      [disabled]="getFormComponentCount(FormComponentType.LieferRechnungsadresse) > 0 || getFormComponentCount(FormComponentType.Lieferadresse) > 0 || getFormComponentCount(FormComponentType.Rechnungsadresse) > 0">{{getFormComponentTypeName(FormComponentType.LieferRechnungsadresse)}}</button>
    <button mat-menu-item (click)="addFormComponent(FormComponentType.Notiz)" [disabled]="getFormComponentCount(FormComponentType.Notiz) > 0"
      *ngIf="formDefinitionEdit.bzObjType != ObjectType.Projekt">{{getFormComponentTypeName(FormComponentType.Notiz)}}</button>-->
    <button mat-menu-item (click)="addFormComponent(FormComponentType.Niederlassung)" *ngIf="formDefinitionEdit.bzObjType != ObjectType.Projekt"
      [disabled]="getFormComponentCount(FormComponentType.Niederlassung) > 0">{{getFormComponentTypeName(FormComponentType.Niederlassung)}}</button>
    <!--<button mat-menu-item (click)="addFormComponent(FormComponentType.Projekt)" *ngIf="formDefinitionEdit.bzObjType != ObjectType.Projekt"
      [disabled]="getFormComponentCount(FormComponentType.Projekt) > 0">{{getFormComponentTypeName(FormComponentType.Projekt)}}</button>-->
    <!--<button mat-menu-item (click)="addFormComponent(FormComponentType.Rechnungsadresse)" *ngIf="formDefinitionEdit.bzObjType != ObjectType.Projekt"
      [disabled]="getFormComponentCount(FormComponentType.Rechnungsadresse) > 0 || getFormComponentCount(FormComponentType.LieferRechnungsadresse) > 0">{{getFormComponentTypeName(FormComponentType.Rechnungsadresse)}}</button>-->
    <button mat-menu-item (click)="addFormComponent(FormComponentType.RechnungsadresseAuswahl)" *ngIf="formDefinitionEdit.bzObjType != ObjectType.Projekt"
      [disabled]="getFormComponentCount(FormComponentType.RechnungsadresseAuswahl) > 0 ">{{getFormComponentTypeName(FormComponentType.RechnungsadresseAuswahl)}}</button>
  </ng-container>
</mat-menu>

<div class="p-default overflow-hidden" *ngIf="loginMode == 0 && editMode && !fillMode">
  <button class=" float-left" mat-button [matMenuTriggerFor]="componentMenu">
      <i class="fas fa-plus" ></i>
    Feld hinzufügen
  </button>
  <div class="float-right">
    <button class="" mat-button (click)="cancelEditMode()" color="warn">
      Änderungen verwerfen
    </button>
    <button class="" mat-button (click)="updateShopDefinition()" *ngIf="loginMode == 0 && editMode">
      Änderungen übernehmen
    </button>
  </div>
</div>

<div class="clearfix"></div>
import { Artikel, Projekt, ObjectType, Einkaufspreis, Auftrag, Einheit } from '@work4all/api';
import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { MatDialog, PageEvent, MatPaginator } from '@angular/material';
import { ApiService } from '../../services/api.service';
import { W4aTreeNode } from '../../ui/w4a-tree/w4a-tree-node';
import { ShopService } from '../../services/shop.service';
import { ShopDefinition } from '../../classes/shop-definition';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { VeranstaltungAuswahlComponent } from '../veranstaltung-auswahl/veranstaltung-auswahl.component';
import { List } from 'linqts';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-artikel',
  templateUrl: './artikel.component.html',
  styleUrls: ['./artikel.component.scss']
})
export class ArtikelComponent implements OnInit {

  get loggedUser(): any {
    return this.apiService.getLoggedUser();
  }

  @Input() disableAddArtikel = false;
  @Input() disableChangeProjekt = false;
  currentArtikelIndex = null;

  viewMode = 0;

  suchbegriff = '';
  searchTimeout;

  artikelGruppen: W4aTreeNode = {
    code: 0,
    index: 0,
    level: 0,
    name: '',
    subGruppen: [],
  };
  artikelList: Artikel[] = [];

  anzahlNewPosition = 1;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  pageSizeOptions: number[] = [12, 24, 48, 96];

  pageEvent: PageEvent = new PageEvent();

  loading = false;

  currentShopDefinition: ShopDefinition = null;

  @Output() artikelSelected: EventEmitter<any> = new EventEmitter();

  ObjectType = ObjectType;
  bzObjType: ObjectType;

  projekteFormControl = new FormControl();
  projekteOptions = [];
  filteredProjekteOptions: Observable<Projekt[]>;

  dateVon = new FormControl(moment());
  dateBis = new FormControl(moment().add(3, 'month'));
  disableProjekte = false;

  einheiten: Einheit[];

  auftragList: Auftrag[] = null;

  openSideNav = true;

  sortOptions: any[] = [
    { info: 'Nummer aufsteigend', value: 0 },
    { info: 'Nummer absteigend', value: 1 },
    { info: 'Bezeichnung aufsteigend', value: 2 },
    { info: 'Bezeichnung absteigend', value: 3 },
    { info: 'Verfügbarkeit aufsteigend', value: 4 },
    { info: 'Verfügbarkeit absteigend', value: 5 },
    { info: 'Einzelpreis aufsteigend', value: 6 },
    { info: 'Einzelpreis absteigend', value: 7 },
  ];

  currentLoadingUUID = null

  constructor(public dialog: MatDialog, protected apiService: ApiService, protected shopService: ShopService, protected router: Router) {

  }

  ngOnInit() {

    this.pageEvent

    if(this.disableChangeProjekt){
      this.dateVon.disable()
      this.dateBis.disable()
    }
    else{

      this.dateVon.enable()
      this.dateBis.enable()
    }

    this.getEinheiten();
    this.apiService.getArtikelGruppen(artikelGruppen => {
      this.artikelGruppen.subGruppen = artikelGruppen;
      this.selectArtikelGruppe(this.artikelGruppen);
    });

    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 24;

    this.apiService.setLoginLevel();

    this.viewMode = JSON.parse(localStorage.getItem('w4aShopArtikelViewMode'));
    if (this.viewMode == null) {
      this.viewMode = 0;
    }


    this.shopService.CurrentShopDefinition.subscribe(data => {
      this.currentShopDefinition = data;

      if (this.shopService.getFirstActiveOrderDefinitionIndex()) {
        this.bzObjType = data.orderDefinitions[0].formDefinition.bzObjType;
      }
    });
    this.getProjekteBySuchbegriff('');

    if (localStorage.getItem('w4aShopSelectedProjekt') != undefined && localStorage.getItem('w4aShopSelectedProjekt') != null) {
      this.projekteFormControl.setValue(JSON.parse(localStorage.getItem('w4aShopSelectedProjekt')));
      if (this.projekteFormControl.value) {
        this.dateVon.setValue(this.projekteFormControl.value.anfangDatum);
        this.dateBis.setValue(this.projekteFormControl.value.endeDatum);
      }

      this.projekteFormControl.disable();
      this.dateVon.disable();
      this.dateBis.disable();

      this.disableProjekte = true;
    }
  }

  setViewMode(viewMode: number) {
    this.viewMode = viewMode;
    localStorage.setItem('w4aShopArtikelViewMode', JSON.stringify(viewMode));
  }

  async getEinheiten() {
    this.einheiten = await this.apiService.getEinheiten();
  }

  updateTimeFilter() {

  }

  getProjekteBySuchbegriff(data = this.projekteFormControl.value) {
    //console.log(data);
    this.apiService.getProjekteBySuchbegriff(data, data => {
      //console.log(data);
      if (data) {
        this.projekteOptions = data;

        this.filteredProjekteOptions = this.projekteFormControl.valueChanges
          .pipe(
            startWith<string | any>(''),
            map(value => (typeof value === 'string' || !value) ? value : value.name),
            map(name => name ? this.filterProjekte(name) : this.projekteOptions.slice())
          );
      }
    });
  }

  filterProjekte(data: string): Projekt[] {
    return this.projekteOptions.filter(option =>
      option.notiz.toLowerCase().indexOf(data.toLowerCase()) != -1 || option.nummer.toString().toLowerCase().indexOf(data.toLowerCase()) != -1);
  }

  displayFnProjekte(data?: Projekt): string | undefined {
    return data ? data.nummer + ' | ' + data.name : undefined;
  }


  setTimeRange(event) {
    const projekt: Projekt = event.option.value;
    if (projekt) {
      this.dateVon.setValue(projekt.anfangDatum);
      this.dateBis.setValue(projekt.endeDatum);
    }
  }

  setPage(event) {
    this.pageEvent = event;
  }

  addPosition(artikel: Artikel, nichtVerfuegbar: boolean = null, menge: number = this.anzahlNewPosition) {
    this.artikelSelected.emit({ artikel: artikel, menge: menge, nichtVerfuegbar: nichtVerfuegbar });
    //this.shopService.addPositionToWarenkorb(artikel, this.anzahlNewPosition)
    this.anzahlNewPosition = 1;
  }

  updateManualPage(index) {
    if (this.paginator != undefined)
      this.paginator.pageIndex = index;
    if (this.pageEvent != undefined)
      this.pageEvent.pageIndex = index;
  }

  refreshEinkaufspreise() {
    if (this.bzObjType == ObjectType.Bedarfsanforderung || this.bzObjType == ObjectType.Bestellung) {
      const artikelCodes: number[] = [];
      this.artikelList.forEach(artikel => {
        artikelCodes.push(artikel.code);
      });
      const orderDefinition = this.currentShopDefinition.orderDefinitions[0];
      if (orderDefinition.formDefinition.bzObjType == ObjectType.Bestellung || orderDefinition.formDefinition.bzObjType == ObjectType.Bedarfsanforderung) {
        this.apiService.getEinkaufspreiseByArtikelCodes(artikelCodes, einkaufspreise => {
          einkaufspreise.forEach(einkaufspreis => {
            this.artikelList.some((artikel, artikelindex) => {
              if (artikel.code == einkaufspreis.artikelCode) {
                artikel['preise']['einkaufspreise'].push(einkaufspreis);
                return true;
              }
            });
          });
        }, error => {

        });
      }
    }
  }

  refreshBestaende() {
    const artikelCodes: number[] = [];
    this.artikelList.forEach((artikel, artikelIndex) => {
      if (artikel['stillgelegt']) {
        this.artikelList.splice(artikelIndex, 1);
      }
      else if (!artikel['nichtLagerArtikel'] && !artikel['disposition'])
        artikelCodes.push(artikel.code);
    });
    this.apiService.getBestaende(artikelCodes, data => {
      this.artikelList.some((artikel, artikelIndex) => {
        let bestandList = data[artikel.code]
        let chargen = []
        if (bestandList != undefined && bestandList) {
          let gesamtBestand = 0;
          for (let bestand of bestandList) {
            gesamtBestand += bestand.bestand
            chargen.push(...bestand.chargen)
          }
          artikel['bestand'] = gesamtBestand
          artikel['chargen'] = chargen

        }
      });
    }, error => {
      console.log(error);
    });
  }


  refreshArtikelListBySuchbegriff() {
    clearTimeout(this.searchTimeout);
    this.currentLoadingUUID = UUID.UUID()
    let awaitedUUID = JSON.parse(JSON.stringify(this.currentLoadingUUID))

    this.loading = true;
    this.searchTimeout = setTimeout(() => {

      this.currentArtikelIndex = null;
      this.apiService.getArtikelBySuchbegriff(this.suchbegriff, artikelList => {
        this.loading = false;
        if (artikelList && awaitedUUID == this.currentLoadingUUID) {
          this.updateManualPage(0);
          this.artikelList = artikelList;
          //this.artikelList.sort(this.sortByName)
          this.refreshEinkaufspreise();
          this.refreshBestaende();
          this.pageEvent.length = artikelList.length;

          this.getVerfuegbarkeiten();
        }
      });
    }, 400);

  }

  refreshArtikelListByGruppenCodes(codes: number[]) {
    this.loading = true;
    this.currentLoadingUUID = UUID.UUID()
    let awaitedUUID = JSON.parse(JSON.stringify(this.currentLoadingUUID))
    this.apiService.refreshArtikelListByGruppenCodes(codes, artikelList => {
      this.currentArtikelIndex = null;
      this.loading = false;
      if (artikelList && awaitedUUID == this.currentLoadingUUID) {
        this.updateManualPage(0);
        this.artikelList = artikelList;
        //this.artikelList.sort(this.sortByName)
        this.refreshEinkaufspreise();
        this.refreshBestaende();
        this.getVerfuegbarkeiten();

        this.pageEvent.length = artikelList.length;
        this.suchbegriff = '';
      }
    });
  }

  getEinkaufspreis(einkaufspreise: Einkaufspreis[]): Einkaufspreis {
    let result: Einkaufspreis = null;
    if ($.isArray(einkaufspreise)) {
      einkaufspreise.forEach(einkaufspreis => {
        if (!result || (result.einkaufpreis != undefined && result.einkaufpreis > einkaufspreis.einkaufpreis)) {
          result = einkaufspreis;
        }
      });
    }
    return result;
  }

  sortByName(a, b) {
    if (a.name < b.name)
      return -1;
    if (a.name > b.name)
      return 1;
    return 0;
  }

  selectArtikelGruppe(artikelGruppe) {
    //console.log(artikelGruppe)
    const codes = this.getGruppeCodes(artikelGruppe);
    this.refreshArtikelListByGruppenCodes(codes);
  }

  getGruppeCodes(artikelGruppe): number[] {
    const result = [artikelGruppe.code];

    if (artikelGruppe.subGruppen && artikelGruppe.subGruppen.length > 0) {
      artikelGruppe.subGruppen.forEach(subGruppe => {
        result.push(this.getGruppeCodes(subGruppe)[0]);
      });
    }
    return result;
  }

  orderArrayByName(a, b) {
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
    return 0;
  }

  getArtikelBildByCode(code) {
    return this.apiService.getArtikelBildByCode(code);
  }

  getArtikelStandardBildByCode(code) {
    return this.apiService.getArtikelStandardBildByCode(code);
  }

  async openVeranstaltungAuswahlDialog(): Promise<any> {
    const dialogRef = this.dialog.open(VeranstaltungAuswahlComponent, {
      autoFocus: false,
      disableClose: true,
      width: '1570px'
    });

    let projekt = await dialogRef.afterClosed().toPromise()
    if(projekt)
    {
      this.projekteFormControl.setValue(projekt)
      this.dateVon.setValue(projekt.anfangDatum)
      this.dateBis.setValue(projekt.endeDatum)
      this.getVerfuegbarkeiten()
      this.refreshBestaende()
    }
  }

  removeVerfuegbarkeitsfilter() {
    this.projekteFormControl.setValue(null);

    this.artikelList.forEach(artikel => {
      if (artikel.mietartikel) {
        artikel['verfuegbar'] = undefined;
      }
    });
  }

  getVerfuegbarkeiten() {
    const artikelCodes: number[] = [];
    this.artikelList.forEach(artikel => {
      if (artikel.mietartikel) {
        artikelCodes.push(artikel.code);
      }
    });


    this.apiService.getVerfuegbarkeiten(artikelCodes, this.dateVon.value, this.dateBis.value, this.currentShopDefinition.orderDefinitions[0].formDefinition.bzObjType, verfuegbarkeiten => {
      // console.log({verfuegbarkeiten})
      this.artikelList.forEach(artikel => {
        if (artikel.mietartikel && verfuegbarkeiten[artikel.code] != undefined) {
          artikel['verfuegbar'] = verfuegbarkeiten[artikel.code];
          artikel;
        }
      });
      this.artikelList;
    }, error => {

    });

  }

  getArtikelBestand(event, art) {
    if (!art['shopBestellungohneBestand']) {
      if (art.mietartikel && art.verfuegbar != undefined) {
        if (event.target.value > art.verfuegbar) {
          event.target.value = art.verfuegbar;
          this.anzahlNewPosition = art.verfuegbar;
        }
      } else if (!art.mietartikel && art.bestand != undefined) {
        if (event.target.value > art.bestand) {
          event.target.value = art.bestand;
          this.anzahlNewPosition = art.bestand;
        }
      }
      if (this.anzahlNewPosition < 0) {
        this.anzahlNewPosition = 0
      }
    }

  }


  setEinheit(code) {
    if (this.einheiten.find(e => e.code == code))
      return this.einheiten.filter(element => {
        if (element.code === code && element) {
          return element;
        }
      })[0].name;
  }

  artikelListeSortieren(element) {
    const artikel = new List<Artikel>();
    artikel.AddRange(this.artikelList);

    switch (element.value) {
      case 0:
        artikel.OrderBy(a => a.nummer);
        this.artikelList = artikel.ToArray();
        break;

      case 1:
        artikel.OrderByDescending(a => a.nummer);
        this.artikelList = artikel.ToArray();
        break;

      case 2:
        artikel.OrderBy(a => a.name);
        this.artikelList = artikel.ToArray();
        break;

      case 3:
        artikel.OrderByDescending(a => a.name);
        this.artikelList = artikel.ToArray();
        break;

      case 4:
        artikel.OrderBy(a => {
          return a['verfuegbar'] != undefined ? a['verfuegbar'] : a['bestand'];
        });
        this.artikelList = artikel.ToArray();
        break;

      case 5:
        artikel.OrderByDescending(a => {
          return a['verfuegbar'] != undefined ? a['verfuegbar'] : a['bestand'];
        });
        this.artikelList = artikel.ToArray();
        break;

      case 6:
        artikel.OrderBy(a => a.euroNettopreis);
        this.artikelList = artikel.ToArray();
        break;

      case 7:
        artikel.OrderByDescending(a => a.euroNettopreis);
        this.artikelList = artikel.ToArray();
        break;

      default:
        break;
    }
  }
}

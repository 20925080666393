import { Component, OnInit } from '@angular/core';
import { ShopDefinition } from '../../classes/shop-definition';
import { ShopService } from '../../services/shop.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-veranstaltungen-page',
  templateUrl: './veranstaltungen-page.component.html',
  styleUrls: ['./veranstaltungen-page.component.scss']
})
export class VeranstaltungenPageComponent implements OnInit {

  currentShopDefinition: ShopDefinition
  constructor(protected shopService: ShopService, protected router:Router, protected route:ActivatedRoute) { }

  ngOnInit() {
    console.log("init", this)
    this.shopService.CurrentShopDefinition.subscribe(data => {
      this.currentShopDefinition = data
      if (this.currentShopDefinition != undefined && !this.currentShopDefinition.displayProjekte && this.route.url["value"][0].path == "veranstaltungen"){
        this.currentShopDefinition
        this.router.navigate(["/"])
      }
    })

  }

}

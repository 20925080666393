import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { FormComponent } from '../../classes/form-component';
import { FormComponentType } from '../../enums/form-component-type.enum';
import { ShopDefinition } from '../../classes/shop-definition';
import { FormDefinition } from '../../classes/form-definition';
import { ShopService } from '../../services/shop.service';
import { MatDialog } from '@angular/material';
import { VeranstaltungenComponent } from '../veranstaltungen/veranstaltungen.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Projekt, ObjectType, Position } from '@work4all/api';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-form-generator',
  templateUrl: './form-generator.component.html',
  styleUrls: ['./form-generator.component.scss']
})
export class FormGeneratorComponent implements OnInit {

  formDefinitionBackup: FormDefinition
  formDefinitionEdit: FormDefinition = null

  @Input() formDefinition: FormDefinition = new FormDefinition()
  @Input() editMode: boolean = false;
  @Input() fillMode: boolean = false;
  @Input() viewMode: boolean = false;
  @Input() positionen: Position[] = [];
  @Input() individualFieldType: string = "ERP";

  @Output() editModeChanged: EventEmitter<boolean> = new EventEmitter(this.editMode);
  @Output() formDefinitionUpdated: EventEmitter<FormDefinition> = new EventEmitter(null);
  @Output() formValidated: EventEmitter<FormDefinition> = new EventEmitter(null);

  loginMode: number = 0;
  individuelleFelder: any[] = [];

  currentShopDefinition: ShopDefinition;
  shopDefinitions: ShopDefinition[];

  ObjectType = ObjectType

  activeFormComponentIndex: number = null;

  formComponentNames: string[] = [
    "Textfeld",
    "Textfeld (mehrzeilig)",
    "Checkbox",
    "Optionsfeld",
    "Auswahlliste",
    "Datum",
    "Überschrift",
    "Text",
    "Trennlinie",
    "Zeilenumbruch",
    "Uhrzeit",
    "Lieferadresse",
    "Rechnungsadresse",
    "Liefer-/Rechnungsadresse",
    "Projekt",
    "Kostenstelle",
    "Notiz",
    "Niederlassung",
    "Individuelles Feld",
    "Lieferadressen-Auswahl",
    "Rechnungsadressen-Auswahl"
  ]

  FormComponentType = FormComponentType;
  newOptionText: string = "";

  kostenstellenList: any[] = [];
  niederlassungenList: any[] = [];

  projekteFormControl = new FormControl();
  projekteOptions = [];
  filteredProjekteOptions: Observable<Projekt[]>;

  lieferadressenFormControl = new FormControl();
  lieferadressenOptions = [];
  filteredLieferadressenOptions: Observable<any[]>;

  rechnungsadressenFormControl = new FormControl();
  rechnungsadressenOptions = [];
  filteredRechnungsadressenOptions: Observable<any[]>;

  kostenstellenFormControl = new FormControl();
  kostenstellenOptions = [];
  filteredKostenstellenOptions: Observable<any[]>;

  niederlassungenFormControl = new FormControl();
  niederlassungenOptions = [];
  filteredNiederlassungenOptions: Observable<any[]>;


  get loggedUser(): any {
    return this.apiService.getLoggedUser()
  }

  constructor(protected apiService: ApiService, protected shopService: ShopService, public dialog: MatDialog) {




  }

  fillNotiz() {

    let notizComponents: string[] = [];
    let notiz: string = "";

    switch (this.formDefinitionEdit.bzObjType) {
      case ObjectType.Auftrag: notizComponents.push("AUFTRAG"); break;
      case ObjectType.Angebot: notizComponents.push("ANGEBOT"); break;
      case ObjectType.Bedarfsanforderung: notizComponents.push("BEDARF"); break;
      case ObjectType.Bestellung: notizComponents.push("BESTELLUNG"); break;
      case ObjectType.Kalkulation: notizComponents.push("KALKULATION"); break;
      default: break;
    }

    if (this.positionen.length > 0) {
      notizComponents.push(this.positionen[0].langtext)
    }

    if (this.positionen.length > 1) {
      notizComponents.push("...")
    }


    notiz = notizComponents.join(" | ")

    this.formDefinitionEdit.components.forEach(component => {
      if (component.type == FormComponentType.Notiz && component.value == "") {
        component.value = notiz
      }
    })
  }

  assignNiederlassung(component, event) {
    component.value = event.option.value
    this.niederlassungenFormControl.setValue("")
  }

  assignKostenstelle(component, event) {
    component.value = event.option.value
    this.kostenstellenFormControl.setValue("")
  }

  assignProjekt(component, event) {
    component.value = event.option.value
    this.projekteFormControl.setValue("")
  }

  assignLieferadresse(component, event) {
    component.value = event.option.value
    this.lieferadressenFormControl.setValue("")

    this.apiService.getBriefanschrift(component.value.sdObjType, component.value.code, 0, true).then((briefadresse) => {
      component.value.lieferadresse = briefadresse
    })
  }

  assignRechnungsadresse(component, event) {
    component.value = event.option.value
    this.rechnungsadressenFormControl.setValue("")

    this.apiService.getBriefanschrift(component.value.sdObjType, component.value.code, 0, true).then((briefadresse) => {
      component.value.rechnungsadresse = briefadresse
    })
  }

  sortByName(a, b) {
    if (a.name < b.name)
      return -1;
    if (a.name > b.name)
      return 1;
    return 0;
  }

  getKostenstellenBySuchbegriff(data = this.kostenstellenFormControl.value) {
    //console.log(data);    



  }

  getNiederlassungenBySuchbegriff(data = this.niederlassungenFormControl.value) {
    //console.log(data);



  }

  getProjekteBySuchbegriff(data = this.projekteFormControl.value) {
    //console.log(data);
    this.apiService.getProjekteBySuchbegriff(data, data => {
      //console.log(data);
      if (data) {
        this.projekteOptions = data;

        this.filteredProjekteOptions = this.projekteFormControl.valueChanges
          .pipe(
            startWith<string | any>(''),
            map(value => (typeof value === 'string' || !value) ? value : value.name),
            map(name => name ? this.filterProjekte(name) : this.projekteOptions.slice())
          );
      }
    });
  }

  getLieferadressenBySuchbegriff(data = this.lieferadressenFormControl.value) {
    //console.log(data);
    if (!data)
      data = " "
    this.apiService.getKundenBySuchbegriff(data, data => {
      //console.log(data);
      if (data) {
        this.lieferadressenOptions = data;

        this.filteredLieferadressenOptions = this.lieferadressenFormControl.valueChanges
          .pipe(
            startWith<string | any>(''),
            map(value => (typeof value === 'string' || !value) ? value : value.name),
            map(name => name ? this.filterLieferadressen(name) : this.lieferadressenOptions.slice())
          );
      }
    });
  }

  getRechnungsadressenBySuchbegriff(data = this.rechnungsadressenFormControl.value) {
    //console.log(data);
    if (!data)
      data = " "
    this.apiService.getKundenBySuchbegriff(data, data => {
      //console.log(data);
      if (data) {
        this.rechnungsadressenOptions = data;

        this.filteredRechnungsadressenOptions = this.rechnungsadressenFormControl.valueChanges
          .pipe(
            startWith<string | any>(''),
            map(value => (typeof value === 'string' || !value) ? value : value.name),
            map(name => name ? this.filterRechnungsadressen(name) : this.rechnungsadressenOptions.slice())
          );
      }
    });
  }

  filterNiederlassungen(data: string): any[] {
    return this.niederlassungenOptions.filter(option =>
      option.name.toLowerCase().indexOf(data.toLowerCase()) != -1)
  }

  displayFnNiederlassungen(data?: any): string | undefined {
    return data ? data.name : undefined;
  }

  filterKostenstellen(data: string): any[] {
    return this.kostenstellenOptions.filter(option =>
      option.name.toLowerCase().indexOf(data.toLowerCase()) != -1 || option.nummer.toString().toLowerCase().indexOf(data.toLowerCase()) != -1)
  }

  displayFnKostenstellen(data?: any): string | undefined {
    return data ? data.name : undefined;
  }

  filterProjekte(data: string): Projekt[] {
    return this.projekteOptions.filter(option =>
      option.notiz.toLowerCase().indexOf(data.toLowerCase()) != -1 || option.nummer.toString().toLowerCase().indexOf(data.toLowerCase()) != -1);
  }

  displayFnProjekte(data?: Projekt): string | undefined {
    return data ? data.name : undefined;
  }


  filterLieferadressen(data: string): any[] {
    return this.lieferadressenOptions.filter(option =>
      option.notiz.toLowerCase().indexOf(data.toLowerCase()) != -1 || option.nummer.toString().toLowerCase().indexOf(data.toLowerCase()) != -1);
  }

  displayFnLieferadresse(data?: any): string | undefined {
    return data ? data.name : undefined;
  }


  filterRechnungsadressen(data: string): any[] {
    return this.rechnungsadressenOptions.filter(option =>
      option.notiz.toLowerCase().indexOf(data.toLowerCase()) != -1 || option.nummer.toString().toLowerCase().indexOf(data.toLowerCase()) != -1);
  }

  displayFnRechnungsadresse(data?: any): string | undefined {
    return data ? data.name : undefined;
  }


  individuellesFeldIsTaken(feld): boolean {
    let result: boolean = false;
    this.formDefinitionEdit.components.forEach((comp, componentIndex) => {
      if (comp.mapping != null && comp.mapping.code != undefined && feld.code == comp.mapping.code) {
        result = true
      }
    });

    return result;
  }

  getIndividualFieldInputType(feld) {
    let result = ""
    switch (feld.Control) {
      case "Numeric": result = "number"; break;
      case "Text": result = "text"; break;
      case "DateType": result = "date"; break;
      default: break;
    }
    return result
  }

  createIndiviudelleFelder() {

    this.apiService.getIndividuelleFelder(this.individualFieldType, data => {
      /*data.forEach((feld, feldIndex) => {
        if (feld.Control != "Numeric" && feld.Control != "Text" && feld.Control != "Check" && feld.Control != "DateType" && feld.Control != "Combo") {
          data.splice(feldIndex, 1)
        }
      })*/
      data.sort(this.orderByDisplayOrder)
      this.individuelleFelder = data
    }, error => {

    })
  }

  orderByDisplayOrder(a,b){
    if(a.displayOrder < b.displayOrder)
    return -1
    if(a.displayOrder > b.displayOrder)
    return 1

    return 0
  }

  clearFieldValues() {
    this.shopService.clearFormValues(this.formDefinitionEdit)
  }

  validateFields() {
    if (this.fillMode) {
      let valid: boolean = true;
      this.formDefinitionEdit.components.some((component, componentIndex) => {
        let fail: boolean = false;
        switch (component.type) {
          case FormComponentType.Checkbox: {
            if (component.mandatory && !component.value) {
              fail = true;
            }
            break;
          }
          //case FormComponentType.Projekt:
          case FormComponentType.Kostenstelle:
          case FormComponentType.Niederlassung: {
            if (component.mandatory && (component.value.code == undefined)) {
              //console.log("component.value", component.value)
              fail = true;
            }
            break;
          }
          case FormComponentType.Textfield:
          case FormComponentType.Textarea:
          case FormComponentType.Date:
          case FormComponentType.Select:
          case FormComponentType.Notiz: {
            if (component.mandatory && (component.value == "" || component.value === null)) {
              //console.log("component.value", component.value)
              fail = true;
            }
            break;
          }
          case FormComponentType.LieferRechnungsadresse: {
            if (component.value.rechnungsadresse.ansprechpartner == "" || component.value.rechnungsadresse.ansprechpartner == null) {
              fail = true;
            }
            if (component.value.rechnungsadresse.strasse == "" || component.value.rechnungsadresse.strasse == null) {
              fail = true;
            }
            if (component.value.rechnungsadresse.plz == "" || component.value.rechnungsadresse.plz == null) {
              fail = true;
            }
            if (component.value.rechnungsadresse.ort == "" || component.value.rechnungsadresse.ort == null) {
              fail = true;
            }
            if (!component.value.lieferadresseIdentischMitRechnungsadresse) {
              if (component.value.lieferadresse.ansprechpartner == "" || component.value.lieferadresse.ansprechpartner == null) {
                fail = true;
              }
              if (component.value.lieferadresse.strasse == "" || component.value.lieferadresse.strasse == null) {
                fail = true;
              }
              if (component.value.lieferadresse.plz == "" || component.value.lieferadresse.plz == null) {
                fail = true;
              }
              if (component.value.lieferadresse.ort == "" || component.value.lieferadresse.ort == null) {
                fail = true;
              }
            }
            break;
          }
          case FormComponentType.Lieferadresse: {
            if (component.value.lieferadresse.ansprechpartner == "" || component.value.lieferadresse.ansprechpartner == null) {
              fail = true;
            }
            if (component.value.lieferadresse.strasse == "" || component.value.lieferadresse.strasse == null) {
              fail = true;
            }
            if (component.value.lieferadresse.plz == "" || component.value.lieferadresse.plz == null) {
              fail = true;
            }
            if (component.value.lieferadresse.ort == "" || component.value.lieferadresse.ort == null) {
              fail = true;
            }
            break;
          }
          case FormComponentType.Rechnungsadresse: {
            if (component.value.rechnungsadresse.ansprechpartner == "" || component.value.rechnungsadresse.ansprechpartner == null) {
              fail = true;
            }
            if (component.value.rechnungsadresse.strasse == "" || component.value.rechnungsadresse.strasse == null) {
              fail = true;
            }
            if (component.value.rechnungsadresse.plz == "" || component.value.rechnungsadresse.plz == null) {
              fail = true;
            }
            if (component.value.rechnungsadresse.ort == "" || component.value.rechnungsadresse.ort == null) {
              fail = true;
            }
            break;
          }
          case FormComponentType.Individuell: {
            if (component.mandatory) {
              if (component.mapping != null && component.mapping.Control == "Text" && component.value == "") {
                fail = true;
              }
              if (component.mapping != null && component.mapping.Control == "Numeric" && component.value == "") {
                fail = true;
              }
              if (component.mapping != null && component.mapping.Control == "Check" && component.value == false) {
                fail = true;
              }
              if (component.mapping != null && component.mapping.Control == "Combo" && (component.value == "" || component.value == null)) {
                fail = true;
              }

            }
            break;
          }
          case FormComponentType.LieferadresseAuswahl:
          case FormComponentType.RechnungsadresseAuswahl: {
            if (component.mandatory) {
              if (component.value.code != undefined || component.value.code) {
                fail = true;
              }
            }
            break;
          }
          default: break;
        }
        if (fail) {
          valid = false
          return true
        }
      })

      if (valid) {
        this.formValidated.emit(this.formDefinitionEdit)
      }
      else {
        this.formValidated.emit(null)
      }
    }
  }


  moveComponentToPosition(componentIndex: number, newPositionIndex: number) {
    if (newPositionIndex >= this.formDefinitionEdit.components.length) {
      var k = newPositionIndex - this.formDefinitionEdit.components.length + 1;
      while (k--) {
        this.formDefinitionEdit.components.push(undefined);
      }
    }
    this.formDefinitionEdit.components.splice(newPositionIndex, 0, this.formDefinitionEdit.components.splice(componentIndex, 1)[0]);
    this.activeFormComponentIndex = newPositionIndex
  }

  getFormComponentTypeName(type: FormComponentType): string {
    return this.formComponentNames[type]
  }

  deleteOptionFromFormComponent(componentIndex, optionIndex) {
    this.formDefinitionEdit.components[componentIndex].options.splice(optionIndex, 1)
  }

  deleteFormComponent(componentIndex) {
    this.formDefinitionEdit.components.splice(componentIndex, 1)
  }

  addOptionToFormComponent(componentIndex, value) {
    this.formDefinitionEdit.components[componentIndex].options.push(value)
  }

  ngOnInit() {

    this.apiService.LoginMode.subscribe(data => {
      this.loginMode = data

      if (data != 0) {
        this.toggleEditMode(false)
      }
    })

    if (this.fillMode) {
      this.getProjekteBySuchbegriff("")
      this.getLieferadressenBySuchbegriff("")
      this.getRechnungsadressenBySuchbegriff("")
      this.getKostenstellenBySuchbegriff("")
      this.getNiederlassungenBySuchbegriff("")
    }


    this.formDefinitionEdit = new FormDefinition()
    $.extend(true, this.formDefinitionEdit, this.formDefinition)


    this.formDefinitionEdit.components.forEach(component => {


      if(component.mapping != undefined){

        if(component.mapping.Code == undefined){
          component.mapping.Code = component.mapping.code
        }
        if(component.mapping.Control == undefined){
          component.mapping.Control = component.mapping.control
        }
        if(component.mapping.DisplayOrder == undefined){
          component.mapping.DisplayOrder = component.mapping.displayOrder
        }
        if(component.mapping.FieldNumber == undefined){
          component.mapping.FieldNumber = component.mapping.fieldNumber
        }
        if(component.mapping.IndividualFieldData == undefined){
          component.mapping.IndividualFieldData = component.mapping.individualFieldData
        }
        if(component.mapping.IndividualPageCode == undefined){
          component.mapping.IndividualPageCode = component.mapping.individualPageCode
        }
        if(component.mapping.PflichtFeld == undefined){
          component.mapping.PflichtFeld = component.mapping.pflichtFeld
        }
        if(component.mapping.TableType == undefined){
          component.mapping.TableType = component.mapping.tableType
        }
        if(component.mapping.Title == undefined){
          component.mapping.Title = component.mapping.title
        }
        if(component.mapping.Tooltip == undefined){
          component.mapping.Tooltip = component.mapping.tooltip
        }

        if(component.mapping.Control == "DateType" && component.value != "" && component.value.indexOf("T") != -1){
          component.value = component.value.substring(0,component.value.indexOf("T"))
        }


      }
    });

    if (!this.viewMode) {
      this.validateFields()

      if (this.loggedUser.code != undefined) {
        this.createIndiviudelleFelder();
      }

      this.apiService.getKostenstellen(data => {
        data.sort(this.sortByName)
        this.kostenstellenOptions = data

        this.filteredKostenstellenOptions = this.kostenstellenFormControl.valueChanges
          .pipe(
            startWith<string | any>(''),
            map(value => typeof value === 'string' ? value : value.name),
            map(name => name ? this.filterKostenstellen(name) : this.kostenstellenOptions.slice())
          );
      })

      this.apiService.getLookup("Niederlassungen", data => {
        data.sort(this.sortByName)
        this.niederlassungenOptions = data

        this.filteredNiederlassungenOptions = this.niederlassungenFormControl.valueChanges
          .pipe(
            startWith<string | any>(''),
            map(value => (typeof value === 'string' || !value) ? value : value.name),
            map(name => name ? this.filterNiederlassungen(name) : this.niederlassungenOptions.slice())
          );
      })
    }

    if (this.fillMode == true) {
      //this.fillNotiz()
    }
  }

  getDimensionsClass(componentIndex): string {
    let result: string = "col-sm-12";
    let type = this.formDefinitionEdit.components[componentIndex].type
    if (type != FormComponentType.Divider && type != FormComponentType.Headline && type != FormComponentType.LineBreak) {
      result = "col-sm-" + this.formDefinitionEdit.components[componentIndex].dimensions
    }

    return result;
  }

  addFormComponent(type: FormComponentType) {
    let newComponent = new FormComponent(type)
    newComponent.dimensions = this.getFormComponentDimensions(type)

    if (type == FormComponentType.Kostenstelle) {
      newComponent.options = this.kostenstellenList
    }

    this.formDefinitionEdit.components.push(newComponent)
    this.activeFormComponentIndex = this.formDefinitionEdit.components.length - 1

  }

  addIndividualFormComponent(feld) {
    let newComponent = new FormComponent(FormComponentType.Individuell)

    if (feld.control == "Combo") {
      feld.title = feld.title.substring(0, feld.title.length - 3)
      newComponent.label = this.sanitizeIndividualFieldTitle(feld.title)
      newComponent.options = this.extractOptionsFromFieldTitle(feld.title)
      newComponent.options.splice(0, 1)
    }
    else {
      newComponent.label = feld.title
    }

    feld.title = this.extractOptionsFromFieldTitle(feld.title)[0]
    newComponent.mandatory = feld.pflichtFeld
    newComponent.dimensions = 4
    newComponent.mapping = feld
    this.formDefinitionEdit.components.push(newComponent)
    this.activeFormComponentIndex = this.formDefinitionEdit.components.length - 1
  }

  sanitizeIndividualFieldTitle(title: string): string {
    if (title.indexOf(";") != -1) {
      return title.substring(0, title.indexOf(";"))
    }
    else {
      return title
    }
  }

  extractOptionsFromFieldTitle(title: string): string[] {
    let result = title.split(/;;;[0-9]```/g)
    //console.log(result)
    return result
  }

  getFormComponentCount(type: FormComponentType): number {
    let result = 0;
    this.formDefinitionEdit.components.forEach(component => {
      if (component.type == type)
        result++
    })
    return result;
  }

  toggleEditMode(value = !this.editMode) {
    this.clearFieldValues()
    this.editMode = value;
    this.editModeChanged.emit(this.editMode)

    if (value) {
      this.formDefinitionBackup = new FormDefinition();
      $.extend(true, this.formDefinitionBackup, this.formDefinitionEdit)

    }
    else {

    }
  }

  getFormComponentEditorHeight(): number {
    return $(".form-component-editor").outerHeight() + 15;
  }

  cancelEditMode() {
    this.formDefinition = this.formDefinitionBackup
    //this.shopService.updateShopDefinition(this.currentShopDefinition)
    this.toggleEditMode()
  }


  updateShopDefinition() {
    //this.shopService.updateShopDefinition(this.currentShopDefinition)
    this.toggleEditMode()
    this.formDefinitionUpdated.emit(this.formDefinitionEdit)
  }

  changeFormComponentType(type) {

    /*if (type == FormComponentType.LieferRechnungsadresse) {
      this.formDefinition.components[this.activeFormComponentIndex].value = {
        rechnungsadresse: {
          firma: "",
          ansprechpartner: "",
          strasse: "",
          plz: "",
          ort: ""
        },
        lieferadresse: {
          firma: "",
          ansprechpartner: "",
          strasse: "",
          plz: "",
          ort: ""
        },
        lieferadresseIdentischMitRechnungsadresse: true
      }
    }
    else{
      this.formDefinition.components[this.activeFormComponentIndex].value = ""
    }*/

  }

  checkValidFieldType(feld) {
    let result = false
    if (feld.control == "List") {
      result = true
    }
    if (feld.control == "Text") {
      result = true
    }
    if (feld.control == "Combo") {
      result = true
    }
    if (feld.control == "Check") {
      result = true
    }
    if (feld.control == "DateType") {
      result = true
    }
    if (feld.control == "Options") {
      result = true
    }
    if (feld.control == "Numeric") {
      result = true
    }
    return result
  }

  getFormComponentDimensions(type: FormComponentType): number {
    let result: number = 12;
    switch (type) {
      case FormComponentType.Time:
      case FormComponentType.Date: {
        result = 2;
        break;
      }
      case FormComponentType.Textfield:
      case FormComponentType.Textarea:
      case FormComponentType.Select: {
        result = 6;
        break;
      }
      case FormComponentType.RadioButtonGroup:
      case FormComponentType.Checkbox: {
        result = 4;
        break;
      }
      default: {
        result = 12;
        break;
      }
    }

    return result
  }


  openProjektAuswahl(formComponentIndex: number): void {
    let dialogRef = this.dialog.open(VeranstaltungenComponent, {
      width: '1800px',
      autoFocus: false,
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(projekt => {
      if (projekt) {
        this.formDefinitionEdit.components[formComponentIndex].value = projekt
        this.validateFields()
      }
    });
  }

}

import { ExternalLoginRechte } from './external-login-rechte';
import { Ansprechpartner, Kunde } from '@work4all/api';

export class ManagedExternalLogin {
    loginID:string = null
    ansprechpartnerCode: number = 0;
    ansprechpartner: Ansprechpartner = null;
    ansprechpartnerAnzeigename: string = "";
    vorname: string = "";
    name: string = "";
    eMail: string = "";
    telefon: string = "";
    passwortHash: string = "";
    passwort: string = "";
    benutzername: string = "";
    master: boolean = false;
    mustChangePassword: boolean = false;
    aktiv: boolean = true;
    sdObjMemberCode: number = 0;
    kunde: Kunde = null;
    kundeName = ""
    kundeNummer = 0 
    anrede: string = ""
    new: boolean = false
    rechte: string = JSON.stringify(new ExternalLoginRechte());
    rechteParsed: ExternalLoginRechte = new ExternalLoginRechte();
    rechtBestellungenBearbeiten: boolean = false
    rechtBestellungenEinsehen: boolean = false
    rechtGenehmigungErteilen: boolean = false 
    rechtProjektErstellen: boolean = false 
    rechtProjektLoeschen: boolean = false 


    constructor() {
        //this.rechteParsed = new ExternalLoginRechte()
    }
}
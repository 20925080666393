<ng-container *ngIf="projektKategorieMode">
  <div class="p-default">
    <strong>VERANSTALTUNG ANGELEGT</strong>
    <div>
      Bitte wählen Sie noch mindestens eine der folgenden Kategorien:
    </div>
    <br>
    <ng-container *ngFor="let kategorie of projektKategorien">
      <div>
        <mat-checkbox [(ngModel)]="kategorie['active']">
          {{kategorie.name}}
        </mat-checkbox>
      </div>
    </ng-container>
  </div>
  <div class="p-default border-top"> 
    <div class="float-right">
      <button mat-button (click)="saveProjektKategorien()" class="" [disabled]="countActiveProjektKategorien() == 0">OK</button>
    </div>
    <div class="clearfix"></div>
  </div>
</ng-container>
<ng-container *ngIf="!projektKategorieMode">
  <div class="p-default">
    <div class="row">
      <div class="col-sm-12">
        <strong>VERANSTALTUNG</strong>

        <button *ngIf="favourite" mat-button class="narrow-btn float-right"
          style="position: absolute; right: 10px; top: 0px;">
          <i class="fas fa-star" style="color:#499ce0;"></i>
        </button>
        <button *ngIf="!favourite" mat-button class="narrow-btn float-right"
          style="position: absolute; right: 10px; top: 0px;">
          <i class="far fa-star"></i>
        </button>

        <!-- <button mat-button class="narrow-btn float-right" style="position: absolute; right: 10px; top: 0px;">
        <i class="far fa-star"></i>
      </button> -->

        <br>
        <br>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field>
              <input matInput placeholder="Bezeichnung" [disabled]="editMode" [(ngModel)]="projekt.name">
            </mat-form-field>

          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-sm-6">
                <mat-form-field>
                  <input matInput [matDatepicker]="pickerAnfang" placeholder="Beginn" [formControl]="dateAnfang">
                  <mat-datepicker-toggle matSuffix [for]="pickerAnfang"></mat-datepicker-toggle>
                  <mat-datepicker #pickerAnfang></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-sm-6">
                <mat-form-field>
                  <input matInput [matDatepicker]="pickerEnde" placeholder="Ende" [formControl]="dateEnde">
                  <mat-datepicker-toggle matSuffix [for]="pickerEnde"></mat-datepicker-toggle>
                  <mat-datepicker #pickerEnde [startAt]="pickerAnfang['value']"></mat-datepicker>
                </mat-form-field>
              </div>
              <!--<div class="col-sm-12">
                    <mat-form-field>
                      <input matInput type="text" placeholder="Projektleiter" [disabled]="editMode" [(ngModel)]="projekt.projektleiterExtern">
                    </mat-form-field>
                  </div>
          
                  <div class="col-sm-12">
                    <mat-form-field>
                      <input matInput type="text" placeholder="Projektleiter intern" [disabled]="editMode">
                    </mat-form-field>
                  </div>
                </div>-->
            </div>
          </div>
        </div>
        <!--<mat-form-field>
        <textarea matInput placeholder="Notiz" rows="5" [disabled]="editMode" [(ngModel)]="projekt.notiz"></textarea>
      </mat-form-field>-->
      </div>
      <div class="col-sm-6">

      </div>
    </div>

  </div>

  <!--<app-form-generator *ngIf="currentShopDefinition != undefined && currentShopDefinition" [fillMode]="true"
    [formDefinition]="currentShopDefinition.projekteFormDefinition" (formValidated)="validateForm($event)">
  </app-form-generator>-->

  <ng-container
    *ngIf="projektCode && currentShopDefinition.displayInfofensterCodes && currentShopDefinition.displayInfofensterCodes != undefined && currentShopDefinition.displayInfofensterCodes.length > 0">
    <div class="border-top p-default">
      <strong>Dokumente</strong>
      <br>
      <br>

      <ng-container *ngIf="dokumente != undefined && dokumente.length == 0">
        <em>Keine angefügt</em>
      </ng-container>
      <div class="clearfix"></div>
      <ng-container *ngIf="dokumente != undefined && dokumente.length > 0">
        <div class="row">
          <ng-container *ngFor="let dokument of dokumente">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 p-bottom-default">
              <mat-card>
                <div class="p-default pointer hover-button border-bottom"
                  (click)="downloadFile(dokument.code, dokument.notiz)">
                  <div class="one-liner">
                    <div class="float-left p-right-default"><i class="fas fa-paperclip"></i></div> {{dokument.notiz}}
                  </div>
                </div>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>



  </ng-container>

  <div class="p-default border-top" *ngIf="!editMode">
    <div class="float-right">
      <button mat-button class="" color="warn" *ngIf="currentShopDefinition && currentShopDefinition.projekteDeletable">
        Löschen
      </button>
      <button mat-button (click)="close()" class="">Schließen</button>
      <!--<button mat-button (click)="close()" class="" *ngIf="currentShopDefinition && currentShopDefinition.projektEditable">Abbrechen</button>-->
      <button mat-button (click)="save()" class="" [disabled]="!projekt.name || projekt.name.length == 0"
        *ngIf="currentShopDefinition && ((currentShopDefinition.projekteEditable && api.shopRechte.projektErstellen) || ( api.shopRechte.projektBearbeiten && currentShopDefinition.projekteInsertable && (projekt.code == undefined || !projekt.code)))">Speichern</button>
    </div>
    <div class="clearfix"> </div>
  </div>
</ng-container>
<div class="max-height" *ngIf="currentShopDefinition != undefined">
    <div class="absolute max-width">
        <app-header></app-header>
    </div>
    <div
        class="main-content max-height relative z-100 p-top-lg-70 p-top-md-70 p-bottom-lg-55 p-bottom-md-55 p-bottom-sm-55 p-bottom-xs-0">
        <div class="container max-height">
            <mat-card class="max-height fade-left y-scroll relative float-left"
                style="width:300px;margin:0 -15px;padding-right: 15px !important;background:whitesmoke"
                *ngIf="editPage">

                <div class="p-default border-bottom">
                    <button mat-raised-button class="float-left w-50" style="" (click)="editPage = false;rollback()">
                        <i class="fas fa-times"></i> Abbrechen
                    </button>
                    <button mat-raised-button class="float-left w-50" color="accent" (click)="editPage = false;save()">
                        <i class="fas fa-save"></i> Speichern
                    </button>
                    <div class="clearfix"></div>
                </div>

                <div class="" *ngIf="editComponentIndex != null">
                    <div class="p-default border-bottom">
                        <button mat-button class="float-right narrow-btn" style="    margin: -7.5px 0 0 0"
                            (click)="addPageComponent()">
                            <i class="fas fa-plus"></i>
                        </button>
                        <button mat-button class="float-right narrow-btn" style="margin: -7.5px 0 0 0"
                            (click)="deletePageComponent(editComponentIndex)"
                            *ngIf="currentShopDefinition.startseiteDefinition.components.length > 0">
                            <i class="fas fa-trash"></i>
                        </button>
                        <strong>
                            <ng-container *ngIf="currentShopDefinition.startseiteDefinition.components.length > 0">
                                {{getComponentTypeString(currentShopDefinition.startseiteDefinition.components[editComponentIndex].type)}}
                            </ng-container>
                            <ng-container *ngIf="currentShopDefinition.startseiteDefinition.components.length == 0">
                                Keine Komponenten vorhanden
                            </ng-container>
                        </strong>
                    </div>
                    <ng-container *ngIf="currentShopDefinition.startseiteDefinition.components.length > 0">
                        <mat-tab-group [selectedIndex]="selectedIndex">
                            <mat-tab label="Text"
                                *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].type == PageComponentType.ImageTextBox">
                                <div class="p-default">

                                    <label class="small">Ausrichtung</label><br>
                                    <button mat-button class="narrow-btn  float-left w-25" color="accent"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align == PageComponentContentAlign.Left">
                                        <i class="fas fa-align-left"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25"
                                        (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align = PageComponentContentAlign.Left"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align != PageComponentContentAlign.Left">
                                        <i class="fas fa-align-left"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25" color="accent"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align == PageComponentContentAlign.Center">
                                        <i class="fas fa-align-center"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25"
                                        (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align = PageComponentContentAlign.Center"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align != PageComponentContentAlign.Center">
                                        <i class="fas fa-align-center"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25" color="accent"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align == PageComponentContentAlign.Right">
                                        <i class="fas fa-align-right"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25"
                                        (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align = PageComponentContentAlign.Right"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align != PageComponentContentAlign.Right">
                                        <i class="fas fa-align-right"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25" color="accent"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align == PageComponentContentAlign.Justify">
                                        <i class="fas fa-align-justify"></i>
                                    </button>

                                    <button mat-button class="narrow-btn float-left w-25"
                                        (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align = PageComponentContentAlign.Justify"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.align != PageComponentContentAlign.Justify">
                                        <i class="fas fa-align-justify"></i>
                                    </button>
                                    <div class="clearfix"></div><br>
                                    <mat-form-field>
                                        <textarea matInput placeholder="Titel"
                                            [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.title"></textarea>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <textarea matInput placeholder="Text"
                                            [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.text"
                                            style="height:90px"></textarea>
                                    </mat-form-field>
                                </div>
                            </mat-tab>
                            <mat-tab label="Bild"
                                *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].type == PageComponentType.ImageTextBox">
                                <div class="p-default border-bottom">

                                    <mat-form-field>
                                        <textarea matInput placeholder="Bildquelle"
                                            [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.src"
                                            style="height:90px"></textarea>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <textarea matInput placeholder="Alternativtext"
                                            [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.alt"
                                            style="height:90px"></textarea>
                                    </mat-form-field>

                                </div>
                                <div class="p-default">

                                    <label class="small">Orientierung</label><br>
                                    <button mat-button class="narrow-btn  float-left w-25" color="accent"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation == PageComponentContentOrientation.ImageLeft">
                                        <i class="fas fa-arrow-left"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25"
                                        (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation = PageComponentContentOrientation.ImageLeft"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation != PageComponentContentOrientation.ImageLeft">
                                        <i class="fas fa-arrow-left"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25" color="accent"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation == PageComponentContentOrientation.ImageTop">
                                        <i class="fas fa-arrow-up"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25"
                                        (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation = PageComponentContentOrientation.ImageTop"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation != PageComponentContentOrientation.ImageTop">
                                        <i class="fas fa-arrow-up"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25" color="accent"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation == PageComponentContentOrientation.ImageBottom">
                                        <i class="fas fa-arrow-down"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25"
                                        (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation = PageComponentContentOrientation.ImageBottom"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation != PageComponentContentOrientation.ImageBottom">
                                        <i class="fas fa-arrow-down"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25" color="accent"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation == PageComponentContentOrientation.ImageRight">
                                        <i class="fas fa-arrow-right"></i>
                                    </button>

                                    <button mat-button class="narrow-btn  float-left w-25"
                                        (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation = PageComponentContentOrientation.ImageRight"
                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.orientation != PageComponentContentOrientation.ImageRight">
                                        <i class="fas fa-arrow-right"></i>
                                    </button>
                                    <div class="clearfix"></div>
                                    <br>

                                    <div class="row">
                                        <div class="col-sm-6">
                                            <mat-form-field class="">
                                                <mat-select
                                                    [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.imageWidth"
                                                    placeholder="Bildbreite">
                                                    <mat-option [value]="0">auto</mat-option>
                                                    <mat-option [value]="100">100 %</mat-option>
                                                    <mat-option [value]="84">84 %</mat-option>
                                                    <mat-option [value]="75">75 %</mat-option>
                                                    <mat-option [value]="66">66 %</mat-option>
                                                    <mat-option [value]="50">50 %</mat-option>
                                                    <mat-option [value]="33">33 %</mat-option>
                                                    <mat-option [value]="25">25 %</mat-option>
                                                    <mat-option [value]="16">16 %</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-sm-6">
                                            <mat-form-field>
                                                <input type="number" matInput placeholder="Textabstand (px)"
                                                    [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.imageMargin"
                                                    class="align-right">
                                            </mat-form-field>

                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Link">
                                <div class="p-default">
                                    <mat-form-field class="">
                                        <input matInput
                                            [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.link"
                                            placeholder="Linkadresse">
                                    </mat-form-field>
                                    <mat-form-field class="">
                                        <mat-select [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].content.target"
                                            placeholder="Ziel">
                                            <mat-option value="_self">Im selben Fenster öffnen</mat-option>
                                            <mat-option value="_blank">In einem neuen Fenster öffnen</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </mat-tab>
                            <mat-tab label="Darstellung">

                                <div class="p-default border-bottom">
                                    <label for="" class="small">Position ändern</label><br>
                                    <button mat-button class="float-left w-50"
                                        (click)="moveComponentUp(editComponentIndex)">
                                        <i class="fas fa-arrow-left"></i>
                                    </button>
                                    <button mat-button class="float-left w-50"
                                        (click)="moveComponentDown(editComponentIndex)">
                                        <i class="fas fa-arrow-right"></i>
                                    </button>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="p-default border-bottom">
                                    <label class="small">Responsive Einstellungen</label>
                                    <table width="100%">
                                        <tr>
                                            <td valign="middle" style="padding-right:7.5px">
                                                <i class="fas fa-desktop"></i>
                                            </td>
                                            <td valign="middle">
                                                <mat-form-field class="no-bottom">
                                                    <mat-select
                                                        [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].width.lg"
                                                        (selectionChange)="updateWidth(editComponentIndex)" placeholder="Breite">
                                                        <mat-option [value]="12">100 %</mat-option>
                                                        <mat-option [value]="10">84 %</mat-option>
                                                        <mat-option [value]="9">75 %</mat-option>
                                                        <mat-option [value]="8">66 %</mat-option>
                                                        <mat-option [value]="6">50 %</mat-option>
                                                        <mat-option [value]="4">33 %</mat-option>
                                                        <mat-option [value]="3">25 %</mat-option>
                                                        <mat-option [value]="2">16 %</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                            <td valign="middle">
                                                <button mat-button class="narrow-btn"
                                                    (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.lg = !currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.lg">
                                                    <i class="fas fa-eye"
                                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.lg"></i>
                                                    <i class="fas fa-eye-slash"
                                                        *ngIf="!currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.lg"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" style="padding-right:7.5px">
                                                <i class="fas fa-tablet-alt"></i>
                                            </td>
                                            <td valign="middle">
                                                <mat-form-field class="no-bottom">
                                                    <mat-select
                                                        [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].width.md"
                                                        (selectionChange)="updateWidth(editComponentIndex)" placeholder="Breite">
                                                        <mat-option [value]="12">100 %</mat-option>
                                                        <mat-option [value]="10">84 %</mat-option>
                                                        <mat-option [value]="9">75 %</mat-option>
                                                        <mat-option [value]="8">66 %</mat-option>
                                                        <mat-option [value]="6">50 %</mat-option>
                                                        <mat-option [value]="4">33 %</mat-option>
                                                        <mat-option [value]="3">25 %</mat-option>
                                                        <mat-option [value]="2">16 %</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                            <td valign="middle">
                                                <button mat-button class="narrow-btn"
                                                    (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.md = !currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.md">
                                                    <i class="fas fa-eye"
                                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.md"></i>
                                                    <i class="fas fa-eye-slash"
                                                        *ngIf="!currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.md"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" style="padding-right:7.5px">
                                                <i class="fas fa-tablet-alt"></i>
                                            </td>
                                            <td valign="middle">
                                                <mat-form-field class="no-bottom">
                                                    <mat-select
                                                        [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].width.sm"
                                                        (selectionChange)="updateWidth(editComponentIndex)" placeholder="Breite">
                                                        <mat-option [value]="12">100 %</mat-option>
                                                        <mat-option [value]="10">84 %</mat-option>
                                                        <mat-option [value]="9">75 %</mat-option>
                                                        <mat-option [value]="8">66 %</mat-option>
                                                        <mat-option [value]="6">50 %</mat-option>
                                                        <mat-option [value]="4">33 %</mat-option>
                                                        <mat-option [value]="3">25 %</mat-option>
                                                        <mat-option [value]="2">16 %</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                            <td valign="middle">
                                                <button mat-button class="narrow-btn"
                                                    (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.sm = !currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.sm">
                                                    <i class="fas fa-eye"
                                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.sm"></i>
                                                    <i class="fas fa-eye-slash"
                                                        *ngIf="!currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.sm"></i>
                                                </button>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="middle" style="padding-right:7.5px">
                                                <i class="fas fa-mobile-alt"></i>
                                            </td>
                                            <td valign="middle">
                                                <mat-form-field class="no-bottom">
                                                    <mat-select
                                                        [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].width.xs"
                                                        (selectionChange)="updateWidth(editComponentIndex)" placeholder="Breite">
                                                        <mat-option [value]="12">100 %</mat-option>
                                                        <mat-option [value]="10">84 %</mat-option>
                                                        <mat-option [value]="9">75 %</mat-option>
                                                        <mat-option [value]="8">66 %</mat-option>
                                                        <mat-option [value]="6">50 %</mat-option>
                                                        <mat-option [value]="4">33 %</mat-option>
                                                        <mat-option [value]="3">25 %</mat-option>
                                                        <mat-option [value]="2">16 %</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                            <td valign="middle">
                                                <button mat-button class="narrow-btn"
                                                    (click)="currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.xs = !currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.xs">
                                                    <i class="fas fa-eye"
                                                        *ngIf="currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.xs"></i>
                                                    <i class="fas fa-eye-slash"
                                                        *ngIf="!currentShopDefinition.startseiteDefinition.components[editComponentIndex].visible.xs"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="p-default border-bottom" (mouseenter)="marginPaddingHover = true"
                                    (mouseleave)="marginPaddingHover = false">
                                    <label for="" class="small">Padding / Innerer Abstand (px)</label><br>
                                    <div class="float-left w-25">
                                        <mat-form-field>
                                            <input type="number" matInput placeholder="Oben" min="0"
                                                [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].paddingTop"
                                                class="align-right">
                                        </mat-form-field>
                                    </div>
                                    <div class="float-left w-25">
                                        <mat-form-field>
                                            <input type="number" matInput placeholder="Rechts" min="0"
                                                [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].paddingRight"
                                                class="align-right">
                                        </mat-form-field>
                                    </div>
                                    <div class="float-left w-25">
                                        <mat-form-field>
                                            <input type="number" matInput placeholder="Unten" min="0"
                                                [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].paddingBottom"
                                                class="align-right">
                                        </mat-form-field>
                                    </div>
                                    <div class="float-left w-25">
                                        <mat-form-field>
                                            <input type="number" matInput placeholder="Links" min="0"
                                                [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].paddingLeft"
                                                class="align-right">
                                        </mat-form-field>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="p-default" (mouseenter)="marginPaddingHover = true"
                                    (mouseleave)="marginPaddingHover = false">
                                    <label for="" class="small">Margin / Äußerer Abstand (px)</label><br>
                                    <div class="float-left w-25">
                                        <mat-form-field>
                                            <input type="number" matInput placeholder="Oben"
                                                [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].marginTop"
                                                class="align-right">
                                        </mat-form-field>
                                    </div>
                                    <div class="float-left w-25">
                                        <mat-form-field>
                                            <input type="number" matInput placeholder="Rechts"
                                                [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].marginRight"
                                                class="align-right">
                                        </mat-form-field>
                                    </div>
                                    <div class="float-left w-25">
                                        <mat-form-field>
                                            <input type="number" matInput placeholder="Unten"
                                                [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].marginBottom"
                                                class="align-right">
                                        </mat-form-field>
                                    </div>
                                    <div class="float-left w-25">
                                        <mat-form-field>
                                            <input type="number" matInput placeholder="Links"
                                                [(ngModel)]="currentShopDefinition.startseiteDefinition.components[editComponentIndex].marginLeft"
                                                class="align-right">
                                        </mat-form-field>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </mat-tab>

                        </mat-tab-group>
                    </ng-container>
                </div>

            </mat-card>
            <mat-card class="max-height  y-scroll relative z-100"
                style="margin:0 -15px;  height:auto;  max-height: 100%;min-height:175px;">
                <div class=" p-default z-100 align-center" style="position:fixed;"
                    *ngIf="loggedUser != null && loggedUser.master && !editPage">
                    <button mat-raised-button color="accent" class="" style=""
                        (click)="editPage = true; editComponentIndex = 0;backup()">
                        <i class="fas fa-pen"></i> Seite bearbeiten
                    </button>
                </div>

                <div class="p-default" style="padding-bottom:0">
                    <div class="row" *ngIf="currentShopDefinition.startseiteDefinition">

                        <ng-container
                            *ngFor="let component of currentShopDefinition.startseiteDefinition.components;let componentIndex = index">

                            <a [href]="editPage? '#' : component.content.link" [target]="editPage? '_self' : component.content.target">
                                <div style="color:rgb(31, 31, 31)" class="page-component col-lg-{{component.width.lg}} col-md-{{component.width.md}} col-sm-{{component.width.sm}} col-xs-{{component.width.xs}}"
                                    [ngClass]="{'pointer':component.content.link.length > 0, 'align-left':component.content.align == PageComponentContentAlign.Left,'align-right':component.content.align == PageComponentContentAlign.Right,'align-center':component.content.align == PageComponentContentAlign.Center,'align-justify':component.content.align == PageComponentContentAlign.Justify, 'hidden-lg':component.visible.lg == false && !editPage, 'hidden-md':component.visible.md == false && !editPage, 'hidden-sm':component.visible.sm == false && !editPage, 'hidden-xs':component.visible.xs == false && !editPage}">
                                    <div class="relative" [style.marginTop.px]="component.marginTop"
                                        [style.marginBottom.px]="component.marginBottom"
                                        [style.marginRight.px]="component.marginRight"
                                        [style.marginLeft.px]="component.marginLeft"
                                        [style.paddingTop.px]="component.paddingTop"
                                        [style.paddingBottom.px]="component.paddingBottom"
                                        [style.paddingRight.px]="component.paddingRight"
                                        [style.paddingLeft.px]="component.paddingLeft"
                                        [style.borderTopWidth.px]="component.borderWidthTop"
                                        [style.borderRightWidth.px]="component.borderWidthRight"
                                        [style.borderBottomWidth.px]="component.borderWidthBottom"
                                        [style.borderLeftWidth.px]="component.borderWidthLeft"
                                        [style.borderColor.px]="component.borderColor"
                                        [style.backgroundColor.px]="component.backgroundColor">
                                        <ng-container *ngIf="component.type == PageComponentType.ImageTextBox">

                                            <ng-container
                                                *ngIf="component.content.src.length > 0 && component.content.orientation == PageComponentContentOrientation.ImageTop">
                                                <ng-container *ngIf="component.content.imageWidth == 0">
                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100"
                                                        [style.marginBottom.px]="component.content.imageMargin"
                                                        *ngIf="component.content.title.length > 0 || component.content.text.length > 0">
                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100"
                                                        *ngIf="component.content.title.length == 0 && component.content.text.length == 0">
                                                </ng-container>
                                                <ng-container *ngIf="component.content.imageWidth != 0">
                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100"
                                                        [style.width.%]="component.content.imageWidth"
                                                        [style.marginBottom.px]="component.content.imageMargin"
                                                        *ngIf="component.content.title.length > 0 || component.content.text.length > 0">
                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100"
                                                        [style.width.%]="component.content.imageWidth"
                                                        *ngIf="component.content.title.length == 0 && component.content.text.length == 0">
                                                </ng-container>

                                            </ng-container>

                                            <ng-container
                                                *ngIf="component.content.src.length > 0 && component.content.orientation == PageComponentContentOrientation.ImageRight">

                                                <ng-container *ngIf="component.content.imageWidth == 0">

                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100 float-right"
                                                        [style.marginLeft.px]="component.content.imageMargin"
                                                        *ngIf="component.content.title.length > 0 || component.content.text.length > 0">

                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100 float-right"
                                                        *ngIf="component.content.title.length == 0 && component.content.text.length == 0">

                                                </ng-container>
                                                <ng-container *ngIf="component.content.imageWidth != 0">

                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100 float-right"
                                                        [style.width.%]="component.content.imageWidth"
                                                        [style.marginLeft.px]="component.content.imageMargin"
                                                        *ngIf="component.content.title.length > 0 || component.content.text.length > 0">

                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100 float-right"
                                                        [style.width.%]="component.content.imageWidth"
                                                        *ngIf="component.content.title.length == 0 && component.content.text.length == 0">

                                                </ng-container>

                                            </ng-container>

                                            <ng-container
                                                *ngIf="component.content.src.length > 0 && component.content.orientation == PageComponentContentOrientation.ImageLeft">

                                                <ng-container *ngIf="component.content.imageWidth == 0">

                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100 float-left"
                                                        [style.marginRight.px]="component.content.imageMargin"
                                                        *ngIf="component.content.title.length > 0 || component.content.text.length > 0">

                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100 float-left"
                                                        *ngIf="component.content.title.length == 0 && component.content.text.length == 0">

                                                </ng-container>
                                                <ng-container *ngIf="component.content.imageWidth != 0">

                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100 float-left"
                                                        [style.marginRight.px]="component.content.imageMargin"
                                                        [style.width.%]="component.content.imageWidth"
                                                        *ngIf="component.content.title.length > 0 || component.content.text.length > 0">

                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100 float-left"
                                                        [style.width.%]="component.content.imageWidth"
                                                        *ngIf="component.content.title.length == 0 && component.content.text.length == 0">
                                                </ng-container>

                                            </ng-container>

                                            <div *ngIf="component.content.title.length > 0">
                                                <h2>{{component.content.title}}</h2>
                                            </div>
                                            <div *ngIf="component.content.text.length > 0"
                                                [innerHtml]="component.content.text">

                                            </div>

                                            <ng-container
                                                *ngIf="component.content.src.length > 0 && component.content.orientation == PageComponentContentOrientation.ImageBottom"> 
                                                <ng-container *ngIf="component.content.imageWidth == 0">

                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100"
                                                        [style.marginTop.px]="component.content.imageMargin"
                                                        *ngIf="component.content.title.length > 0 || component.content.text.length > 0">
                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100"
                                                        *ngIf="component.content.title.length == 0 && component.content.text.length == 0">

                                                </ng-container>
                                                <ng-container *ngIf="component.content.imageWidth != 0">

                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100"
                                                        [style.width.%]="component.content.imageWidth"
                                                        [style.marginTop.px]="component.content.imageMargin"
                                                        *ngIf="component.content.title.length > 0 || component.content.text.length > 0">

                                                    <img [src]="component.content.src" [alt]="component.content.alt"
                                                        class="max-width-100"
                                                        [style.width.%]="component.content.imageWidth"
                                                        *ngIf="component.content.title.length == 0 && component.content.text.length == 0">

                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                        <div class="page-component-padding page-component-padding-top"
                                            *ngIf="editPage && component.paddingTop != 0"
                                            [style.top.px]="component.paddingTop"
                                            [ngClass]="{'hover':marginPaddingHover}"></div>
                                        <div class="page-component-padding page-component-padding-right"
                                            *ngIf="editPage && component.paddingRight != 0"
                                            [style.right.px]="component.paddingRight"
                                            [ngClass]="{'hover':marginPaddingHover}"></div>
                                        <div class="page-component-padding page-component-padding-bottom"
                                            *ngIf="editPage && component.paddingBottom != 0"
                                            [style.bottom.px]="component.paddingBottom"
                                            [ngClass]="{'hover':marginPaddingHover}"></div>
                                        <div class="page-component-padding page-component-padding-left"
                                            *ngIf="editPage && component.paddingLeft != 0"
                                            [style.left.px]="component.paddingLeft"
                                            [ngClass]="{'hover':marginPaddingHover}"></div>
                                    </div>

                                    <div class="page-component-hitbox" *ngIf="editPage"
                                        (click)="editComponentIndex = componentIndex"
                                        [ngClass]="{'active':editComponentIndex == componentIndex}"></div>

                                    <div class="page-component-margin page-component-margin-top"
                                        *ngIf="editPage && component.marginTop != 0"
                                        [style.top.px]="component.marginTop" [ngClass]="{'hover':marginPaddingHover}">
                                    </div>
                                    <div class="page-component-margin page-component-margin-right"
                                        *ngIf="editPage && component.marginRight != 0"
                                        [style.right.px]="component.marginRight"
                                        [ngClass]="{'hover':marginPaddingHover}"></div>
                                    <div class="page-component-margin page-component-margin-bottom"
                                        *ngIf="editPage && component.marginBottom != 0"
                                        [style.bottom.px]="component.marginBottom"
                                        [ngClass]="{'hover':marginPaddingHover}"></div>
                                    <div class="page-component-margin page-component-margin-left"
                                        *ngIf="editPage && component.marginLeft != 0"
                                        [style.left.px]="component.marginLeft" [ngClass]="{'hover':marginPaddingHover}">
                                    </div>


                                    <div class="visible-lg absolute z-200"
                                        style="background:rgba(255, 255, 255, 0.8);top:0;left:7.5px;padding:3px 5px;"
                                        *ngIf="editPage && !component.visible.lg"
                                        title="Diese Komponente ist bei der aktuellen Bildschirmgröße ausgeblendet">
                                        <i class="fas fa-eye-slash"></i>
                                    </div>
                                    <div class="visible-md absolute z-200"
                                        style="background:rgba(255, 255, 255, 0.8);top:0;left:7.5px;padding:3px 5px;"
                                        *ngIf="editPage && !component.visible.md"
                                        title="Diese Komponente ist bei der aktuellen Bildschirmgröße ausgeblendet">
                                        <i class="fas fa-eye-slash"></i>
                                    </div>
                                    <div class="visible-sm absolute z-200"
                                        style="background:rgba(255, 255, 255, 0.8);top:0;left:7.5px;padding:3px 5px;"
                                        *ngIf="editPage && !component.visible.sm"
                                        title="Diese Komponente ist bei der aktuellen Bildschirmgröße ausgeblendet">
                                        <i class="fas fa-eye-slash"></i>
                                    </div>
                                    <div class="visible-xs absolute z-200"
                                        style="background:rgba(255, 255, 255, 0.8);top:0;left:7.5px;padding:3px 5px;"
                                        *ngIf="editPage && !component.visible.xs"
                                        title="Diese Komponente ist bei der aktuellen Bildschirmgröße ausgeblendet">
                                        <i class="fas fa-eye-slash"></i>
                                    </div>
                                </div>
                            </a>
                        </ng-container>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="absolute max-width z-100 hidden-xs" style="margin-top:-55px">
        <app-footer></app-footer>
    </div>
</div>
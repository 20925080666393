import * as tslib_1 from "tslib";
import { List } from 'linqts';
import { OnInit, OnDestroy } from '@angular/core';
import { AuthService, ObjectType, Position, Config, SendMailRequest, Projekt, Anhaenge, Ansprechpartner, Artikel } from '../../../../node_modules/@work4all/api';
import { OrderDefinition } from '../../classes/order-definition';
import { ArtikelDialogComponent } from '../artikel-dialog/artikel-dialog.component';
import { VeranstaltungAuswahlComponent } from '../veranstaltung-auswahl/veranstaltung-auswahl.component';
import { EditableType } from '../../enums/editable-type.enum';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { MailConfigurationDialogComponent } from '../../dialogs/mail-configuration-dialog/mail-configuration-dialog.component';
export class MeineBestellungenComponent {
    constructor(currencyFormat, authService, apiService, shopService, dialog, config, router, route) {
        this.currencyFormat = currencyFormat;
        this.authService = authService;
        this.apiService = apiService;
        this.shopService = shopService;
        this.dialog = dialog;
        this.config = config;
        this.router = router;
        this.route = route;
        this.subscribers = [];
        this.loggedUser = null;
        this.loading = false;
        this.orders = [];
        this.anhenge = [];
        this.tmpAnhaenge = [];
        this.deletedPositions = [];
        this.openOrderIndex = null;
        this.openOrderDefinition = null;
        this.editOpenOrder = false;
        this.neuePositionen = [];
        this.ObjectType = ObjectType;
        this.EditableType = EditableType;
        this.fieldsValidated = true;
        this.ansprechpartner = null;
        this.artikelGruppen = {
            code: 0,
            index: 0,
            level: 0,
            name: "",
            subGruppen: [],
        };
        this.artikleGroups = [];
        this.noRequiredGroup = false;
        this.requiredArtikleGroups = [];
        this.projekteFormControl = new FormControl();
        this.dateVon = new FormControl(moment());
        this.dateBis = new FormControl(moment().add(90, 'days'));
        this.artikelliste = [];
        this._apiService = this.apiService;
        this.firstLoaded = false;
        //openOrderCreatorLogin: ManagedExternalLogin = null
        this.searchText = "";
        this.currentBeleg = null;
        this.additionalBestaende = {};
        this.dataSource = null;
        this.projekt = null;
    }
    getFirstLine(value) {
        let result = value.split('|')[0];
        return result;
    }
    getAfterFirstLine(value) {
        let result = value.split('|');
        result.splice(0, 1);
        return result.join('|');
    }
    getOrderDefinitionById(id) {
        let result = null;
        this.currentShopDefinition.orderDefinitions.some((orderDefinition, orderDefinitionIndex) => {
            if (orderDefinition.id == id) {
                result = orderDefinition;
                return true;
            }
        });
        return result;
    }
    toggleGenehmigung(bzObject) {
        bzObject.shopGenehmigt = !bzObject.shopGenehmigt;
        bzObject.shopGenehmigtDatum = new Date();
        bzObject.shopGenehmigtDurchLoginID = this.apiService.loginId;
        this.saveOpenOrderChanges(bzObject.shopGenehmigt);
    }
    editAllowed() {
        let result = false;
        let beleg = this.currentBeleg;
        if (this.currentShopDefinition.orderDefinitions[0].editable == EditableType.EditableUntilProjektStart && beleg.projekt != undefined) {
            let daysBeforeEnd = this.currentShopDefinition.orderDefinitions[0].editableUntil;
            let endDate = new Date(beleg.projekt.anfangDatum);
            let today = new Date();
            today.setHours(0, 0, 0);
            var timeDiff = endDate.getTime() - today.getTime();
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            if (diffDays > daysBeforeEnd) {
                result = true;
            }
        }
        return result;
    }
    editAllowedList(order) {
        let result = false;
        let beleg = order;
        if (this.currentShopDefinition.orderDefinitions[0].editable == EditableType.EditableUntilProjektStart && beleg.projekt != undefined) {
            let daysBeforeEnd = this.currentShopDefinition.orderDefinitions[0].editableUntil;
            let endDate = new Date(beleg.projekt.anfangDatum);
            let today = new Date();
            today.setHours(0, 0, 0);
            var timeDiff = endDate.getTime() - today.getTime();
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            if (diffDays > daysBeforeEnd) {
                result = true;
            }
        }
        return result;
    }
    anhaengeUploaded(event) {
        this.tmpAnhaenge = (event);
    }
    saveOpenOrderChanges(genehmigung = null) {
        if (this.currentBeleg.code) {
            this.currentBeleg.positionen = this.currentBeleg.positionen.concat(this.deletedPositions);
        }
        this.deletedPositions = [];
        this.loading = true;
        if (this.currentBeleg.anhaenge == undefined) {
            this.currentBeleg.anhaenge = [];
        }
        this.currentBeleg.webShopOrderDefinitionData = this.currentShopDefinition.id;
        if (this.loggedUser) {
            this.currentBeleg.benutzerCode = this.currentBeleg.benutzerCode2 = this.loggedUser.code;
        }
        if (this.ansprechpartner && (this.currentBeleg.code == undefined || !this.currentBeleg.code)) {
            this.currentBeleg.createdByLoginId = this.ansprechpartner.loginId;
        }
        this.currentBeleg = this.shopService.fillBzObject(this.currentBeleg, this.openOrderDefinition.formDefinition);
        if (this.currentBeleg.projekt != undefined && this.currentBeleg.projekt && this.currentBeleg.projekt.vonDatum && this.currentBeleg.projekt.bisDatum) {
            this.currentBeleg.positionen.forEach((position) => {
                position.dispositionBegin = this.currentBeleg.projekt.vonDatum;
                position.dispositionEnde = this.currentBeleg.projekt.bisDatum;
            });
        }
        for (let i = 0; i < this.currentBeleg.positionen.length; i++) {
            const pos = this.currentBeleg.positionen[i];
            pos.index = i;
        }
        let wert = 0;
        this.currentBeleg.positionen.forEach(position => {
            if (!position.deleteOnUpdate)
                wert += position.anzahl * position.einzelpreis;
        });
        this.currentBeleg.wert = wert;
        setTimeout(() => {
            if (this.currentBeleg.code && this.currentBeleg.code != 0) {
                switch (this.currentBeleg.bzObjType) {
                    case ObjectType.Angebot:
                        this.apiService.updateAngebot(this.currentBeleg, () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            this.setAnhaenge();
                            let beleg = yield this.apiService.getAngebot(this.currentBeleg.code);
                            this.closeOpenOrder();
                            if (this.currentShopDefinition.notificationEdit)
                                yield this.sendConfirmationMail(beleg, genehmigung);
                        }), (err) => { console.log(err); });
                        break;
                    case ObjectType.Auftrag:
                        this.apiService.updateAuftrag(this.currentBeleg, () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            this.setAnhaenge();
                            let beleg = yield this.apiService.getAuftrag(this.currentBeleg.code);
                            this.closeOpenOrder();
                            if (this.currentShopDefinition.notificationEdit)
                                yield this.sendConfirmationMail(beleg, genehmigung);
                        }), (err) => { console.log(err); });
                        break;
                    case ObjectType.Bedarfsanforderung:
                        this.apiService.updateBedarf(this.currentBeleg, () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            this.setAnhaenge();
                            let beleg = yield this.apiService.getBedarf(this.currentBeleg.code);
                            this.closeOpenOrder();
                            if (this.currentShopDefinition.notificationEdit)
                                yield this.sendConfirmationMail(beleg, genehmigung);
                        }), (err) => { console.log(err); });
                        break;
                    case ObjectType.Bestellung:
                        this.apiService.updateBestellung(this.currentBeleg, () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            this.setAnhaenge();
                            let beleg = yield this.apiService.getBestellung(this.currentBeleg.code);
                            this.closeOpenOrder();
                            if (this.currentShopDefinition.notificationEdit)
                                yield this.sendConfirmationMail(beleg, genehmigung);
                        }), (err) => { console.log(err); });
                        break;
                    case ObjectType.Kalkulation:
                        this.apiService.updateKalkulation(this.currentBeleg, () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            this.setAnhaenge();
                            let beleg = yield this.apiService.getKalkulation(this.currentBeleg.code);
                            this.closeOpenOrder();
                            if (this.currentShopDefinition.notificationEdit)
                                yield this.sendConfirmationMail(beleg, genehmigung);
                        }), (err) => { console.log(err); });
                        break;
                    default:
                        this.closeOpenOrder();
                        break;
                }
            }
            else {
                this.currentBeleg.hauptadresseCompanyCode = this.shopService.getSdObjmemberCode();
                this.currentBeleg.hauptadresseCompanyType = this.shopService.getSdObjmemberType();
                this.currentBeleg.webShopOrderDefinitionData = this.currentShopDefinition.id;
                if (this.ansprechpartner)
                    this.currentBeleg.hauptadresseApCode = this.ansprechpartner.code;
                if (this.loggedUser) {
                    this.currentBeleg.benutzerCode = this.currentBeleg.benutzerCode2 = this.loggedUser.code;
                }
                if (this.ansprechpartner) {
                    this.currentBeleg.createdByLoginId = this.ansprechpartner.loginId;
                }
                switch (this.currentBeleg.bzObjType) {
                    case ObjectType.Angebot:
                        this.apiService.insertAngebot(this.currentBeleg, (code) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            let beleg = yield this.apiService.getAngebot(code);
                            this.setAnhaenge();
                            this.closeOpenOrder();
                            if (this.currentShopDefinition.notificationNew)
                                yield this.sendConfirmationMail(beleg, genehmigung);
                        }), (err) => { console.log(err); });
                        break;
                    case ObjectType.Auftrag:
                        this.apiService.insertAuftrag(this.currentBeleg, (code) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            let beleg = yield this.apiService.getAuftrag(code);
                            this.setAnhaenge();
                            this.closeOpenOrder();
                            if (this.currentShopDefinition.notificationNew)
                                yield this.sendConfirmationMail(beleg, genehmigung);
                        }), (err) => { console.log(err); });
                        break;
                    case ObjectType.Bedarfsanforderung:
                        this.apiService.insertBedarf(this.currentBeleg, (code) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            let beleg = yield this.apiService.getBedarf(code);
                            this.setAnhaenge();
                            this.closeOpenOrder();
                            if (this.currentShopDefinition.notificationNew)
                                yield this.sendConfirmationMail(beleg, genehmigung);
                        }), (err) => { console.log(err); });
                        break;
                    case ObjectType.Bestellung:
                        this.apiService.insertBestellung(this.currentBeleg, (code) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            let beleg = yield this.apiService.getBestellung(code);
                            this.setAnhaenge();
                            this.closeOpenOrder();
                            if (this.currentShopDefinition.notificationNew)
                                yield this.sendConfirmationMail(beleg, genehmigung);
                        }), (err) => { console.log(err); });
                        break;
                    case ObjectType.Kalkulation:
                        this.apiService.insertKalkulation(this.currentBeleg, (code) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            let beleg = yield this.apiService.getKalkulation(code);
                            this.setAnhaenge();
                            this.closeOpenOrder();
                            if (this.currentShopDefinition.notificationNew)
                                yield this.sendConfirmationMail(beleg, genehmigung);
                        }), (err) => { console.log(err); });
                        break;
                    default:
                        this.closeOpenOrder();
                        break;
                }
            }
        }, 200);
    }
    setAnhaenge() {
        this.tmpAnhaenge.forEach((anhang) => {
            anhang.bzobjMemberCode = this.currentBeleg.code;
            anhang.bzObjType = this.currentBeleg.bzObjType;
            this.apiService.saveAnhangPermanently(anhang, anh => {
                anh.bzObjType = this.currentBeleg.bzObjType;
                anh.bzobjMemberCode = anhang.bzobjMemberCode;
                anh.dateiname = anhang.dateiname;
                this.currentBeleg.anhaenge.push(anh);
            }, error => {
                console.log(error);
            });
        });
    }
    validateForm(formDefinition) {
        if (formDefinition != null) {
            this.openOrderDefinition.formDefinition = formDefinition;
            this.fieldsValidated = true;
        }
        else {
            this.fieldsValidated = false;
        }
    }
    cancelOpenOrderChanges() {
        this.shopService.setCurrentArtikelGruppen([]);
        this.requiredArtikleGroups = [];
        this.shopService.setCurrentBeleg(null);
        this.closeOpenOrder();
    }
    closeOpenOrder() {
        this.shopService.setCurrentArtikelGruppen([]);
        this.requiredArtikleGroups = [];
        this.shopService.setCurrentBeleg(null);
        this.openOrderIndex = null;
        this.editOpenOrder = false;
        this.currentBeleg = null;
        this.orders = [];
        this.loading = false;
        this.additionalBestaende = {};
        this.getOrders();
    }
    orderIsEditable() {
        let result = false;
        let orderDefinition = this.getOrderDefinitionById(this.openOrderDefinition.id);
        if (orderDefinition && orderDefinition.editable) {
            result = true;
        }
        if (this.apiService.loginId && this.apiService.loginId !== this.currentBeleg.createdByLoginId && !this.apiService.shopRechte.bestellungenBearbeiten) {
            result = false;
        }
        return result;
    }
    ngOnInit() {
        this.initComponent();
        /*this.router.events.subscribe((val) => {
          this.unsubscribeObservables()
        });*/
        this.subscribers.push(this.shopService.CurrentShopDefinition.subscribe(currentShopDefinition => {
            this.currentShopDefinition = currentShopDefinition;
            if (this.currentShopDefinition != undefined && !this.currentShopDefinition.displayBestellungen && this.route.url["value"][0].path == "bestellungen") {
                this.router.navigate(["/"]);
            }
            else {
                if (!this.firstLoaded && this.currentShopDefinition.orderDefinitions.length > 0) {
                    this.firstLoaded = true;
                    this.getOrders();
                }
            }
        }));
    }
    initComponent() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //await this.shopService.getShopDefinitions()
            yield this.shopService.setShopDefinition();
            if (localStorage.getItem("ansprechpartner") != undefined && localStorage.getItem("ansprechpartner") != null) {
                this.ansprechpartner = JSON.parse(localStorage.getItem("ansprechpartner"));
                this.kundenCode = this.ansprechpartner.kundenCode;
            }
            else {
                this.loggedUser = this.apiService.getLoggedUser();
                this.kundenCode = this.config.get("kundenCode");
            }
        });
    }
    sortByIndex(a, b) {
        if (a.index > b.index) {
            return 1;
        }
        if (a.index < b.index) {
            return -1;
        }
        // a muss gleich b sein
        return 0;
    }
    getFreigabeStatus() {
        let request = [];
        this.orders.forEach(bestellung => {
            let requestItem = {
                item1: bestellung.bzObjType,
                item2: bestellung.code
            };
            request.push(requestItem);
        });
        this.apiService.getFreigabeStatus(request, freigaben => {
            if ($.isArray(freigaben)) {
                freigaben.forEach((freigabeStatus, freigabeIndex) => {
                    this.orders.some((bestellung, bestellungIndex) => {
                        if (freigabeStatus.requestBzObjMemberCode == bestellung.code) {
                            bestellung.freigabeStatus = freigabeStatus;
                            return true;
                        }
                    });
                });
            }
        }, error => {
        });
    }
    getObjecTypeString(objType) {
        let result = this.apiService.getObjectTypeString(objType);
        if (result == "Bedarfsanforderung")
            result = "Bedarf";
        return result;
    }
    getAdditionalBelegData() {
        let projektCodes = [];
        this.orders.forEach(beleg => {
            if (beleg.projektCode) {
                projektCodes.push(beleg.projektCode);
            }
        });
        this.apiService.getProjekteByCodes(projektCodes, projekte => {
            if (projekte) {
                this.orders.forEach(beleg => {
                    projekte.some(projekt => {
                        if (beleg.projektCode == projekt.code) {
                            beleg.projekt = projekt;
                            beleg.projektName = projekt.name;
                            beleg.projektAnfangDatum = projekt.anfangDatum;
                            beleg.projektEndeDatum = projekt.endeDatum;
                            beleg.editable = this.editAllowedList(beleg);
                            return true;
                        }
                    });
                });
            }
            this.orders.forEach(beleg => {
                beleg.creator = this.getManagedExternalLoginByID(beleg.createdByLoginId);
                if (beleg.creator)
                    beleg.creatorAnzeigeName = beleg.creator.vorname + " " + beleg.creator.name;
            });
            this.dataSource = this.orders;
            this.loading = false;
        });
    }
    ngOnDestroy() {
    }
    unsubscribeObservables() {
        for (let sub of this.subscribers) {
            sub.unsubscribe();
        }
    }
    getManagedExternalLoginByID(id) {
        let result = this.shopService.getManagedExternalLoginByID(id);
        return result;
    }
    getOrders() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.shopService.getManagedExternalLogins();
            this.orders = [];
            let benutzerCode = null;
            if (this.loggedUser != null) {
                benutzerCode = this.loggedUser.code;
            }
            let orderDefinition = this.currentShopDefinition.orderDefinitions[0];
            if (orderDefinition.active) {
                this.loading = true;
                switch (orderDefinition.formDefinition.bzObjType) {
                    case ObjectType.Angebot: {
                        this.apiService.getAngeboteByBenutzerCode(benutzerCode, this.currentShopDefinition.id, data => {
                            data.forEach(bestellung => {
                                bestellung.positionen.sort(this.sortByIndex);
                            });
                            this.orders = data;
                            this.getAdditionalBelegData();
                        });
                        break;
                    }
                    case ObjectType.Auftrag: {
                        this.apiService.getAuftraegeByBenutzerCode(benutzerCode, this.currentShopDefinition.id, data => {
                            data.forEach(bestellung => {
                                bestellung.positionen.sort(this.sortByIndex);
                            });
                            this.orders = data;
                            this.getAdditionalBelegData();
                        });
                        break;
                    }
                    case ObjectType.Bedarfsanforderung: {
                        this.apiService.getBedarfsanforderungenByBenutzerCode(benutzerCode, this.currentShopDefinition.id, data => {
                            data.forEach(bestellung => {
                                bestellung.positionen.sort(this.sortByIndex);
                            });
                            this.orders = data;
                            this.getAdditionalBelegData();
                            this.getFreigabeStatus();
                        });
                        break;
                    }
                    case ObjectType.Bestellung: {
                        this.apiService.getBestellungenByBenutzerCode(benutzerCode, this.currentShopDefinition.id, data => {
                            data.forEach(bestellung => {
                                bestellung.positionen.sort(this.sortByIndex);
                            });
                            this.orders = data;
                            this.getAdditionalBelegData();
                            this.getFreigabeStatus();
                        });
                        break;
                    }
                    case ObjectType.Kalkulation: {
                        this.apiService.getKalkulationenByBenutzerCode(benutzerCode, this.currentShopDefinition.id, data => {
                            data.forEach(bestellung => {
                                bestellung.positionen.sort(this.sortByIndex);
                            });
                            this.orders = data;
                            this.getAdditionalBelegData();
                        });
                        break;
                    }
                    default: break;
                }
            }
        });
    }
    createNewBeleg() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            switch (this.currentShopDefinition.orderDefinitions[0].formDefinition.bzObjType) {
                case ObjectType.Angebot:
                    this.currentBeleg = yield this.apiService.createAngebotAsync(this.shopService.getSdObjmemberCode(), this.shopService.getSdObjmemberType());
                    break;
                case ObjectType.Auftrag:
                    this.currentBeleg = yield this.apiService.createAuftragAsync(this.shopService.getSdObjmemberCode(), this.shopService.getSdObjmemberType());
                    break;
                case ObjectType.Kalkulation:
                    this.currentBeleg = yield this.apiService.createKalkulationAsync(this.shopService.getSdObjmemberCode(), this.shopService.getSdObjmemberType());
                    break;
                case ObjectType.Bestellung:
                    this.currentBeleg = yield this.apiService.createBestellungAsync(this.shopService.getSdObjmemberCode(), this.shopService.getSdObjmemberType());
                    break;
                case ObjectType.Bedarfsanforderung:
                    this.currentBeleg = yield this.apiService.createBedarfAsync(this.shopService.getSdObjmemberCode(), this.shopService.getSdObjmemberType());
                    break;
                default: break;
            }
            return;
            //this.currentBeleg.individualFieldData = []
        });
    }
    proceedNewBestellung() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.createNewBeleg();
            this.openOrderIndex = 0;
            this.openOrderDefinition = new OrderDefinition("");
            $.extend(true, this.openOrderDefinition, this.currentShopDefinition.orderDefinitions[0]);
            this.openOrderDefinition.formDefinition = this.shopService.fillFormDefinition(this.currentBeleg, this.openOrderDefinition.formDefinition);
            this.editOpenOrder = true;
            this.currentBeleg.webShopOrderDefinitionData = this.currentShopDefinition.id;
            if (this.currentBeleg.bzObjType == ObjectType.Auftrag) {
                this.currentBeleg.auftragsDatum = new Date();
            }
            else {
                this.currentBeleg.datum = new Date();
            }
            this.currentBeleg.notiz = this.currentBeleg.hauptadresseText.substr(0, this.currentBeleg.hauptadresseText.indexOf("\r\n"));
            if (this.currentShopDefinition.displayProjekte) {
                this.openVeranstaltungAuswahlDialog();
            }
            else {
                this.openArtikelDialog();
            }
            this.loading = false;
        });
    }
    addOrder() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.shopService.loadGroupCodes();
            //this.shopService.loadCurrentPflichtartikelGruppen();
            this.requiredArtikleGroups = yield this.shopService.getCurrentPflichtartikelGruppen();
            this.loading = true;
            this.proceedNewBestellung();
        });
    }
    calculateGesamtpreis(positionen) {
        let result = 0;
        positionen.forEach(position => {
            if (position != null)
                result += position.einzelpreis * position.anzahl;
        });
        return result;
    }
    deletePosition(positionIndex) {
        let data = {
            title: "Achtung",
            message: "Möchten Sie die Position verbindlich löschen?",
            buttons: [{
                    title: "Abbrechen",
                    callback: () => {
                    }
                },
                {
                    title: "Löschen",
                    callback: () => {
                        this.currentBeleg.positionen[positionIndex].deleteOnUpdate = true;
                        this.deletedPositions.push(JSON.parse(JSON.stringify(this.currentBeleg.positionen[positionIndex])));
                        this.currentBeleg.positionen.splice(positionIndex, 1);
                        this.getArtikelGruppenFromBeleg();
                        this.shopService.setCurrentBeleg(this.currentBeleg);
                        //this.refreshBestaende();
                        this.hasRequiredArtikleGroup();
                    }
                }]
        };
        this.shopService.openGenericDialog(data);
    }
    updatePosition(positionIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            let beleg = this.currentBeleg;
            let position = beleg.positionen[positionIndex];
            let artikel = new List(this.artikelliste).FirstOrDefault(x => x.code == position.artikelCode);
            //let artikel: Artikel = await this.apiService.getArtikelByCodeAsync(position.artikelCode)
            if (artikel) {
                this.getArtikelBestand(position);
            }
            let artikelCode = position.artikelCode;
            this.apiService.createAuftragPosition(beleg, "", artikelCode, position.anzahl, (createdPosition) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.currentBeleg.positionen[positionIndex].deleteOnUpdate = true;
                this.deletedPositions.push(JSON.parse(JSON.stringify(this.currentBeleg.positionen[positionIndex])));
                this.currentBeleg.positionen.splice(positionIndex, 1, createdPosition);
                yield this.refreshBestaende();
                this.getArtikelBestand(this.currentBeleg.positionen[positionIndex]);
                this.loading = false;
            }));
        });
    }
    displayCurrencyFormat(value) {
        return this.shopService.displayCurrencyFormat(value);
    }
    createOrderDefinition() {
        if (this.currentBeleg.projekt != undefined && this.currentBeleg.projekt) {
            localStorage.setItem("w4aShopSelectedProjekt", JSON.stringify(this.currentBeleg.projekt));
        }
        this.openOrderDefinition = new OrderDefinition("");
        $.extend(true, this.openOrderDefinition, this.currentShopDefinition.orderDefinitions[0]);
        this.openOrderDefinition.formDefinition = this.shopService.fillFormDefinition(this.currentBeleg, this.openOrderDefinition.formDefinition);
    }
    checkForFormDefinition(input) {
        try {
            if (input.startsWith(`{"`)) {
                return true;
            }
            else {
                return false;
            }
        }
        catch (err) {
            return false;
        }
    }
    logout() {
        localStorage.removeItem("loginLevel");
        localStorage.removeItem("apiUrl");
        localStorage.removeItem("w4aShopWarenkorb");
        localStorage.removeItem("setting_ShopDefinition");
        this.authService.logout();
    }
    openArtikelDialog() {
        // this.shopService.loadCurrentPflichtartikelGruppen()
        let dialogRef = this.dialog.open(ArtikelDialogComponent, {
            width: '1570px',
            height: '90%',
            autoFocus: false,
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (result) {
                result.forEach(item => {
                    let found = false;
                    this.currentBeleg.positionen.some((position, positionindex) => {
                        if (position.artikelCode == item.artikel.code) {
                            this.apiService.createBelegPosition(this.currentBeleg, "", item.artikel.code, position.anzahl + item.menge, (createdPosition) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                this.currentBeleg.positionen[positionindex].deleteOnUpdate = true;
                                this.deletedPositions.push(JSON.parse(JSON.stringify(this.currentBeleg.positionen[positionindex])));
                                this.currentBeleg.positionen[positionindex] = createdPosition;
                                //this.shopService.setCurrentBeleg(this.currentBeleg)
                                yield this.getArtikelGruppenFromBeleg();
                                yield this.refreshBestaende();
                                this.getArtikelBestand(this.currentBeleg.positionen[positionindex]);
                                this.hasRequiredArtikleGroup();
                            }));
                            found = true;
                            return true;
                        }
                    });
                    if (!found) {
                        this.apiService.createBelegPosition(this.currentBeleg, "", item.artikel.code, item.menge, (createdPosition) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            if (item.nichtVerfuegbar == true) {
                                createdPosition.stattGPreis = "Auf Anfrage";
                            }
                            this.currentBeleg.positionen.push(createdPosition);
                            //this.shopService.setCurrentBeleg(this.currentBeleg)
                            this.artikelliste.push(item.artikel);
                            yield this.getArtikelGruppenFromBeleg();
                            this.shopService.cachingArtikleGroups(this.currentBeleg.positionen);
                            yield this.refreshBestaende();
                            this.getArtikelBestand(this.currentBeleg.positionen[this.currentBeleg.positionen.length - 1]);
                            this.hasRequiredArtikleGroup();
                        }));
                    }
                });
            }
        }));
    }
    openVeranstaltungAuswahlDialog() {
        let dialogRef = this.dialog.open(VeranstaltungAuswahlComponent, {
            autoFocus: false,
            disableClose: true,
            width: "1570px"
        });
        dialogRef.afterClosed().subscribe(projekt => {
            if (projekt != null) {
                localStorage.setItem("w4aShopSelectedProjekt", JSON.stringify(projekt));
                this.currentBeleg.projektCode = projekt.code;
                this.currentBeleg.projekt = projekt;
                this.currentBeleg.notiz += " | " + projekt.name;
                this.currentBeleg.kostenstelle = projekt.kostenStellenCode;
                this.currentBeleg.dispositionsbeginn = projekt.vonDatum;
                this.currentBeleg.dispositionsende = projekt.bisDatum;
                this.openArtikelDialog();
                console.log(this.currentBeleg);
            }
            else {
                this.closeOpenOrder();
            }
        });
    }
    searchByText() {
        this.dataGrid.instance.searchByText(this.searchText);
    }
    onSelectionChanged(e) {
        //this.openBeleg(e.data)
    }
    onRowClick(e) {
        this.openBeleg(e.data);
    }
    onRowDblClicked(e) {
        this.openBeleg(e.data);
    }
    getIndividualFieldData(bzObj, definitionCode) {
        return new List(bzObj.individualFieldData).FirstOrDefault(x => x.definitionCode == definitionCode);
    }
    replaceTextmarken(bzObj, bodyHtml) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (bzObj.projektCode) {
                let projekt = yield this.apiService.getProjektByCodeAsync(bzObj.projektCode);
                if (projekt) {
                    bodyHtml = bodyHtml.replace(/\[projekt.name\]/gi, projekt.name);
                    bodyHtml = bodyHtml.replace(/\[projekt.nummer\]/gi, projekt.nummer);
                    bodyHtml = bodyHtml.replace(/\[projekt.start\]/gi, moment(projekt.anfangDatum).format("DD.MM.YYYY"));
                    bodyHtml = bodyHtml.replace(/\[projekt.ende\]/gi, moment(projekt.endeDatum).format("DD.MM.YYYY"));
                }
            }
            console.log(this.currentShopDefinition.notificationStartText);
            this.currentShopDefinition.orderDefinitions[0].formDefinition.components.forEach(component => {
                let selector = '\\[bestellung.sonstigeAngaben.' + this.shopService.lowerCamelCase(component.label) + '\\]';
                if (component.mapping && (this.getIndividualFieldData(bzObj, component.mapping.Code) || this.getIndividualFieldData(bzObj, component.mapping.code))) {
                    let value = component.mapping.code != undefined ? this.getIndividualFieldData(bzObj, component.mapping.code).value : this.getIndividualFieldData(bzObj, component.mapping.Code).value;
                    if (component.mapping.Control == "DateType" || component.mapping.control == "DateType") {
                        value = moment(value).format("DD.MM.YYYY").toLocaleLowerCase() != "invalid date" ? moment(value).format("DD.MM.YYYY") : "--";
                    }
                    if (value == null || value == "null" || value.trim().length == 0) {
                        value = "--";
                    }
                    bodyHtml = bodyHtml.replace(new RegExp(selector, "gi"), value);
                }
            });
            if (bzObj.bzObjType == ObjectType.Auftrag) {
                bodyHtml = bodyHtml.replace(/\[bestellung.datum\]/gi, moment(bzObj.auftragsDatum).format("DD.MM.YYYY").toLocaleLowerCase() != "invalid date" ? moment(bzObj.auftragsDatum).format("DD.MM.YYYY") : "--");
                bodyHtml = bodyHtml.replace(/\[bestellung.nummer\]/gi, bzObj.auftragsNummer);
            }
            else {
                bodyHtml = bodyHtml.replace(/\[bestellung.datum\]/gi, moment(bzObj.datum).format("DD.MM.YYYY").toLocaleLowerCase() != "invalid date" ? moment(bzObj.datum).format("DD.MM.YYYY") : "--");
                bodyHtml = bodyHtml.replace(/\[bestellung.nummer\]/gi, bzObj.nummer);
            }
            bodyHtml = bodyHtml.replace(/\[bestellung.notiz\]/gi, bzObj.notiz);
            bodyHtml = bodyHtml.replace(/\[datum\]/gi, moment().format("DD.MM.YYYY"));
            if (localStorage.getItem("ansprechpartner")) {
                let ansprechpartner = JSON.parse(localStorage.getItem("ansprechpartner"));
                bodyHtml = bodyHtml.replace(/\[ansprechpartner.briefanrede\]/gi, ansprechpartner.briefanrede.trim().length > 0 ? ansprechpartner.briefanrede : "--");
                bodyHtml = bodyHtml.replace(/\[ansprechpartner.email\]/gi, ansprechpartner.eMail.trim().length > 0 ? ansprechpartner.eMail : "--");
                bodyHtml = bodyHtml.replace(/\[ansprechpartner.nachname\]/gi, ansprechpartner.name.trim().length > 0 ? ansprechpartner.name : "--");
                bodyHtml = bodyHtml.replace(/\[ansprechpartner.vorname\]/gi, ansprechpartner.vorname.trim().length > 0 ? ansprechpartner.vorname : "--");
                bodyHtml = bodyHtml.replace(/\[ansprechpartner.telefon\]/gi, ansprechpartner.telefon.trim().length > 0 ? ansprechpartner.telefon : "--");
                bodyHtml = bodyHtml.replace(/\[ansprechpartner.funktion\]/gi, ansprechpartner.funktion.trim().length > 0 ? ansprechpartner.funktion : "--");
                bodyHtml = bodyHtml.replace(/\[ansprechpartner.ort\]/gi, ansprechpartner.ort.trim().length > 0 ? ansprechpartner.ort : "--");
                bodyHtml = bodyHtml.replace(/\[ansprechpartner.plz\]/gi, ansprechpartner.plz.trim().length > 0 ? ansprechpartner.plz : "--");
                bodyHtml = bodyHtml.replace(/\[ansprechpartner.strasse\]/gi, ansprechpartner.strasse.trim().length > 0 ? ansprechpartner.strasse : "--");
                bodyHtml = bodyHtml.replace(/\[ansprechpartner.geburtsdatum\]/gi, moment(ansprechpartner.geburtsdatum).format("DD.MM.YYYY"));
            }
            return bodyHtml;
        });
    }
    sendConfirmationMail(bzObj, genehmigung = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let cssTH = "padding:3px 5px;border-bottom:1px solid silver;vertical-align:top;";
            let cssTD = "padding:3px 5px;border-bottom:1px solid silver;vertical-align:top;";
            let subject = this.currentShopDefinition.notificationSubject;
            let bodyHtml = this.currentShopDefinition.notificationStartText;
            if (genehmigung === true)
                bodyHtml = `<span style='background:#bee6be;      color: green;      padding: 15px;      border: 1px solid green;'>Genehmigung erteilt durch <strong>${this.ansprechpartner.vorname} ${this.ansprechpartner.name} </strong></span>` + bodyHtml;
            if (genehmigung === false)
                bodyHtml = `<span style='background: #ffeece;      color: #d88c00;      padding: 15px;      border: 1px solid #d88c00;'>Genehmigung aufgehoben durch <strong>${this.ansprechpartner.vorname} ${this.ansprechpartner.name} </strong></span>` + bodyHtml;
            subject = yield this.replaceTextmarken(bzObj, subject);
            bodyHtml = yield this.replaceTextmarken(bzObj, bodyHtml);
            let positionenHtml = " <table style='width:100%'><tr><th style='" + cssTH + "'>Nr.</th><th style='" + cssTH + "text-align:left;'>Bezeichnung</th><th style='" + cssTH + "'>Menge</th>";
            if (this.currentShopDefinition.displayPreiseInEMail) {
                positionenHtml += "<th style='" + cssTH + "text-align:right;'>Einzelpreis</th><th style='" + cssTH + "text-align:right;'>Gesamtpreis</th>";
            }
            positionenHtml += "</tr>";
            bzObj.positionen.forEach((position, positionIndex) => {
                if (!position.deleteOnUpdate) {
                    positionenHtml += "<tr><td style='" + cssTD + "text-align:center;'>" + (positionIndex + 1) + "</td><td  style='" + cssTD + " text-align:left;'>" + position.langtext + "</td><td style='" + cssTD + "text-align:center;'>" + position.anzahl + " " + position.einheit + "</td>";
                    if (this.currentShopDefinition.displayPreiseInEMail) {
                        positionenHtml += "<td style='" + cssTD + "text-align:right;'>" + this.currencyFormat.transform(position.einzelpreis) + " </td><td style='" + cssTD + "text-align:right;'>" + this.currencyFormat.transform(position.gesamtpreis) + " </td>";
                    }
                    positionenHtml += "</tr>";
                }
            });
            if (this.currentShopDefinition.displayPreiseInEMail) {
                positionenHtml += "<tr><th colspan='4'  style='" + cssTH + " text-align:right;'>Gesamt<br><small>zzgl. MwSt.</small></th><th style='" + cssTH + " text-align:right;'>" + this.currencyFormat.transform(this.calculateGesamtpreis(bzObj.positionen)) + "</th></tr>";
            }
            positionenHtml += `</table><br>`;
            bodyHtml = bodyHtml.replace(/\[bestellung.positionen\]/gi, positionenHtml);
            let mailrequest = new SendMailRequest();
            mailrequest.subject = subject;
            mailrequest.priority = "Normal";
            mailrequest.attachements = [];
            mailrequest.body = bodyHtml;
            mailrequest.bccRecipient = [];
            if (this.currentShopDefinition.notificationRecipientEmails.trim().length > 0) {
                mailrequest.bccRecipient = this.currentShopDefinition.notificationRecipientEmails.split(",");
                mailrequest.bccRecipient.forEach((email, emailIndex) => {
                    mailrequest.bccRecipient[emailIndex] = email.trim();
                });
            }
            mailrequest.bccRecipient.push(...yield this.openMailConfigDialog());
            if (this.loggedUser != null) {
                mailrequest.toRecipient = [this.loggedUser.eMail];
            }
            if (this.ansprechpartner != null) {
                mailrequest.toRecipient = [this.ansprechpartner.eMail];
            }
            mailrequest.senderMailbox = this.currentShopDefinition.notificationSenderEmail;
            if (!this.currentShopDefinition.notificationSenderEmail || this.currentShopDefinition.notificationSenderEmail.length == 0) {
                mailrequest.senderMailbox = null;
            }
            /*if (this.config.get("sendBackToSender")) {
              mailrequest.toRecipient = [this.config.get("senderEmail")]
            }*/
            this.apiService.sendMail(mailrequest, (success) => {
                this.clearFormValues();
                this.loading = false;
            }, (error) => {
                console.log(error);
            });
        });
    }
    clearFormValues() {
        this.currentShopDefinition.orderDefinitions.forEach((def, defIndex) => {
            this.shopService.clearFormValues(def.formDefinition);
        });
        this.shopService.clearWarenkorb();
    }
    openMailConfigDialog() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let dialogRef = this.dialog.open(MailConfigurationDialogComponent, {
                autoFocus: false,
                disableClose: true,
                width: "450px"
            });
            return dialogRef.afterClosed().toPromise();
        });
    }
    downloadAttachement(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var result = yield this.apiService.downloadFile("ErpAnhang", item.code, item.originalDateiname);
            return result;
        });
    }
    getAdditionalBestaende() {
        this.additionalBestaende = {};
        for (let position of this.currentBeleg.positionen) {
            if (position.artikelCode) {
                this.additionalBestaende[position.artikelCode] = position.anzahl;
            }
        }
    }
    openBeleg(beleg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = true;
            this.currentBeleg = yield this.apiService.getBelegByCode(beleg.bzObjType, beleg.code);
            this.getAdditionalBestaende();
            if (this.currentBeleg && this.currentBeleg.projektCode) {
                this.apiService.getProjekteByCodes([this.currentBeleg.projektCode], projekte => {
                    if (projekte && projekte.length == 1) {
                        this.currentBeleg.projekt = projekte[0];
                    }
                });
            }
            //this.openOrderIndex = orderIndex;
            this.createOrderDefinition();
            this.artikelliste = [];
            let artikelCodes = [];
            if (this.currentBeleg.positionen.length > 0) {
                artikelCodes = new List(this.currentBeleg.positionen).Select(x => x.artikelCode).ToArray();
                this.artikelliste = yield this.apiService.getArtikelByCodesAsync(artikelCodes);
                this.refreshBestaende();
            }
            //this.getVerfuegbarkeiten();
            this.shopService.setCurrentBeleg(this.currentBeleg);
            this.loading = false;
        });
    }
    getArtikelGruppenFromBeleg() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.artikleGroups = [];
            let artikelCodes = new List(this.currentBeleg.positionen).Select(x => x.artikelCode).ToArray();
            let artikelList = yield this.apiService.getArtikelByCodesAsync(artikelCodes);
            for (let artikel of artikelList) {
                this.artikleGroups.push(artikel.grCode);
            }
        });
    }
    hasRequiredArtikleGroup() {
        if (this.requiredArtikleGroups && this.artikleGroups)
            if (this.requiredArtikleGroups.every(r => this.artikleGroups.indexOf(r) > -1)) {
                this.noRequiredGroup = false;
            }
            else {
                this.noRequiredGroup = true;
            }
    }
    refreshBestaende() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let artikelCodes = new List(this.artikelliste).Select(x => x.code).ToArray();
            let data = yield this.apiService.getBestaendeAsync(artikelCodes);
            this.artikelliste.forEach((artikel, artikelIndex) => {
                let bestandList = data[artikel.code];
                let chargen = [];
                if (bestandList != undefined && bestandList) {
                    let gesamtBestand = 0;
                    for (let bestand of bestandList) {
                        gesamtBestand += bestand['bestand'];
                        chargen.push(...bestand.chargen);
                    }
                    artikel['bestand'] = gesamtBestand;
                    artikel['chargen'] = chargen;
                }
            });
            if (this.currentBeleg.projekt && this.currentBeleg.projekt != undefined) {
                let verfuegbarkeiten = yield this.apiService.getVerfuegbarkeitenAsync(artikelCodes, this.currentBeleg.projekt.anfangDatum, this.currentBeleg.projekt.endeDatum, this.currentShopDefinition.orderDefinitions[0].formDefinition.bzObjType);
                // console.log({verfuegbarkeiten})
                this.artikelliste.forEach(artikel => {
                    if (artikel.mietartikel && verfuegbarkeiten[artikel.code] != undefined) {
                        artikel['verfuegbar'] = verfuegbarkeiten[artikel.code];
                    }
                });
            }
        });
    }
    getArtikelBestand(position) {
        //let position = this.currentBeleg.positionen[index]
        let artikel = new List(this.artikelliste).FirstOrDefault(x => x.code == position.artikelCode);
        if (!artikel)
            return;
        let additionBestand = 0;
        if (this.additionalBestaende[artikel.code] != undefined)
            additionBestand = this.additionalBestaende[artikel.code];
        if (!artikel['shopBestellungohneBestand']) {
            if (artikel.mietartikel && artikel['verfuegbar'] != undefined) {
                if (position.anzahl > artikel['verfuegbar'] + additionBestand) {
                    position.anzahl = artikel['verfuegbar'] + additionBestand;
                }
            }
            else if (!artikel.mietartikel && artikel['bestand'] != undefined) {
                if (position.anzahl > artikel['bestand'] + additionBestand) {
                    position.anzahl = artikel['bestand'] + additionBestand;
                }
            }
        }
        if (position.anzahl < 0) {
            position.anzahl = 0;
        }
    }
}

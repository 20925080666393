import { Component, OnInit, ViewChild } from '@angular/core';
import { ExternalLogin } from 'src/app/classes/external-login';
import { ApiService } from 'src/app/services/api.service';
import { ObjectType, Kunde } from '@work4all/api';
import { DxDataGridComponent } from 'devextreme-angular';
import { ManagedExternalLogin } from 'src/app/classes/managed-external-login';
import { Md5 } from 'ts-md5/dist/md5';
import { ShopService } from 'src/app/services/shop.service';
import { SucheDialogObjectType } from 'src/app/enums/suche-dialog-object-type.enum';
import { SucheDialogData } from 'src/app/classes/suche-dialog-data';
import { ShopDefinition } from 'src/app/classes/shop-definition';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  @ViewChild("dataGrid", { static: false }) dataGrid: DxDataGridComponent
  dataSource: ManagedExternalLogin[] = []
  ObjectType = ObjectType
  loading: boolean = false;
  searchText: string = ""

  currentExternalLogin: ManagedExternalLogin = null
  _shopService = this.shopService

  currentShopDefinition: ShopDefinition = null
  ownUsername: string = null

  constructor(protected apiService: ApiService, protected shopService: ShopService) {
    this.shopService.CurrentShopDefinition.subscribe(data => {
      this.currentShopDefinition = data
    })
  }

  ngOnInit() {
    this.getExternalLogins()
  }

  async getExternalLogins() {
    this.loading = true
    this.dataSource = []

    this.dataSource = await this.apiService.getManagedExternalLogins()
    this.loading = false
  }

  searchByText() {
    this.dataGrid.instance.searchByText(this.searchText)
  }

  onSelectionChanged(e) {

  }

  onRowClick(e) {
let login: ManagedExternalLogin = e.data
    this.currentExternalLogin = login
    this.ownUsername = JSON.parse(JSON.stringify(this.currentExternalLogin.benutzername))
    if (this.currentExternalLogin.passwortHash == undefined || this.currentExternalLogin.passwortHash.length == 0) {
      this.currentExternalLogin.benutzername = login.eMail
      this.currentExternalLogin.passwort = ""
      this.currentExternalLogin.aktiv = true
      //this.currentExternalLogin.new = true
    }
    this.passwortConfirm = ""
  }

  async checkUsername(): Promise<boolean> {
    this.loading = true
    let usernameValid = await this.apiService.checkUsername(this.currentExternalLogin.benutzername)
    if (!usernameValid && this.currentExternalLogin.benutzername != this.ownUsername) {
      return false
    }
    return true
  }

  onRowDblClicked(e) {
    
  }


  async addUser() {

    let licenseStatus = await this.apiService.getLicenseStatus()
    if (licenseStatus.anzahlAktiveLogins >= licenseStatus.maximaleAktiveLogins) {
      let data = {
        title: "Achtung",
        message: "Sie haben die maximale Anzahl an aktiven Logins aufgebraucht. Bitte wenden Sie sich zwecks Aufstockung an Ihren Dienstleister.",
        buttons: [
          {
            title: "OK",
            callback: () => {
            }
          }]
      }
      this.shopService.openGenericDialog(data)
    }
    else {
      this.currentExternalLogin = new ManagedExternalLogin()
      this.currentExternalLogin.new = true
      this.currentExternalLogin.aktiv = true
      if (localStorage.getItem("sdObjMemberCode"))
        this.currentExternalLogin.sdObjMemberCode = JSON.parse(localStorage.getItem("sdObjMemberCode"))
      this.passwortConfirm = ""
    }
  }

  closeEditMode() {
    this.currentExternalLogin = null
    this.getExternalLogins()
  }

  passwortConfirm: string = ""

  savingDisabled() {
    return (
      this.currentExternalLogin.benutzername.length == 0 ||
      this.currentExternalLogin.name.length == 0 ||
      this.currentExternalLogin.vorname.length == 0 ||
      (this.currentExternalLogin.new && !this.passwordValid())
    )
  }

  passwordValid(): boolean {
    return (
      this.currentExternalLogin.passwort.length > 0 &&
      this.passwortConfirm == this.currentExternalLogin.passwort
    )
  }

  async saveExternalLogin() {
    //this.loading = true;
    if (this.currentExternalLogin.passwortHash.length == 0) {
      let licenseStatus = await this.apiService.getLicenseStatus()
      if (licenseStatus.anzahlAktiveLogins >= licenseStatus.maximaleAktiveLogins) {
        let data = {
          title: "Achtung",
          message: "Sie haben die maximale Anzahl an aktiven Logins aufgebraucht. Bitte wenden Sie sich zwecks Aufstockung an Ihren Dienstleister.",
          buttons: [
            {
              title: "OK",
              callback: () => {
              }
            }]
        }
        this.shopService.openGenericDialog(data)
        return
      }
    }

    let usernameValid: boolean = await this.checkUsername()
    if (!usernameValid) {
      let data = {
        title: "Achtung",
        message: "Der Benutzername ist bereits vergeben. Bitte wählen Sie einen anderen.",
        buttons: [
          {
            title: "OK",
            callback: () => {
            }
          }]
      }
      this.shopService.openGenericDialog(data)
      return
    }
    


      let hash: string = this.currentExternalLogin.passwortHash
      if (this.currentExternalLogin.passwort.length > 0)
        hash = <string>Md5.hashStr(this.currentExternalLogin.passwort)
      this.currentExternalLogin.passwort = undefined

      if (!this.currentExternalLogin.loginID) {
        this.currentExternalLogin.loginID = "00000000-0000-0000-0000-000000000000"
      }

      if (!this.currentExternalLogin.new) {
        this.currentExternalLogin.passwortHash = hash
        let result = await this.apiService.putManagedExternalLogin(this.currentExternalLogin)
      }
      else {
        this.currentExternalLogin.passwortHash = hash
        let result = await this.apiService.postManagedExternalLogin(this.currentExternalLogin)
      }

      this.closeEditMode() 
  }

  async openKundenSuche() {
    let kunde: Kunde = await this.shopService.openSucheDialog(new SucheDialogData(SucheDialogObjectType.Kunde))
    if (!kunde) return
    this.setKunde(kunde)

  }

  checkRights(mode: number) {
    switch (mode) {
      case 1: {
        if (!this.currentExternalLogin.rechteParsed.bestellungenEinsehen) {
          this.currentExternalLogin.rechteParsed.bestellungenBearbeiten = false
          this.currentExternalLogin.rechteParsed.genehmigungErteilen = false
          break;
        }
        break;
      }
      case 2:
      case 3: {
        if (this.currentExternalLogin.rechteParsed.bestellungenBearbeiten || this.currentExternalLogin.rechteParsed.genehmigungErteilen) {
          this.currentExternalLogin.rechteParsed.bestellungenEinsehen = true
        }
        break;
      }

      default: break;
    }
  }

  setKunde(kunde: Kunde) {
    this.currentExternalLogin.kunde = kunde
    this.currentExternalLogin.kundeName = kunde.name
    this.currentExternalLogin.kundeNummer = kunde.nummer
  }

}
